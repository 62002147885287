import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { PiArrowLineRight,PiTrash,PiSquaresFourFill, PiListDashesFill, PiPencilLineLight, PiPlusBold } from "react-icons/pi";

import {
    Checkbox,
    FormControl,
    FormLabel,
    useColorModeValue,
    Link,
    Modal, InputGroup, InputRightElement,
    ModalBody, 
    ModalCloseButton, 
    ModalContent, 
    ModalFooter, 
    ModalHeader, 
    ModalOverlay, 
    Stack, 
    useDisclosure ,

Box, Flex, Text, Image, Icon,
Button,
Input,

} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

  
import { useNavigate } from 'react-router-dom';



function Login() {
const [otpErrorMessage, setOtpErrorMessage] = useState('');
const [resetPasswordErrorMessage, setResetPasswordErrorMessage] = useState('');
  const [forgotEmail, setForgotEmail] = useState('');
  const [otpVisible, setOtpVisible] = useState(false);
  const [otp, setOtp] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);
  const { isOpen, onOpen, onClose } = useDisclosure();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // For navigation after login
  
    const handleLogin = async () => {
      await loginUser(email, password, setErrorMessage);
    };


    function getCookie(name) {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }
      return cookieValue;
  }
  

    const loginUser = async (email, password, setErrorMessage) => {
        const registrationData = {
          email: email,
          password: password,
        };
        const csrftoken = getCookie('csrftoken'); // A function to retrieve the CSRF token from cookies

        try {
            const loginResponse = await fetch('/api/login/', {
                method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrftoken,

            },
            body: JSON.stringify(registrationData),
            credentials: 'include',  // Ensure cookies are included in the request

          });
      
          if (loginResponse.ok) {
            const data = await loginResponse.json();
            console.log(data);
            localStorage.setItem('authToken', data.token); // Use localStorage for web
            localStorage.setItem('userPhoto', data.user_profile_photo_url); // Use localStorage for web
            localStorage.setItem('authenticated', 'True'); // Use localStorage for web
            setAuthenticated(true); // Update the authentication state

            if (!localStorage.getItem('redirectPath')) {
              localStorage.setItem('redirectPath', '/'); // Use localStorage for web
            }



            navigate(localStorage.getItem('redirectPath')); // Navigate to home after state update and successful login
            localStorage.removeItem('redirectPath');

            for (let i = 0; i < localStorage.length; i++) {
                // Get the key of the current item
                const key = localStorage.key(i);
                // If the key is not null, log the key and its value
                if (key) {
                  console.log(`${key}: ${localStorage.getItem(key)}`);
                }
              }            // Navigate to the user's profile or home screen
            // useHistory hook to programmatically navigate
          } else {
            const errorData = await loginResponse.json();
            let errorMessage = '';
            if (errorData.email) {
              errorMessage += `Email Error: ${errorData.email[0]}`;
            }
            if (errorData.password) {
              if (errorMessage) errorMessage += '\n';
              errorMessage += `Password Error: ${errorData.password[0]}`;
            }
      
            if (!errorMessage) {
              errorMessage = 'Invalid credentials. Please try again.';
            }
      
            console.log(errorMessage);
          }
        } catch (error) {
          console.log('An error occurred. Please try again later.');
        }
      };



      const handleForgotPassword = async () => {
        try {
            const response = await fetch('https://www.pursueli.com/api/forgot_password/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: forgotEmail }),
            });

            if (response.ok) {
                // Handle success (e.g., show a success message)
                setOtpVisible(true);
            } else {
              const errorData = await response.json();
              setErrorMessage(errorData.detail || 'Failed to send OTP.');
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
          setErrorMessage('An error occurred. Please try again later.');

            // Handle error (e.g., show an error message)
        }
    };
      
    const handleOTP = async () => {
      try {
          const response = await fetch('https://www.pursueli.com/api/handle_otp/', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ otp: otp, email: forgotEmail }),
          });

          if (response.ok) {
              // Handle success (e.g., show a success message)
              setPasswordVisible(true);

          } else {
              // Handle error (e.g., show an error message)
              const errorData = await response.json();
            setOtpErrorMessage(errorData.detail || 'Invalid OTP.');

          }
      } catch (error) {
          // Handle error (e.g., show an error message)
          setOtpErrorMessage('An error occurred. Please try again later.');

      }
  };


  const loginUserAfterPasswordReset = async (otp,forgotEmail, newPassword, newPassword2, setErrorMessage) => {

      if (newPassword !== newPassword2) {
        setResetPasswordErrorMessage('Passwords do not match.');
      } else {
        // Handle password submission
        console.log("Passwords match. Proceed with submission.");
      }

    const registrationData = {
      email: forgotEmail,
      password: newPassword,
      otp: otp,
    };
  
    try {
        const loginResponse = await fetch('https://www.pursueli.com/api/login_after_password_reset/', {
            method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });
  
      if (loginResponse.ok) {
        const data = await loginResponse.json();
        console.log(data);
        localStorage.setItem('authToken', data.token); // Use localStorage for web
        localStorage.setItem('userPhoto', data.user_profile_photo_url); // Use localStorage for web
        localStorage.setItem('authenticated', 'True'); // Use localStorage for web
        setAuthenticated(true); // Update the authentication state

        if (!localStorage.getItem('redirectPath')) {
          localStorage.setItem('redirectPath', '/'); // Use localStorage for web
        }
        navigate(localStorage.getItem('redirectPath')); // Navigate to home after state update and successful login
        localStorage.removeItem('redirectPath');
      } else {
        const errorData = await loginResponse.json();
        let errorMessage = '';
        if (errorData.email) {
          errorMessage += `Email Error: ${errorData.email[0]}`;
        }
        if (errorData.password) {
          if (errorMessage) errorMessage += '\n';
          errorMessage += `Password Error: ${errorData.password[0]}`;
        }
  
        if (!errorMessage) {
          const errorData = await loginResponse.json();
            setResetPasswordErrorMessage(errorData.detail || 'Invalid credentials. Please try again.');        }
  
        console.log(errorMessage);
      }
    } catch (error) {
      setResetPasswordErrorMessage('An error occurred. Please try again later.');
    }
  };
  

  return (
<Box paddingLeft={{ base: 5, lg: '10%' }} paddingRight={{ base: 5, lg: '10%' }}>


<Box minH="80vh" pt={'60px'} d="flex" alignItems="center"  justifyContent="center">
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          {/* <Image src="https://jobskistatic.s3.amazonaws.com/static/images/Outivity_Logo_Vertical_Gradient_Dark_Blue.png" height="100px" /> */}
          <Text fontSize={'lg'} fontWeight={500}>
            Sign in to your account
          </Text>
          <Text fontSize={'sm'} color={'gray.600'}>
            Don't have an account?{' '}
            <Link color={'blue.400'} href="/signup">
              Sign up
            </Link>
          </Text>
        </Stack>
        <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e) => setEmail(e.target.value)}  />
            </FormControl>
            <FormControl id="password">
              <FormLabel display={'flex'} justifyContent={'space-between'} >Password

              <Link fontSize={'xs'} onClick={onOpen} color={'gray.600'}>Forgot password?</Link>

              </FormLabel>
              <Input type="password" onChange={(e) => setPassword(e.target.value)}  />
            </FormControl>
            <Stack spacing={5} >

              <Button colorScheme={'blue'} variant={'solid'} onClick={handleLogin}>
                Sign in
              </Button>
            </Stack>
            {/* <Stack pt={6}>
              <Text align={'center'}>
                or continue with
              </Text>
              <Button leftIcon={<FaGoogle />} variant={'outline'} width="full" mt={2}>
                Google
              </Button>
              <Button leftIcon={<FaTwitter />} variant={'outline'} width="full" mt={2}>
                Twitter
              </Button>
              <Button leftIcon={<FaGithub />} variant={'outline'} width="full" mt={2}>
                GitHub
              </Button>
            </Stack> */}
          </Stack>
        </Box>
      </Stack>
    </Box>


    <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px)'
                />
                <ModalContent>
                    <ModalHeader>Forgot password?</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                    <div>
                    {!otpVisible && !passwordVisible && (
          <div>
          <Text color={'gray.600'} mt={'-15px'}>Enter the email that you used to register your account. We'll send you a one time password to reset your password </Text>
          <Input my={6} placeholder='name@email.com' type="email" value={forgotEmail} onChange={(e) => setForgotEmail(e.target.value)} />
          {errorMessage && <Text textAlign={'center'} mt={'-10px'} mb={3} color='red.500'>{errorMessage}</Text>}
          <ModalFooter mb={3} p={0}>
             <Button colorScheme="blue" w={'100%'} onClick={handleForgotPassword}>
                 Send OTP
             </Button>
         </ModalFooter>
     </div>
            )}


{otpVisible && !passwordVisible && (
  <div>

  <Text color={'gray.600'} mt={'-15px'}>Enter the OTP code that was just emailed to you.</Text>
       <Input my={6} placeholder='*******'
       id="otp"
       value={otp}
       onChange={(e) => setOtp(e.target.value)}
       />
      
      {otpErrorMessage && <Text mt={'-10px'} mb={3} textAlign={'center'} color='red.500'>{otpErrorMessage}</Text>}
       <ModalFooter mb={3} p={0}>
          <Button colorScheme="blue" w={'100%'} onClick={handleOTP}>
              Reset password
          </Button>
      </ModalFooter>

  </div>
            )}

              
        </div>

        {passwordVisible && (
                <div>

<Text color={'green'} mt={'-15px'}>Success! Create a new password below.</Text>

<Flex gap={4}>

<FormControl my={6} id="new-password" isRequired width={{ base: "100%", md: "48%" }}>
        <FormLabel>New password</FormLabel>
        <InputGroup>
          <Input
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
          />
          <InputRightElement h="full">
            <Button variant="ghost" onClick={toggleShowPassword}>
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>

      <FormControl my={6} id="confirm-password" isRequired width={{ base: "100%", md: "48%" }}>
        <FormLabel>Confirm password</FormLabel>
        <InputGroup>
          <Input
            value={newPassword2}
            onChange={(e) => setNewPassword2(e.target.value)}
            type={showPassword ? 'text' : 'password'}
          />
          <InputRightElement h="full">
            <Button variant="ghost" onClick={toggleShowPassword}>
              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>

           </Flex>

           {resetPasswordErrorMessage && <Text color='red.500'>{resetPasswordErrorMessage}</Text>}

                     <ModalFooter mb={3} p={0}>
                        <Button colorScheme="blue" w={'100%'}     onClick={() => loginUserAfterPasswordReset(otp,forgotEmail, newPassword, newPassword2, setErrorMessage)}>
                            Save password & sign in
                        </Button>
                    </ModalFooter>



                </div>
            )}

                    </ModalBody>
                </ModalContent>
            </Modal>


    </Box>
  );
}

    


export default Login;
