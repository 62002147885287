import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ComparisonChart = ({ labels, datasets, showLegend }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets,
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
            legend: {
              position: 'top',
            //   align: 'start',
            display: showLegend,
            
            },
          },
      },
    });

    return () => {
      myChart.destroy();
    };
  }, [labels, datasets]);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default ComparisonChart;
