import {
  Box,
  Button,
  Container,
  Heading,
  Select, Flex, Spinner,
  Stack, Img,
  Text,
  VStack, Grid,
  HStack,
  Divider
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiBriefcase, FiMapPin } from "react-icons/fi";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Card, Row, Col } from 'antd';

import { LuSchool } from "react-icons/lu";
import Carousel from '../../../components/carousel';


function OrgJobsListingPage() {
  
  let { slug } = useParams();
  const [jobs, setJobs] = useState([]);
  const [groupedJobs, setGroupedJobs] = useState({});
  const [location, setLocation] = useState('')
  const [schoolLogo, setSchoolLogo] = useState(null)
  const [org, setOrg] = useState(null)
  const [title, setTitle] = useState('')
  const [subtitle, setSubtitle] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {    
    // Assuming 'school' is the query parameter expected by your backend
    // and 'school' state contains the current selected school value.
    fetch(`https://www.pursueli.com/api/school_public_route_get_org_jobs_and_profile/?org=${slug}`, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      const jobsData = data.jobs;
      console.log(data)
      setOrg(data.org)
      setTitle(data.org.career_page_title)
      setSubtitle(data.org.career_page_subtitle)
      setLocation(data.location)
      const grouped = jobsData.reduce((acc, job) => {
          // Assuming job_group is an object with a name property
          // If job_group is nullable, make sure to handle that case as well
          const groupName = job.job_group ? job.job_group.name : 'Others';
          (acc[groupName] = acc[groupName] || []).push(job);
          return acc;
      }, {});

      setJobs(jobsData); // If you still need to setJobs separately
      setGroupedJobs(grouped);
      setSchoolLogo(data.logo)
      setLoading(false)
    })
    .catch(error => console.error("Error fetching jobs:", error));
  }, []);

  const [prevHover, setPrevHover] = useState(false);
  const [nextHover, setNextHover] = useState(false);

  const arrowStyle = (hover) => ({
      position: 'absolute',
      display:'none',
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '24px',
      color: hover ? '#fff' : 'rgba(255, 255, 255, 0.5)', // Light white turning to full white on hover
      background: hover ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.3)', // Darker background on hover
      border: 'none',
      borderRadius: '50%',
      padding: '10px',
      cursor: 'pointer',
      transition: 'all 0.3s ease', // Smooth transition for hover effect
    });
  
  
    const customPrevArrow = (clickHandler, hasPrev, label) => (
        <button
          type="button"
          onClick={clickHandler}
          aria-label={label}
          onMouseEnter={() => setPrevHover(true)}
          onMouseLeave={() => setPrevHover(false)}
          // style={arrowStyle(prevHover)}
          style={{
            left: 20,
            ...arrowStyle(prevHover), // Spread operator to merge styles
          }}
        >
          &#10094; {/* Left arrow symbol */}
        </button>
    );
  
    const customNextArrow = (clickHandler, hasNext, label) => (
      hasNext && (
        <button
          type="button"
          onClick={clickHandler}
          aria-label={label}
          onMouseEnter={() => setNextHover(true)}
          onMouseLeave={() => setNextHover(false)}
          style={{
            right: 20,
            ...arrowStyle(nextHover), // Spread operator to merge styles
          }}
        >
          &#10095; {/* Right arrow symbol */}
        </button>
      )
    );
  
    const fadeAnimationHandler = (props, state) => {
      const transitionTime = props.transitionTime + 'ms';
      const transitionTimingFunction = 'ease-in-out';

      let slideStyle = {
          position: 'absolute',
          display: 'block',
          zIndex: -2,
          minHeight: '100%',
          opacity: 0,
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          transitionTimingFunction: transitionTimingFunction,
          msTransitionTimingFunction: transitionTimingFunction,
          MozTransitionTimingFunction: transitionTimingFunction,
          WebkitTransitionTimingFunction: transitionTimingFunction,
          OTransitionTimingFunction: transitionTimingFunction,
      };

      if (!state.swiping) {
          slideStyle = {
              ...slideStyle,
              WebkitTransitionDuration: transitionTime,
              MozTransitionDuration: transitionTime,
              OTransitionDuration: transitionTime,
              transitionDuration: transitionTime,
              msTransitionDuration: transitionTime,
          };
      }

      return {
          slideStyle,
          selectedStyle: { ...slideStyle, opacity: 1, position: 'relative' },
          prevStyle: { ...slideStyle },
      };
  };

  const cardStyle = {
      width: '100%',
      transition: 'box-shadow 0.3s ease-in-out', // Smooth transition for box-shadow
    };
  
    const cardHoverStyle = {
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Elevated shadow on hover
    };
  
    
  return (
      <Box>
              
             <Container  bg="white"  color="black"   p={1}  position="sticky"   top={0}   zIndex={1}    boxShadow="md"   minW={'100%'}  py={2}>
          <Box mx={'auto'} maxW={{ base: '100%', md: '75%', lg: '70%' }}>
                  <Flex  
                  py={2} alignItems="center"  >
          {
          schoolLogo
              ? (
                  <Img
                  src={schoolLogo} // Replace with your logo path
                  alt="School Logo"
                  h="45px"
                  />
              )
              : (
                  <Box style={{ height: '45px'}}/>
              )
          }
                  {/* Add more elements here if needed */}
                  </Flex>
                  </Box>

              </Container>

              {loading ? (
              <Box></Box>
    ) : (
      <>       
      
      <Box>


              <Box display={{ base: '', md: 'flex', lg: 'flex' }} mt={5} templateColumns="repeat(2, 1fr)" gap={6}  mx={'auto'} maxW={{ base: '100%', md: '75%', lg: '70%' }}>


              <Box textAlign={'start'}  p={5} minW={{ base: '100%', md: '50%', lg: '40%' }} maxW={{ base: '100%', md: '50%', lg: '40%' }} >
              <Heading as='h3' size='xl' textAlign={'start'} mb={3}>{title}</Heading>
              <Text mb={5}>{subtitle}</Text>
              </Box>

              <Box textAlign={'start'}  p={5} minW={{ base: '100%', md: '50%', lg: '60%' }}  maxW={{ base: '100%', md: '50%', lg: '60%' }}>
              {org && org.media && org.media.length > 0 ? (


<Carousel photos={org.media} />
              ) : (
                  <div>
                  </div>
              )}
              </Box>
              </Box>
    

                  <Container py={5}  maxW={{ base: '100%', md: '75%', lg: '70%' }}>
                  <Heading mb={'-20px'} as='h2' size='lg' textAlign={'center'} >Job Openings</Heading>

                  <Flex display={'none'} justifyContent={'flex-start'} mt={0} direction={['column', 'row']} spacing={4} mb={10} align="center">
                  {/* <Select mr={5} placeholder="Department" width={['100%', 'auto']}>
                  </Select> */}
                  {/* <Select mr={5} placeholder="Location" width={['100%', 'auto']}>
                  {locations.map((location, index) => (
                  <option key={index} value={location}>{location}</option>
                  ))}
                  </Select> */}
                  <Select mr={5} placeholder="Team" width={['100%', 'auto']}>
                      {/* Options... */}
                  </Select>
                  <Select placeholder="Employment Type" width={['100%', 'auto']}>
                      {/* Options... */}
                  </Select>
                  </Flex>
          
          
                  {Object.entries(groupedJobs).map(([group, jobs]) => (
                  <Box py={3} key={group}>
                      <Text textAlign={'start'} fontWeight="bold" pt={6} fontSize={'18px'}  mb={4}>{group}</Text> {/* Render the group name */}
                      <Divider borderColor={'gray'} mt={'-8px'}  color={'gray'}/>
                      {  jobs.sort((a, b) => a.title.localeCompare(b.title)).map((job, index) => (

                      <HStack justify="space-between" w="full" py={4} key={index}>
                          <VStack align="start" spacing={0}>
                          <Text fontWeight="bold">{job.title}</Text>

                          <Text fontSize="sm" color="gray.600" display={'flex'} alignItems={'center'}>
                          <FiMapPin style={{marginRight:'5px'}} />
                              {location}</Text>
                          <Text fontSize="sm" color="gray.600" display={'flex'} alignItems={'center'}>
                          <FiBriefcase style={{marginRight:'5px'}} />
                              {job.employment_type}</Text>
                              <Text fontSize="sm" color="gray.600" display={'flex'} alignItems={'center'}>
                          <LuSchool style={{marginRight:'5px'}} />
                              {job.first_school_site.school_name}</Text>

                          </VStack>
                          
                          <Button colorScheme="blue" as={'a'}
                          href={`/job/${job.slug}/`}
                          >Apply</Button>
                      </HStack>
                      ))}
                  </Box>
                  
                  ))}
                  
              </Container>

              </Box>

</>
)}

              </Box>
  );
}

export default OrgJobsListingPage;

  