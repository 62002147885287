import { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [authenticated, setAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // For navigation after login

  const handleRegistration = async () => {
    await registerUser(email, firstName, lastName, password, setErrorMessage);
  };


  const loginUser = async (email, password) => {
    const registrationData = {
      email: email,
      password: password,
    };
  
    try {
        const loginResponse = await fetch('https://www.pursueli.com/api/login/', {
            method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });
  
      if (loginResponse.ok) {
        const data = await loginResponse.json();
        console.log(data);
        localStorage.setItem('authToken', data.token); // Use localStorage for web
        localStorage.setItem('userPhoto', data.user_profile_photo_url); // Use localStorage for web
        localStorage.setItem('authenticated', 'True'); // Use localStorage for web
        setAuthenticated(true); // Update the authentication state
        navigate('/upload_resume/'); // Navigate to home after state update and successful login
      } else {
        const errorData = await loginResponse.json();
        let errorMessage = '';
        if (errorData.email) {
          errorMessage += `Email Error: ${errorData.email[0]}`;
        }
        if (errorData.password) {
          if (errorMessage) errorMessage += '\n';
          errorMessage += `Password Error: ${errorData.password[0]}`;
        }
  
        if (!errorMessage) {
          errorMessage = 'Invalid credentials. Please try again.';
        }
  
        setErrorMessage(errorMessage);
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    }
  };




  const registerUser = async (email,firstName, lastName, password, setErrorMessage) => {
      const registrationData = {
        email: email,
        password: password,
        first_name: firstName,
        last_name:lastName,
      };
    
      try {
          const registerResponse = await fetch('https://www.pursueli.com/api/register/', {
              method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(registrationData),
        });
    
        if (registerResponse.ok) {
          const data = await registerResponse.json();
          console.log(data);
          loginUser(email, password)
          // localStorage.setItem('authToken', data.token); // Use localStorage for web
          // localStorage.setItem('userPhoto', data.user_profile_photo_url); // Use localStorage for web
          // localStorage.setItem('authenticated', 'True'); // Use localStorage for web
          // setAuthenticated(true); // Update the authentication state
          // navigate('/home'); // Navigate to home after state update and successful login
        } else {
          const errorData = await registerResponse.json();
          let errorMessage = '';
          if (errorData.email) {
            errorMessage += `Email Error: ${errorData.email[0]}`;
          }
          if (errorData.password) {
            if (errorMessage) errorMessage += '\n';
            errorMessage += `Password Error: ${errorData.password[0]}`;
          }
    
          if (!errorMessage) {
            errorMessage = 'Invalid credentials. Please try again.';
          }
    
          setErrorMessage(errorMessage);
        }
      } catch (error) {
        setErrorMessage('An error occurred. Please try again later.');
      }
    };




  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      // bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx="auto" maxW="lg" py={8} px={6}>
        <Stack align="center">
          <Heading fontSize="4xl" textAlign="center">
            Sign up
          </Heading>
          <Text fontSize="lg" color="gray.600">
            to create talent collective profile
          </Text>
        </Stack>
        <Box
          rounded="lg"
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow="lg"
          p={8}
        >
          <Stack spacing={4}>
            <HStack>
              <FormControl id="firstName" isRequired>
                <FormLabel>First Name</FormLabel>
                <Input type="text"
                onChange={(e) => setFirstName(e.target.value)}
                />
              </FormControl>
              <FormControl id="lastName">
                <FormLabel>Last Name</FormLabel>
                <Input type="text"
                onChange={(e) => setLastName(e.target.value)}
                />
              </FormControl>
            </HStack>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'} />
                <InputRightElement h="full">
                  <Button variant="ghost" onClick={toggleShowPassword}>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
              onClick={handleRegistration}
                loadingText="Submitting"
                size="lg"
                bg="blue.400"
                color="white"
                _hover={{
                  bg: 'blue.500',
                }}
              >
                Sign up
              </Button>
              <Text>{errorMessage}</Text>
            </Stack>
            <Stack pt={6}>
              <Text align="center">
                Already a user? <Link href="/login" color="blue.400">Login</Link>
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
