import React, { useState, useEffect, useRef } from 'react';
import { Box, Img, Flex,  Skeleton, Spinner,
    SkeletonCircle,  VStack, Container, Input, Spacer,
    SkeletonText, Stack, Grid,Card,Badge, GridItem, Text, Button, Image, Heading, Tag, Avatar, Divider, ChakraProvider, Link } from '@chakra-ui/react';

import { FiFolder, FiChevronRight, FiPlus } from 'react-icons/fi';
import { UploadSingleResume } from '../../../components/upload_resume';
import { FaLinkedin, FaEmail } from 'react-icons/fa';
import { FiMail, FiPhoneCall, FiMapPin } from "react-icons/fi";
import { PiArrowLineRight,PiTrash, PiPencilLineLight, PiPlusBold } from "react-icons/pi";
import { format, parseISO } from 'date-fns';
import Textarea from 'react-expanding-textarea'
import { FaPaperPlane } from "react-icons/fa6";
import Lottie from 'react-lottie';
import animationData from './json files/chat_bubbles.json'; // Path to your animation JSON
import { useLocation, useNavigate } from 'react-router-dom';



function AiInterview() {
    const navigate = useNavigate(); // For navigation after login


    const showAlert = () => {
        alert("The ability to create AI conversations is coming soon! In the meantime, please use this beta AI conversation to share your career goals and preferences. We'll use this information to match you with the best jobs in education.");
      };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
    
      // Function to render Lottie animation
      const renderLottieAnimation = () => (
        <Lottie options={defaultOptions} height={200} width={200} />
      );



const location = useLocation();

  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    if (!localStorage.getItem('authToken')) {
        localStorage.setItem('redirectPath', location.pathname); // Use localStorage for web
        navigate('/login');
      }
    // Fetch the upcoming events
    fetch("https://www.pursueli.com/api/my_profile/", {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
        }
      })          
      .then((response) => response.json())
      .then((data) => {
        console.log(data.user); // Add this line
        setUserData(data.user); // Update state with fetched data

      })
    .catch((error) => console.error("Error fetching upcoming events:", error));

  }, []);


const [inputValue, setInputValue] = useState('');
const message = {
    inputValue,
  };
  

const [aiTyping, setAiTypying] = useState(false);

const handleSubmit = async () => {
    const newMessage = { message: inputValue, from: userData.first_name}; // Adjust this object according to your needs and API expectations
    // Optimistically add the message to the UI
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInputValue('')
    setAiTypying(true)
    try {
      const response = await fetch(`https://www.pursueli.com/api/add_chat_message/`, {
        method: 'POST', // or 'DELETE'
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
            // Include other headers as required
          },
          body: JSON.stringify({
            message: inputValue, // Assuming 'inputValue' is your message text
            conversationId: conversationId // Make sure 'conversationId' is defined in your component's state or props
          })
      });

      if (response.ok) {
        const responseData = await response.json();  // Parse the JSON response once
        console.log(responseData['action']);  // Log the 'action'
        const updatedMessages = responseData['messages'];  // Access the 'messages'
        setMessages(updatedMessages); 
        console.log("success");
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to delete the event:", error);
      // Handle error
    }
    setAiTypying(false)
  };

  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState([]);

  const bottomRef = useRef(null); // Ref for scrolling into view
 
  
  useEffect(() => {
    const scrollToBottom = () => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };
  
    if (messagesLoaded) {
      scrollToBottom();
    }
  
    // Optional: If you have images or other media in messages,
    // consider adding an event listener for when they load and then call scrollToBottom.
  }, [messages,conversations, messagesLoaded]); 


  
  useEffect(() => {
    // Scroll the bottomRef into view whenever messages update
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]); // Dependency array includes messages so it runs every time messages change

  const [conversationId, setConversationId] = useState(null);

  const handleSend = () => {
    if (inputValue.trim() !== '') {
      setMessages([...messages, inputValue]);
      setInputValue('');
    }
  };


  useEffect(() => {
    // Fetch the upcoming events
    fetch("https://www.pursueli.com/api/get_chat_conversations/", {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
        }
      })          
      .then((response) => response.json())
      .then((data) => {
        console.log("hello")
        console.log(data.conversations); // Add this line
        // setPastEvents(data.past_things_to_do);
        setConversationId(data.conversation_id)
        setConversations(data.conversations)
        setMessages(data.messages); // Update state with fetched data
        setMessagesLoaded(true); // Indicate that messages are loaded


      })
    .catch((error) => console.error("Error fetching upcoming events:", error));

  }, []);


  const getMessagesForThisConversation = async (conversationId) => {
    setMessages([])
    setConversationId(conversationId)
    setMessagesLoaded(false)
    try {
        const response = await fetch(`https://www.pursueli.com/api/get_messages_for_conversation/${conversationId}/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json',
            }
        });
        if (response.ok) {
            const data = await response.json();
            setMessages(data.messages); // Update messages state with the fetched conversation messages
        } else {
            console.error(`Error fetching messages: ${response.status}`);
        }
    } catch (error) {
        console.error(`Error fetching conversation messages:`, error);
    }
    setMessagesLoaded(true)
};

useEffect(() => {
    if (messages.length === 0 && userData) {
        fetch("https://www.pursueli.com/api/get_first_chat/", {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json',
            }
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("hello");
                console.log(data.conversations);
                setConversationId(data.conversation_id);
                setConversations(data.conversations);
                setMessages(data.messages);
                setMessagesLoaded(true);
            })
            .catch((error) => console.error("Error fetching conversations:", error));
    }
}, [conversationId]);


  

  return (

<Box display={{ base: "", md: "flex" }}  width={'100%'} height="100vh" maxH={'95vh'} pt={10} maxW={{ base: "100%", md: "70%", lg: "95%" }}  >
  {/* Left sidebar */}
  <Box
    width={{ base: "100%", md: "20%", lg: "25%" }}
    p={4}
    pl={{ base: 4, md: 12}} 
    overflowY="auto"
    textAlign={'start'}
    sx={{
      '&::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
      },
      scrollbarWidth: 'none', // Hide scrollbar for Firefox
      msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
    }}
  >
    {/* Place sidebar content here, e.g., navigation links, user profile, etc. */}
    <Text display={'flex'} justifyContent={'space-between'} alignItems={'center'} fontSize="lg" fontWeight="bold" mb={4}>Conversations 
    <Button variant={'ghost'} size={'xs'} borderRadius={'50%'}
            onClick={showAlert}

    >
        <FiPlus/>
    </Button>
    </Text>

    {conversations.length > 0 && userData ? (
      <Box w="100%">
        {conversations.map((conversation, index) => (
          <Box key={conversation.id} mb={4}>
               <Link
                display={'flex'}
              as="a"
              onClick={() => getMessagesForThisConversation(conversation.id)}
              color={conversationId === conversation.id ? 'black' : 'gray'}
              _hover={{  color:'#3182ce' }}
              cursor="pointer"
            >
               {conversationId === conversation.id && (
              <FiChevronRight style={{ marginTop: 4, marginRight: 7 }} />
            )}

              {conversation.purpose.purpose}
            </Link>
          </Box>
        ))}
      </Box>
    ) : null}






  </Box>


  <Grid
      pl={{ base: 0, md: 10}} 

    maxH={'100%'}
    templateColumns="repeat(1, 1fr)" // Ensure content takes full width of the grid
    gap={6}
    w={{ base: "100%", md: "80%", lg: "65%" }} // Responsive width
    minH={{ base: "90vh", md: "50vh", lg: "40vh" }} // Responsive width
    overflowY={'scroll'}
    sx={{
      '&::-webkit-scrollbar': {
        display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
      },
      scrollbarWidth: 'none', // Hide scrollbar for Firefox
      msOverflowStyle: 'none', // Hide scrollbar for IE and Edge
    }}
  >
<Box>
  {!messagesLoaded ? (
    <Flex justifyContent="center" alignItems="center" height={'77vh'} >
      <Spinner size="md" />
    </Flex>
  ) : (
   <Box
   ></Box>
  )}
</Box>




    {messages.length > 0 && userData ? (
      <Box w="100%" px={4} minH={'75vh'} overflow={'scroll'}>
        {messages.map((message, index) => (
          <Box key={index} mb={6}>
            {message.from_ai === true ? (
              <Box>
                <Flex alignItems={'center'}>
                  <Img
                    width={'25px'}
                    height={'25px'}
                    borderRadius={'13px'}
                    p={0}
                    mr={2}
                    src="https://i.ibb.co/WGBRk70/Edpursuit-Logomark-Dark-Grey.png"
                  />
                  <Text fontWeight={'bold'}>edpursuit AI</Text>
                </Flex>
                <Text
                  textAlign={'start'}
                  pt={1}
                  borderRadius="lg"
                  w="fit-content"
                >
                  {message.message}
                </Text>
              </Box>
            ) : (
              <Box>
                <Flex alignItems={'center'}>
                  <Avatar
                    width={'25px'}
                    height={'25px'}
                    borderRadius={'13px'}
                    p={0}
                    mr={2}
                    src={userData.photo}
                  />
                  <Text fontWeight={'bold'}>you</Text>
                </Flex>
                <Text
                  textAlign={'start'}
                  pt={1}
                  borderRadius="lg"
                  w="fit-content"
                >
                  {message.message}
                </Text>
              </Box>
            )}
            <Box ref={bottomRef} />
          </Box>

        ))}


{
  aiTyping && (
    <Box mb={'-20px'}>
      <Box>
        <Flex alignItems={'center'}>
          <Img
            width={'25px'}
            height={'25px'}
            borderRadius={'13px'}
            p={0}
            mr={2}
            src="https://i.ibb.co/WGBRk70/Edpursuit-Logomark-Dark-Grey.png"
          />
          <Text fontWeight={'bold'}>edpursuit AI</Text>
        </Flex>
        <Text
          textAlign={'start'}
          pt={1}
          borderRadius="lg"
          w="fit-content"
        >
          <Lottie options={defaultOptions} height={60} width={150} style={{position:'relative', left:'-50px'}} />
        </Text>
      </Box>
    </Box>
  )
}

      </Box>
    ) : 
    <Box 
    height={messagesLoaded === true ? '75vh' : ''}
    >
        {/* IIf this code is loaded send request to backend */}
    <Box>
      <Box>
        <Flex alignItems={'center'}>
          <Img
            width={'25px'}
            height={'25px'}
            borderRadius={'13px'}
            p={0}
            mr={2}
            src="https://i.ibb.co/WGBRk70/Edpursuit-Logomark-Dark-Grey.png"
          />
          <Text fontWeight={'bold'}>edpursuit AI</Text>
        </Flex>
        <Text
          textAlign={'start'}
          pt={1}
          borderRadius="lg"
          w="fit-content"
        >
          <Lottie options={defaultOptions} height={60} width={150} style={{position:'relative', left:'-50px'}} />
        </Text>
      </Box>
    </Box>
    </Box>
    }

    {/* Input field at the bottom */}
    <Grid
      position={'sticky'}
      bottom={'0px'}
      templateColumns="repeat(1, 1fr)"
      paddingLeft={'10px'}
      w={{ base: '100%' }}
      mx="auto"
    >
      <Flex alignItems={'flex-end'} bg={'white'}>
        <Textarea
          style={{
            padding: '7px',
            backgroundColor: 'gray.100',
            borderWidth: '2px',
            borderColor: 'blue.500',
            width: '92%',
            _hover: {
              borderColor: 'green.500',
            },
            _focus: {
              boxShadow: '0 0 0 2px #3182ce',
            },
            borderRadius: '5px',
          }}
          rows={'1'}
          placeholder="Type your message here..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Box paddingLeft={'10px'} paddingRight={'10px'}>
          <Button colorScheme="blue" onClick={handleSubmit} isDisabled={aiTyping}>
            <FaPaperPlane />
          </Button>
        </Box>
      </Flex>

      <Box height={'40px'} bg={'white'} mt={0} pt={0}>


      </Box>
      
    </Grid>
  </Grid>
</Box>

  );
}

export default AiInterview;
