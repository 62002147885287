import React, { useState, useCallback } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure,
    Avatar,
    HStack,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Input,
  } from '@chakra-ui/react'
const containerStyle = {
  width: '400px',
  height: '40px',
  position:'relative'
};



  
const center = {
  lat: -3.745,
  lng: -38.523
};

const libraries = ["places"];


function GoogleMapComponent({handleLocationSetting, handleCityAndState}) {
    

    const [location, setLocation] = useState('')
    const [isFocused, setIsFocused] = useState(false);
    const inputStyle = {
    width: '100%',
    height: '2.5rem',
    fontSize: '1rem',
    paddingInlineStart: '1rem',
    paddingInlineEnd: '1rem',
    borderRadius: '0.375rem',  // equivalent to Chakra's md radius
    outline: '2px solid transparent',
    outlineOffset: '2px',
    position: 'relative',
    border:  isFocused ? '2px solid': '1px solid', 
    borderColor: isFocused ? '#3182ce' : '#E2E8F0',
    boxShadow: isFocused ? '0 0 0 1px var(#3182ce)' :'',
      display: 'block',
    background: 'white',  // Default background, replace as needed
    transitionProperty: 'common',  // Replace with standard CSS values if needed
    transitionDuration: '0.2s',  // Standard transition duration
    marginBottom:'-50px',
  };

  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(center);

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const onLoadAutocomplete = (autoC) => {
    setAutocomplete(autoC);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      if (place.geometry) {
        // setMarkerPosition(place.geometry.location);
        // map.panTo(place.geometry.location);
        handleLocationSetting(place.formatted_address);
        console.log(place.address_components)
        let cityLongName = '';
        let stateShortName = '';
        let cityState = '';
        place.address_components.forEach(component => {
          if (component.types.includes('locality')) {
            cityLongName = component.long_name;
            cityState = component.long_name;
          }
          if (component.types.includes('administrative_area_level_1')) {
            stateShortName = component.short_name
            cityState += ", "
            cityState += component.short_name
          }
        });
        console.log(cityState)
        handleCityAndState(cityState)
      } else {
        console.log('Place has no geometry');
      }
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };

  return (
    
    <>
          <style>
        {`
          .pac-container {
            display: relative;
            z-index: 11111111;
            margin-top:0px;
          }
        `}
      </style>
    <LoadScript
      googleMapsApiKey="AIzaSyB8lBzJmVMr2X6ec0eyuY75G-_v0J5P_K4"
      libraries={["places"]}
    >
        <Box display=''>
      <Autocomplete
        onLoad={onLoadAutocomplete}
        onPlaceChanged={onPlaceChanged}
      >



        <input
          type="text"
          placeholder="Enter school address"
          style={inputStyle}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => setLocation(e.target.value)}
        />
      </Autocomplete>
      {/* <GoogleMap
        mapContainerStyle={containerStyle}
        center={markerPosition}
        zoom={15}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker position={markerPosition} />
      </GoogleMap> */}
      </Box>
    </LoadScript>
    </>
  )
}

export default React.memo(GoogleMapComponent);
