import React, { useState } from 'react';
import {
  Modal, Box, VStack, ModalOverlay, Text, Img, ModalContent, Menu,
  MenuButton, MenuList, InputGroup, InputRightElement, MenuItem,
  ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Spinner,
  Button, Input, useToast, Flex
} from '@chakra-ui/react';
import { FiBriefcase, FiShare2 } from "react-icons/fi";
import { ChevronDownIcon } from '@chakra-ui/icons';

function ShareJobModal({ isOpen, onClose, job, replaceJob, schoolId }) {
  const [jobTitle, setJobTitle] = useState(job.title);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSelection = (reason) => {
    handleSubmit(job.id, reason)
  };

  const handleSubmit = async (job_id, reason) => {
    setIsLoading(true)
    const payload = {
      id: job_id,
      reason: reason,
    };

    try {
      const response = await fetch('https://www.pursueli.com/api/admin_archive_job/', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        const newJob = data.new_job;
        replaceJob(newJob);
        toast({
          title: 'Job post archived.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to archive job post.');
      }
    } catch (error) {
      toast({
        title: 'Error archiving job post.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText('https://www.edpursuit.com'+text).then(() => {
      toast({
        title: 'Link copied to clipboard.',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    }).catch((err) => {
      toast({
        title: 'Failed to copy link.',
        description: err.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="50vw">
        <ModalHeader>
          Share Job
          <Box display={'flex'} color={'#4A5568'} alignItems={'center'}>
            <FiBriefcase fontSize={'14px'} />
            <Text ml={2} color={'#4A5568'} fontSize={'sm'}>{job.title} at {job.school_sites[0].school_name}</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />

        <Box p={6}>
          <InputGroup size='sm' style={{ marginTop: '-10px' }}>
            <Input
              pr='4.5rem'
              type={'text'}
              value={`https://www.edpursuit.com/job/${job.slug}`}
              disabled
            />
            <InputRightElement width='4.5rem'>
              <Button h='1.75rem' size='sm' onClick={() => handleCopy(`https://www.edpursuit.com/job/${job.slug}`)} colorScheme='teal'>
                copy
              </Button>
            </InputRightElement>
          </InputGroup>

          <Text fontSize={'sm'} textAlign={'start'} color={'gray'} mt={6} fontWeight={'400'}>
            When sharing job postings on other job boards or platforms, use custom links for tracking metrics over time.
          </Text>

          <form onSubmit={handleSubmit}>
            <Flex w={'100%'} gap={4} mt={4} textAlign={'start'}>
              <Img justifySelf={'start'} style={{ height: '55px', width: 'auto', borderRadius: '50%', marginLeft: '-4px' }} src="https://i.ibb.co/xLfNLB9/edjoin-icon.png" />
              <Box w={'100%'}>
                <Text fontWeight={''} size={'sm'}>
                  edJoin
                </Text>
                <InputGroup size='sm'>
                  <Input
                    pr='4.5rem'
                    type={'text'}
                    value={`/job/${job.slug}/?source=edjoin`}
                    disabled
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={() => handleCopy(`/job/${job.slug}/?source=edjoin`)} colorScheme='teal'>
                      copy
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Flex>

            <Flex w={'100%'} gap={4} mt={6} textAlign={'start'}>
              <Img justifySelf={'start'} style={{ height: '45px', width: 'auto' }} src="https://images.rawpixel.com/image_png_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvdjk4Mi1kNS0xMF8xLnBuZw.png" />
              <Box w={'100%'}>
                <Text fontWeight={''} size={'sm'}>
                  Linkedin
                </Text>
                <InputGroup size='sm'>
                  <Input
                    pr='4.5rem'
                    type={'text'}
                    value={`/job/${job.slug}/?source=linkedin`}
                    disabled
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={() => handleCopy(`/job/${job.slug}/?source=linkedin`)} colorScheme='teal'>
                      copy
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Flex>

            <Flex w={'100%'} gap={4} mt={6} textAlign={'start'}>
              <Img justifySelf={'start'} style={{ height: '45px', width: 'auto' }} src="https://i.ibb.co/p4Z2Xks/indeedicon.webp" />
              <Box w={'100%'}>
                <Text fontWeight={''} size={'sm'}>
                  Indeed
                </Text>
                <InputGroup size='sm'>
                  <Input
                    pr='4.5rem'
                    type={'text'}
                    value={`/job/${job.slug}/?source=indeed`}
                    disabled
                  />
                  <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' onClick={() => handleCopy(`/job/${job.slug}/?source=indeed`)} colorScheme='teal'>
                      copy
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Flex>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
}

export default ShareJobModal;
