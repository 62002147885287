import React from 'react'
import { Box, IconButton, useBreakpointValue } from '@chakra-ui/react'
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
// And react-slick as our Carousel Lib
import Slider from 'react-slick'

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  autoplay: true,
  autoplaySpeed: 5000,
  slidesToShow: 1,
}

export default function Carousel({photos}) {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState(null);
  console.log(photos)
  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' })
  const side = useBreakpointValue({ base: '30%', md: '10px' })

  // These are the images used in the slide
  const cards = [
    'https://images.unsplash.com/photo-1612852098516-55d01c75769a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60',
    'https://images.unsplash.com/photo-1627875764093-315831ac12f7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60',
    'https://images.unsplash.com/photo-1571432248690-7fd6980a1ae2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDl8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60',
  ]

  return (
<Box position={'relative'} width={'100%'} overflow={'hidden'}>
      {/* Links to CSS files for react-slick */}
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
      
      {photos && photos.length > 1 && (
        <>
          <IconButton
            aria-label="left-arrow"
            colorScheme="gray"
            borderRadius="full"
            position="absolute"
            left={side}
            top={top}
            transform={'translate(0%, -50%)'}
            zIndex={2}
            onClick={() => slider?.slickPrev()}
          >
            <BiLeftArrowAlt />
          </IconButton>
          <IconButton
            aria-label="right-arrow"
            colorScheme="gray"
            borderRadius="full"
            position="absolute"
            right={side}
            top={top}
            transform={'translate(0%, -50%)'}
            zIndex={2}
            onClick={() => slider?.slickNext()}
          >
            <BiRightArrowAlt />
          </IconButton>
        </>
      )}

      {/* Slider */}
      <Slider {...settings} ref={setSlider}>
        {photos.map((item, index) => (
          <Box key={index} position="relative">
            {item.type === "Image" ? (
              <img
                src={item.photo} // Assuming 'item.photo' is the URL to the image
                alt={`Slide ${index}`}
                style={{ width: '100%', aspectRatio:'1.6', objectFit: 'cover', borderRadius:'10px' }}
              />
            ) : (
              <video
                style={{ width: '100%', aspectRatio:'1.6', objectFit: 'cover', borderRadius:'10px' }}
                controls
                muted // Optional: keeps the video muted by default
                loop
                src={item.video} // Assuming 'item.video' is the URL to the video
              >
              </video>
            )}
          </Box>
        ))}
      </Slider>
    </Box>
  )
}