import React, { useRef, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  Flex,
  Box,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Switch } from 'antd';

function ManageReportChartsModal({ isOpen, onClose, schoolName, schoolId, addNewJob, userId, selectedCharts, handleReportViewUpdate }) {
  const modalRef = useRef();
  const toast = useToast();

  // Initialize switch states based on selectedCharts
  const initialSwitchesState = {
    applicationsOverTime: selectedCharts.some(chart => chart.slug === 'applications_over_time'),
    applicationsByJobPosting: selectedCharts.some(chart => chart.slug === 'applications_by_job_posting'),
    applicationBySource: selectedCharts.some(chart => chart.slug === 'applications_by_source'),
    viewsOverTime: selectedCharts.some(chart => chart.slug === 'views_over_time'),
    viewsByJobPosting: selectedCharts.some(chart => chart.slug === 'views_by_job_posting'),
    viewsBySource: selectedCharts.some(chart => chart.slug === 'views_by_source'),
  };

  const [switches, setSwitches] = useState(initialSwitchesState);

  // Function to handle switch changes
  const handleSwitchChange = (key, value) => {
    // Optimistically update the switch state
    setSwitches(prevState => ({ ...prevState, [key]: value }));
    
    sendSwitchChange(key, value)
      .catch(() => {
        // Revert the switch state if the request fails
        setSwitches(prevState => ({ ...prevState, [key]: !value }));
      });
  };

  // Function to send POST request on switch change
  const sendSwitchChange = async (key, value) => {
    const response = await fetch('https://www.pursueli.com/api/admin_update_report_view/', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ key, value }),
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    handleReportViewUpdate(data.viewable_charts);

    // toast({
    //   title: 'Success',
    //   description: 'Preferences updated successfully.',
    //   status: 'success',
    //   duration: 3000,
    //   isClosable: true,
    // });
  };

  useEffect(() => {
    setSwitches(initialSwitchesState);
  }, [selectedCharts]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxWidth="35vw" backgroundColor={'#F7FAFC'}>
        <ModalHeader>Manage Report Charts for {schoolName}</ModalHeader>
        <ModalCloseButton />
        <form style={{ padding: '10px', backgroundColor: '#F7FAFC' }}>
          <ModalBody ref={modalRef}>
            {/* Add switches here */}
            <VStack spacing={4} align="stretch">
              <Flex align="center">
                <Box flex="1">
                  <Text fontWeight="bold">Application Charts</Text>
                </Box>
              </Flex>
              <Flex align="center">
                <Box flex="1">
                  <Text>Applications over time</Text>
                </Box>
                <Switch checked={switches.applicationsOverTime} onChange={(checked) => handleSwitchChange('applications_over_time', checked)} />
              </Flex>
              <Flex align="center">
                <Box flex="1">
                  <Text>Application by source</Text>
                </Box>
                <Switch checked={switches.applicationBySource} onChange={(checked) => handleSwitchChange('applications_by_source', checked)} />
              </Flex>
              <Flex align="center">
                <Box flex="1">
                  <Text>Applications by job posting</Text>
                </Box>
                <Switch checked={switches.applicationsByJobPosting} onChange={(checked) => handleSwitchChange('applications_by_job_posting', checked)} />
              </Flex>
            
              <Flex align="center" mt={5}>
                <Box flex="1">
                  <Text fontWeight="bold">Job Post Views Charts</Text>
                </Box>
              </Flex>
              <Flex align="center">
                <Box flex="1">
                  <Text>Views over time</Text>
                </Box>
                <Switch checked={switches.viewsOverTime} onChange={(checked) => handleSwitchChange('views_over_time', checked)} />
              </Flex>
              <Flex align="center">
                <Box flex="1">
                  <Text>Views by job posting</Text>
                </Box>
                <Switch checked={switches.viewsByJobPosting} onChange={(checked) => handleSwitchChange('views_by_job_posting', checked)} />
              </Flex>
              <Flex align="center">
                <Box flex="1">
                  <Text>Views by source</Text>
                </Box>
                <Switch checked={switches.viewsBySource} onChange={(checked) => handleSwitchChange('views_by_source', checked)} />
              </Flex>
            </VStack>
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            {/* <Button colorScheme="blue" mr={3} type="submit">
              Finish
            </Button> */}
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default ManageReportChartsModal;
