import React, { useState, useEffect, useRef } from 'react';
import { Box, Img, Flex,  Skeleton,
    SkeletonCircle,  VStack, Container, Input, Spacer,
    SkeletonText, Stack, Grid,Card,Badge, GridItem, Text, Button, Image, Heading, Tag, Avatar, Divider, ChakraProvider } from '@chakra-ui/react';

import { FiFolder } from 'react-icons/fi';
import { UploadSingleResume } from '../../../components/upload_resume';
import { FaLinkedin, FaEmail } from 'react-icons/fa';
import { FiMail, FiPhoneCall, FiMapPin } from "react-icons/fi";
import { PiArrowLineRight,PiTrash, PiPencilLineLight, PiPlusBold } from "react-icons/pi";
import { format, parseISO } from 'date-fns';
import Textarea from 'react-expanding-textarea'
import { FaPaperPlane } from "react-icons/fa6";


function ConfirmPreferences() {

  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    // Fetch the upcoming events
    fetch("https://www.pursueli.com/api/my_profile/", {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
        }
      })          
      .then((response) => response.json())
      .then((data) => {
        console.log(data.user); // Add this line
        // setPastEvents(data.past_things_to_do);
        setUserData(data.user); // Update state with fetched data

      })
    .catch((error) => console.error("Error fetching upcoming events:", error));

  }, []);

  const [images, setImages] = React.useState([]);

  const handleImagesChange = (updatedImages) => {
    setImages(updatedImages);
};
const [inputValue, setInputValue] = useState('');

const message = {
    inputValue,
  };
  function formatPhoneNumber(phoneNumber) {
    // Convert to string in case it's not already
    const phoneStr = phoneNumber.toString();
    
    // Use a regular expression to add a dash after the third digit
    return phoneStr.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}


const handleSubmit = async () => {
    const newMessage = { message: inputValue, from: userData.first_name }; // Adjust this object according to your needs and API expectations

    // Optimistically add the message to the UI
    setMessages(prevMessages => [...prevMessages, newMessage]);
    setInputValue('')
    try {
      const response = await fetch(`https://www.pursueli.com/api/add_onboarding_conversation_message/`, {
        method: 'POST', // or 'DELETE'
        // ... headers and other configurations
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
            // Include other headers as required
          },
          body: JSON.stringify(message)
      });

      if (response.ok) {
        const responseData = await response.json();  // Parse the JSON response once
        console.log(responseData['action']);  // Log the 'action'
        const updatedMessages = responseData['messages'];  // Access the 'messages'
        setMessages(updatedMessages); 
        console.log("success");
        // Add this new outivity to the existing list
        // setUpcomingEvents(prevEvents => [...prevEvents, newOutivity]);
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to delete the event:", error);
      // Handle error
    }
  };

  const [messages, setMessages] = useState([]);

  const bottomRef = useRef(null); // Ref for scrolling into view
 
  
  useEffect(() => {
    const scrollToBottom = () => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };
  
    if (messagesLoaded) {
      scrollToBottom();
    }
  
    // Optional: If you have images or other media in messages,
    // consider adding an event listener for when they load and then call scrollToBottom.
  }, [messages, messagesLoaded]); 


  
  useEffect(() => {
    // Scroll the bottomRef into view whenever messages update
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]); // Dependency array includes messages so it runs every time messages change


  const handleSend = () => {
    if (inputValue.trim() !== '') {
      setMessages([...messages, inputValue]);
      setInputValue('');
    }
  };

  useEffect(() => {
    // Fetch the upcoming events
    fetch("https://www.pursueli.com/api/get_onboarding_ai_messages/", {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
        }
      })          
      .then((response) => response.json())
      .then((data) => {
        console.log("hello")
        console.log(data.messages); // Add this line
        // setPastEvents(data.past_things_to_do);
        setMessages(data.messages); // Update state with fetched data
        setMessagesLoaded(true); // Indicate that messages are loaded


      })
    .catch((error) => console.error("Error fetching upcoming events:", error));

  }, []);



  

  return (

    <Flex width={'100%'}  height="90vh" alignItems="center" justifyContent="center">

    <Grid
    maxH={'100%'}
      templateColumns="repeat(1, 1fr)" // This ensures content takes full width of the grid
      gap={6}
      w={{ base: "100%", md: "75%", lg: "50%" }} // Responsive width
      mx="auto" // Centers the grid in the viewport
      minH={{ base: "90vh", md: "50vh", lg: "40vh" }} // Responsive width
        overflowY={'scroll'}
        sx={{
            '&::-webkit-scrollbar': {
              display: 'none', // Hide scrollbar for Chrome, Safari, and Opera
            },
            scrollbarWidth: 'none', // Hide scrollbar for Firefox
            msOverflowStyle: 'none',  // Hide scrollbar for IE and Edge
          }}
    >        
            
        {
  messages.length > 0 && userData ? (
    <Box w="100%" p={4}>
      {messages.map((message, index) => (
        <Box key={index} mb={6}>

            {
            message.from_ai === true ? (
                <Box>
                     <Flex alignItems={'center'}>
                        <Img width={'25px'} height={'25px'} borderRadius={'13px'} p={0} mr={2} src="https://files.oaiusercontent.com/file-67DO2izfRzMLqRM6duaMiRqf?se=2024-02-22T02%3A43%3A37Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Df9ba4739-9a24-4414-944d-b3ba8980da5b.webp&sig=R%2B4%2BhgDnt0LRYGAcjeVGwbMKDWXjOuiCvo3ZAx9BXuo%3D"/>
                        <Text fontWeight={'bold'}>Ed</Text>
                    </Flex>
                <Text textAlign={'start'} pt={1}  borderRadius="lg" w="fit-content">
                    {message.message}
                </Text>



{/* 
                <Box>
                {message.job_recomndations.map((job, index) =>
                <Box>
                    job
                </Box>
                )}
                </Box> */}









                </Box>
            ) : (
            <Box>
                <Flex alignItems={'center'}>
                   <Img width={'25px'} height={'25px'} borderRadius={'13px'} p={0} mr={2} src={userData.photo}/>
                   <Text fontWeight={'bold'}>{userData.first_name}</Text>
               </Flex>
                <Text textAlign={'start'} pt={1}  borderRadius="lg" w="fit-content">
                    {message.message}
                </Text>
           </Box>
            )
            }

         


<Box ref={bottomRef} />

        </Box>
      ))}
    </Box>
  ) : null /* or render a placeholder if you prefer */
}

<Grid
    position={'sticky'} bottom={'0px'}
      templateColumns="repeat(1, 1fr)" // This ensures content takes full width of the grid
      gap={6}
      paddingLeft={'10px'}
      w={{ base: "100%" }} // Responsive width
      mx="auto" // Centers the grid in the viewport
    >    
        <Flex alignItems={'flex-end'} bg={'white'}>
          <Textarea
          style={{ padding:'7px',
          backgroundColor: 'gray.100', // Using Chakra UI color scheme
          borderWidth: '2px',
          borderColor: 'blue.500',
          width: '92%',
          _hover: {
            borderColor: 'green.500', // Change border color on hover
          },
          _focus: {
            boxShadow: '0 0 0 2px #3182ce', // Custom focus ring color
          },
          borderRadius:'5px',
    
          
        }}
            rows={'1'}
            placeholder="Type your message here..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            // onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          />
          <Box paddingLeft={'10px'} paddingRight={'10px'}>
          <Button colorScheme="blue" onClick={handleSubmit}><FaPaperPlane />
          </Button>
          </Box>
        </Flex>
        </Grid>

    </Grid>

    </Flex>

  );
}

export default ConfirmPreferences;
