import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { PiArrowLineRight,PiTrash,PiSquaresFourFill, PiListDashesFill, PiPencilLineLight, PiPlusBold } from "react-icons/pi";

import {
    Checkbox,
    FormControl,
    FormLabel,
    Stack,
    useColorModeValue,
    Link,

Box, Flex, Text, Image, Icon,
Button,
Input,

} from '@chakra-ui/react';

  
import { useNavigate } from 'react-router-dom';



function RecruiterLogin() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); // For navigation after login
  
    const handleLogin = async () => {
      await loginUser(email, password, setErrorMessage);
    };


    const loginUser = async (email, password, setErrorMessage) => {
        const registrationData = {
          email: email,
          password: password,
        };
      
        try {
            const loginResponse = await fetch('https://www.pursueli.com/api/admin_login/', {
                method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(registrationData),
          });
      
          if (loginResponse.ok) {
            const data = await loginResponse.json();
            console.log(data);
            localStorage.setItem('authToken', data.token); // Use localStorage for web
            localStorage.setItem('userPhoto', data.user_profile_photo_url); // Use localStorage for web
            localStorage.setItem('authenticated', 'True'); // Use localStorage for web
            setAuthenticated(true); // Update the authentication state

            if (!localStorage.getItem('redirectPath')) {
              localStorage.setItem('redirectPath', '/admin_school_jobs'); // Use localStorage for web
            }
            navigate(localStorage.getItem('redirectPath')); // Navigate to home after state update and successful login
            localStorage.removeItem('redirectPath');





            // navigate('/admin_school_jobs'); // Navigate to home after state update and successful login
            for (let i = 0; i < localStorage.length; i++) {
                // Get the key of the current item
                const key = localStorage.key(i);
                // If the key is not null, log the key and its value
                if (key) {
                  console.log(`${key}: ${localStorage.getItem(key)}`);
                }
              }            // Navigate to the user's profile or home screen
            // useHistory hook to programmatically navigate
          } else {
            const errorData = await loginResponse.json();
            let errorMessage = '';
            if (errorData.email) {
              errorMessage += `Email Error: ${errorData.email[0]}`;
            }
            if (errorData.password) {
              if (errorMessage) errorMessage += '\n';
              errorMessage += `Password Error: ${errorData.password[0]}`;
            }
      
            if (!errorMessage) {
              errorMessage = 'Invalid credentials. Please try again.';
            }
      
            setErrorMessage(errorMessage);
          }
        } catch (error) {
          setErrorMessage('An error occurred. Please try again later.');
        }
      };
      
  

  return (
<Box paddingLeft={{ base: 5, lg: '10%' }} paddingRight={{ base: 5, lg: '10%' }}>


<Box minH="80vh" pt={'60px'} d="flex" alignItems="center"  justifyContent="center">
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={16} px={6}>
        <Stack align={'center'}>
          <Image src="https://i.ibb.co/7bSBvCL/Edpursuit-Logo-Dark-Grey-Red.png" height="60px" />
          <Text fontSize={'lg'} fontWeight={500}>
            Sign in to your account
          </Text>
          {/* <Text fontSize={'sm'} color={'gray.600'}>
            Don't have an account?{' '}
            <Link color={'blue.400'} href="/signup">
              Sign up
            </Link>
          </Text> */}
        </Stack>
        <Box rounded={'lg'} bg={useColorModeValue('white', 'gray.700')} boxShadow={'lg'} p={8}>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e) => setEmail(e.target.value)}  />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input type="password" onChange={(e) => setPassword(e.target.value)}  />
            </FormControl>
            <Stack spacing={5} >
              <Stack display={'none'} direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'}>
                <Checkbox>Remember me</Checkbox>
                <Link color={'blue.400'}>Forgot password?</Link>
              </Stack>
              <Button colorScheme={'blue'} variant={'solid'} onClick={handleLogin}>
                Sign in
              </Button>
            </Stack>
            {/* <Stack pt={6}>
              <Text align={'center'}>
                or continue with
              </Text>
              <Button leftIcon={<FaGoogle />} variant={'outline'} width="full" mt={2}>
                Google
              </Button>
              <Button leftIcon={<FaTwitter />} variant={'outline'} width="full" mt={2}>
                Twitter
              </Button>
              <Button leftIcon={<FaGithub />} variant={'outline'} width="full" mt={2}>
                GitHub
              </Button>
            </Stack> */}
          </Stack>
        </Box>
      </Stack>
    </Box>
    </Box>
  );
}

    


export default RecruiterLogin;
