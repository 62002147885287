import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
  Textarea,FormControl,
  Center, Spinner,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { FiFolder } from 'react-icons/fi';
import { UploadSingleResume } from '../../../components/upload_resume';
import { useNavigate } from 'react-router-dom';


function UploadResume() {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const navigate = useNavigate();

  const inputFileRef = React.useRef(null);

  const handleFileInputClick = () => {
    inputFileRef.current.click();
  };

  const [images, setImages] = React.useState([]);

  const handleImagesChange = (updatedImages) => {
    setImages(updatedImages);
};

const eventData = {
    images,
  };



  const handleSubmit = async () => {
    try {
      setIsLoading(true); // Start loading
      setLoadingText('Updating your profile ...')
      // Define base URL
      const baseUrl = 'https://www.pursueli.com/api';
      
      // Initial data for the first POST request
      const eventData = {
        images,
      };      
      // First request to OnboardingScreenOne
      let response = await fetch(`${baseUrl}/upload_resume/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData)
      });
  
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      setLoadingText('')
      setLoadingText('Updating your work history ...')
      let data = await response.json();
      let text = data.text;
  
      // Use 'text' from the first response in the second request
      response = await fetch(`${baseUrl}/ai_update_work_exp/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }) // Pass 'text' for processing
      });
  
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      data = await response.json();
      text = data.text; // Update 'text' if necessary
  
      setLoadingText('')
      setLoadingText('Updating your education & credentials ...')
      // Use 'text' from the second response in the third request
      response = await fetch(`${baseUrl}/ai_update_edu_exp/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text }) // Pass 'text' for final processing
      });
  
      if (!response.ok) throw new Error(`Error: ${response.status}`);
  
      // Finalize the process
      setIsLoading(false); // Stop loading
      navigate('/home'); // Navigate after successful processing
      console.log("success");
  
    } catch (error) {
      console.error("Failed to process the data:", error);
      setIsLoading(false); // Ensure loading is stopped in case of an error
    }
  };
  

  return (
    <ChakraProvider backgroundColor={'red'}>
      <Center width={'100%'} >
        <Box width="lg" p={5} mb={5} boxShadow="md"  mt={5} borderRadius="lg">
            <Box textAlign={'start'} mb={5} mt={5}>
            Welcome to edpursuit. Upload your resume or paste in the details to create your talent profile.
            </Box>
          <Tabs isFitted variant="enclosed">
            <TabList mb="1e">
              <Tab>Upload Resume</Tab>
              <Tab>Paste Resume</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <VStack spacing={4}>


                    <UploadSingleResume onImagesChange={handleImagesChange} />






                {/* <Box height={'25vh'}></Box> */}

                </VStack>
              </TabPanel>
              <TabPanel>
                <Textarea placeholder="Paste your resume here" height={'35vh'} size="lg" />
              </TabPanel>
            </TabPanels>
          </Tabs>
          

          <Box  mt={'-30px'} >{loadingText}</Box>

          <Box display={'flex'} justifyContent={'center'} mb={1}>

          <Button colorScheme='blue' size="lg" variant='solid' onClick={handleSubmit}
            isDisabled={isLoading} // Disable button when isLoading is true

          mt="1">
            {isLoading ? <Spinner color='white' /> : "Submit"}
          </Button>
        </Box>
        </Box>
      </Center>
    </ChakraProvider>
  );
}

export default UploadResume;
