import {
    Box,
    Button,
    Container,
    Heading,
    Select, Flex,
    Stack, Img,
    Text,
    VStack,
    HStack,
    Divider
  } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiBriefcase, FiMapPin } from "react-icons/fi";



  function JobListingsPage() {
    let { slug } = useParams();
    const [jobs, setJobs] = useState([]);
    const [groupedJobs, setGroupedJobs] = useState({});
    const [location, setLocation] = useState('')
    const [schoolLogo, setSchoolLogo] = useState(null)

    useEffect(() => {    
      // Assuming 'school' is the query parameter expected by your backend
      // and 'school' state contains the current selected school value.
      fetch(`https://www.pursueli.com/api/school_public_route_get_jobs/?school=${slug}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
        }
      })
      .then(response => response.json())
      .then(data => {
        const jobsData = data.jobs;
        setLocation(data.location)
        const grouped = jobsData.reduce((acc, job) => {
            // Assuming job_group is an object with a name property
            // If job_group is nullable, make sure to handle that case as well
            const groupName = job.job_group ? job.job_group.name : 'Others';
            (acc[groupName] = acc[groupName] || []).push(job);
            return acc;
        }, {});

        setJobs(jobsData); // If you still need to setJobs separately
        setGroupedJobs(grouped);
        setSchoolLogo(data.logo)
      })
      .catch(error => console.error("Error fetching jobs:", error));
    }, []);
    
    
    

    return (
        <Box>
               <Container
        bg="white"
        color="black"
        p={1}
        position="sticky"
        top={0}
        zIndex={1}
        boxShadow="md"
        minW={'100%'}
        py={2}

      
      >
<Box mx={'auto'} maxW={{ base: '100%', md: '75%', lg: '70%' }}>
        <Flex  
         py={2} alignItems="center"  >
{
schoolLogo
    ? (
        <Img
          src={schoolLogo} // Replace with your logo path
          alt="School Logo"
          h="45px"
        />
      )
    : (
        <Box style={{ height: '45px'}}/>
      )
}
          {/* Add more elements here if needed */}
        </Flex>
        </Box>

      </Container>
        <Container maxW={{ base: '100%', md: '75%', lg: '60%' }} py={5}>
            
        <Flex display={'none'} justifyContent={'flex-start'} mt={6} direction={['column', 'row']} spacing={4} mb={10} align="center">
          {/* <Select mr={5} placeholder="Department" width={['100%', 'auto']}>
          </Select> */}
          {/* <Select mr={5} placeholder="Location" width={['100%', 'auto']}>
          {locations.map((location, index) => (
          <option key={index} value={location}>{location}</option>
           ))}
          </Select> */}
          <Select mr={5} placeholder="Team" width={['100%', 'auto']}>
            {/* Options... */}
          </Select>
          <Select placeholder="Employment Type" width={['100%', 'auto']}>
            {/* Options... */}
          </Select>
        </Flex>
        {Object.entries(groupedJobs).map(([group, jobs]) => (
          <Box py={3} key={group}>
            <Text textAlign={'start'} fontWeight="bold" pt={6} fontSize={'22px'} mb={4}>{group}</Text> {/* Render the group name */}
            <Divider borderColor={'gray'} mt={'-8px'}  color={'gray'}/>
            {jobs.map((job, index) => (
              <HStack justify="space-between" w="full" py={4} key={index}>
                <VStack align="start" spacing={0}>
                  <Text fontWeight="bold">{job.title}</Text>

                  <Text fontSize="sm" color="gray.600" display={'flex'} alignItems={'center'}>
                  <FiMapPin style={{marginRight:'5px'}} />
                    {location}</Text>
                  <Text fontSize="sm" color="gray.600" display={'flex'} alignItems={'center'}>
                  <FiBriefcase style={{marginRight:'5px'}} />
                    {job.employment_type}</Text>


                </VStack>
                
                <Button colorScheme="blue" as={'a'}
                 href={`/job/${job.slug}/`}
                >Apply</Button>
              </HStack>
            ))}
          </Box>
        ))}
      </Container>
      </Box>
    );
  }
  
  export default JobListingsPage;
  