import React from 'react';

const CCSAFooter = () => {
  return (
    <footer style={styles.footer}>
      <div style={styles.footerSection}>
        <h4 style={{fontWeight:'bold'}}>Members</h4>
        <ul style={styles.ul}>
          <li><a href="/info-support" style={styles.link}>Information & Support</a></li>
          <li><a href="/legal-services" style={styles.link}>Legal Services</a></li>
          <li><a href="/membership-options" style={styles.link}>Membership Options</a></li>
        </ul>
      </div>
      <div style={styles.footerSection}>
        <h4 style={{fontWeight:'bold'}}>Connect</h4>
        <ul style={styles.ul}>
          <li><a href="/blog" style={styles.link}>#CharterNation Blog</a></li>
          <li><a href="/podcast" style={styles.link}>#CharterNation Podcast</a></li>
        </ul>
      </div>
      <div style={styles.footerSection}>
        <h4 style={{fontWeight:'bold'}}>Resources</h4>
        <ul style={styles.ul}>
          <li><a href="/data-snapshots" style={styles.link}>Data Snapshots</a></li>
          <li><a href="/research-data" style={styles.link}>Research & Data</a></li>
        </ul>
      </div>
      <div style={styles.footerSection}>
        <h4 style={{fontWeight:'bold'}}>Careers</h4>
        <ul style={styles.ul}>
          <li><a href="/apply" style={styles.link}>Apply Today</a></li>
        </ul>
      </div>
      
      
      <div style={styles.footerBottom}>
        <img src="https://info.ccsa.org/hs-fs/hubfs/CCSA_Logo_COLOR-2.png?width=1611&name=CCSA_Logo_COLOR-2.png" alt="CCSA Logo" style={styles.logo} />
      </div>
    </footer>
    
  );
};

const styles = {
  footer: {
    backgroundColor: '#F79234',
    color: 'white',
    paddingLeft: '140px',
    paddingRight: '100px',
    paddingTop: '60px',
    paddingBottom: '60px',
    display: 'flex',
    flexWrap: 'wrap',
    margin:'auto',
    justifyContent: 'space-between',
  },
  footerSection: {
    flex: 1,
    margin: '10px',
    textAlign: 'start'
  },
  ul: {
    listStyle: 'none',
    padding: 0,
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    marginBottom: '5px',
    display: 'block'
  },
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    padding: '5px',
    marginRight: '5px',
    flex: 1,
  },
  button: {
    padding: '5px 10px',
    backgroundColor: 'white',
    color: '#F79234',
    border: 'none',
    cursor: 'pointer',
  },
  socialIcons: {
    display: 'flex',
    gap: '10px',
  },
  socialIcon: {
    color: 'white',
    textDecoration: 'none',
    fontSize: '20px',
  },
  contactButton: {
    backgroundColor: 'white',
    color: '#F79234',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer',
    marginTop: '10px',
  },
  footerBottom: {
    flexBasis: '100%',
    textAlign: 'center',
    marginTop: '20px',
    minWidth:'100vh',
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    maxWidth: '200px',
    marginBottom: '10px',
    paddingLeft: '10px'
  },
};

export default CCSAFooter;
