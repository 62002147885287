import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Text, Card, useDisclosure } from '@chakra-ui/react';
import CandidateDrawer from './candidate_drawer';

function Item({ id, application, index, stages, onApplicationStatusUpdate }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const openDrawer = () => {
        onOpen();
    };

    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => {
                const style = {
                    ...provided.draggableProps.style,
                    marginBottom: '10px',
                };

                return (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={style}
                    >
                        <Card p={3} mb={3} style={{ textAlign: 'start', position: 'relative', zIndex: '1' }} variant={'outline'}
                            onClick={openDrawer}
                        >
                            <Text fontSize={'sm'} style={{ fontWeight: 'bold' }}>
                                {application.user ? 
                                    `${application.user.first_name} ${application.user.last_name}` : 
                                    `${application.unauthticated_applicant?.first_name} ${application.unauthticated_applicant?.last_name}`
                                }
                            </Text>

                            <Text fontSize={'xs'}>
                                {application.job.title}
                            </Text>
                        </Card>
                        {isOpen && (
                            <CandidateDrawer
                                isOpen={isOpen}
                                onClose={onClose}
                                selectedUserId={application.user?.id}
                                applicationId={application.id}
                                jobTitle={application.job.title}
                                aiMatchScoreReasoning={application.ai_match_score_reasoning}
                                applicationMatchScore={application.match_score}
                                onApplicationStatusUpdate={() => {}}
                                applicationStatus={application.status}
                                applicationSource={application.source}
                                applicationDate={application.application_date}
                                applicationSlug={application.slug}
                                stages={stages}
                                onApplicationUpdate={onApplicationStatusUpdate}
                            />
                        )}
                    </div>
                );
            }}
        </Draggable>
    );
}

export default Item;
