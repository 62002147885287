import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Avatar, Button, Switch, Tag } from 'antd';
import {
  Box, Image, Menu, MenuButton, MenuItem, MenuList,
  Grid, Heading, Badge, GridItem,
  Drawer, Spinner, Icon, SimpleGrid,
  useDisclosure, useMediaQuery, VStack, 
  DrawerBody, Flex, Text, Table, Thead, Tbody, Tr, Th, Td,
  DrawerOverlay, Img, useColorModeValue,
  DrawerContent, ModalBody, ModalCloseButton, ModalOverlay, ModalContent, ModalHeader, ModalFooter
} from '@chakra-ui/react';
import { Card } from '@chakra-ui/react';
import { PiArrowLineRight ,PiFunnel,PiCheckBold, PiXBold, PiArchive,PiNote, PiTrash, PiPencilLineLight, PiPlusBold, PiCameraBold } from "react-icons/pi";
import { EditIcon, ViewIcon, SearchIcon, TimeIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import EditProfileFormAfterApplying from '../../../components/edi_profile_logged_in';
import UserProfileSection from '../../../components/user_profile';
import { FiCamera, FiEdit3 } from 'react-icons/fi';



const Home = () => {
  const [imageSrc, setImageSrc] = useState(localStorage.getItem('userPhoto'));
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result); // Update the avatar preview
        setFile(newFile); // Store the file for saving
      };
      reader.readAsDataURL(newFile);
    }
  };

  const handleSave = async () => {
    const fileInput = document.querySelector('input[type="file"]');
    const file = fileInput.files[0];

    const reader = new FileReader();
    reader.onloadend = async () => {
        const formData = new FormData();
        formData.append('data_url', reader.result);  // reader.result contains the Data URL

        try {
            const response = await fetch('https://www.pursueli.com/api/user/change_profile_photo/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: formData
            });
            
            if (response.ok) {
              const data = await response.json();
              console.log('Upload successful', data);
              if (data.photo_url) {
                  console.log('Photo URL:', data.photo_url);
                  // Optionally save the photo URL in local storage or state
                  localStorage.setItem('userPhoto', data.photo_url);
                  setFile('')
              }
            } else {
                console.error('Upload failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    reader.onerror = () => {
        console.error('Error reading file');
    };
    reader.readAsDataURL(file);
}



    function formatDate(dateString) {
        const date = new Date(dateString);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const day = date.getDate();
        let daySuffix;
        switch (day) {
          case 1: case 21: case 31:
            daySuffix = 'st';
            break;
          case 2: case 22:
            daySuffix = 'nd';
            break;
          case 3: case 23:
            daySuffix = 'rd';
            break;
          default:
            daySuffix = 'th';
        }
        const monthIndex = date.getMonth();
        const monthName = monthNames[monthIndex];
        return `${monthName} ${day}${daySuffix}`;
      }
      function daysFromPast(dateString) {
        const pastDate = new Date(dateString);
        const currentDate = new Date();
        
        // Set hours to 00:00:00 to ignore time part
        pastDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
      
        const diffTime = currentDate - pastDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
        // Check if the difference is 0, which means the date is today
        if (diffDays === 0) {
          return "today";
        } else {
          return `${diffDays} days ago`;
        }
      }
  const [userAppliedJobs, setUserAppliedJobs] = useState([]);
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(true);
  const [isUserOpenToJobs, setIsUserOpenToJobs] = useState(false);
  useEffect(() => {
    // Assuming `user.open_to_jobs` is available after fetching screen data
    setIsUserOpenToJobs(user.open_to_jobs);
  }, [user.open_to_jobs]);

  const handleSwitchChange = (checked) => {
    // Optimistically update the UI
    setIsUserOpenToJobs(checked);

    const url = `https://www.pursueli.com/api/user/open_to_jobs/`;
    const payload = { open_to_jobs: checked };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(payload),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
      // The optimistic update is already done, so we might not need to do anything here,
      // unless you want to update the user object with fresh data from the server.
    })
    .catch(error => {
      console.error('Error:', error);
      // Revert the switch's state if the request fails
    //   setIsUserOpenToJobs(!checked);
    });
};



  const fetchScreenData = () => {
    setLoading(true);
    console.log("coming to fetch");
    const url = `https://www.pursueli.com/api/user_applications/`;
    fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUserAppliedJobs(data.applications);
        setUser(data.user);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching upcoming events:", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchScreenData();
  }, []);

  const statusColorMapping = {
    'Pending': 'orange', // Ant Design does not have a 'gray' but uses 'default' which is usually rendered as gray
    'Accepted': 'success',
    'Rejected': '',
  };
  
  // Function to get color based on status
  const getColorForStatus = (status) => statusColorMapping[status] || 'default';
  
  const ApplicationTag = ({ status }) => (
    <Tag  bordered={false} color={getColorForStatus(status)} style={{ fontSize: '16px' }}>
      {status}
    </Tag>
  );

  
  const [isApplicationHistoryVisible, setIsApplicationHistoryVisible] = useState(false);
  const toggleApplicationHistory = () => {
    setIsApplicationHistoryVisible(!isApplicationHistoryVisible);
    setIsProfileVisible(false);
  };

  const [isProfileVisible, setIsProfileVisible] = useState(true);
  const toggleProfileVisibility = () => {
    setIsProfileVisible(!isProfileVisible);
    setIsApplicationHistoryVisible(false);

  };

  const visitJobsPage = () => {
    window.location.href = '/jobs'; // Change the URL to your jobs page
};

const visitChatPage = () => {
  window.location.href = '/chat'; // Change the URL to your jobs page
};

  const ActionCard = ({ title, icon, onClick }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const hoverBgColor = useColorModeValue('gray.50', 'gray.700');
    const colorScheme = useColorModeValue('blue', 'orange'); // Change as per your theme
  
  
    return (
      <Box as="article"  maxW="sm" p="5" borderWidth="1px" borderRadius="lg" overflow="hidden" onClick={onClick} 
      boxShadow={{ base: 'md', md: 'md' }}
      minW={{ base: '200px', md: '250px' }}
      
      cursor="pointer"  // Change cursor to pointer on hover
      transition="all 0.3s ease"  // Smooth transition for all properties
      _hover={{
        transform: "translateY(-5px)",  // Slight upward movement
      }}
      >
      <VStack spacing={5} align="stretch">
        {/* <Button
          onClick={onClick}
          bg={bgColor}
          colorScheme={colorScheme}
          size="lg"
          borderRadius="full"
          p={0}
          minH="80px"
          w="120px"
          alignSelf="center"
          _hover={{ bg: hoverBgColor }}
        >
          <Icon as={icon} w={12} h={12} p={2} />
        </Button> */}
        <Box textAlign="start"  display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Text  fontWeight="bold" color="gray.600">
            {title}
          </Text>
        </Box>
      </VStack>
    </Box>

    );
  };
  
  return (
    <Box  paddingBottom={{ base: 12, md: 24 }} paddingTop={{ base: 12, md: 12}} paddingLeft={{ base: 0, md: 24}} paddingRight={{ base: 0, md: 24}}>
      {loading ? (
        <Flex textAlign="center" alignItems={'center'} justifyContent={'center'} height={'50vh'}>
          <Spinner />
        </Flex>
      ) : (
        <Row justify="center" style={{ width: '100%' }}>
          <Col xs={24} lg={22}>
            <div style={{ padding: 0 }}>
              <div style={{ marginBottom: '30px', justifyContent: 'center', display: 'flex' }}>
                <div>


                <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the file input
        accept="image/*" // Accept only images
      />
      <Avatar
        style={{ height: '110px', width: '110px', cursor: 'pointer' , borderWidth:2, borderColor:'white',}}
        src={imageSrc}
        onClick={handleAvatarClick}

        alt="User Photo"
      />
    <Button onClick={handleAvatarClick} padding={0} background={'white'} variant={'solid'}   size={'small'}       
    style={{position:'absolute', marginLeft:'-32px', boxShadow:'none', marginTop:75, borderRadius:'50%', minWidth:'42px', padding:0, minHeight:'42px', background:'white', textAlign:'center', borderColor:'transparent'}}>
        <PiCameraBold style={{width:'22px', background:'inherrit',  height:'auto', marginLeft:'7px', marginBottom:'7px'}} />
      </Button>

      <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>

      {file && <Button size={'medium'} style={{marginTop:10}} colorScheme='blue' marginTop={5} onClick={handleSave}>Save</Button>}

        </div>

                  <div style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                    <Box mt={5} style={{ fontSize: '20px' }}>Welcome back, <strong>{user.first_name}</strong>!</Box>
                  </div>
                  <Card padding={4} mt={3}>
                    <div style={{ textAlign: 'start', display: 'flex' }}>
                      <Text style={{ fontSize: '16px' }} mr={3}>Open to new job opportunities</Text>
                      <Switch checked={isUserOpenToJobs} onChange={handleSwitchChange} />
                    </div>
                  </Card>
                </div>
              </div>
              <hr />




             
<Flex
  maxW={'100%'}
  mx="auto"
  justifyContent={{ base: 'start', md: 'center' }}
  spacing={5}
  overflowX="scroll"
  flexWrap="nowrap"
  mt={6}
  css={{
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none', // IE and Edge
    'scrollbar-width': 'none', // Firefox
  }}
>
  <Box flex="0 0 auto" p={2}>
    <ActionCard
      title="👤 Profile"
      icon={SearchIcon}
      onClick={toggleProfileVisibility}
    />
  </Box>

  <Box flex="0 0 auto" p={2}>
    <ActionCard
      title="📋 Application History"
      icon={TimeIcon}
      onClick={toggleApplicationHistory}
    />
  </Box>

  <Box flex="0 0 auto" p={2}>
    <ActionCard
      title="💼 Apply"
      icon={EditIcon}
      onClick={visitJobsPage}
    />
  </Box>

  <Box flex="0 0 auto" p={2}>
    <ActionCard
      title="✨ Chat"
      icon={EditIcon}
      onClick={visitChatPage}
    />
  </Box>
</Flex>
    
    {isProfileVisible && 
    <Box maxW={{base:'100%', md:'100%%'} } mx={{ base: 2, md: 'auto' }} px={{base:0, md:0}} >
    <UserProfileSection />
    </Box>
    }

    {isApplicationHistoryVisible && 

    <Table variant="simple" overflow={'scroll'}>
        <Thead sx={{ position: 'sticky', top: 0, zIndex: 1}}>

      <Tr w={'100%'}>
      {/* <Th textTransform="uppercase"  sx={{ minWidth: '50px' }}  fontSize={'sm'} fontWeight={'bold'} color={'gray.500'}></Th> */}
        <Th  sx={{ width:'30%' }} textTransform="capitalCase"   fontSize={'sm'} fontWeight={'bold'} color={'gray.500'}>School & Role</Th>
        {/* <Th textTransform="uppercase"  sx={{ minWidth: '200px' }}  fontSize={'sm'} fontWeight={'bold'} color={'gray.500'}>Role</Th> */}
        <Th  sx={{ width: '30%' }} textTransform="capitalCase"  textAlign={'center'} fontSize={'sm'} fontWeight={'bold'} color={'gray.500'}>Status</Th>
        <Th sx={{ width: '30%' }} textTransform="capitalCase"   textAlign={'center'} fontSize={'sm'} fontWeight={'bold'} color={'gray.500'}>Applied On</Th>
      </Tr>
    </Thead>
    <Tbody>
      {userAppliedJobs.sort((b, a) => a.id - b.id).map((application) => (
        <Tr key={application.id} height={'50px'}>
          {/* <Td>
            <Img src={application.job.school_sites[0].logo} height={'50px'} width={'50px'} borderRadius={'50%'}/>
          </Td> */}
          <Td>
            <Box style={{display:'flex'}}>
            <Img src={application.job.school_sites[0].icon} mr={4} height={'50px'} width={'50px'} borderRadius={'50%'}/>
            <Box>
            <Text fontWeight={'bold'}>
            {application.job.title} 
            </Text>
            <Text color={'gray.600'}>
            {application.job.school_sites[0].school_name}
            </Text>
            </Box>
            </Box>
          </Td>
          {/* <Td>
            <Text fontSize={'sm'}>
            {application.job.title}
            </Text>
          </Td> */}
            <Td textAlign={'center'}>
            <ApplicationTag status={application.status} />
            </Td>

          <Td textAlign={'center'}>
            <Text>{formatDate(application.application_date)}</Text>
            {/* <Text fontSize={'xs'} color={'gray.600'}>{daysFromPast(application.application_date)}</Text> */}
            </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
  }






            </div>
          </Col>
        </Row>
      )}
    </Box>
  );
};

export default Home;
