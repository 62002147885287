import React, { useEffect, useState } from 'react';
import {
  Box,
  Switch,
  IconButton,
  Table,
  Thead,
  Tbody,Grid, GridItem,
  Tr,
  Th,
  Td,
  TableContainer,   Skeleton,
  VStack, Card,
  Button,
  Text,
  textDecoration,
} from '@chakra-ui/react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { PiArrowLineRight,PiArchive, PiTrash, PiPencilLineLight, PiPlusBold } from "react-icons/pi";

import SideNavBar from '../../../components/side_nav_bar';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { useSchool } from '../../../context/admin_selected_school';
import JobPostModal from '../../../components/post_job_modal';
import { useDisclosure } from '@chakra-ui/react';
import { Empty } from 'antd';
import EditJobPostModal from '../../../components/edit_job_post_modal';
import ConfirmArchiveModal from '../../../components/archive_job_post_confirm_modal';

import { FiArchive, FiArrowUpRight, FiEdit3, FiGlobe, FiMail, FiRefreshCw, FiShare2, FiZap } from "react-icons/fi";
import BoostJobModal from '../../../components/boost_job_post_modal';
import AdminJobScreen from '../../../components/admin_job_post_drawer';
import EditJobPostEmailsModal from '../../../components/edit_job_emails_modal';
import ShareJobModal from '../../../components/share_job_modal';
import ArchivedJobsModal from '../../../components/archived_jobs_modal';




function SchoolDashboardJobs() {

  const { isOpen: isArchivedJobsModalOpen, onOpen: onArchivedJobsModalOpen, onClose: onArchivedJobsModalClose } = useDisclosure();
  const [archivedJobs, setArchivedJobs] = useState([]);

  // Code to open edit modall
  const [selectedJobEmail, setSelectedJobEmail] = useState(null);
  const { isOpen: isEmailModalOpen, onOpen: onEmailModalOpen, onClose: onEmailModalClose } = useDisclosure(); // New modal control
  const openEmailModal = (job) => {
    setSelectedJobEmail(job);
  };
  useEffect(() => {
    if (selectedJobEmail) {
      onEmailModalOpen(); // Open job details modal
    }
  }, [selectedJobEmail]);

  const onEmailCloseModal = () => {
    onEmailModalClose();
    setSelectedJobEmail(null); // Reset selected job
  };
////////////////////////////////

// Code to open edit modall
  const [selectedJob, setSelectedJob] = useState(null);
  const { isOpen: isDetailsModalOpen, onOpen: onDetailsModalOpen, onClose: onDetailsModalClose } = useDisclosure(); // New modal control
  const openJobDetailsModal = (job) => {
    setSelectedJob(job);
  };
  useEffect(() => {
    if (selectedJob) {
      onDetailsModalOpen(); // Open job details modal
    }
  }, [selectedJob]);
  const onEditCloseModal = () => {
    onDetailsModalClose();
    setSelectedJob(null); // Reset selected job
  };
////////////////////////////////

//Code to archive job
  const [archivingJob, setArchivingJob] = useState(null);
  const { isOpen: isCofirmArchiveModalOpen, onOpen: onCofirmArchiveModalOpen, onClose: onCofirmArchiveModalClose } = useDisclosure(); // New modal control

  const openCofirmArchiveModal = (job) => {
    setArchivingJob(job);
    onCofirmArchiveModalOpen(); // Open confirm archive modal directly
};
  const onArchiveCloseModal = () => {
    onCofirmArchiveModalClose();
    setArchivingJob(null); // Reset selected job
  };
///////////////////////////////////

//Code to boost job
const [boostingJob, setBoostingJob] = useState(null);
const { isOpen: isBoostJobModalOpen, onOpen: onBoostJobModalOpen, onClose: onBoostJobModalClose } = useDisclosure(); // New modal control

const openBoostJobModal = (job) => {
  setBoostingJob(job);
  onBoostJobModalOpen(); // Open confirm archive modal directly
};
const onBoostJobCloseModal = () => {
  onBoostJobModalClose();
  setBoostingJob(null); // Reset selected job
};
///////////////////////////////////

//Code to open job drawer
const {
  isOpen: isAdminJobDrawerOpen,
  onOpen: onAdminJobDrawerOpen,
  onClose: onAdminJobDrawerClose,
} = useDisclosure();
const [selectedAdminJob, setSelectedAdminJob] = useState(null);
const handleOpenAdminJobDrawer = (job) => {
  setSelectedAdminJob(job); // Set the selected job
  onAdminJobDrawerOpen(); // Open the drawer
};
//////////////////////////////////

//Code to share job
const [sharingJob, setSharingJob] = useState(null);
const { isOpen: isShareJobModalOpen, onOpen: onShareJobModalOpen, onClose: onShareJobeModalClose } = useDisclosure(); // New modal control

const openShareJobModal = (job) => {
  setSharingJob(job);
  onShareJobModalOpen(); // Open confirm archive modal directly
};
const onShareJobCloseModal = () => {
  onShareJobeModalClose();
  setSharingJob(null); // Reset selected job
};
///////////////////////////////////

  const [isLoading, setIsLoading] = useState(true);

  const calculateDaysUntilExpiration = (expirationDateString) => {
    const expirationDate = new Date(expirationDateString);
    const currentDate = new Date();
    const differenceInMilliseconds = expirationDate - currentDate;
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    return differenceInDays;
  };
  
  const [jobPosts, setJobPosts] = useState([]);
  const [schoolName, setSchoolName] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const replaceJob = (updatedJob) => {
    const updatedJobPosts = jobPosts.map((job) => {
      if (job.id === updatedJob.id) {
        // Replace the job post with the updated one
        return updatedJob;
      }
      return job;
    });
    setJobPosts(updatedJobPosts); // Update the state with the modified array
  };

const { school } = useSchool();

  // Function to append a new job to the list
  const addNewJobToList = (newJob) => {
    setJobPosts((prevJobPosts) => [...prevJobPosts, newJob]);
  };
  
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [org, setOrg] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [pipelines, setPipelines] = useState([]);

  
useEffect(() => {
  const schoolParam = encodeURIComponent(school); // Use this if `school` is a string

  // Assuming 'school' is the query parameter expected by your backend
  // and 'school' state contains the current selected school value.
  fetch(`https://www.pursueli.com/api/admin_get_school_jobs/?school=${schoolParam}`, {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
    console.log("Job posts:", data.jobs);
    setJobPosts(data.jobs)
    setSchoolName(data.school_name)
    setSchoolId(data.school_id)
    setEmailTemplates(data.email_templates)
    console.log(data.email_templates)
    setIsLoading(false)
    setOrg(data.org)
    setTeamMembers(data.team_members)
    setPipelines(data.pipelines)
    console.log(data)
    setUserId(data.user_id)

    // Handle your data
  })
  .catch(error => console.error("Error fetching jobs:", error));
}, [school]);

  useEffect(() => {
    console.log(school)
  }, [school]); // Re-fetch jobs when the school changes
  
  const handleSwitchChange = async (jobId, isActive) => {
    // Optimistically update the UI
    setJobPosts((prevJobPosts) => prevJobPosts.map(jobPost => {
      if (jobPost.id === jobId) {
        // Temporarily update the job post that was toggled
        return { ...jobPost, is_active: isActive };
      }
      return jobPost;
    }));
  
    try {
      const response = await fetch(`https://www.pursueli.com/api/update_job_active_status/${jobId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ is_active: isActive }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update job status.');
      }
      const data = await response.json();
      console.log("data mane, ", data);
      const newJobId = data.new_job.id;

      const newJob = data.new_job; // Assuming the API returns the new job data under 'new_job'
      replaceJob(newJob); // Update the job list with the new job details
  
      // Request succeeded, no further action needed
      console.log('Job status updated successfully');
    } catch (error) {
      console.error('Error updating job status:', error);
      // Revert the change in the UI if the request fails
      setJobPosts((prevJobPosts) => prevJobPosts.map(jobPost => {
        if (jobPost.id === jobId) {
          // Revert the is_active status
          return { ...jobPost, is_active: !isActive };
        }
        return jobPost;
      }));

      // Optionally, inform the user that the update failed
    }
  };
  const numberOfRows = 8; // For example

  return (
    <ChakraProvider>

{isLoading ? (
  <Box flex="1" left={'6%'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'} bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}> 

<Box px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px', borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
                     <Card h={'93%'} px={'80px'} top={5}>
    <Table variant="simple">
          <Thead>
            <Tr>
              <Th color={'transparent'}>Active</Th>
              <Th color={'transparent'}>Job Title</Th>
              <Th color={'transparent'}>Views</Th>
              <Th color={'transparent'}>Applications</Th>
              <Th color={'transparent'}>Expires</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array(numberOfRows).fill().map((_, index) => (
              <Tr key={index}>
                <Td><Skeleton borderRadius={'20px'} height="25px" /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" width={'280px'} /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" width={'80px'} /></Td>

                <Td><Skeleton borderRadius={'10px'} height="40px" width={'80px'} /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" width={'80px'} /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" width={'40px'} /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" marginLeft={'-30px'} width={'40px'} /></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        </Card>
    </Box>
</Box>


) : (


<Box flex="1" p={8} left={'6%'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'} bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}> 

<Box style={{  borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
        <Card px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px'}} py={'30px'}  boxShadow="xl"
   >
          <Flex justifyContent={'space-between'}>
          <Text mb={4} fontSize="xl" textAlign={'start'} fontWeight="bold">{schoolName} Jobs</Text>
          <Box>
          <Button variant={'ghost'} colorScheme='red' mr={4} size={'sm'} onClick={onArchivedJobsModalOpen}>
            <PiArchive />
            <Text pl={3}>
            Archived Jobs
            </Text>
            </Button>
          <Button colorScheme='blue' size={'sm'} onClick={onOpen}>
            <PiPlusBold />
            <Text pl={3}>
            Post Job
            </Text>
            </Button>
            </Box>
          </Flex>

              {
      jobPosts && jobPosts.length > 0 ? (

        <ChakraProvider>
      <Box w="100%">
        {/* Header Grid */}
        <Grid px={4} textAlign={'start'} fontSize={'sm'} fontWeight={'500'} color={'gray.500'}  p={2} templateColumns="repeat(24, 1fr)"  mb={2} borderBottomWidth={0} borderColor="gray.200" py={2} borderRadius="0">
          <GridItem textTransform="uppercase" colSpan={2}><Text>Active</Text></GridItem>
          <GridItem textTransform="uppercase" colSpan={9}><Text>Job Title</Text></GridItem>
          <GridItem textTransform="uppercase" colSpan={3} textAlign={'center'}><Text>Views</Text></GridItem>
          <GridItem textTransform="uppercase" colSpan={3} textAlign={'center'}><Text>Applications</Text></GridItem>
          {/* <GridItem textTransform="uppercase" colSpan={4}  textAlign={'center'} ><Text display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <FiGlobe style={{marginRight:'10px'}}/>
            Promote</Text></GridItem> */}
          <GridItem colSpan={5} textAlign={'center'}><Text></Text></GridItem>
        </Grid>

        {/* Job Posts Grid */}
        {jobPosts.sort((a, b) => a.title.localeCompare(b.title)).map((jobPost, index) => (

          <Grid
          textAlign={'start'}
          alignItems="center" // This will vertically center the content of all GridItems
          marginX={'auto'}
            templateColumns="repeat(24, 1fr)"
            gap={4}
            boxShadow="md"
            borderWidth="1px"
            borderRadius="md"
            p={2}
            px={4}
            mb={4} // Margin bottom for spacing between cards
            key={jobPost.id}
          >
            <GridItem colSpan={2}>
              <Switch size="md" colorScheme="green" isChecked={jobPost.is_active}
                onChange={(e) => handleSwitchChange(jobPost.id, e.target.checked)}
              />
            </GridItem>
            <GridItem colSpan={9}>
              <a >
              <Text
              onClick={() => handleOpenAdminJobDrawer(jobPost)}
              >{jobPost.title}</Text>
                            </a>

              </GridItem>
            <GridItem colSpan={3} textAlign={'center'}><Text>{jobPost.gross_views}</Text></GridItem>
            <GridItem colSpan={3} textAlign={'center'}><Text>{jobPost.applications_count}</Text></GridItem>
            <GridItem colSpan={4}  textAlign={'center'} justifyContent={'center'} display={'none'}>
              {/* <Text>
                {
                  calculateDaysUntilExpiration(jobPost.expiration_date) < 1
                  ? 'Expired'
                  : `${calculateDaysUntilExpiration(jobPost.expiration_date)} days`
                }
              </Text> */}
              <Button
              onClick={() => openBoostJobModal(jobPost)}
              colorScheme='yellow' variant='ghost' size={'sm'}
              >
                     <Text mr={2}>
                {
                  calculateDaysUntilExpiration(jobPost.expiration_date) < 1
                  ? 'Expired'
                  : `${calculateDaysUntilExpiration(jobPost.expiration_date)} days`
                }
              </Text>
              <FiArrowUpRight/>

                </Button>
            </GridItem>
            <GridItem colSpan={7} textAlign={'end'} display={'flex'} justifyContent={'end'} >
            {/* <Button colorScheme='yellow' variant='ghost' onClick={() => openBoostJobModal(jobPost)}>
                <FiZap w={4} h={4} />
              </Button> */}
            <Button colorScheme='yellow' variant='ghost' ml={1} onClick={() => openShareJobModal(jobPost)}>
                <FiShare2 w={4} h={4} />
            </Button>
            <Button colorScheme='teal' variant='ghost' ml={1} onClick={() => openJobDetailsModal(jobPost)}>
                <FiEdit3 w={4} h={4} />
              </Button>
              <Button colorScheme='purple' variant='ghost' ml={1} onClick={() => openEmailModal(jobPost)}>
                <FiMail w={4} h={4} />
              </Button>
              <Button colorScheme='red' variant='ghost' ml={1} onClick={() => openCofirmArchiveModal(jobPost)}>
                <FiArchive w={4} h={4} />
              </Button>
              

            </GridItem>
          </Grid>
        ))}
      </Box>
    </ChakraProvider>

      ) : (
        // Display a message instead of the table if there are no job posts
        <Box textAlign="center" fontSize="xl" mt={5}>
          <Empty description={"No Jobs Posted"}/>
        </Box>
      )
    }
      </Card>
      </Box>
    </Box>
          )}
              <ArchivedJobsModal isOpen={isArchivedJobsModalOpen} onClose={onArchivedJobsModalClose} archivedJobs={archivedJobs} />
              <JobPostModal isOpen={isOpen} onClose={onClose} schoolName={schoolName} schoolId={schoolId} addNewJob={addNewJobToList} teamMembers={teamMembers} userId={userId} pipelines={pipelines} />
              {selectedJob && (
                <EditJobPostModal isOpen={isDetailsModalOpen} onClose={onEditCloseModal} job={selectedJob}   replaceJob={replaceJob} schoolId={schoolId} teamMembers={teamMembers} userId={userId} pipelines={pipelines} />       
              )}
              {archivingJob && (
                  <ConfirmArchiveModal isOpen={isCofirmArchiveModalOpen} onClose={onArchiveCloseModal} job={archivingJob} replaceJob={replaceJob} />
              )}
              {boostingJob && (
                  <BoostJobModal isOpen={isBoostJobModalOpen} onClose={onBoostJobCloseModal} job={boostingJob} replaceJob={replaceJob} />
              )}
              {selectedAdminJob && (
              <AdminJobScreen
                isOpen={isAdminJobDrawerOpen}
                onClose={onAdminJobDrawerClose}
                job={selectedAdminJob}
              />
              )}
              {selectedJobEmail && (
                <EditJobPostEmailsModal isOpen={isEmailModalOpen} onClose={onEmailCloseModal} job={selectedJobEmail}   replaceJob={replaceJob} emailTemplates={emailTemplates} org={org} />       
              )}
                  {sharingJob && (
                  <ShareJobModal isOpen={isShareJobModalOpen} onClose={onShareJobCloseModal} job={sharingJob} replaceJob={replaceJob} />
              )}

    </ChakraProvider>

  );
}

export default SchoolDashboardJobs;
