import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Avatar,
  Grid,
  GridItem,
  ChakraProvider,
  Flex,
  Skeleton,
  Checkbox,
  useDisclosure, useToast,
  Table, Card,
  Thead,
  Tbody,
  Tr,
  Th,
  Td, Tabs, TabList, TabPanels, Tab, TabPanel,
} from '@chakra-ui/react';
import { Empty, Input, Tag } from 'antd';
import { FiChevronDown, FiChevronUp, FiClock, FiLink, FiPlus } from "react-icons/fi";
import { PiHandsClapping } from "react-icons/pi";

import { useNavigate, useLocation } from 'react-router-dom';
import { useSchool } from '../../../context/admin_selected_school';
import JobPostModal from '../../../components/post_job_modal';
import EditJobPostModal from '../../../components/edit_job_post_modal';
import ConfirmArchiveModal from '../../../components/archive_job_post_confirm_modal';
import BoostJobModal from '../../../components/boost_job_post_modal';
import AdminJobScreen from '../../../components/admin_job_post_drawer';
import ShareJobModal from '../../../components/share_job_modal';
import SourceDrawer from '../../../components/source_profile_drawer';


function AdminTalentCollective() {
  const toast = useToast();
  const [isCopied, setIsCopied] = useState(false);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [applicationId, setApplicationId] = useState(null);
    const [aiMatchScoreReason, setAiMatchScoreReason] = useState(null);
    const [selectedJobTitle, setSelectedJobTitle] = useState(null);
    const [selectApplicationMatchScore, setSelectApplicationMatchScore] = useState(null);
    const [selectedApplicationStatus, setSelectedApplicationStatus] = useState(null);
    const [selectedApplicationSource, setsSlectedApplicationSource] = useState(null);
    const [selectedSourceUserId, setSelectedSourceUserId] = useState(null);
  
    const [selectedApplicationDate, setSlectedApplicationDate] = useState(null);
    const [applications, setApplications] = useState([]);

    const openDrawer = (userId, applicationID, aiMatchScoreReasoning, jobTitle, applicationMatchScore, applicationStatus, applicationSource, applicationDate, selectedSourceUserId) => {
        //  alert(applicationSlug)
          setSelectedUserId(userId);
          setApplicationId(applicationID)
          setAiMatchScoreReason(aiMatchScoreReasoning)
          setSelectedJobTitle(jobTitle)
          setIsDrawerOpen(true);
          setSelectApplicationMatchScore(applicationMatchScore)
          setSelectedApplicationStatus(applicationStatus)
          setsSlectedApplicationSource(applicationSource)
          setSlectedApplicationDate(applicationDate)
          setSelectedSourceUserId(selectedSourceUserId)
        };
      
        const closeDrawer = () => {
          setIsDrawerOpen(false);
          setSelectedUserId(null); // Optionally reset selected user ID on close
          setApplicationId(null);
          setSelectedSourceUserId(null);
        };
      
        const handleApplicationStatusUpdate = (updatedApplication) => {
            // Update the applications state with the updated application data
            setApplications(prevApplications => {
              return prevApplications.map(application => {
                if (application.id === updatedApplication.id) {
                  return updatedApplication; // This is the updated application
                }
                return application; // This returns unchanged applications
              });
            });
          };


const navigate = useNavigate();
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const initialPage = parseInt(searchParams.get('page')) || 1;
const initialSortBy = searchParams.get('sort_by') || 'school_name';
const initialOrder = searchParams.get('order') || 'asc';
const [currentPage, setCurrentPage] = useState(initialPage);
const [sortBy, setSortBy] = useState(initialSortBy);
const [order, setOrder] = useState(initialOrder);
const { isOpen, onOpen, onClose } = useDisclosure();
const [userId, setUserId] = useState(null);
const [isLoading, setIsLoading] = useState(true);
const [schools, setSchools] = useState([]);
const [totalPages, setTotalPages] = useState(1);

const [users, setUsers] = useState([]);

 


  const {
    isOpen: isAdminJobDrawerOpen,
    onOpen: onAdminJobDrawerOpen,
    onClose: onAdminJobDrawerClose,
  } = useDisclosure();
  const [selectedAdminJob, setSelectedAdminJob] = useState(null);
  const handleOpenAdminJobDrawer = (job) => {
    setSelectedAdminJob(job);
    onAdminJobDrawerOpen();
  };




  useEffect(() => {
    fetchCommunity(currentPage, sortBy, order);
  }, [currentPage, sortBy, order]);

  const fetchCommunity = (page, sortBy, order) => {
    setIsLoading(true);
    fetch(`https://www.pursueli.com/api/admin_get_source_talent/?page=${page}&sort_by=${sortBy}&order=${order}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      setUsers(data.users);
      setTotalPages(data.total_pages);
      setIsLoading(false);
    })
    .catch(error => console.error("Error fetching schools:", error));
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    navigate(`?page=${newPage}`);
  };

const handleSortChange = (sortField) => {
  const newOrder = sortBy === sortField && order === 'asc' ? 'desc' : 'asc';
  setSortBy(sortField);
  setOrder(newOrder);
  navigate(`?page=1&sort_by=${sortField}&order=${newOrder}`);
};


const replaceSchool = (updatedSchool) => {
    const updatedSchools = schools.map((school) => {
      if (school.id === updatedSchool.id) {
        // Replace the job post with the updated one
        return updatedSchool;
      }
      return school;
    });
    setSchools(updatedSchools); // Update the state with the modified array
  };
const handleCCSAMembership = async (schoolId, isActive) => {
    // Optimistically update the UI
    setSchools((prevSchools) => prevSchools.map(school => {
      if (school.id === schoolId) {
        // Temporarily update the job post that was toggled
        return { ...school, ccsa_member: isActive };
      }
      return school;
    }));
  
    try {
      const response = await fetch(`https://www.pursueli.com/api/ccsa_admin_get_community/${schoolId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ is_active: isActive }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update job status.');
      }
      const data = await response.json();
      console.log("data mane, ", data);
      const newSchoolId = data.new_school.id;

      const newSchool = data.new_school; // Assuming the API returns the new job data under 'new_job'
      replaceSchool(newSchool); // Update the job list with the new job details
  
      // Request succeeded, no further action needed
      console.log('Job status updated successfully');
    } catch (error) {
      console.error('Error updating job status:', error);
      // Revert the change in the UI if the request fails
      setSchools((prevSchools) => prevSchools.map(school => {
        if (school.id === schoolId) {
          // Revert the is_active status
          return { ...school, ccsa_member: !isActive };
        }
        return school;
      }));

      // Optionally, inform the user that the update failed
    }
  };

const renderSortArrow = (field) => {
    if (sortBy === field) {
      if (order === 'asc') {
        return <FiChevronDown style={{marginLeft:'10px', fontSize:'17px'}} />;
      } else {
        return <FiChevronUp style={{marginLeft:'10px', fontSize:'17px'}}/>;

      }
    }
    return null;
  };


  const bios = {
    "alexia.divittorio@gmail.com": "Alexia Divittorio is a dedicated high school mathematics teacher with over ten years of experience in the classroom. She specializes in creating engaging and inclusive learning environments for her students.",
    "admin@admin.com": "Robert Crayton serves as the principal of a charter school in Concord, CA. He is committed to fostering academic excellence and holistic development in his students.",
    "test3@email.com": "Test3 Test3 is an educational consultant with a focus on curriculum development and teacher training. She has worked with various schools to enhance educational outcomes.",
    "jane@test.com": "Jane Doea is a middle school science teacher known for her innovative teaching methods. She is passionate about inspiring students to explore and understand the natural world.",
    "janesmith@test.com": "Jane Smith is a veteran elementary school teacher with expertise in early childhood education. She is dedicated to nurturing young minds and fostering a love for learning.",
    "craytonrobert@gmail.com": "Kia Sonya is an assistant principal at a private school, where she focuses on student affairs and disciplinary actions. She aims to create a positive and supportive school culture.",
    "courtney.allen108@yahoo.com": "Courtney Allen is a literacy coach who works with teachers to improve reading and writing instruction. She is committed to helping students develop strong literacy skills.",
    "stephanie.espinoza.60@gmail.com": "Stephanie Espinoza is a bilingual education specialist who advocates for English Language Learners. She designs programs to support language development and academic achievement.",
    "jh@test.com": "Jessica Herenedez is a school counselor providing academic, career, and emotional support to students. She strives to help students achieve their full potential.",
    "sandramurillo78@hotmail.com": "Sandra Mojica Murillo is a special education teacher dedicated to meeting the needs of students with disabilities. She collaborates with families and educators to create individualized learning plans."
  };
  const cities = [
    "Los Angeles, CA",
    "San Francisco, CA",
    "San Diego, CA",
    "Sacramento, CA",
    "Fresno, CA",
    "San Jose, CA",
    "Oakland, CA",
    "Long Beach, CA",
    "Bakersfield, CA",
    "Anaheim, CA"
  ];
  const citiesByEmail = {
    "alexia.divittorio@gmail.com": cities[0],
    "admin@admin.com": cities[1],
    "test3@email.com": cities[2],
    "jane@test.com": cities[3],
    "janesmith@test.com": cities[4],
    "craytonrobert@gmail.com": cities[5],
    "courtney.allen108@yahoo.com": cities[6],
    "stephanie.espinoza.60@gmail.com": cities[7],
    "jh@test.com": cities[8],
    "sandramurillo78@hotmail.com": cities[9]
  };

  const tagColors = {
    "Hidden Gem": "blue",
    "Local": "green",
    "Highly Decorated": "purple",
    "Seasoned": "orange",
  };

  const candidates = [
    {
      name: "Robela Clay",
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3hyK-M2Ne7mesafHaU5-9c-Khwz9oNlNbLGLTpEUcE0tJMQ9227FIyxJ2zmIqJjSa_Os&usqp=CAU",
      overview: "Robela is a highly skilled educator with over 10 years of experience. She is known for her innovative teaching methods and strong leadership skills.",
      tags: ["Hidden Gem", "Local"],
    },
    {
      name: "Alexia Divittorio",
      photo: "https://liketherazor.com/wp-content/uploads/2022/12/Azeb-Kingsmore008-Edit-WEB-VERSION-Chris-Gillett-Houston-Headshot-Photographer.jpg",
      overview: "Alexia is a dedicated high school mathematics teacher with a passion for creating engaging learning environments.",
      tags: ["Highly Decorated"],
    },
    {
      name: "Robert Crayton",
      photo: "https://media.licdn.com/dms/image/v2/D5603AQH8KXzIJssE4Q/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1697217689514?e=1729123200&v=beta&t=jdOuZOTfwcz8bgj-ERwVC5pB90rN_-nAxh4WGzCR1_c",
      overview: "Robert is a principal committed to fostering academic excellence and holistic development.",
      tags: ["Seasoned"],
    },
    {
      name: "Jane Doe",
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToaYSs-VyVlxydtrGyjm0-AF2Lw_cAYzRHSA&s",
      overview: "Jane is a middle school science teacher known for her innovative methods.",
      tags: ["Local", "Seasoned"],
    },
    {
      name: "Courtney Allen",
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUKNomCi3qs0vl2aQkv92B6DoKv1J4sUwe_w&s",
      overview: "Courtney is a literacy coach focused on improving reading and writing instruction.",
      tags: ["Hidden Gem"],
    },
    {
      name: "Stephanie Espinoza",
      photo: "https://example.com/stephanie.jpg",
      overview: "Stephanie is a bilingual education specialist advocating for English Language Learners.",
      tags: ["Highly Decorated"],
    },
    {
      name: "Jessica Hernandez",
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3H3YuCX7--vDhqftPwcvVSC2sc3d229krDw&s",
      overview: "Jessica is a school counselor dedicated to academic, career, and emotional support for students.",
      tags: ["Seasoned"],
    },
    {
      name: "Sandra Murillo",
      photo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0wlCuAqlLx1TgGT_-1-EzxT7dtAcvyZgaDQ&s",
      overview: "Sandra is a special education teacher dedicated to meeting the needs of students with disabilities.",
      tags: ["Local"],
    },
  ];
  
  const dummyUsers = [
    {
      id: 1,
      first_name: 'John',
      last_name: 'Doe',
      photo: 'https://media.licdn.com/dms/image/v2/C5603AQG6f6fHtZ4ESw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626900010162?e=2147483647&v=beta&t=epu6Bmy4wrkHPc6pTMcZm2-qWWSRHzi1lLupFNuvFo4',
      date_joined: '2024-05-10',
      open_to_jobs: true,
    },
    {
      id: 2,
      first_name: 'Maria',
      last_name: 'Garcia',
      photo: 'https://media.licdn.com/dms/image/v2/D4E03AQGqdS8B4WXEww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704579292377?e=2147483647&v=beta&t=IQ0lYMbZ8s8k3JTnz9F42xsT_ogpdrLpNPiJE1c07_4',
      date_joined: '2024-06-15',
      open_to_jobs: false,
    },
    {
      id: 3,
      first_name: 'Michael',
      last_name: 'Johnson',
      photo: 'https://media.licdn.com/dms/image/v2/C4E03AQGQj7CBRBoLaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619600527737?e=2147483647&v=beta&t=zXwT4mQeq5RV1AAUrt0XmusUTnSf6rV8OIMjuisPwxY',
      date_joined: '2024-07-20',
      open_to_jobs: true,
    },
    {
      id: 4,
      first_name: 'Ana',
      last_name: 'Martinez',
      photo: 'https://media.licdn.com/dms/image/v2/D4E03AQHcGNAT5_yjog/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1720805573888?e=2147483647&v=beta&t=fdj7K0_KflTm9ViFeANf_AKRysoyxw4iQyNPRP71tx4',
      date_joined: '2024-04-05',
      open_to_jobs: true,
    },
    {
      id: 5,
      first_name: 'Chris',
      last_name: 'Brown',
      photo: 'https://media.licdn.com/dms/image/v2/C4E03AQFntOrRJrC6Vg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1637942595324?e=2147483647&v=beta&t=_x0K4cHJscuamRWklQAJ2wgX2_aahTQkzNSLjy7Shq0',
      date_joined: '2024-08-01',
      open_to_jobs: false,
    },
    {
      id: 6,
      first_name: 'Sofia',
      last_name: 'Lopez',
      photo: 'https://media.licdn.com/dms/image/v2/C4E03AQEYYlloy7smqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661470246636?e=2147483647&v=beta&t=QECMrTPxkMgL3oYfVDHIuefS2F9TDiAN-cvi9X7jzJg',
      date_joined: '2024-03-18',
      open_to_jobs: true,
    },
    {
      id: 7,
      first_name: 'James',
      last_name: 'Williams',
      photo: 'https://media.licdn.com/dms/image/v2/C5603AQEqF3Y8PqRVIg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1655924810926?e=2147483647&v=beta&t=i03FSFSrQXdGwXBai7x1yXHnOUmAyiX0cKvIxCLQIlg',
      date_joined: '2024-02-27',
      open_to_jobs: true,
    },
    {
      id: 8,
      first_name: 'Isabella',
      last_name: 'Johnson',
      photo: 'https://media.licdn.com/dms/image/C4D03AQGRBGnYED1UNw/profile-displayphoto-shrink_800_800/0/1626896898527?e=2147483647&v=beta&t=ukJVlw9pFGVwAmzU13Gz_Jg1kkcT0P6luimOcgYnwOI',
      date_joined: '2024-01-22',
      open_to_jobs: false,
    },
    {
      id: 9,
      first_name: 'David',
      last_name: 'Miller',
      photo: 'https://media.licdn.com/dms/image/v2/D5610AQHrWnHgG_cxXg/image-shrink_1280/image-shrink_1280/0/1712085908342?e=2147483647&v=beta&t=vOUu_-Izz4vNirzP-zD06mZ0Y14CZpTAEiWSwnBP6h0',
      date_joined: '2024-07-11',
      open_to_jobs: true,
    },
    {
      id: 10,
      first_name: 'Bob',
      last_name: 'Manner',
      photo: 'https://media.licdn.com/dms/image/v2/D5603AQGHltgLOkbWWQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1709775291284?e=2147483647&v=beta&t=KQBJbymv5WV8A947YbuPwG3h4leryOpldf1mjP5rGD4',
      date_joined: '2024-05-14',
      open_to_jobs: true,
    },
  ];
  
  

  
  const numberOfRows = 10; // For example
  const handleUserJobSearchStatusUpdate = (id) => {
    const url = `https://www.pursueli.com/api/edpursuit_admin_update_user_open_to_work_status/`;
    const payload = { user: id };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(payload),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
      setUsers(prevUsers => prevUsers.map(user => {
        if (user.id === id) {
          return { ...user, open_to_jobs: !user.open_to_jobs };
        }
        return user;
      }));
    })
    .catch(error => {
      console.error('Error:', error);
    });
};

const handleHideUser = (id) => {
    const url = `https://www.pursueli.com/api/edpursuit_admin_hide_user_from_recruiters/`;
    const payload = { user: id };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(payload),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
      setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
    })
    .catch(error => {
      console.error('Error:', error);
    });
};


const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    
    const formattedDate = date.toLocaleDateString('en-US', options);
    
    // Extract the parts of the date
    const [month, day, year] = formattedDate.split(' ');
    
    // Add the appropriate suffix to the day
    const dayWithSuffix = day.replace(',', '').replace(/\d+/, (match) => {
      switch (match) {
        case '1':
        case '21':
        case '31': return `${match}st`;
        case '2':
        case '22': return `${match}nd`;
        case '3':
        case '23': return `${match}rd`;
        default: return `${match}th`;
      }
    });
  
    // Return the formatted date with the correct placement of the suffix and comma
    return `${month} ${dayWithSuffix}, ${year}`;
  };
  
  const handleCopyReferralUrl = () => {
    const referralUrl = "https://www.edpursuit.com/talent_collective_invitation?src=acme_charter_high_school"; // Replace with your actual URL
    navigator.clipboard.writeText(referralUrl).then(() => {
      setIsCopied(true);
      toast({
        title: "Referral URL copied.",
        description: "The referral URL has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };
  

  return (
    <ChakraProvider>
        

<Box flex="1" p={8} left={'6%'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'}> 

        <Box px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px', borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
        <Text mb={4} fontSize="xl" textAlign={'start'} fontWeight="bold">Edpursuit Talent Collective</Text>



        <Tabs orientation="" >

        <TabList mt={5}>
    <Tab py={0} px={0} style={{WebkitJustifyContent:'flex-start', justifyContent:'flex-start', width:'50%'}}
      _active={{ bg: 'transparent' }}
    >
    <Box justifyContent={'space-between'}>
                <Text mb={0} fontSize="md" textAlign={'start'} fontWeight="bold">Recommendations</Text>
                <Box mb={3} fontSize="sm" textAlign={'start'}>Talent collective members edpursuit AI has recommended you consider.
              </Box>
              </Box>       
           
        
        
        </Tab>
    <Tab py={2} style={{WebkitJustifyContent:'flex-start', justifyContent:'flex-start', width:'50%'}}
          _active={{ bg: 'transparent' }}

    >
    <Box width={'100%'}>
    <Flex mb={0} minW={'100%'} fontSize="md"  textAlign={'start'} justifyContent={'space-between'} alignItems={'flex-end'} fontWeight="bold">
        <Box>
        Members you've referred

        </Box>
                <Button variant={'outline'} size={'sm'} alignSelf={'bottom'} onClick={handleCopyReferralUrl} >
                    <FiLink style={{marginRight:'5px'}}/>
                    copy referral url
                </Button>
                </Flex>
                <Box mb={3} fontSize="sm" textAlign={'start'}>Candidates you've contributed to the collective.
              </Box>
              </Box>       
           
    </Tab>

        
  </TabList>

  <TabPanels >
        
        <TabPanel  minW={'100%'} px={0}     textAlign={'start'}>
        <Box style={{ borderWidth:'0px'}} py={'0px'}>

     <Grid templateColumns="repeat(4, 1fr)" gap={4}>
  {candidates.map((candidate, index) => (
    <Card key={index} p={3} shadow={'lg'}
    transition="transform 0.2s, box-shadow 0.2s"
  _hover={{
    transform: 'translateY(-5px)',
    boxShadow: 'xl',
  }}
    >
      <Flex justifyContent={'center'}>
        <Avatar size={'lg'} src={candidate.photo} />
      </Flex>
      <Box>
        <Text style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {candidate.name}
        </Text>
      </Box>
      <Box noOfLines={3} fontSize={'sm'}>
        {candidate.overview}
      </Box>
      <Box mt={3} mb={3} display={'flex'} gap={2}>
        {candidate.tags.map((tag, idx) => (
          <Tag key={idx} color={tagColors[tag]}>
            {tag}
          </Tag>
        ))}
      </Box>
      <hr />
      <Flex justifyContent={'center'} gap={3} mt={3}>
        <Button width={'75%'} size={'sm'} variant={'outline'} colorScheme='blue'>
          View
        </Button>
        <Button width={'100%'} size={'sm'} variant={'solid'} colorScheme='blue'>
          Invite to Apply
        </Button>
      </Flex>
    </Card>
  ))}
</Grid>


          </Box>

        </TabPanel>

                 
        <TabPanel  minW={'100%'} px={0}   textAlign={'start'} minH={'100%'}  maxH={'78vh'} overflowY={'scroll'}>
        {isLoading ? (
        <Box>
        <Box px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px', borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
                <Table variant="simple">
              <Thead>
                <Tr>
                  <Th color={'transparent'}>Active</Th>
                  <Th color={'transparent'}>Job Title</Th>
                  <Th color={'transparent'}>Views</Th>
                  <Th color={'transparent'}>Applications</Th>
                  <Th color={'transparent'}>Expires</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array(numberOfRows).fill().map((_, index) => (
                  <Tr key={index} padding={0} margin={0}>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'50%'} height="40px" width="40px"/></Td>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'320px'} /></Td>
                    <Td paddingY={3}margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'80px'} /></Td>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'80px'} /></Td>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'80px'} /></Td>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'40px'} /></Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box>
            <Box>
              {users && users.length > 0 ? (
                <ChakraProvider>
                  <Box w="100%" >
                  <Grid
  px={4}
  textAlign={'start'}
  fontSize={'sm'}
  fontWeight={'500'}
  p={2}
  templateColumns="repeat(24, 1fr)"
  mb={1}
  borderBottomWidth={0}
  borderColor="gray.200"
  py={2}
  borderRadius="0"
>
  <GridItem textTransform="" colSpan={7}>
    <Text
      display={'flex'}
      alignItems={'center'}
      onClick={() => handleSortChange('school_name')}
      cursor="pointer"
      _hover={{ color: 'blue.500' }}
    >
    </Text>
  </GridItem>
  {/* <GridItem textTransform="" colSpan={4} textAlign={'start'}>
    <Text
      display={'flex'}
      justifyContent={'start'}
      alignItems={'center'}
      onClick={() => handleSortChange('num_jobs')}
      cursor="pointer"
      _hover={{ color: 'blue.500' }}
    >
      Joined On
    </Text>
  </GridItem> */}


  
</Grid>
                    {dummyUsers.map((user, index) => (
                      <Grid
                        textAlign={'start'}
                        alignItems="center"
                        marginX={'auto'}
                        templateColumns="repeat(24, 1fr)"
                        gap={2}
                        boxShadow="md"
                        borderWidth="1px"
                        borderRadius="md"
                        p={2}
                        px={4}
                        mb={3}
                        key={user.id}
                        overflowX={'scroll'}
                      >
                        <GridItem colSpan={6}>
                          <a style={{display:'flex', alignItems:'center', overflow:'clip'}} onClick={() => openDrawer(user.id, 1, "reasoning here", "job title here", 2, "application.status", "application.source", "formatDate(application.application_date)", user.id  )}>
                            <Avatar src={user.photo}   name={`${user.first_name} ${user.last_name}`}  mr={3} size={'md'}></Avatar>
                            <Box>
                            <Text fontWeight={'bold'}  onClick={() => handleOpenAdminJobDrawer(user)}>{user.first_name} {user.last_name}</Text>
                            {/* <Text fontSize={'sm'} onClick={() => handleOpenAdminJobDrawer(user)}>{user.email}</Text> */}

                            </Box>
                          </a>
                        </GridItem>
                        <GridItem colSpan={4}  textAlign={'start'}>
                        <Text noOfLines={2} >
                        {formatDate(user.date_joined)}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={4}  textAlign={'start'} style={{display:'flex'}}>
                        {user.open_to_jobs ? 
                                            <Button style={{fontWeight:'normal'}}  onClick={() => handleUserJobSearchStatusUpdate(user.id)}>
                                                <PiHandsClapping style={{marginRight:'5px'}} />
                                              endorse
                                            </Button>      
                        
                        : 
                        <Flex alignItems={'center'} color ='teal' style={{fontWeight:'normal'}} onClick={() => handleUserJobSearchStatusUpdate(user.id)}>
                        <PiHandsClapping style={{marginRight:'5px'}} />
                        endorsed
                        </Flex>      
                        
                        }

                        {/* <Button style={{fontWeight:'normal'}} size={'sm'} onClick={() => handleUserJobSearchStatusUpdate(user.id)}>
                            <PiHandsClapping style={{marginRight:'5px'}} />
                            {user.open_to_jobs ? 'endorse' : 'Not Open to Jobs'}
                        </Button>               */}
                        </GridItem>
                        <GridItem colSpan={10}  textAlign={'start'} style={{display:'flex'}}>
                            <Input
                            placeholder='Leave a public review for this candidate ...'
                            />
                        <Button ml={2} variant={'ghost'} onClick={() => handleUserJobSearchStatusUpdate(user.id)}>
                            <Box>
                            <FiPlus/>
                            </Box>
                        </Button>
                        </GridItem>
                      </Grid>
                    ))}
                    <Flex justifyContent="center" alignItems={'center'} mt={4}>

                        {currentPage !== 1 && totalPages > 1 && (
                            <Button size={'sm'} onClick={() => handlePageChange(currentPage - 1)}>
                                Previous
                            </Button>
                            )}

                      <Text fontSize={'small'} mx={7}>Page {currentPage} of {totalPages}</Text>
                      <Button size={'sm'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        Next
                      </Button>
                    </Flex>
                  </Box>
                </ChakraProvider>
              ) : (
                <Box textAlign="center" fontSize="xl" mt={5}>
                    {users}
                  <Empty description={"No Schools Found"}/>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
            
        </TabPanel>
        </TabPanels>

</Tabs>


















          </Box>








        </Box>


      
      <SourceDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        selectedUserId={selectedUserId}
        applicationId={applicationId}
        jobTitle={selectedJobTitle}
        aiMatchScoreReasoning={aiMatchScoreReason}
        applicationMatchScore={selectApplicationMatchScore}
        onApplicationStatusUpdate={handleApplicationStatusUpdate}
        applicationStatus={selectedApplicationStatus}
        applicationSource={selectedApplicationSource}
        applicationDate={selectedApplicationDate}
        sourceUserId={selectedSourceUserId}
      />
    </ChakraProvider>
  );
}

export default AdminTalentCollective;
