import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {Upload } from 'antd';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const UploadMultipleImages = ({onImagesChange}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([
   
  ]);
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = async ({ fileList: newFileList }) => {
    // Filter out removed files and only process newly added files
    const filesToProcess = newFileList.filter(file => !file.url && !file.preview);
    
    // Process each file to convert it to base64 and then set the preview property
    const processedFiles = await Promise.all(filesToProcess.map(async file => {
        // Convert file to base64
        const base64 = await getBase64(file.originFileObj);
        // Assign the base64 as the file's preview property
        file.preview = base64;
        return file;
    }));

    // Update fileList with processed files
    const updatedFileList = newFileList.map(file => {
        // Find the processed file that matches the original file
        const processedFile = processedFiles.find(pf => pf.uid === file.uid);
        // If a processed file is found, use it, otherwise return the original file
        return processedFile || file;
    });

    // Update local state with the updated file list
    setFileList(updatedFileList);

    // Call the onImagesChange prop with the new file list, including base64 URLs
    onImagesChange(updatedFileList.map(file => ({ imageUrl: file.preview })));
};

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  return (
    <>
      <Upload
        action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        multiple={true}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>


      <Modal isOpen={previewOpen} onClose={handleCancel}
      isCentered
      motionPreset='slideInBottom'

      >
        <ModalOverlay />
        <ModalContent p={2} style={{backgroundColor:'white'}}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
        </ModalContent>
      </Modal>





    </>
  );
};
export default UploadMultipleImages;