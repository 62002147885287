import React, { useRef, useEffect, useState } from 'react';
import {
  Modal, Alert, AlertDescription, AlertTitle, AlertIcon,
  ModalOverlay, Text, Box, VStack,
  ModalContent,  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ModalHeader,
  ModalFooter,
  ModalBody, option,
  ModalCloseButton, Spinner,
  Button,
  FormControl,
  FormLabel,
  Input, Select,
  useToast, Flex, Card,
  Textarea,NumberInputField, NumberInput, Divider
} from '@chakra-ui/react';
import { FiCheck } from "react-icons/fi";
import { FiUpload, FiClipboard, FiServer, FiX } from "react-icons/fi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { marked } from 'marked';
import { upload } from '@testing-library/user-event/dist/upload';
import { UploadSingleResume } from './upload_resume';
import ImageUploading from 'react-images-uploading';
import { FiArchive } from "react-icons/fi";
import { ChevronDownIcon } from '@chakra-ui/icons';

function ConfirmArchiveModal({ isOpen, onClose, job, replaceJob, schoolId }) {

  const [jobTitle, setJobTitle] = useState(job.title);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();


  const handleSelection = (reason) => {
    handleSubmit(job.id, reason)
  };


  const handleSubmit = async (job_id, reason) => {
    // Prepare the data to be sent
    setIsLoading(true)
    const payload = {
      id: job_id,
      reason: reason,
    };
  
    try {
      const response = await fetch('https://www.pursueli.com/api/admin_archive_job/', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Send the JSON payload
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("data mane, ", data);
        const newJobId = data.new_job.id;
  
        const newJob = data.new_job; // Assuming the API returns the new job data under 'new_job'
        replaceJob(newJob); // Update the job list with the new job details
        toast({
          title: 'Job post archived.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose(); // Close the modal
      } else {
        // Handle server errors (e.g., 4XX, 5XX responses)
        const errorData = await response.json(); // Assuming the server sends an error message as JSON
        throw new Error(errorData.message || 'Failed to arhive job post.');
      }
    } catch (error) {
      // Handle errors in the request itself (e.g., network error)
      toast({
        title: 'Error archiving job post.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      
      <ModalOverlay  />
      <ModalContent size={'md'} backgroundColor={'#F7FAFC'}>

        <ModalHeader>

            <Text color={'#4A5568'} fontSize={'sm'}>{job.title} at {job.school_sites[0].school_name}</Text>
        </ModalHeader>
        <ModalCloseButton />



           
        <Box p={5}>
                    <form onSubmit={handleSubmit} >
                    <Flex justifyContent={'center'} mb={3}>
                    <Box w={'50px'} h={'50px'} justifySelf={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={'#FED7D7'}>
                    <FiArchive color='#E53E3E' fontSize={'20px'}/>
                    </Box>
                    </Flex>



            <Text fontWeight={'bold'} textAlign={'center'} fontSize={'lg'}>
                Are you sure you want to archive this job?
            </Text>
            <Text fontSize={'sm'} textAlign={'center'} color={'gray'}>
            If you'll hire for this role again, consider deactivating it instead of archiving from the previous screen.
            </Text>
        
        


<Flex mx={4} justifyContent={'space-between'} alignItems={'center'}>
{/* <Button size={'xs'}  colorScheme='red' variant={'outline'} mt={'-8px'} >clear all fields</Button> */}
</Flex>

          <ModalBody>
        



          </ModalBody>

          <ModalFooter justifyContent={'center'}>
          <Button w={'50%'} variant="ghost" onClick={onClose}>Cancel</Button>
 

          
          <Menu>
            <MenuButton as={Button} w={'50%'} ml={4} style={{backgroundColor:'rgb(229, 62, 62)'}} borderColor='red' color='white'>
                {isLoading ? <Spinner color="white" /> : "Archive"}
            </MenuButton>
            <MenuList>
                <MenuItem onClick={() => handleSelection("We've filled this role with a Pursueli applicant.")}>We've filled this role with a Pursueli applicant.</MenuItem>
                <MenuItem onClick={() => handleSelection("We've filled this role with an external applicant.")}>We've filled this role with an external applicant.</MenuItem>
                <MenuItem onClick={() => handleSelection("We are no longer hiring for this position.")}>We are no longer hiring for this position.</MenuItem>
                <MenuItem onClick={() => handleSelection("The job posting was made in error.")}>The job posting was made in error.</MenuItem>
                <MenuItem onClick={() => handleSelection("Other")}>Other</MenuItem>
            </MenuList>
            </Menu>






          </ModalFooter>

        </form>
        </Box>


      </ModalContent>
    </Modal>
  );
}

export default ConfirmArchiveModal;
