import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Spinner,
  Card,
  Flex, Skeleton, SkeletonText,
  Text,
  ChakraProvider,
  useDisclosure
} from '@chakra-ui/react';
import { DragDropContext } from 'react-beautiful-dnd';

import { useSchool } from '../../../context/admin_selected_school';

import SideNavBar from '../../../components/side_nav_bar';
import JobPostModal from '../../../components/post_job_modal';
import EditJobPostModal from '../../../components/edit_job_post_modal';
import ConfirmArchiveModal from '../../../components/archive_job_post_confirm_modal';

import { FiArchive, FiArrowUpRight, FiEdit3, FiGlobe, FiMail, FiPlus, FiRefreshCw, FiZap } from "react-icons/fi";
import BoostJobModal from '../../../components/boost_job_post_modal';
import AdminJobScreen from '../../../components/admin_job_post_drawer';
import EditJobPostEmailsModal from '../../../components/edit_job_emails_modal';

import Column from '../../../components/column';
import Item from '../../../components/item';
import { Select, Empty, Tag } from 'antd';

function CandidatePipeline() {
  const { Option } = Select;
  const [roleFilter, setRoleFilter] = useState('all');
  const [filterLoading, setFilterLoading] = useState(false);
  const [columns, setColumns] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { school } = useSchool();

  const [selectedJobEmail, setSelectedJobEmail] = useState(null);
  const { isOpen: isEmailModalOpen, onOpen: onEmailModalOpen, onClose: onEmailModalClose } = useDisclosure();
  const [selectedJob, setSelectedJob] = useState(null);
  const { isOpen: isDetailsModalOpen, onOpen: onDetailsModalOpen, onClose: onDetailsModalClose } = useDisclosure();
  const [archivingJob, setArchivingJob] = useState(null);
  const { isOpen: isCofirmArchiveModalOpen, onOpen: onCofirmArchiveModalOpen, onClose: onCofirmArchiveModalClose } = useDisclosure();
  const [boostingJob, setBoostingJob] = useState(null);
  const { isOpen: isBoostJobModalOpen, onOpen: onBoostJobModalOpen, onClose: onBoostJobModalClose } = useDisclosure();
  const {
    isOpen: isAdminJobDrawerOpen,
    onOpen: onAdminJobDrawerOpen,
    onClose: onAdminJobDrawerClose,
  } = useDisclosure();
  const [selectedAdminJob, setSelectedAdminJob] = useState(null);
  const [jobPosts, setJobPosts] = useState([]);
  const [schoolName, setSchoolName] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [org, setOrg] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [interviewPipelines, setInterviewPipelines] = useState([]);
  const [applications, setApplications] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState('');
  const [pipelines, setPipelines] = useState([]);

  const fetchPipelinesAndCandidates = () => {
    const schoolParam = encodeURIComponent(school);
    fetch(`https://www.pursueli.com/api/admin_get_interview_pipelines/?school=${schoolParam}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      }
    })
      .then(response => response.json())
      .then(data => {
        setInterviewPipelines(data.interview_pipelines || []);
        setSchoolName(data.school_name);
        setSchoolId(data.school_id);
        setIsLoading(false);
        setOrg(data.org);
        setUserId(data.user_id);
        setJobs(data.jobs || []);
        console.log(data.default_interview_pipeline);
        if (data.default_interview_pipeline && data.default_interview_stages) {
          const stages = data.default_interview_stages.sort((a, b) => a.order - b.order);
          const applications = data.default_applications || [];
          setSelectedPipeline(data.default_interview_pipeline.id);
          const initialColumns = stages.reduce((acc, stage) => {
            acc[stage.id] = {
              name: stage.name,
              items: [],
              color: stage.color,
              background: stage.background,
              order: stage.order,
              id: stage.id
            };
            return acc;
          }, {});

          applications.forEach(application => {
            if (application.stage && application.stage.id && initialColumns[application.stage.id]) {
              initialColumns[application.stage.id].items.push(application);
            } else {
              console.error('Application or stage is null:', application);
            }
          });

          setColumns(initialColumns);
        } else {
          console.error('Default interview pipeline or stages not defined.');
        }
      })
      .catch(error => console.error("Error fetching pipelines and candidates:", error));
  };

  useEffect(() => {
    fetchPipelinesAndCandidates();
  }, [school]);

  const handleDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      sourceItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
      });
    } else {
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });

      // Making POST request to backend to update the application
      try {
        const response = await fetch('https://www.pursueli.com/api/admin_update_application_stage/', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            applicationId: removed.id,
            newStageId: destination.droppableId
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to update application stage');
        }

        const data = await response.json();
        console.log('Application updated:', data);
      } catch (error) {
        console.error('Error updating application:', error);
      }
    }

    console.log('Item moved:', removed);
    console.log('Source column:', source.droppableId);
    console.log('Destination column:', destination.droppableId);
  };

  const openJobDetailsModal = (job) => {
    setSelectedJob(job);
    onDetailsModalOpen();
  };

  const onEditCloseModal = () => {
    onDetailsModalClose();
    setSelectedJob(null);
  };

  const openEmailModal = (job) => {
    setSelectedJobEmail(job);
    onEmailModalOpen();
  };

  const onEmailCloseModal = () => {
    onEmailModalClose();
    setSelectedJobEmail(null);
  };

  const openCofirmArchiveModal = (job) => {
    setArchivingJob(job);
    onCofirmArchiveModalOpen();
  };

  const onArchiveCloseModal = () => {
    onCofirmArchiveModalClose();
    setArchivingJob(null);
  };

  const openBoostJobModal = (job) => {
    setBoostingJob(job);
    onBoostJobModalOpen();
  };

  const onBoostJobCloseModal = () => {
    onBoostJobModalClose();
    setBoostingJob(null);
  };

  const handleOpenAdminJobDrawer = (job) => {
    setSelectedAdminJob(job);
    onAdminJobDrawerOpen();
  };

  const [statusFilter, setStatusFilter] = useState('');
  const [pipelineFilter, setPipelineFilter] = useState('');
  const [matchScoreFilter, setMatchScoreFilter] = useState('');
  const prevPipelineRef = useRef();
  const [pipelineChangeNumber, setPipelineChangeNumber] = useState(1);

  const handlePipelineChange = (value) => {
    setPipelineFilter(value);
    setSelectedPipeline(value);
    setRoleFilter('all');
    setPipelineChangeNumber(pipelineChangeNumber + 1)
  };
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
        isInitialMount.current = false;
        return; // Skip the first render
    }

    console.log("prev")
    console.log(prevPipelineRef.current)
    console.log("new")
    console.log(selectedPipeline)

    if (prevPipelineRef.current !== selectedPipeline) {
        // setRoleFilter('all');
        prevPipelineRef.current = selectedPipeline; // Move this line inside the conditional
      }
  

    const filters = [];
    if (roleFilter) filters.push(`role=${encodeURIComponent(roleFilter)}`);
    if (statusFilter) filters.push(`status=${encodeURIComponent(statusFilter)}`);
    if (matchScoreFilter) filters.push(`matchScore=${encodeURIComponent(matchScoreFilter)}`);

    const filterQueryString = filters.length > 0 ? `&${filters.join('&')}` : '';

    const schoolParam = encodeURIComponent(school);

    fetch(`https://www.pursueli.com/api/admin_filter_pipeline_candidates/?pipeline=${selectedPipeline}&&school=${schoolParam}${filterQueryString}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.default_interview_pipeline && data.default_interview_stages) {
        const stages = data.default_interview_stages.sort((a, b) => a.order - b.order);
        const applications = data.default_applications || [];
        setJobs([]);
        setJobs(data.jobs || []);
        setApplications([]);
        setColumns([]);
        const initialColumns = stages.reduce((acc, stage) => {
          acc[stage.id] = {
            name: stage.name,
            items: [],
            color: stage.color,
            background: stage.background,
            order: stage.order,
            id: stage.id
          };
          return acc;
        }, {});

        applications.forEach(application => {
          if (application.stage && application.stage.id && initialColumns[application.stage.id]) {
            initialColumns[application.stage.id].items.push(application);
          } else {
            console.error('Application or stage is null:', application);
          }
        });

        setColumns(initialColumns);
        console.log("new data below");
        console.log(columns);
        console.log(applications);
        setFilterLoading(false);
      } else {
        console.error('Default interview pipeline or stages not defined.');
      }
    })
    .catch(error => console.error("Error fetching pipelines and candidates:", error));

  }, [school, roleFilter, pipelineChangeNumber]);

  const addStageStyle = {
    display: 'none',
    backgroundColor: 'white',
    minWidth: '275px',
    borderTopRightRadius: '5px',
    borderWidth: '0px',
    marginLeft: '10px',
    borderStyle: 'dotted',
    borderRadius: '15px',
    position: 'relative',
    maxHeight: '100%',
    minHeight: '73vh',
    overflow: 'hidden',
  };

  const handleApplicationStatusUpdate = (updatedApplication) => {
    setColumns(prevColumns => {
      // Find the previous stage of the application
      let previousStageId = null;
      for (const [stageId, stage] of Object.entries(prevColumns)) {
        if (stage.items.some(item => item.id === updatedApplication.id)) {
          previousStageId = stageId;
          break;
        }
      }

      // Remove the application from the previous stage
      const updatedColumns = { ...prevColumns };
      if (previousStageId) {
        updatedColumns[previousStageId].items = updatedColumns[previousStageId].items.filter(
          item => item.id !== updatedApplication.id
        );
      }

      // Add the application to the new stage
      if (updatedApplication.stage && updatedColumns[updatedApplication.stage.id]) {
        updatedColumns[updatedApplication.stage.id].items.push(updatedApplication);
      }

      return updatedColumns;
    });
  };

  return (
    <ChakraProvider>
      {isLoading ? (
   <Box flex="1" p={8} left={'6%'} overflow={'clip'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'} bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}> 

   <Box style={{  borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
            <Card paddingTop="30px" minHeight="91vh" maxHeight="91vh" boxShadow="xl">
              <Box position="sticky" paddingLeft="30px" paddingRight="30px">
                <Flex justifyContent="space-between">
                  <Skeleton height="20px" width="300px" />
                </Flex>
                <Flex width="100%" py={4} align="center">
                  <Box width="80%">
                    <Skeleton height="30px" />
                  </Box>
                </Flex>
              </Box>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                  paddingLeft: '27px',
                  paddingRight: '28px',
                  minHeight: '68vh',
                  maxHeight: '120vh',
                  zIndex: '0',
                }}
              >
                {[1, 2, 3, 4].map((_, index) => (
                  <Box
                    key={index}
                    backgroundColor="white"
                    minWidth="275px"
                    height="100vh"
                    paddingBottom="20px"
                    borderRadius="0px"
                    borderTopRightRadius="5px"
                    borderRightWidth="1px"
                    marginX="auto"
                    position="relative"
                    overflow="hidden"
                    marginRight="20px"
                  >
                    <Skeleton
                      position="sticky"
                      left="-10px"
                      top="0"
                      zIndex="10"
                      minWidth="100%"
                      borderTopRadius="5px"
                      py={3}
                      px={1}
                      borderBottomWidth={1}
                    >
                      <Flex justifyContent="space-between" alignItems="center" pr={3}>
                        <Skeleton height="20px" width="100px" />
                        <Skeleton height="20px" width="20px" />
                      </Flex>
                    </Skeleton>
                    <Box
                      style={{
                        paddingRight: '12px',
                        paddingLeft: '12px',
                        flexGrow: 1,
                        height: '100%',
                        overflowY: 'scroll',
                        paddingTop: 10,
                        scrollbarWidth: 'none', // Firefox
                        msOverflowStyle: 'none', // Internet Explorer 10+
                      }}
                    >
                      <style>{`
                        /* Hide scrollbar for Chrome, Safari and Opera */
                        .no-scrollbar::-webkit-scrollbar {
                          display: none;
                        }

                        /* Hide scrollbar for IE, Edge and Firefox */
                        .no-scrollbar {
                          -ms-overflow-style: none;  /* Internet Explorer 10+ */
                          scrollbar-width: none;  /* Firefox */
                        }
                      `}</style>
                      <Box className="no-scrollbar">
                        {[1, 2, 3, 4, 5, 6, 7].map((itemIndex) => (
                          <Card key={itemIndex} p={3} mb={3} style={{ textAlign: 'start', position: 'relative', zIndex: '1' }}>
                            <SkeletonText mt="4" noOfLines={2} spacing="4" />
                          </Card>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </div>
            </Card>
          </Box>
        </Box>
      ) : (
        <DragDropContext onDragEnd={handleDragEnd}>
<Box flex="1" p={8} left={'6%'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'} bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}> 
    <Box style={{  borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
              <Card paddingTop="30px" minHeight="91vh" maxHeight="91vh" boxShadow="xl" overflowY="clip">
                <Box position="sticky" style={{ paddingLeft: '30px', paddingRight: '30px' }}>
                  <Flex justifyContent="space-between">
                    <Text fontSize="xl" fontWeight="bold">{schoolName} Pipelines</Text>
                  </Flex>
                  <Flex width="100%" py={4} align="center">
                    <Box width="25%" mr={4}>
                      <Select style={{ width: '100%' }} placeholder="Filter by pipeline" defaultValue={selectedPipeline} onChange={(value) => { handlePipelineChange(value); setFilterLoading(true); setSelectedPipeline(value) }}>
                        {interviewPipelines.map(pipeline => (
                          <Option key={pipeline.id} value={pipeline.id}>{pipeline.name}</Option>
                        ))}
                      </Select>
                    </Box>
                    <Box width="25%" mr={4}>
                      <Select style={{ width: '100%' }} placeholder="Filter by role" value={roleFilter} onChange={(value) => { setRoleFilter(value); setFilterLoading(true); }}>
                        <Option value="all">All Roles</Option>
                        {jobs.map(job => (
                          <Option key={job.id} value={job.id}>{job.title}</Option>
                        ))}
                      </Select>
                    </Box>
                    <Box width="fit-content">
                      {filterLoading && <Spinner size="md" ml={6} color="gray" />}
                    </Box>
                  </Flex>
                </Box>

                {filterLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'nowrap',
                    //   overflowX: 'hidden',
                      overflowY: 'hidden',
                      paddingLeft: '27px',
                      paddingRight: '28px',
                      minHeight: '68vh',
                      maxHeight: '120vh',
                      zIndex: '0',
                    }}
                  >
                    {[1, 2, 3, 4].map((_, index) => (
                      <Box
                        key={index}
                        backgroundColor="white"
                        minWidth="275px"
                        height="100vh"
                        paddingBottom="20px"
                        borderRadius="0px"
                        borderTopRightRadius="5px"
                        borderRightWidth="1px"
                        marginX="auto"
                        position="relative"
                        overflow="hidden"
                        marginRight="20px"
                      >
                        <Skeleton
                          position="sticky"
                          left="-10px"
                          top="0"
                          zIndex="10"
                          minWidth="100%"
                          borderTopRadius="5px"
                          py={3}
                          px={1}
                          borderBottomWidth={1}
                        >
                          <Flex justifyContent="space-between" alignItems="center" pr={3}>
                            <Skeleton height="20px" width="100px" />
                            <Skeleton height="20px" width="20px" />
                          </Flex>
                        </Skeleton>
                        <Box
                          style={{
                            paddingRight: '12px',
                            paddingLeft: '12px',
                            flexGrow: 1,
                            height: '100%',
                            overflowY: 'scroll',
                            paddingTop: 10,
                            scrollbarWidth: 'none', // Firefox
                            msOverflowStyle: 'none', // Internet Explorer 10+
                          }}
                        >
                          <style>{`
                            /* Hide scrollbar for Chrome, Safari and Opera */
                            .no-scrollbar::-webkit-scrollbar {
                              display: none;
                            }

                            /* Hide scrollbar for IE, Edge and Firefox */
                            .no-scrollbar {
                              -ms-overflow-style: none;  /* Internet Explorer 10+ */
                              scrollbar-width: none;  /* Firefox */
                            }
                          `}</style>
                          <Box className="no-scrollbar">
                            {[1, 2, 3, 4, 5, 6, 7].map((itemIndex) => (
                              <Card key={itemIndex} p={3} mb={3} style={{ textAlign: 'start', position: 'relative', zIndex: '1' }}>
                                <SkeletonText mt="4" noOfLines={2} spacing="4" />
                              </Card>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </div>
                ) : (
                  <div style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'scroll', overflowY: 'hidden', paddingLeft: '27px', paddingRight: '28px', minHeight: '100vh', zIndex: '0' }}>
                    {Object.values(columns)
                      .sort((a, b) => a.order - b.order)
                      .map((column, index) => (
                        <div key={column.id}>
                          <Column
                            key={column.id}
                            id={String(column.id)}
                            initialName={column.name}
                            initialItems={column.items}
                            isLastColumn={index === Object.keys(columns).length - 1}
                            initialColor={column.color}
                            initialBackground={column.background}
                            stages={columns}
                            onApplicationStatusUpdate={handleApplicationStatusUpdate} 
                          />
                        </div>
                      ))}

                    <Box>
                      <Box style={addStageStyle}>
                        <Box position='sticky' left='-10px' top='0' zIndex='10' minWidth='100%' borderTopRadius={'5px'} backgroundColor="white" color="black" py={3} px={1} borderBottomWidth={1}>
                          <Flex justifyContent='space-between' alignItems='center' pr={3}>
                            <Text fontWeight='bold' textAlign='start' pl='12px'> Add New </Text>
                          </Flex>
                        </Box>

                        <Box
                          style={{
                            paddingRight: '12px',
                            paddingLeft: '12px',
                            background: 'transparent',
                            flexGrow: 1,
                            minHeight: '100%',
                            paddingTop: 10,
                            height: '100%', // Ensure the content box takes full height
                            overflowY: 'auto', // Allow vertical scrolling
                            paddingBottom: '100%',
                            scrollbarWidth: 'none', /* Firefox */
                            msOverflowStyle: 'none',
                          }}
                        >
                          {/* Additional content here if needed */}
                        </Box>
                      </Box>
                    </Box>
                  </div>
                )}

              </Card>
            </Box>
          </Box>
        </DragDropContext>
      )}
      <JobPostModal isOpen={isOpen} onClose={onClose} schoolName={schoolName} schoolId={schoolId} addNewJob={job => setJobPosts([...jobPosts, job])} teamMembers={teamMembers} userId={userId} pipelines={pipelines} />
      {selectedJob && (
        <EditJobPostModal isOpen={isDetailsModalOpen} onClose={onEditCloseModal} job={selectedJob} replaceJob={updatedJob => setJobPosts(jobPosts.map(job => job.id === updatedJob.id ? updatedJob : job))} schoolId={schoolId} teamMembers={teamMembers} userId={userId} />
      )}
      {archivingJob && (
        <ConfirmArchiveModal isOpen={isCofirmArchiveModalOpen} onClose={onArchiveCloseModal} job={archivingJob} replaceJob={updatedJob => setJobPosts(jobPosts.map(job => job.id === updatedJob.id ? updatedJob : job))} />
      )}
      {boostingJob && (
        <BoostJobModal isOpen={isBoostJobModalOpen} onClose={onBoostJobCloseModal} job={boostingJob} replaceJob={updatedJob => setJobPosts(jobPosts.map(job => job.id === updatedJob.id ? updatedJob : job))} />
      )}
      {selectedAdminJob && (
        <AdminJobScreen isOpen={isAdminJobDrawerOpen} onClose={onAdminJobDrawerClose} job={selectedAdminJob} />
      )}
      {selectedJobEmail && (
        <EditJobPostEmailsModal isOpen={isEmailModalOpen} onClose={onEmailCloseModal} job={selectedJobEmail} replaceJob={updatedJob => setJobPosts(jobPosts.map(job => job.id === updatedJob.id ? updatedJob : job))} emailTemplates={emailTemplates} org={org} />
      )}
    </ChakraProvider>
  );
}

export default CandidatePipeline;
