import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody, Flex,
  ModalCloseButton,
  Button,
  Text,
  Box,
  Spinner,
} from '@chakra-ui/react';
import { useSchool } from '../context/admin_selected_school';
import { Tag } from 'antd';

const ArchivedJobsModal = ({ isOpen, onClose }) => {
  const [archivedJobs, setArchivedJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { school } = useSchool();

  useEffect(() => {
    const schoolParam = encodeURIComponent(school); // Use this if `school` is a string
    if (isOpen) {
      // Fetch archived jobs data when modal is opened


      // Assuming 'school' is the query parameter expected by your backend
      // and 'school' state contains the current selected school value.
      fetch(`https://www.pursueli.com/api/admin_get_archived_jobs/?school=${schoolParam}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        }
      })
        .then(response => response.json())
        .then(data => {
          setArchivedJobs(data.archived_jobs);
          console.log(data)
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Error fetching archived jobs:", error);
          setIsLoading(false);
        });
    }
  }, [isOpen]); // Only run this effect when `isOpen` changes

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    
    const formattedDate = date.toLocaleDateString('en-US', options);
    
    // Extract the parts of the date
    const [month, day, year] = formattedDate.split(' ');
    
    // Add the appropriate suffix to the day
    const dayWithSuffix = day.replace(',', '').replace(/\d+/, (match) => {
      switch (match) {
        case '1':
        case '21':
        case '31': return `${match}st`;
        case '2':
        case '22': return `${match}nd`;
        case '3':
        case '23': return `${match}rd`;
        default: return `${match}th`;
      }
    });
  
    // Return the formatted date with the correct placement of the suffix and comma
    return `${month} ${dayWithSuffix}, ${year}`;
  };

  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Archived Jobs</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Spinner size="sm" />
          ) : archivedJobs && archivedJobs.length > 0 ? (
            archivedJobs.map((job) => (
              <Box key={job.id} mb={4}>
                <Text fontWeight="bold">{job.title}</Text>
                <Box mr={3} style={{fontSize:'14px', color:'gray'}}>
                        {job.archived_reason}
                    </Box>
                    <Box mr={3} style={{fontSize:'14px', color:'gray'}}>
                        Archived on {formatDate(job.archive_date)}
                    </Box>
              </Box>
            ))
          ) : (
            <Text>No archived jobs available.</Text>
          )}
        </ModalBody>
        <ModalFooter>
          {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ArchivedJobsModal;
