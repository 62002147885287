import React, { useEffect } from "react";
// import { Link as Img } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover, Link,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Avatar, Img,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from '@chakra-ui/react'
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'

import { PiPlusBold } from "react-icons/pi";
import WebFont from "webfontloader";
import { useSchool } from "../context/admin_selected_school";


export default function CCSATopNav() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Kurale']
      }
    });
  }, []);

  const { isOpen, onToggle } = useDisclosure()
  const userPhoto = localStorage.getItem('userPhoto'); // Retrieve the user photo URL from localStorage

  return (
    <Box marginBottom={"2.5rem"}>
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 10 }}
        borderBottom={{ base: 0, md: 1 }} 
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
        position="fixed" // Added for sticky position
        top={0} // Stick to the top of the viewport
        zIndex={'1'} // Ensure it's above other content
        width="full" // Ensure it stretches across the full width
        >

        <Flex  flex={{ base: 1 }} justify={{ base: 'space-between', md: 'space-between' }}  alignItems={{ base: 'center', md: 'center' }} >
          <Text
          
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'Kurale, serif'}
            fontSize={'30px'} 
            fontWeight={800}
            color={useColorModeValue('gray.800', 'white')}>
            <Link fontFamily={'Kurale, serif'} href="/" style={{display:'flex', textDecoration:'none'}}>
              {/* Pursueli */}
            {/* <Img  height="40px" src="https://i.ibb.co/7bSBvCL/Edpursuit-Logo-Dark-Grey-Red.png"/> */}
            <Img  height="40px" src="https://info.ccsa.org/hs-fs/hubfs/CCSA_Logo_COLOR-2.png?width=1611&name=CCSA_Logo_COLOR-2.png"/>

            </Link>
          </Text>

          <Flex
          flex={{ base: "", md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>

          

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
          
        </Flex>

        
        
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  )
}

const DesktopNav = () => {
  const location = useLocation(); // Get the current location

  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const linkHoverColor = useColorModeValue('gray.800', 'white')
  const popoverContentBgColor = useColorModeValue('white', 'gray.800')
  const userPhoto = localStorage.getItem('userPhoto'); // Retrieve the user photo URL from localStorage
  const isUserAuthenticated = localStorage.getItem('authToken');
  const navigate = useNavigate(); // For navigation after login

  const { school, changeSchool } = useSchool();

  const logoutFunction = () => {
    // Clear the authToken from localStorage
    localStorage.removeItem('authToken');
    localStorage.removeItem('authenticated');
    localStorage.removeItem('userPhoto');
    localStorage.removeItem('id');
    localStorage.removeItem('redirectPath');

    
    changeSchool('');
    
    navigate('/'); // Navigate to home after state update and successful login

    // If you have other related data in localStorage, clear them as well
    // For example: localStorage.removeItem('userPhoto');

    // Add any other logout logic here
    // For example, you might want to redirect the user to the login page
    // or update the application's state to reflect that the user is logged out
};

  return (
    <Stack direction={'row'} spacing={4}   alignItems={'center'} 

    >

{!isUserAuthenticated ? (

            <>

<Box
      minH={'5vh'}
      maxH={'5vh'}
                as="a"
                p={2}
                href={'/login'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                Member Login
              </Box>


              <Button
                as="a"
                p={2}
                href={'https://pursueli.framer.ai/'}
                fontSize={'sm'}
                fontWeight={500}
                size={'sm'}
                variant='solid'
                colorScheme='blue'>
                  Hire Talent
              </Button>

              {/* <Box
                as="a"
                p={2}
                href={'/client/home'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                Find something to do
              </Box> */}



              </>





) : (
        <>
{location.pathname === "/" && (
        <Button
          as="a"
          p={2}
          href={'https://pursueli.framer.ai/'}
          fontSize={'sm'}
          fontWeight={500}
          size={'sm'}
          variant='ghost'
          colorScheme='blue'>
            Hire Talent
        </Button>
      )}
                          <Box
      minH={'5vh'}
      maxH={'5vh'}
                as="a"
                p={2}
                href={'/login'}
                fontSize={'sm'}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: 'none',
                  color: linkHoverColor,
                }}>
                Join mailing list
              </Box>


      

                    <Box>
                        
                    <Flex alignItems={'center'}>
            <Menu p={0}>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                <Avatar
                  size={'sm'}
                  src={userPhoto }
                />
              </MenuButton>
              <MenuList p={0}>
              {/* <Link href="/">
                   <MenuItem>Apply to Jobs</MenuItem>
              </Link> */}


              <Link href="/home/">

                <MenuItem>Home</MenuItem>
                </Link>
                
                {/* <Link href="/apply/confirm-profile/">
                <MenuItem>My Profile</MenuItem>
                </Link> */}

                {/* <MenuDivider /> */}
                <MenuItem  onClick={logoutFunction}>Logout</MenuItem>
                {/* <MenuItem>Link 3</MenuItem> */}
              </MenuList>
            </Menu>
          </Flex>
                    </Box>

              {/* <Button
                as="a"
                p={2}
                href={'https://pursueli.framer.ai/'}
                fontSize={'sm'}
                fontWeight={500}
                size={'sm'}
                variant='solid'
                colorScheme='blue'>
                  Hire Talent
              </Button> */}

        </>
        )}
    </Stack>
  )
}

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
    <Box
      as="a"
      href={href}
      role={'group'}
      display={'block'}
      p={2}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('pink.50', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            transition={'all .3s ease'}
            _groupHover={{ color: 'pink.400' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'pink.400'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  )
}

const MobileNav = () => {
  const isUserAuthenticated = localStorage.getItem('authToken');

  const NAV_ITEMS = getNavItems(isUserAuthenticated);
  return (
    <Stack bg={useColorModeValue('gray.600', 'gray.800')}  position={'absolute'} width={'100%'} top={'60px'} style={{zIndex:'99999999999999'}} p={4} display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();


  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? '#'}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: 'none',
        }}>
        <Text fontWeight={600} color={useColorModeValue('white', 'white')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const getNavItems = (isUserAuthenticated) => {
  return isUserAuthenticated
    ? [
        { label: 'My Dashboard', href: '/dashboard' },
        { label: 'Apply To Jobs', href: '/' },
        { label: 'Hire Talent', href: 'https://pursueli.framer.ai/' },
        { label: 'Logout', href: '/logout' },
      ]
    : [
        { label: 'Login', href: '/login' },
        { label: 'Hire Talent', href: 'https://pursueli.framer.ai/' },
      ];
};


// const NAV_ITEMS = [
//     {
//       label: 'My Dashboard',
//       href: '/home',
//     },
//   ];
  