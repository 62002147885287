import React, { useEffect, useState } from 'react';
import {
  Modal, Menu, MenuButton, MenuItem,Image, MenuList,
  ModalOverlay, Box, Text, Flex,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  Button,
  FormControl,
  FormLabel,
  useToast,
  Input,
  Select
} from '@chakra-ui/react';
import { Radio } from 'antd';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { HiOutlinePaintBrush } from "react-icons/hi2";


const colors = [
    { name: 'Blue', main: '#1890ff', light: '#e6f7ff' },
    { name: 'Green', main: '#52c41a', light: '#f6ffed' },
    { name: 'Red', main: '#f5222d', light: '#fff1f0' },
    { name: 'Magenta', main: '#eb2f96', light: '#fff0f6' },
    { name: 'Orange', main: '#fa8c16', light: '#fff7e6' },
    { name: 'Lime green', main: '#a0d911', light: '#fcffe6' },
    { name: 'Cyan', main: '#13c2c2', light: '#e6fffb' },
    { name: 'Purple', main: '#722ed1', light: '#f9f0ff' },
    { name: 'Black', main: 'black', light: 'white' },

  ];


function EditPipelineStageModal({ isOpen, onClose, stageId, onSave }) {
  const [value, setValue] = useState(1);
  const [selectedColor, setSelectedColor] = useState('black');
  const [selectedBackgroundColor, setBackgroundColor] = useState('white');

  const [stageDetails, setStageDetails] = useState(null);
  const toast = useToast();

  useEffect(() => {
    if (!stageId) {
      return;
    }
    const url = new URL("https://www.pursueli.com/api/admin_get_pipeline_stage/");
    url.searchParams.append("stageId", stageId);
    fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      }
    })
      .then((response) => response.json())
      .then((data) => {
        setStageDetails(data.pipeline_stage);
        setSelectedColor(data.pipeline_stage.color)
        setBackgroundColor(data.pipeline_stage.background)
      })
      .catch((error) => console.error("Error fetching stage details:", error));
  }, [stageId]);

  const handleSave = () => {
    onSave({ ...stageDetails, color: selectedColor, background: selectedBackgroundColor });
    onClose();
  };

  const handleColorSelect = (color) => {
    setSelectedColor(color.main);
    setBackgroundColor(color.light);

  };

  


  if (!stageDetails) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Loading...</ModalHeader>
          <ModalBody>
            <Spinner />
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Stage</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel mb={'5px'}>Stage Name</FormLabel>
            <Flex justifyContent={'space-between'} alignItems={'center'}>
            <Input
            style={{paddingRight:'20px', width:'85%'}}
                value={stageDetails.name}
                onChange={(e) => setStageDetails({ ...stageDetails, name: e.target.value })}
                />
                    
            <Menu>
            <MenuButton as={Button} size={'sm'} backgroundColor={selectedBackgroundColor} borderWidth={1} borderColor={selectedColor} style={{borderRadius:'50%', display:'flex',justifyContent:'center', alignItems:'center',}}>
            {/* <HiOutlinePaintBrush style={{color:selectedColor, fontSize:'16px'}} /> */}
            </MenuButton>
                <MenuList>
                {colors.map((color) => (
                            <MenuItem minH='24px' key={color.name} onClick={() => handleColorSelect(color)}>
                            <Flex boxSize='2rem' borderRadius='5px' mr='12px' bg={color.light} justifyContent={'center'} alignItems={'center'}>
                        <Text color={color.main}>{color.name.charAt(0).toUpperCase()}</Text>
                    </Flex>
                    <Text color={color.main}>{color.name.charAt(0).toUpperCase() + color.name.slice(1)}</Text>
                    </MenuItem>
                ))}
                </MenuList>
            </Menu>
            </Flex>
            </FormControl>


   
        </ModalBody>
        <ModalFooter justifyContent={'center'}>
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EditPipelineStageModal;
