import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Avatar,
  Grid,
  GridItem,
  ChakraProvider,
  Flex,
  Skeleton,
  Card,
  Checkbox,
  useDisclosure,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { Empty } from 'antd';
import { useSchool } from '../../context/admin_selected_school';
import JobPostModal from '../../components/post_job_modal';
import EditJobPostModal from '../../components/edit_job_post_modal';
import ConfirmArchiveModal from '../../components/archive_job_post_confirm_modal';
import BoostJobModal from '../../components/boost_job_post_modal';
import AdminJobScreen from '../../components/admin_job_post_drawer';
import EditJobPostEmailsModal from '../../components/edit_job_emails_modal';
import ShareJobModal from '../../components/share_job_modal';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { useNavigate, useLocation } from 'react-router-dom';

function Community() {


const navigate = useNavigate();
const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const initialPage = parseInt(searchParams.get('page')) || 1;
const initialSortBy = searchParams.get('sort_by') || 'school_name';
const initialOrder = searchParams.get('order') || 'asc';
const [currentPage, setCurrentPage] = useState(initialPage);
const [sortBy, setSortBy] = useState(initialSortBy);
const [order, setOrder] = useState(initialOrder);
const { isOpen, onOpen, onClose } = useDisclosure();
const [userId, setUserId] = useState(null);
const [isLoading, setIsLoading] = useState(true);
const [schools, setSchools] = useState([]);
const [totalPages, setTotalPages] = useState(1);

const [users, setUsers] = useState([]);

 


  const {
    isOpen: isAdminJobDrawerOpen,
    onOpen: onAdminJobDrawerOpen,
    onClose: onAdminJobDrawerClose,
  } = useDisclosure();
  const [selectedAdminJob, setSelectedAdminJob] = useState(null);
  const handleOpenAdminJobDrawer = (job) => {
    setSelectedAdminJob(job);
    onAdminJobDrawerOpen();
  };




  useEffect(() => {
    fetchCommunity(currentPage, sortBy, order);
  }, [currentPage, sortBy, order]);

  const fetchCommunity = (page, sortBy, order) => {
    setIsLoading(true);
    fetch(`https://www.pursueli.com/api/ccsa_admin_get_community/?page=${page}&sort_by=${sortBy}&order=${order}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      setUsers(data.users);
      setTotalPages(data.total_pages);
      setIsLoading(false);
    })
    .catch(error => console.error("Error fetching schools:", error));
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    navigate(`?page=${newPage}`);
  };

const handleSortChange = (sortField) => {
  const newOrder = sortBy === sortField && order === 'asc' ? 'desc' : 'asc';
  setSortBy(sortField);
  setOrder(newOrder);
  navigate(`?page=1&sort_by=${sortField}&order=${newOrder}`);
};


const replaceSchool = (updatedSchool) => {
    const updatedSchools = schools.map((school) => {
      if (school.id === updatedSchool.id) {
        // Replace the job post with the updated one
        return updatedSchool;
      }
      return school;
    });
    setSchools(updatedSchools); // Update the state with the modified array
  };
const handleCCSAMembership = async (schoolId, isActive) => {
    // Optimistically update the UI
    setSchools((prevSchools) => prevSchools.map(school => {
      if (school.id === schoolId) {
        // Temporarily update the job post that was toggled
        return { ...school, ccsa_member: isActive };
      }
      return school;
    }));
  
    try {
      const response = await fetch(`https://www.pursueli.com/api/ccsa_admin_get_community/${schoolId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ is_active: isActive }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update job status.');
      }
      const data = await response.json();
      console.log("data mane, ", data);
      const newSchoolId = data.new_school.id;

      const newSchool = data.new_school; // Assuming the API returns the new job data under 'new_job'
      replaceSchool(newSchool); // Update the job list with the new job details
  
      // Request succeeded, no further action needed
      console.log('Job status updated successfully');
    } catch (error) {
      console.error('Error updating job status:', error);
      // Revert the change in the UI if the request fails
      setSchools((prevSchools) => prevSchools.map(school => {
        if (school.id === schoolId) {
          // Revert the is_active status
          return { ...school, ccsa_member: !isActive };
        }
        return school;
      }));

      // Optionally, inform the user that the update failed
    }
  };

const renderSortArrow = (field) => {
    if (sortBy === field) {
      if (order === 'asc') {
        return <FiChevronDown style={{marginLeft:'10px', fontSize:'17px'}} />;
      } else {
        return <FiChevronUp style={{marginLeft:'10px', fontSize:'17px'}}/>;

      }
    }
    return null;
  };


  const bios = {
    "alexia.divittorio@gmail.com": "Alexia Divittorio is a dedicated high school mathematics teacher with over ten years of experience in the classroom. She specializes in creating engaging and inclusive learning environments for her students.",
    "admin@admin.com": "Robert Crayton serves as the principal of a charter school in Concord, CA. He is committed to fostering academic excellence and holistic development in his students.",
    "test3@email.com": "Test3 Test3 is an educational consultant with a focus on curriculum development and teacher training. She has worked with various schools to enhance educational outcomes.",
    "jane@test.com": "Jane Doea is a middle school science teacher known for her innovative teaching methods. She is passionate about inspiring students to explore and understand the natural world.",
    "janesmith@test.com": "Jane Smith is a veteran elementary school teacher with expertise in early childhood education. She is dedicated to nurturing young minds and fostering a love for learning.",
    "craytonrobert@gmail.com": "Kia Sonya is an assistant principal at a private school, where she focuses on student affairs and disciplinary actions. She aims to create a positive and supportive school culture.",
    "courtney.allen108@yahoo.com": "Courtney Allen is a literacy coach who works with teachers to improve reading and writing instruction. She is committed to helping students develop strong literacy skills.",
    "stephanie.espinoza.60@gmail.com": "Stephanie Espinoza is a bilingual education specialist who advocates for English Language Learners. She designs programs to support language development and academic achievement.",
    "jh@test.com": "Jessica Herenedez is a school counselor providing academic, career, and emotional support to students. She strives to help students achieve their full potential.",
    "sandramurillo78@hotmail.com": "Sandra Mojica Murillo is a special education teacher dedicated to meeting the needs of students with disabilities. She collaborates with families and educators to create individualized learning plans."
  };
  const cities = [
    "Los Angeles, CA",
    "San Francisco, CA",
    "San Diego, CA",
    "Sacramento, CA",
    "Fresno, CA",
    "San Jose, CA",
    "Oakland, CA",
    "Long Beach, CA",
    "Bakersfield, CA",
    "Anaheim, CA"
  ];
  const citiesByEmail = {
    "alexia.divittorio@gmail.com": cities[0],
    "admin@admin.com": cities[1],
    "test3@email.com": cities[2],
    "jane@test.com": cities[3],
    "janesmith@test.com": cities[4],
    "craytonrobert@gmail.com": cities[5],
    "courtney.allen108@yahoo.com": cities[6],
    "stephanie.espinoza.60@gmail.com": cities[7],
    "jh@test.com": cities[8],
    "sandramurillo78@hotmail.com": cities[9]
  };
  
  

  const numberOfRows = 10; // For example

  return (
    <ChakraProvider>
      {isLoading ? (
        <Box flex="1" p={8} left={'20%'} overflowY="auto" width={'80%'} height={'100vh'} position="relative">
        <Box px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px', borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
              <Flex justifyContent={'space-between'}>
                <Text mb={1} fontSize="lg" textAlign={'start'} fontWeight="bold">Charter talent community</Text>
              </Flex>
                <Table variant="simple">
              <Thead>
                <Tr>
                  <Th color={'transparent'}>Active</Th>
                  <Th color={'transparent'}>Job Title</Th>
                  <Th color={'transparent'}>Views</Th>
                  <Th color={'transparent'}>Applications</Th>
                  <Th color={'transparent'}>Expires</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array(numberOfRows).fill().map((_, index) => (
                  <Tr key={index} padding={0} margin={0}>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'50%'} height="40px" width="40px"/></Td>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'320px'} /></Td>
                    <Td paddingY={3}margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'80px'} /></Td>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'80px'} /></Td>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'80px'} /></Td>
                    <Td paddingY={3} margin={0}><Skeleton borderRadius={'10px'} height="30px" width={'40px'} /></Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      ) : (
        <Box flex="1" p={4} left={'20%'} overflowY="auto" width={'80%'} height={'100vh'} position="relative">
          <Box w="100%" p={4}>
            <Box px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px', borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
              <Flex justifyContent={'space-between'}>
                <Text mb={1} fontSize="lg" textAlign={'start'} fontWeight="bold">Charter talent community</Text>
                <Button size={'sm'}>
                    export csv
                </Button>
              </Flex>
              {users && users.length > 0 ? (
                <ChakraProvider>
                  <Box w="100%">
                  <Grid
  px={4}
  textAlign={'start'}
  fontSize={'sm'}
  fontWeight={'500'}
  p={2}
  templateColumns="repeat(24, 1fr)"
  mb={1}
  borderBottomWidth={0}
  borderColor="gray.200"
  py={2}
  borderRadius="0"
>
  <GridItem textTransform="" colSpan={6}>
    <Text
      display={'flex'}
      alignItems={'center'}
      onClick={() => handleSortChange('school_name')}
      cursor="pointer"
      _hover={{ color: 'blue.500' }}
    >
      Community member
    </Text>
  </GridItem>
  <GridItem textTransform="" colSpan={4} textAlign={'start'}>
    <Text
      display={'flex'}
      justifyContent={'start'}
      alignItems={'center'}
      onClick={() => handleSortChange('num_jobs')}
      cursor="pointer"
      _hover={{ color: 'blue.500' }}
    >
      Location
    </Text>
  </GridItem>
  <GridItem textTransform="" colSpan={14} textAlign={'start'}>
    <Text
      display={'flex'}
      justifyContent={'start'}
      alignItems={'center'}
      onClick={() => handleSortChange('num_jobs')}
      cursor="pointer"
      _hover={{ color: 'blue.500' }}
    >
      AI generated bio
    </Text>
  </GridItem>
  {/* <GridItem textTransform="" colSpan={5} textAlign={'center'}>
    <Text
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={() => handleSortChange('num_views')}
      cursor="pointer"
      _hover={{ color: 'blue.500' }}
    >
      Total Views {renderSortArrow('num_views')}
    </Text>
  </GridItem>
  <GridItem textTransform="" colSpan={5} textAlign={'center'}>
    <Text
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={() => handleSortChange('num_applications')}
      cursor="pointer"
      _hover={{ color: 'blue.500' }}
    >
      Total Applications {renderSortArrow('num_applications')}
    </Text>
  </GridItem>
  <GridItem textTransform="" colSpan={2} textAlign={'center'}>
    <Text>Member</Text>
  </GridItem> */}
</Grid>
                    {users.map((user, index) => (
                      <Grid
                        textAlign={'start'}
                        alignItems="center"
                        marginX={'auto'}
                        templateColumns="repeat(24, 1fr)"
                        gap={2}
                        boxShadow="md"
                        borderWidth="1px"
                        borderRadius="md"
                        p={2}
                        px={4}
                        mb={3}
                        key={user.id}
                      >
                        <GridItem colSpan={6}>
                          <a style={{display:'flex', alignItems:'center', overflow:'clip'}}>
                            <Avatar   name={`${user.first_name} ${user.last_name}`} style={{height:'40px', width:'40px'}} mr={3} size={'sm'}></Avatar>
                            <Box>
                            <Text fontWeight={'bold'} fontSize={'sm'} onClick={() => handleOpenAdminJobDrawer(user)}>{user.first_name} {user.last_name}</Text>
                            <Text fontSize={'sm'} onClick={() => handleOpenAdminJobDrawer(user)}>{user.email}</Text>

                            </Box>
                          </a>
                        </GridItem>
                        <GridItem colSpan={4}  textAlign={'start'}>
                            <Text noOfLines={2} fontSize={'sm'}>
                            {citiesByEmail[user.email]}
                            </Text>
                        </GridItem>
                        <GridItem colSpan={14}  textAlign={'start'}>
                        <Text noOfLines={2} fontSize={'sm'}>
                            {bios[user.email]}
                          </Text>
                        </GridItem>
                        {/* <GridItem colSpan={5} textAlign={'center'}><Text>{school.num_views}</Text></GridItem>
                        <GridItem colSpan={5} textAlign={'center'}><Text>{school.num_applications}</Text></GridItem>
                        <GridItem colSpan={2} textAlign={'center'}>
                          <Checkbox size="md" colorScheme="green" isChecked={school.ccsa_member}
                            onChange={(e) => handleCCSAMembership(school.id, e.target.checked)}

                          />
                        </GridItem> */}
                      </Grid>
                    ))}
                    <Flex justifyContent="center" alignItems={'center'} mt={4}>

                        {currentPage !== 1 && totalPages > 1 && (
                            <Button size={'sm'} onClick={() => handlePageChange(currentPage - 1)}>
                                Previous
                            </Button>
                            )}

                      <Text fontSize={'small'} mx={7}>Page {currentPage} of {totalPages}</Text>
                      <Button size={'sm'} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        Next
                      </Button>
                    </Flex>
                  </Box>
                </ChakraProvider>
              ) : (
                <Box textAlign="center" fontSize="xl" mt={5}>
                  <Empty description={"No Schools Found"}/>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
      {/* <JobPostModal isOpen={isOpen} onClose={onClose}   userId={userId} /> */}
    </ChakraProvider>
  );
}

export default Community;
