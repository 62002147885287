import React, { useEffect, useState } from 'react';
import {
  Box, SimpleGrid,
  Card,
  ChakraProvider,
  Flex,
  Skeleton,
  Table,
  Tbody, Button,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { Empty, Select, DatePicker, Radio, Space } from 'antd';
import { parseISO, format, subDays, startOfWeek, startOfMonth, startOfQuarter, startOfYear, eachDayOfInterval, eachWeekOfInterval, eachMonthOfInterval } from 'date-fns';
import ChartComponent from '../../../components/chart_componet';
import { useSchool } from '../../../context/admin_selected_school';
import moment from 'moment';
import ComparisonChart from '../../../components/chart_compare_componet';
import ManageReportChartsModal from '../../../components/manage_report_charts';
import ChartModal from '../../../components/chart_modal';
import { FiMaximize2 } from 'react-icons/fi';


const { RangePicker } = DatePicker;

const dateOptions = [
  { key: 'last_30_days', label: 'Last 30 Days', group: 'relative' },
  { key: 'current_year', label: 'Current Year', group: 'standard' },
  { key: 'current_quarter', label: 'Current Quarter', group: 'standard' },
  { key: 'current_month', label: 'Current Month', group: 'standard' },
  { key: 'current_week', label: 'Current Week', group: 'standard' },
  { key: 'last_week', label: 'Last Week', group: 'relative' },
  { key: 'last_12_months', label: 'Last 12 Months', group: 'relative' },
  { key: 'custom', label: 'Select Dates', group: 'custom' },
];

const getDateRange = (key, customRange) => {
  const now = new Date();
  switch (key) {
    case 'last_30_days':
      return [subDays(now, 30), now];
    case 'current_week':
      return [startOfWeek(now), now];
    case 'last_week':
      return [startOfWeek(subDays(now, 7)), startOfWeek(now)];
    case 'current_month':
      return [startOfMonth(now), now];
    case 'current_quarter':
      return [startOfQuarter(now), now];
    case 'current_year':
      return [startOfYear(now), now];
    case 'last_12_months':
      return [subDays(now, 365), now];
    case 'custom':
      return customRange ? customRange : [new Date(0), now];
    default:
      return [new Date(0), now];
  }
};

const generateLabels = (start, end, grouping) => {
  switch (grouping) {
    case 'days':
      return eachDayOfInterval({ start, end }).map(date => format(date, 'MM/dd'));
    case 'weeks':
      return eachWeekOfInterval({ start, end }).map(date => `Week of ${format(date, 'MM/dd')}`);
    case 'months':
      return eachMonthOfInterval({ start, end }).map(date => format(date, 'MMM yyyy'));
    default:
      return [];
  }
};

function AdminReports() {
  const [applications, setApplications] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [modalContent, setModalContent] = useState({ title: '', component: null }); // Modal content

  const addNewJobToList = (newJob) => {
    setApplications((prevapplications) => [...prevapplications, newJob]);
  };
  const { Option } = Select;
  const [roleFilter, setRoleFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('Pending');
  const [filterLoading, setFilterLoading] = useState(false);
  const handleFilterLoading = () => {
    setFilterLoading(true)
  };

  const [selectedDateOption, setSelectedDateOption] = useState('current_year');
  const [customRange, setCustomRange] = useState([null, null]);
  const [grouping, setGrouping] = useState('months');
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [filteredViews, setFilteredViews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [jobPosts, setJobPosts] = useState([]);
  const [schoolName, setSchoolName] = useState('');
  const { school } = useSchool();
  const [schoolId, setSchoolId] = useState('');
  const [selectedCharts, setSelectedCharts] = useState([]);

  useEffect(() => {
    const schoolParam = encodeURIComponent(school);
    fetch(`https://www.pursueli.com/api/admin_get_data/?school=${schoolParam}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      setJobPosts(data.views);
      setSchoolName(data.school_name);
      setSchoolId(data.school_id)
      setIsLoading(false);
      filterData(data.applications, data.views, selectedDateOption, customRange);
      setFilteredApplications(data.applications);
      setFilteredViews(data.views);
      setSelectedCharts(data.viewable_charts)
      console.log(selectedCharts)
    })
    .catch(error => console.error("Error fetching views:", error));
  }, [school]);

  const handleReportViewUpdate = (data) => {
    setSelectedCharts(data)
  };

  useEffect(() => {
    filterData(filteredApplications, filteredViews, selectedDateOption, customRange);
  }, [selectedDateOption, customRange, grouping]);

  const filterData = (applications, views, dateOption, customRange) => {
    const [start, end] = getDateRange(dateOption, customRange);
    const filteredApplications = applications.filter(app => {
      const applicationDate = parseISO(app.application_date);
      return applicationDate >= start && applicationDate <= end;
    });
    const filteredViews = views.filter(job => {
      const createdDate = parseISO(job.view_date);
      return createdDate >= start && createdDate <= end;
    });
    // setFilteredApplications(filteredApplications);
    // setFilteredViews(filteredViews);
  };

  const handleDateOptionChange = (value) => {
    setSelectedDateOption(value);
    if (value !== 'custom') {
      setCustomRange([null, null]);
    }
  };

  const onCustomRangeChange = (dates) => {
    setCustomRange(dates ? [dates[0].toDate(), dates[1].toDate()] : [null, null]);
  };

  const handleGroupingChange = (e) => {
    setGrouping(e.target.value);
  };

  const groupedOptions = [
    {
      label: 'Standard',
      options: dateOptions.filter(option => option.group === 'standard').map(option => ({ label: option.label, value: option.key })),
    },
    {
      label: 'Custom',
      options: dateOptions.filter(option => option.group === 'custom').map(option => ({ label: option.label, value: option.key })),
    },
    // {
    //   label: 'Relative',
    //   options: dateOptions.filter(option => option.group === 'relative').map(option => ({ label: option.label, value: option.key })),
    // },
  ];

  const [start, end] = getDateRange(selectedDateOption, customRange);
  const labels = generateLabels(start, end, grouping);

  const groupByLabel = (items, dateField, grouping) => {
    return items.reduce((acc, item) => {
      let dateLabel;
      if (grouping === 'weeks') {
        const date = parseISO(item[dateField]);
        dateLabel = `Week of ${format(startOfWeek(date), 'MM/dd')}`;
      } else {
        dateLabel = generateLabels(parseISO(item[dateField]), parseISO(item[dateField]), grouping)[0];
      }
      if (!acc[dateLabel]) {
        acc[dateLabel] = 0;
      }
      acc[dateLabel] += 1;
      return acc;
    }, {});
  };

  const applicationsByLabel = groupByLabel(filteredApplications, 'application_date', grouping);
  const chartData = labels.map(label => applicationsByLabel[label] || 0);

  const viewsByLabel = groupByLabel(filteredViews, 'view_date', grouping);
  const chartDataViews = labels.map(label => viewsByLabel[label] || 0);

  const statuses = Array.from(new Set(filteredApplications.map(app => app.status)));
  const applicationsByStatusAndTime = statuses.reduce((acc, status) => {
    const data = labels.map(label => {
      const count = filteredApplications.filter(app => {
        // console.log("Heres the status")
        // console.log(status)
            const date = parseISO(app.application_date);
            const dateLabel = grouping === 'weeks' ? `Week of ${format(startOfWeek(date), 'MM/dd')}` : generateLabels(date, date, grouping)[0];
        return app.status === status && dateLabel === label;
      }).length;
      return count;
    });
    acc[status] = data;
    return acc;
  }, {});

  const sources = Array.from(new Set(filteredApplications.map(app => app.source)));
  const applicationsBySourceAndTime = sources.reduce((acc, source) => {
    const data = labels.map(label => {
      const count = filteredApplications.filter(app => {
            const date = parseISO(app.application_date);
            const dateLabel = grouping === 'weeks' ? `Week of ${format(startOfWeek(date), 'MM/dd')}` : generateLabels(date, date, grouping)[0];
        return app.source === source && dateLabel === label;
      }).length;
      return count;
    });
    acc[source] = data;
    return acc;
  }, {});


  const views = Array.from(new Set(filteredViews.map(view => view.source)));
  const viewsBySourceAndTime = views.reduce((acc, source) => {
    const data = labels.map(label => {
      const count = filteredViews.filter(view => {
            const date = parseISO(view.view_date);
            const dateLabel = grouping === 'weeks' ? `Week of ${format(startOfWeek(date), 'MM/dd')}` : generateLabels(date, date, grouping)[0];
        return view.source === source && dateLabel === label;
      }).length;
      return count;
    });
    acc[source] = data;
    return acc;
  }, {});

  const job_posts = Array.from(new Set(filteredApplications.map(app => app.job.title)));
  const applicationsByJobAndTime = job_posts.reduce((acc, job) => {
    const data = labels.map(label => {
      const count = filteredApplications.filter(app => {
            const date = parseISO(app.application_date);
            const dateLabel = grouping === 'weeks' ? `Week of ${format(startOfWeek(date), 'MM/dd')}` : generateLabels(date, date, grouping)[0];
        return app.job.title === job && dateLabel === label;
      }).length;
      return count;
    });
    acc[job] = data;
    return acc;
  }, {});

  const viewsByJobAndTime = job_posts.reduce((acc, job) => {
    const data = labels.map(label => {
      const count = filteredViews.filter(view => {
            const date = parseISO(view.view_date);
            const dateLabel = grouping === 'weeks' ? `Week of ${format(startOfWeek(date), 'MM/dd')}` : generateLabels(date, date, grouping)[0];
        return view.job.title === job && dateLabel === label;
      }).length;
      return count;
    });
    acc[job] = data;
    return acc;
  }, {});

  const colors = [
    { background: 'rgba(24, 144, 255, 0.2)', border: 'rgba(24, 144, 255, 1)' }, // processing
    { background: 'rgba(82, 196, 26, 0.2)', border: 'rgba(82, 196, 26, 1)' }, // success
    { background: 'rgba(245, 34, 45, 0.2)', border: 'rgba(245, 34, 45, 1)' }, // error
    { background: 'rgba(250, 173, 20, 0.2)', border: 'rgba(250, 173, 20, 1)' }, // warning
    { background: 'rgba(235, 47, 150, 0.2)', border: 'rgba(235, 47, 150, 1)' }, // magenta
    { background: 'rgba(255, 77, 79, 0.2)', border: 'rgba(255, 77, 79, 1)' }, // red
    { background: 'rgba(250, 84, 28, 0.2)', border: 'rgba(250, 84, 28, 1)' }, // volcano
    { background: 'rgba(250, 140, 22, 0.2)', border: 'rgba(250, 140, 22, 1)' }, // orange
    { background: 'rgba(250, 173, 20, 0.2)', border: 'rgba(250, 173, 20, 1)' }, // gold
    { background: 'rgba(139, 199, 65, 0.2)', border: 'rgba(139, 199, 65, 1)' }, // lime
    { background: 'rgba(82, 196, 26, 0.2)', border: 'rgba(82, 196, 26, 1)' }, // green
    { background: 'rgba(19, 194, 194, 0.2)', border: 'rgba(19, 194, 194, 1)' }, // cyan
    { background: 'rgba(24, 144, 255, 0.2)', border: 'rgba(24, 144, 255, 1)' }, // blue
    { background: 'rgba(47, 84, 235, 0.2)', border: 'rgba(47, 84, 235, 1)' }, // geekblue
    { background: 'rgba(114, 46, 209, 0.2)', border: 'rgba(114, 46, 209, 1)' }, // purple
    { background: 'rgba(0, 128, 128, 0.2)', border: 'rgba(0, 128, 128, 1)' }, // teal
    { background: 'rgba(255, 99, 71, 0.2)', border: 'rgba(255, 99, 71, 1)' }, // tomato
    { background: 'rgba(75, 0, 130, 0.2)', border: 'rgba(75, 0, 130, 1)' }, // indigo
    { background: 'rgba(255, 105, 180, 0.2)', border: 'rgba(255, 105, 180, 1)' }, // hot pink
    { background: 'rgba(30, 144, 255, 0.2)', border: 'rgba(30, 144, 255, 1)' }, // dodger blue
  ];

  const chartConfigurations = [
    {
      slug: 'applications_over_time',
      title: 'Applications over time',
      component: (showLegend) => (
        <ChartComponent
          labels={labels}
          datasets={[
            {
              label: 'Total applications',
              data: chartData,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ]}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: showLegend,
              },
            },
          }}
          showLegend={showLegend}
        />
      ),
    },
    {
      slug: 'applications_by_job_posting',
      title: 'Applications by job post, over time',
      component: (showLegend) => (
        <ComparisonChart
          labels={labels}
          datasets={job_posts.map((job, index) => ({
            label: job,
            data: applicationsByJobAndTime[job],
            backgroundColor: colors[index % colors.length].background,
            borderColor: colors[index % colors.length].border,
            borderWidth: 1,
          }))}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: showLegend,
              },
            },
          }}
          showLegend={showLegend}
        />
      ),
    },
    {
      slug: 'applications_by_source',
      title: 'Applications by source, over time',
      component: (showLegend) => (
        <ComparisonChart
          labels={labels}
          datasets={sources.map((source, index) => ({
            label: source,
            data: applicationsBySourceAndTime[source],
            backgroundColor: colors[index % colors.length].background,
            borderColor: colors[index % colors.length].border,
            borderWidth: 1,
          }))}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: showLegend,
              },
            },
          }}
          showLegend={showLegend}
        />
      ),
    },
    {
      slug: 'views_over_time',
      title: 'Views over time',
      component: (showLegend) => (
        <ChartComponent
          labels={labels}
          datasets={[
            {
              label: 'Total views',
              data: chartDataViews,
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
            },
          ]}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: showLegend,
              },
            },
          }}
          showLegend={showLegend}
        />
      ),
    },
    {
      slug: 'views_by_source',
      title: 'Views by source, over time',
      component: (showLegend) => (
        <ComparisonChart
          labels={labels}
          datasets={views.map((view, index) => ({
            label: view,
            data: viewsBySourceAndTime[view],
            backgroundColor: colors[index % colors.length].background,
            borderColor: colors[index % colors.length].border,
            borderWidth: 1,
          }))}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: showLegend,
              },
            },
          }}
          showLegend={showLegend}
        />
      ),
    },
    {
      slug: 'views_by_job_posting',
      title: 'Views by job post, over time',
      component: (showLegend) => (
        <ComparisonChart
          labels={labels}
          datasets={job_posts.map((job, index) => ({
            label: job,
            data: viewsByJobAndTime[job],
            backgroundColor: colors[index % colors.length].background,
            borderColor: colors[index % colors.length].border,
            borderWidth: 1,
          }))}
          options={{
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: showLegend,
              },
            },
          }}
          showLegend={showLegend}
        />
      ),
    },
  ];

  const openChartModal = (title, component) => {
    setModalContent({ title, component: component(true) });
    setIsModalOpen(true);
  };

  const getRandomHeights = (numBars) => {
    return Array.from({ length: numBars }).map(() => `${Math.random() * 70 + 30}%`);
  };

  
  const RandomGraphSkeleton = () => {
    const heights = getRandomHeights(15); // Generate random heights for 15 bars
    return (
      <Box overflow="hidden" height="300px" p={10}>
        <Skeleton height="20px" width="50%" mb={4} />
        <Flex gap={2} alignItems={'flex-end'} height={'100%'}>
          {heights.map((height, index) => (
            <Skeleton key={index} height={height} width="20px" borderRadius="4px" />
          ))}
        </Flex>
      </Box>
    );
  };
  return (
    <ChakraProvider>
      {isLoading ? (
        <Box flex="1" p={8} left={'6%'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'} bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}> 

<Box style={{  borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>         
 <Card h={'93%'} p={'20px'} top={0}>

          <SimpleGrid columns={3} spacing={10}>
        <RandomGraphSkeleton />
        <RandomGraphSkeleton />
        <RandomGraphSkeleton />
        <RandomGraphSkeleton />
        <RandomGraphSkeleton />
        <RandomGraphSkeleton />
      </SimpleGrid>

          </Card>
        </Box>
        </Box>
      ) : (
<Box flex="1" p={8} left={'6%'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'} bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}> 
<Box style={{  borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
            <Card px={'80px'} style={{ paddingLeft: '30px', paddingRight: '30px' }} py={'30px'} boxShadow="xl">
              <Flex justifyContent={'space-between'} mb={4}>
                <Text fontSize="xl" textAlign={'start'} fontWeight="bold">{schoolName} Metrics</Text>
                <Button size={'sm'} onClick={onOpen}>
                    add chart
                </Button>
              </Flex>

              <Flex justifyContent={'center'} mb={6}>
              <Space>
                  <Select
                    defaultValue="current_year"
                    style={{ width: 200 }}
                    onChange={handleDateOptionChange}
                    options={groupedOptions}
                  />
                  <Radio.Group onChange={handleGroupingChange} value={grouping}>
                    <Radio.Button value="months">Months</Radio.Button>
                    <Radio.Button value="weeks">Weeks</Radio.Button>
                    <Radio.Button value="days">Days</Radio.Button>
                  </Radio.Group>
                  {selectedDateOption === 'custom' && (
                    <RangePicker onChange={onCustomRangeChange} />
                  )}
                </Space>
              </Flex>
              <Text>
              
              </Text>
              {jobPosts && jobPosts.length > 0 && selectedCharts.length > 0 ? (
                <ChakraProvider>
                  <Box w="100%">
                    <SimpleGrid columns={3} spacing={6}>
                      {chartConfigurations.map((config) => {
                        if (selectedCharts.some(chart => chart.slug === config.slug)) {
                          return (
                            <Box key={config.slug}>
                              <Flex justifyContent="space-between" alignItems="center">
                                <Text fontWeight={'bold'} color={'#555'} textAlign={'start'}>{config.title}</Text>
                                <Button size="xs" variant={'ghost'} onClick={() => openChartModal(config.title, config.component)}>
                                    <FiMaximize2/>
                                </Button>
                              </Flex>
                              {config.component(false)}
                            </Box>
                          );
                        }
                        return null;
                      })}
                    </SimpleGrid>
                  </Box>
                </ChakraProvider>
              ) : (
                <Box textAlign="center" fontSize="xl" mt={5}>
                  <Empty description={"No data or charts elected"} />
                </Box>
              )}
            </Card>
          </Box>
        </Box>
      )}
      <ManageReportChartsModal isOpen={isOpen} onClose={onClose} schoolName={schoolName} schoolId={schoolId} addNewJob={addNewJobToList} selectedCharts={selectedCharts} handleReportViewUpdate={handleReportViewUpdate} />
      <ChartModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} title={modalContent.title} component={modalContent.component} />
    </ChakraProvider>
  );
}

export default AdminReports;
