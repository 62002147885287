  import {
    Drawer, Box, Link, Avatar, Container,Spinner, Heading, Skeleton, SkeletonText,Flex,
    DrawerBody, Grid, GridItem,Divider, Card, Img, Button,Badge,
    DrawerHeader,Icon,
    DrawerOverlay,Tab, TabList,Tabs, TabPanels, TabPanel,
    DrawerContent,
    DrawerCloseButton,IconButton,
    Text,
    AvatarBadge,
    Modal, SkeletonCircle, Stack,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  } from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditProfileForm from '../../../components/edit_profile_form';
import { Input, Tag, Breadcrumb } from 'antd';
import { FiMail, FiPhoneCall, FiMapPin, FiSave, FiLock } from "react-icons/fi";
import { format, parseISO } from 'date-fns';
import { AddIcon } from '@chakra-ui/icons';
import { DownloadIcon, SettingsIcon } from '@chakra-ui/icons';  // Adjust icons as needed
import { Link as RouterLink } from 'react-router-dom';


import { PiArrowLineRight,PiTrash, PiPencilLineLight, PiPlusBold, PiNote, PiNewspaperClipping, PiNotePencil, PiGraduationCap } from "react-icons/pi";
import { BsArrowUpRight } from 'react-icons/bs';
const renderStars = (score) => {
    const totalStars = 5;
    const fullStarCount = Math.floor((score / 10) * 5);
    const halfStar = score % 2 >= 1 ? 1 : 0;
    const emptyStars = totalStars - fullStarCount - halfStar;
  
    return (
      <>
        {Array(fullStarCount)
          .fill()
          .map((_, index) => (
            <span key={`full-${index}`} style={{ color: "#F44100" }}>★</span>
          ))}
        {halfStar > 0 && <span style={{ color: "#F44100" }}>★</span>}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <span key={`empty-${index}`} style={{ color: "#EDF2F7" }}>★</span>
          ))}
      </>
    );
  };

function StandAloneApplication() {

    const [isExpanded, setIsExpanded] = useState(false);
    const toggleDetails = () => {
      setIsExpanded(!isExpanded);
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const day = date.getDate();
        const year = date.getFullYear();  // Retrieve the year from the date object
        let daySuffix;
        switch (day) {
          case 1: case 21: case 31:
            daySuffix = 'st';
            break;
          case 2: case 22:
            daySuffix = 'nd';
            break;
          case 3: case 23:
            daySuffix = 'rd';
            break;
          default:
            daySuffix = 'th';
        }
        const monthIndex = date.getMonth();
        const monthName = monthNames[monthIndex];
        return `${monthName} ${day}${daySuffix}, ${year}`;  // Include the year in the return statement
    }
    


const [noteText, setNoteText] = useState('');
const [sendMessageLoading, setSendMessageLoading] = useState(false);
const [updatingStatus, setUpdatingStatus] = useState(false);
const [showFullText, setShowFullText] = useState(false);

const [applicationId, setApplicationId] = useState('');
const [selectedUserId, setSelectedUserId] = useState('');
const [applicationDate, setApplicationDate] = useState('');
const [applicationSource, setApplicationSource] = useState('');
const [applicationMatchScore, setApplicationMatchScore] = useState('');
const [aiMatchScoreReasoning, setAiMatchScoreReasoning] = useState('');
const [applicationStatus, setApplicationStatus] = useState('');


const { isOpen: isCredentialModalOpened, onOpen: onCredentialModalOpen, onClose: onCredentialModalClose} = useDisclosure();
const [credentialUrl, setCredentialUrl] = useState(null);

const closeCredentialModal = () => {
    onCredentialModalClose(); // Correctly close the modal
  };

  const handleCredentialClick = (id, firstName, lastName) => {
    onCredentialModalOpen(); // Open the PiNote modal after setting the data
    fetch(`https://www.pursueli.com/api/admin_get_credential/?applicationId=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming you're using bearer token authentication
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      setCredentialUrl(data.credential); // Store the URL in state
    })
    .catch(error => console.error("Error fetching data:", error));
  };


 
const { isOpen: isCoverLetterModalOpened, onOpen: onCoverLetterModalOpen, onClose: onCoverLetterModalClose} = useDisclosure();






const { isOpen: isPiNoteModalOpen, onOpen: onPiNoteModalOpen, onClose: onPiNoteModalClose} = useDisclosure();
const [resumeUrl, setResumeUrl] = useState(null);
const [selectedResumeFirstName, setSelectedResumeFirstName] = useState('');
const [selectedResumeLastName, setSelectedResumeLastName] = useState('');

const closeResumeModal = () => {
    onPiNoteModalClose(); // Correctly close the modal
    setSelectedResumeFirstName('');
    setSelectedResumeLastName('');
    setResumeUrl(null);
    console.log(resumeUrl)
  };

  const handlePiNoteClick = (id, firstName, lastName) => {
    setSelectedResumeFirstName(firstName)
    setSelectedResumeLastName(lastName)
    onPiNoteModalOpen(); // Open the PiNote modal after setting the data
    fetch(`https://www.pursueli.com/api/admin_get_resume/?applicationId=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming you're using bearer token authentication
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      setResumeUrl(data.resume); // Store the URL in state
    })
    .catch(error => console.error("Error fetching data:", error));
  };


const handleSubmitNote = () => {
    alert(application.id)
    if (noteText === "") {
        return alert("Cannot add an empty note.");
    }
    setSendMessageLoading(true)
    const url = "https://www.pursueli.com/api/admin_submit_note/"; // Adjust the URL to your API endpoint
    const payload = {
      note: noteText,
      applicationId: application.id,
      userId: applicant.id, // Make sure these IDs are available in your component's scope
    };
  
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      console.log("Note submitted successfully");
      setApplicationNotes([data, ...applicationNotes]); // Prepend the new note
      setNoteText(''); // Clear the textarea
      setSendMessageLoading(false)
    })
    .catch(error => console.error("Error submitting note:", error));
  };


  const handleApplicationUpdate = (applicationAction) => {
    setSendMessageLoading(true);
    const url = "https://www.pursueli.com/api/admin_update_application/";
    const payload = {
      status: applicationAction,
      applicationId: application.id,
      userId: applicant.id,
    };
  
    // Return the fetch call so that it returns a Promise
    return fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log("Here's the data...", data);
      setApplication(data)
    //   if (onApplicationStatusUpdate) {
    //     onApplicationStatusUpdate(data);
    //   }
    })
    .catch(error => {
      console.error("Error:", error);
      throw error; // Rethrow after logging
    })
    .finally(() => {
      setUpdatingStatus(false); // This will execute after the fetch operation is complete
    });
  };
  
  
const { TextArea } = Input;


console.log(applicationId)
const [applicationHistory, setApplicationHistory] = useState([]);
const [applicationNotes, setApplicationNotes] = useState([]);




function formatPhoneNumber(phoneNumber) {
// Convert to string in case it's not already
const phoneStr = phoneNumber.toString();
// Use a regular expression to add a dash after the third digit
return phoneStr.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}


useEffect(() => {
console.log("coming here ", applicationId)
if (!applicationId) {
    console.log("Application ID is null or empty. Skipping fetch.");
    return;
  }
const url = new URL("https://www.pursueli.com/api/admin_get_application_notes/");
url.searchParams.append("applicationId", applicationId);
url.searchParams.append("userId", selectedUserId);
// Fetch the upcoming events
fetch(url, {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
    }
  })          
  .then((response) => response.json())
  .then((data) => {
    setApplicationNotes(data.application_notes); // Update state with fetched data
    console.log("Notes below")
    console.log(data.application_notes)

  })
.catch((error) => console.error("Error fetching upcoming events:", error));

}, [applicationId]);




    
  


   

    let { slug } = useParams();
    const [application, setApplication] = useState(null)
    const [applicant, setApplicant] = useState(null)
    const [unqualified, setUnqualified] = useState(false);

    useEffect(() => {
        fetch(`https://www.pursueli.com/api/admin_get_application/?application=${slug}`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            // Check if the response was successful
            if (!response.ok) {
                // If the response status code was 403, handle permission denied specifically
                if (response.status === 403) {
                    return response.json().then(data => {
                        throw new Error(data.detail || 'You do not have permission to access this resource.');
                    });
                }
                // Handle other errors generically
                throw new Error('Network response was not ok.');
            }
            return response.json(); // Continue to process the response if all is well
        })
        .then(data => {
            setApplication(data.application);
            setApplicationHistory(data.application_history);
            setApplicationNotes(data.application_notes);
            setApplicant(data.applicant);
        })
        .catch(error => {
            console.error("Error fetching application:", error.message);
            console.log(error.message); // Display a more user-friendly error message
            setUnqualified(true); // Use state to manage error visibility or redirect user

        });
    }, []); // Include slug in dependency array to refetch if it changes

    if (unqualified == true) {
        return <Box         flex="1"
        p={4} left={'20%'} minHeight={'100vh'}
        backgroundColor={'#f1f1f1'}
        overflowY="auto" width={'80%'} maxW={'80%'}   position="relative">    {/* Left Side Navigation */}
          <Box w="100%" p={4} pt={4} >
            <Card justifyContent={'center'}  height={'90vh'} style={{ paddingLeft:'30px', paddingRight:'30px'}}   boxShadow="xl"
       >
            
            <Flex mt={'-200px'} justifyContent={'center'} align={'center'} alignSelf={'center'} mb={3}>
                    <Box w={'80px'} h={'80px'} justifySelf={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={'#FED7D7'}>
                    <FiLock color='#E53E3E' fontSize={'30px'}/>
                    </Box>
                    </Flex>



            <Text fontWeight={'bold'} textAlign={'center'} fontSize={'lg'}>
                Sorry, you do not have permission to view this application.
            </Text>
            <Text fontSize={'sm'} textAlign={'center'} color={'gray'}>
            Please send an email to robert@edpursuit.com if you believe this to be an error.
            </Text>




            </Card>
            </Box>
        </Box>
    }
    
    
    if (!application) {
        return      <Box         flex="1"
        p={4} left={'20%'} minHeight={'100vh'}
        backgroundColor={'#f1f1f1'}
        overflowY="auto" width={'80%'} maxW={'80%'}   position="relative">    {/* Left Side Navigation */}
          <Box w="100%" p={4} pt={4} >
            <Card px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px'}} py={'30px'}  boxShadow="xl"
       >


<SkeletonText width="200px" mt="4" noOfLines={1} spacing="4" />

                <Flex p={8} alignItems={'flex-start'} gap={6} justifyContent={'space-between'}>

                <Box minW={'65%'} bg="white">
      <Flex alignItems="center" >
        <SkeletonCircle size="12" />
        <Box ml="4">
          <Skeleton height="4" width="100px" />
          <Skeleton height="4" width="150px" mt="2" />
        </Box>
      </Flex>

      <Box mt="6">
        <SkeletonText mt="4" noOfLines={4} spacing="4" />
      </Box>

      <Stack mt="6" spacing="4">
        <Skeleton height="8" />
        <Skeleton height="8" />
      </Stack>

      <Box mt="6">
        <Skeleton height="4" width="200px" />
        <Skeleton height="4" width="150px" mt="2" />
      </Box>

      <Box mt="6">
        <SkeletonText mt="4" noOfLines={1} spacing="4" />
        <Skeleton height="4" width="100px" mt="2" />
      </Box>

      <Box mt="6">
        <Skeleton height="4" width="300px" />
        <Skeleton height="4" width="150px" mt="2" />
      </Box>
      

      <Box mt="6">
        <SkeletonText mt="4" noOfLines={6} spacing="4" />
        <Skeleton height="4" width="200px" mt="2" />
      </Box>

    </Box>



                <Box padding="6" boxShadowLeft="lg"  minW={'25%'}>
      <Skeleton height="4" width="150px" />
      <Skeleton height="5" width="100px" mt="4" />

      <Box mt="6">
        <Skeleton height="4" width="200px" />
        <Skeleton height="8" width="300px" mt="2" />
      </Box>

      <Stack mt="6" spacing="4">
        <Skeleton height="4" width="100px" />
        <Skeleton height="4" width="150px" mt="2" />
      </Stack>

      <Box mt="6" spacing="2">
        <Skeleton height="4" width="100px" />
        <Skeleton height="4" width="150px"  mt="2"/>
      </Box> 

      <Box mt="6" spacing="4">
        <Skeleton height="4" width="200px" />
        <SkeletonText mt="4" noOfLines={3} spacing="4" />
      </Box>

      <Box mt="6">
        <Skeleton height="8" />
      </Box>
    </Box>


                </Flex>



    



                  </Card>
</Box>
              </Box>
    }

    return (
        <Box         flex="1"
        p={4} left={'20%'} minHeight={'100vh'}
        backgroundColor={'#f1f1f1'}
        overflowY="auto" width={'80%'} maxW={'80%'}   position="relative">    {/* Left Side Navigation */}
          <Box w="100%" p={4} pt={4} >
            <Card maxW={'100%'} px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px'}} py={'30px'}>      
               
            <Breadcrumb style={{ margin: '16px 0', position:'relative', bottom:'35px', marginBottom:'-15px' }}>
      <Breadcrumb.Item>
        <RouterLink to="/admin_school_applications">Applications</RouterLink>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Application
      </Breadcrumb.Item>
    </Breadcrumb>




              <Flex>
            <Box style={{width:'59%'}}>


          <Tabs>
          <Box bg="transparent" pt={ 3}  rounded="md">
            <Flex align="flex-start" mb={0} pl={6} justifyContent={'space-between'}>
                <Flex align="center"  >
                    <Avatar size={'lg'} height={'70px'} width={'70px'}  mr={4} src={applicant.photo.url} />
                    <Box style={{justifyContent:'start', justifySelf:"start"}}>
                        <Heading style={{textAlign:'start'}} as="h2" size="lg">{applicant.first_name} {applicant.last_name}</Heading>

 
 <Flex>

 <Flex align="center" gap="2" my={2} mr={10}>
                <Icon as={FiMail} w={4} h={4} />
                <Text>{applicant.email}</Text>
            </Flex>



            {application && applicant && applicant.phoneNumber && (

            <Flex align="center" gap="2" pr={5}>
                <Icon as={FiPhoneCall} w={4} h={4} />
                <Text>
                {applicant ? formatPhoneNumber(applicant.phoneNumber) : ''} 
                </Text>
            </Flex>

            )}

            </Flex>



                        
                    </Box>
                    </Flex>
                </Flex>
        

            </Box>


  <TabPanels>
    <TabPanel pl={0} pr={0}>
    <Box  align="center"     textAlign={'start'} >





<Box p={6}>
<Text fontWeight="bold" mb={1}>Attachments</Text>

<Flex style={{flexWrap:'wrap'}} gap={3}>
    
{application && applicant && applicant.resume && (


    <Box 

      p={1} 
      borderWidth="1px" 
      borderRadius="md" 
      borderColor="gray.200" 
      backgroundColor="white"
      width={'135px'}
    >
      <Flex alignItems="center" justifyContent="space-between">
        {/* File icon and name */}
        <Flex alignItems="center">
          {/* <Box as="span" color="red.500" mr={2}>
            <PiNewspaperClipping style={{fontSize:'22px', paddingLeft:'2px'}} />
          </Box> */}
          <Text ml={2} fontSize="15px">Resume</Text>
        </Flex>
        
        {/* File size and download icon */}
        <Flex alignItems="center">
          {/* <Text fontSize="sm" color="gray.500">PDF</Text> */}
          <IconButton
            aria-label="Download"
            icon={<BsArrowUpRight />}
            size="sm"
            ml={2}
            variant="solid"
            onClick={() => handlePiNoteClick(application.id, applicant.first_name, applicant.last_name)}
          />
        </Flex>
      </Flex>
    </Box>
)}

{application && applicant && applicant.credential && (

<Box 
     p={1} 
      borderWidth="1px" 
      borderRadius="md" 
      borderColor="gray.200" 
      backgroundColor="white"
      width={'150px'}
    >
      <Flex alignItems="center" justifyContent="space-between">
        {/* File icon and name */}
        <Flex alignItems="center">
          {/* <Box as="span" color="red.500" mr={2}>
            <PiGraduationCap style={{fontSize:'20px'}} />
          </Box> */}
          <Text ml={2}  fontSize="15px">Credential</Text>
        </Flex>
        
        {/* File size and download icon */}
        <Flex alignItems="center">
          {/* <Text fontSize="sm" color="gray.500">PDF</Text> */}
          <IconButton
            aria-label="Download"
            icon={<BsArrowUpRight />}
            size="sm"
            ml={2}
            variant="solid"
            onClick={() => handleCredentialClick(application.id, applicant.first_name, applicant.last_name)}
          />
        </Flex>
      </Flex>
    </Box>
)}

{application && applicant && application.cover_letter && (

<Box 
        p={1} 
      borderWidth="1px" 
      borderRadius="md" 
      borderColor="gray.200" 
      backgroundColor="white"
      width={'200px'}
      
    >
      <Flex alignItems="center" justifyContent="space-between">
        {/* File icon and name */}
        <Flex alignItems="center">
          {/* <Box as="span" color="red.500" mr={2}>
            <PiNotePencil style={{fontSize:'20px'}} />
          </Box> */}
          <Text ml={2} fontSize="15px">Letter of interest</Text>
        </Flex>
        
        {/* File size and download icon */}
        <Flex alignItems="center">
          {/* <Text fontSize="sm" color="gray.500">PDF</Text> */}
          <IconButton
            aria-label="Download"
            icon={<BsArrowUpRight />}
            size="sm"
            ml={2}
            variant="solid"
            onClick={() => onCoverLetterModalOpen(application.id, applicant.first_name, applicant.last_name)}
          />
        </Flex>
      </Flex>
    </Box>

)}
    </Flex>

    </Box>






    {application && applicant && applicant.bio && (

        <Box bg="" p={6} rounded="md">

        <Box  textAlign={'start'} >
        <Text fontWeight="bold" mb={1}>Bio</Text>
        <Flex direction="row" align="center" gap="4" justifyContent={'space-between'} >
                    </Flex>

        </Box>

                        <Box textAlign={'start'}>
                            <Text>{applicant.bio}</Text>
                        </Box>
        </Box>

)}

{application && applicant && applicant.work_history && applicant.work_history.length > 0 && (

<Box bg="white" p={6} width={'100%'}  rounded="md">
  <Box  textAlign={'start'}>
  <Text fontWeight="bold" mb={2}>Work history</Text>
  <Grid
  templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
  gap={6}
  >

{applicant && applicant.work_history.map((workItem, index) => (
<React.Fragment key={index}>
<GridItem w="100%">
<Flex align={'flex-end'}>
  <Img width={'50px'} borderRadius={'5px'} p={0} mr={4} src={workItem.institution.clear_bit_logo_url}/>
  <Box alignSelf={'flex-end'} width={'100%'}>
    <Heading style={{textAlign:'start', display:'flex', justifyContent:"space-between"}} size="sm">
      <Box fontSize={'sm'}>
        {workItem.title}
      </Box>
    </Heading>
    <Flex  justifyContent={'space-between'}>
      <Text color={'gray'} >{workItem.institution_name}</Text>
      <Text pt={'1px'} ml={2} color={'gray'} fontSize={'sm'}>
         {workItem.start_date && format(parseISO(workItem.start_date), 'MMM, yyyy')}
- {workItem.start_date && format(parseISO(workItem.start_date), 'MMM, yyyy') || 'Present'} 
      </Text>
    </Flex>
  </Box>

</Flex>
<Box whiteSpace="pre-line" mt={2}>
      {workItem.description}
    </Box>
</GridItem>
<Divider my={'0px'} />
</React.Fragment>
))}


</Grid>

</Box>
</Box>
)}


{application && applicant && applicant.work_history && applicant.work_history.length > 0 && (

<Box bg="white"  p={6} width={'100%'} mt={4} rounded="md">
  <Box  textAlign={'start'}>
  <Text fontWeight="bold" mb={2}>Education</Text>
  <Grid
  templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
  gap={6}
  >
              {applicant && applicant.education.map((education, index) => (
<React.Fragment key={index}>
<GridItem w="100%">
  <Flex  align={'flex-start'}>
         <Img width={'50px'} borderRadius={'5px'} p={0} mr={4} src={education.institution.clear_bit_logo_url}/>
      <Box alignSelf={'flex-end'} width={'100%'}>
          
      <Heading style={{textAlign:'start', display:'flex', justifyContent:"space-between"}} size="sm">
      <Box>
        {education.major}
        <Badge fontWeight={500} borderRadius={'5px'} py={.5} px={2} size={'md'} ml={2}>
          {education.degree_type}
        </Badge>
      </Box>
    </Heading>



      
    <Flex  justifyContent={'space-between'}>
              <Text color={'gray'} fontWeight={500}>{education.institution_name}</Text> 
              {education.start_date && education.end_date && (
              <Text pt={'1px'} ml={2} color={'gray'} fontSize={'sm'}>
                  {format(parseISO(education.start_date), 'MMM, yyyy')} - {format(parseISO(education.end_date), 'MMM, yyyy')}
              </Text>
              )}
           </Flex>
      </Box>
  </Flex>
  <Box whiteSpace="pre-line" mt={2}>
                  {education.about}
          </Box>
          <Box whiteSpace="pre-line">
                  {education.extra_curriculars}
          </Box>
</GridItem>
<Divider my={'0px'} />
</React.Fragment>
))}
</Grid>


  </Box>

</Box>
)}
</Box>

<Box bg="white"  p={6} width={'100%'} mt={4} rounded="md">
  <Box  textAlign={'start'}>
  <Text fontWeight="bold" mb={2}>Application history</Text>
  <Grid
  templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
  gap={2}
  >
    {applicationHistory.map((historyItem, index) => (
    <Box key={index} mb={0}>
      <Text color={'#333'}  fontSize={'sm'} >{new Date(historyItem.action_date).toLocaleDateString()}</Text>
      <Text color={'#333'} fontSize={'sm'}>{historyItem.action_text}</Text>
      <Divider my={3}/>
    </Box>
  ))}

  </Grid>
  </Box>




<Box mt={1}>..</Box>



</Box>
    </TabPanel>
  </TabPanels>
</Tabs>

</Box>


<Box textAlign={'start'}  pr={3} width={'30%'} maxH={'85vh'} left={'67%'} position={'fixed'}   overflowY={'auto'} // Makes the content scrollable if it exceeds the container's height
 mb={10} >
<Box  ml={4}  pb={2} boxShadow={'md'}  height={'100%'} pl={4}  rounded="md">


<Box mb={8}>

{
  application.status === "Pending" ? (
    <>
      <Text fontWeight="bold" mb={1}>Actions</Text>
      {
  updatingStatus ? (
    <Flex justifyContent={'center'}>
    <Spinner size="sm" color="blue.500" />
    </Flex>
  ) : (
    <Flex justifyContent={'start'}>
      <Button size={'sm'} w={'50%'} mr={3} colorScheme="green" onClick={() => {
        setUpdatingStatus(true);
        handleApplicationUpdate("Accepted").finally(() => setUpdatingStatus(false)); // Assuming handleApplicationUpdate returns a Promise
      }}>
        Accept
      </Button>

      <Button size={'sm'} w={'50%'} colorScheme="red" onClick={() => {
        setUpdatingStatus(true);
        handleApplicationUpdate("Rejected").finally(() => setUpdatingStatus(false)); // Adjust accordingly
      }}>
        Reject    
      </Button>
    </Flex>
  )
}

    </>
  ) : (
    <Box>
          <Text fontWeight="bold" mb={1}>Application Status</Text>
          <Tag style={{fontSize:'15px', paddingLeft:'8px', paddingRight:'8px', paddingTop:'2px', paddingBottom:'2px'}} color={application.status === "Accepted" ? "green" : "red"}>
  {application.status}
</Tag>
      </Box>
  )
}

</Box>




<Flex justifyContent={'space-between'} mb={8}>

<Box style={{width:'100%'}}>
        <Text fontWeight="bold" mb={1}>Role</Text>
        <Tag  bordered={false} style={{fontSize:'15px', paddingLeft:'8px', paddingRight:'8px', paddingTop:'2px', paddingBottom:'2px', textWrap:'wrap'}}>
        <Text mb={1}>{application.job.title} at {application.job.first_school_site.school_name} </Text>
        </Tag>
    </Box>
</Flex>

<Flex justifyContent={'space-between'} gap={3} mb={8}>

    <Box style={{width:'50%'}}>
        <Text fontWeight="bold" mb={1}>Applied On</Text>
        <Tag bordered={false} style={{fontSize:'15px', paddingLeft:'8px', paddingRight:'8px', paddingTop:'2px', paddingBottom:'2px'}}>
        <Text mb={1}>{formatDate(application.application_date)}</Text>
        </Tag>
    </Box>

    <Box style={{width:'50%'}}>
        <Text fontWeight="bold" mb={1}>Source</Text>
        <Tag bordered={false} style={{fontSize:'15px', paddingLeft:'8px', paddingRight:'8px', paddingTop:'2px', paddingBottom:'2px'}}>
        <Text style={{ textTransform: 'capitalize' }} mb={1}>{application.source}</Text>

        </Tag>
    </Box>

</Flex>

<Box mt={5} style={{width:'100%'}}>
    <Text fontWeight="bold" mb={1}>AI Match Score</Text>

        {/* <Button  colorScheme='blackAlpha' variant='ghost' w={'100%'} fontSize={'sm'} size={'sm'} onClick={toggleDetails}>
            {renderStars(application.match_score)}
          </Button> */}
            {renderStars(application.match_score)}
            <Box>
      <Text
        mt={2}
        pl={0} pr={0}
        width="full"
        fontSize="sm"
        noOfLines={showFullText ? null : 2}  // Conditional line clamping
      >
        {application.ai_match_score_reasoning}
      </Text>
      <Link 
        fontSize="xs"
        onClick={() => setShowFullText(!showFullText)}
        mt={2}
      >
        {showFullText ? 'Show Less' : 'Show More'}
      </Link>
    </Box>

    </Box>



    <Box mt={8} style={{width:'100%'}}>
        <Flex justifyContent={'space-between'} alignItems={'end'} >
    <Text fontWeight="bold" mb={1}>Notes</Text>
    <Box pl={2} textAlign={'center'} display={noteText ? 'block' : 'none'}>
    <Button colorScheme="blue" mt={3} size="sm" variant='solid'
      onClick={handleSubmitNote}
      isDisabled={sendMessageLoading} // Disable the button while submitting
    >
{sendMessageLoading ? (
    <Flex>
<Spinner size="sm" />
saving ...
    </Flex>
) : (
    <Flex>
<FiSave style={{fontSize:'18px', marginRight:'10px'}} w={4} h={4}/>
Save
    </Flex>
)}        </Button>
  </Box>
  </Flex>


<Box mb={7}  alignItems={'flex-end'}>
  
<TextArea
    value={noteText}
    onChange={(e) => setNoteText(e.target.value)}
    style={{fontSize:'16px'}}
    placeholder="Start typing to add a note ..."
    autoSize={{
      minRows: 2,
    }}
  />



</Box>


{applicationNotes.map((note, index) => (
<Box key={note.id} mb={4}>

<Flex style={{alignItems:'flex-start'}}>
<Avatar size={'sm'}  mr={4} src={note.user.photo.url} />
<Box>
<Text fontWeight={'bold'}>{note.user.first_name} {note.user.last_name}
<span  style={{marginLeft:"5px",fontWeight:'normal', fontSize:'12px'}} > {new Date(note.note_date).toLocaleDateString()}</span>
</Text>

<Text>{note.note}</Text>


</Box>

</Flex>


  <Divider my={3} borderColor={'#f1f1f1'}/>

</Box>
))}
</Box>




</Box>
</Box>
</Flex>
</Card>

        </Box>


        <Modal isOpen={isPiNoteModalOpen} onClose={closeResumeModal} size="xl">
  <ModalOverlay />
  <ModalContent maxWidth="75vw" height="85vh">
    <ModalHeader>{selectedResumeFirstName} {selectedResumeLastName}'s Resume</ModalHeader>
    <ModalCloseButton />
    <ModalBody overflow="hidden" p={0}> {/* Remove padding and hide overflow */}
      <iframe 
        src={resumeUrl} 
        width="100%" 
        height="100%" 
        style={{border: 'none', maxHeight: '100%', overflow: 'hidden'}} /* Adjusted styles */
        frameBorder="0"
      >
        This browser does not support PDFs. Please download the PDF to view it: <a href={resumeUrl}>Download PDF</a>.
      </iframe>
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={onPiNoteModalClose}>
        Close
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>


<Modal isOpen={isCredentialModalOpened} onClose={onCredentialModalClose} size="xl">
  <ModalOverlay />
  <ModalContent maxWidth="60vw" height="85vh">
    <ModalHeader>{applicant.first_name} {applicant.last_name}'s Credential</ModalHeader>
    <ModalCloseButton />
    <ModalBody overflow="hidden" p={0}> 
      <Img 
        src={credentialUrl} 
        width="100%" 
        height="auto" 
        style={{border: 'none', maxHeight: '100%', overflow: 'hidden'}} 
        frameBorder="0"
      >
        {/* This browser does not support PDFs. Please download the PDF to view it: <a href={credentialUrl}>Download PDF</a>. */}
      </Img>
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={onCredentialModalClose}>
        Close
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>

<Modal isOpen={isCoverLetterModalOpened} onClose={onCoverLetterModalClose} size="xl">
  <ModalOverlay />
  <ModalContent maxWidth="50vw" >
    <ModalHeader>{applicant.first_name} {applicant.last_name}'s Letter of Interest</ModalHeader>
    <ModalCloseButton />
    <ModalBody overflow="hidden" p={0}> 

    <div style={{padding:'20px'}} dangerouslySetInnerHTML={{ __html: application.cover_letter }}></div>

    </ModalBody>
    <ModalFooter>

    </ModalFooter>
  </ModalContent>
</Modal>








        </Box>
    );
  }
  
  export default StandAloneApplication;
  