import React, { useRef, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay, Text, Box, VStack,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Spinner,
  Button,
  FormControl,
  FormLabel,
  useToast, Flex, Card,
  Textarea,NumberInputField, NumberInput, Divider
} from '@chakra-ui/react';
import { FiCheck } from "react-icons/fi";
import { FiUpload, FiClipboard, FiServer, FiX } from "react-icons/fi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { marked } from 'marked';
import { upload } from '@testing-library/user-event/dist/upload';
import { UploadSingleResume } from './upload_resume';
import ImageUploading from 'react-images-uploading';
import { Checkbox, Switch, Select, Space, Input } from 'antd';
import { BiNoEntry } from 'react-icons/bi';

function JobPostModal({ isOpen, onClose, schoolName, schoolId, addNewJob, teamMembers, userId, pipelines }) {

console.log(userId)

  const modalRef = useRef(); // Ref for the modal content
  const employment_type_options = [
    {
      label: 'Administration',
      value: 'Administration',
      desc: 'Administration',
    },
    {
      label: 'Leadership',
      value: 'Leadership',
      desc: 'Leadership',
    },
    {
      label: 'Teaching',
      value: 'Teaching',
      desc: 'Teaching',
    },
    {
      label: 'Teaching support',
      value: 'Teaching support',
      desc: 'Teaching support',
    },
  ];

  
  const handleJobCategoryChange = (value) => {
    setJobCategory(value)
  };


  const pay_time_options = [
    {
      label: 'Salary',
      value: 'Salary',
      desc: 'Salary',
    },
    {
      label: 'Hourly',
      value: 'Hourly',
      desc: 'Hourly',
    },
  ];
  const handlePayTypeChange = (value) => {
    setPayType(value)
  };


  const job_type_options = [
    {
      label: 'Full-time',
      value: 'Full-time',
      desc: 'Full-time',
    },
    {
      label: 'Part-time',
      value: 'Part-time',
      desc: 'Part-time',
    },
    {
      label: 'Contract',
      value: 'Contract',
      desc: 'Contract',
    },
    {
      label: 'Temporary',
      value: 'Temporary',
      desc: 'Temporary',
    },
  ];
  const [jobTypes, setJobTypes] = useState([]);
  const handleEmploymenTypeChange = (value) => {
    setJobTypes(value)
  };

  const [jobCategory, setJobCategory] = useState('Administration');
  const [payType, setPayType] = useState('Salary');

  const inputFileRef = React.useRef(null);
  const handleFileInputClick = () => {
    inputFileRef.current.click();
  };
  const [images, setImages] = React.useState([]);
  const handleImagesChange = (updatedImages) => {
    setImages(updatedImages);
};
const eventData = {
    images,
  };
  const maxNumber = 1;
  const onImageChange = (imageList, addUpdateIndex) => {
    console.log("here are the images", imageList);
    setImages(imageList);
  };

  const [newSchoolUsers, setNewSchoolUsers] = useState([]);
  useEffect(() => {
    setNewSchoolUsers([userId]);
  }, []);


  useEffect(() => {
    setNewSchoolUsers([userId]);

    // Reset newSchoolUsers if teamMembers change and are not compatible
    // Here you might want to implement logic to check or reset `newSchoolUsers`
  }, [teamMembers]);



  const handleNewSchoolUsers = (selectedValues) => {
    setNewSchoolUsers(selectedValues);
    console.log(selectedValues)
  };

  const [pipeline, setPipeline] = useState('Administration');

  const handleNewPipeline = (value) => {
    setPipeline(value);
    console.log(value)
  };

  const [summary, setSummary] = useState('');
  const [qualifications, setQualifications] = useState('');
  const [responsibilities, setResponsibilities] = useState('');
 
  const [selectedButton, setSelectedButton] = useState('');



  const [jobTitle, setJobTitle] = useState('');
  const [minSalary, setMinSalary] = useState('');
  const [displayMinSalaryValue, setDisplayMinSalaryValue] = useState('$0'); // State to store formatted display value
  useEffect(() => {
    // Format minSalary for display whenever it changes
    setDisplayMinSalaryValue(`$${new Intl.NumberFormat().format(minSalary)}`);
  }, [minSalary]);

  const handleMinSalaryChange = (event) => {
    // Remove non-numeric characters and parse integer from the input value
    const numericValue = parseInt(event.target.value.replace(/[^0-9]/g, ''), 10) || 0;
    setMinSalary(numericValue); // Update numeric value
  };
  const [maxSalary, setMaxSalary] = useState('');
  const [displayMaxSalaryValue, setDisplayMaxSalaryValue] = useState('$0'); // State to store formatted display value
  useEffect(() => {
    setDisplayMaxSalaryValue(`$${new Intl.NumberFormat().format(maxSalary)}`);
  }, [maxSalary]);

  const handleMaxSalaryChange = (event) => {
    // Remove non-numeric characters and parse integer from the input value
    const numericValue = parseInt(event.target.value.replace(/[^0-9]/g, ''), 10) || 0;
    setMaxSalary(numericValue); // Update numeric value
  };

  const [employmentType, setEmploymentType] = useState('');



  


  const [pdfText, setPdfText] = useState('');


  
  const [askForCredential, setAskForCredential] = useState(false);
  const onAskForCredentialChange = (checked) => {
    if (askForCredential === true) {
      setCredentialRequired(false);
    }
    setAskForCredential(checked);
  };
  const [credentialRequired, setCredentialRequired] = useState(false);
  const onCredentialRequiredChange = (e) => {
    setCredentialRequired(e.target.checked)
  };

  const [askForLetterOfInterest, setaskForLetterOfInterest] = useState(false);
  const onAskForLetterOfInterestChange = (checked) => {
    if (askForLetterOfInterest === true) {
      setCoverLetterRequired(false);
    }
    setaskForLetterOfInterest(checked);
  };
  const [coverLetterRequired, setCoverLetterRequired] = useState(false);
  const onCoverLetterRequiredChange = (e) => {
    setCoverLetterRequired(e.target.checked)
  };
  
  
  const [loadingAI, setLoadingAI] = useState(false);


  const toast = useToast();
  const [jobDescriptionRawText, setJobDescriptionRawText] = useState('');


  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;
console.log('user refe coming')
    // Reset the height to auto to get the correct scrollHeight
    textarea.style.height = 'auto';
    // Set the height to the scrollHeight plus a little extra space
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [summary]); // Depend on text, so it updates on text change

  const handleChange = (event) => {
    setJobDescriptionRawText(event.target.value);
  };

  const [showTextInput, setShowTextInput] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const togglePasteClick = () => {
    setSelectedButton('paste');
    setShowTextInput(!showTextInput);
    setImages([]);

    // Ensure form is hidden when showing text input
    if (showForm) setShowForm(false);
  };

  const handleUploadClick = () => {
    setSelectedButton('upload');
    setShowTextInput(false);
    if (showForm) setShowForm(false);

    // Your existing logic here
  };

  const toggleManualAddClick = () => {
    // If the form is currently showing, reset selectedButton to 'none' and hide the form
    if (showForm) {
      setSelectedButton('none');
      setShowForm(false);
    } else {
      // If the form is not currently showing, set selectedButton to 'manual' and show the form
      setImages([]);

      setSelectedButton('manual');
      setShowForm(true);
      
    }
    // Ensure text input is hidden when showing form
    if (showTextInput) setShowTextInput(false);
  };

  const handleSubmitText = () => {
    // Handle the submission of the text input
    console.log('Submit the pasted job description');
    // Add your submission logic here
    setLoadingAI(true)
    parseJobDescription();
    setShowTextInput(false);

    // setShowTextInput(false); // Optionally hide the text input and submit button after submission
  };
  const simulateTyping = (text, setterFunction) => {
    let index = 0; // Start at the first character
    console.log("This is the first letter .... ", index)
    const interval = 50; // Interval in milliseconds between each character
  
    const typeCharacterByCharacter = () => {
      if (index < text.length) {
        setterFunction(prev => {
          console.log(`Current: ${prev}, Adding: ${text.charAt(index)}`); // Debugging line
          return prev + text.charAt(index);
        });
        index++; // Move to the next character
        setTimeout(typeCharacterByCharacter, interval); // Schedule the next character
      }
    };
    };


  

  const parseJobDescription = async () => {
    const urls = [
      "https://www.pursueli.com/api/admin_ai_parse_job_description/",
      "https://www.pursueli.com/api/admin_ai_parse_job_description/",
      "https://www.pursueli.com/api/admin_ai_parse_job_description/",
      "https://www.pursueli.com/api/admin_ai_parse_job_description/"
    ];
  
    const sections = [ "details",  "summary", "responsibilities",  "qualifications"];
    // const sections = [ "details"];

  
    try {
      const responses = await Promise.all(sections.map((section, index) => {
        const formData = new FormData();
        formData.append('text', jobDescriptionRawText);
        formData.append('section', section);
  
        // Check if images array is not empty and append the first image file
        if (images.length > 0) {
          formData.append('file', images[0].file);
        }
  
        return fetch(urls[index], {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            // 'Content-Type': 'application/json', // Don't set this for FormData
          },
          body: formData,
        })
        .then(response => response.json());
      }));
  
  
      // Set states after all promises have resolved
      try {
        const [detailsString, summaryData, responsibilitiesData, qualificationsData] = responses;
        // console.log(1, detailsString)
        // console.log(2, summaryData)
        // console.log(3, responsibilitiesData)
        // console.log(4, qualificationsData)
        // Assuming detailsString is the JSON string you need to parse
        const details = JSON.parse(detailsString);
        console.log(details)
        if (details && details.job) {
          const { title, employmentType, minmiumSalary, maximumSalary, payType, jobCategory } = details.job;
          const theTitle = title
          // setJobTitle(title);
          setJobTitle("");
          simulateTyping(title, setJobTitle);
          setJobTitle("");
          setJobTitle(theTitle);
          setPayType(payType)
          setJobCategory(jobCategory)
          console.log(employmentType)
          setJobTypes(employmentType);
          setMinSalary(parseInt(minmiumSalary, 10) || '');
          setMaxSalary(parseInt(maximumSalary, 10) || '');
        } else {
          // Handle the case where details or job are undefined or not as expected
          console.error("Job details are not structured as expected.");
        }
        // simulateTyping(qualificationsData, setQualifications);
        // simulateTyping(summaryData, setSummary);
        // simulateTyping(responsibilitiesData, setResponsibilities);
        setSummary(summaryData);
        setQualifications(qualificationsData);
        setResponsibilities(responsibilitiesData);
        setImages([]);
        setShowForm(true);

        // Your existing code to update state...
      } catch (error) {
        console.error("Error processing job description parts:", error);
        // Handle error, possibly resetting loading states or showing error message
      }

    
      // Update states to reflect the completion of async operations
      setLoadingAI(false);
      setSelectedButton('manual')
      setJobDescriptionRawText('')
      setImages([])
    } catch (error) {
      console.error("Error processing job description parts:", error);
      // Handle error, possibly resetting loading states or showing error message
    }
  };
  const string = "Phone Screen -> onsite -> Offer"

  const pipelineOptions = pipelines.map((pipeline) => ({
    value: pipeline.id,
    label: (
      <div>
        {`${pipeline.name}`}
        {/* <br />
        <small>{string}</small> */}
      </div>
    ),
  }));



  const handleSubmit = async (event) => {
    event.preventDefault();

      if (!pipeline) {
        alert("Please select a pipeline for this job post before submitting");
        return;
      }
    const formData = new FormData(event.target);
  
    formData.set('min_salary', minSalary); // Update to use the integer state value
    formData.set('max_salary', maxSalary); // Update to use the integer state value

    formData.append('qualifications', qualifications);
    formData.append('responsibilities', responsibilities);

    formData.append('ask_for_credential', askForCredential);
    formData.append('teacher_credential_required', credentialRequired);

    formData.append('ask_for_cover_letter', askForLetterOfInterest);
    formData.append('cover_letter_required', coverLetterRequired);


    formData.append('job_category', jobCategory);
    formData.append('employment_types', jobTypes);
    formData.append('pay_type', payType);


    formData.append('users_to_alert', newSchoolUsers);
    formData.append('pipeline', pipeline);
    

    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    console.log('school id is, ', schoolId)
    
    // Example POST request with formData
    try {
      const response = await fetch('https://www.pursueli.com/api/admin_post_a_job/', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            // 'Content-Type': 'application/json', // Comment out for FormData
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("data mane, ", data)
        const job_id = data.new_job.id

        const newJob = data.new_job; // Assuming the API returns the new job data under 'new_job'
        addNewJob(newJob); 
        setSummary('')
        setQualifications('')
        setResponsibilities('')
        setSelectedButton('')
        setJobTitle('')
        setMinSalary('')
        setMaxSalary(''); // Update numeric value
        setEmploymentType('')
        setJobCategory('')
        console.log(job_id)
        setPipeline('')
        // fetch('https://www.pursueli.com/api/enrich_job_post/', {
        //     method: 'POST',
        //     headers: {
        //       'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ job_id }),
        //   }).then(() => {
        //     console.log('Request to perform action with job_id sent.');
        //   }).catch((error) => {
        //     console.error('Error sending job_id:', error);
        //   });

        toast({
          title: 'Job posted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose(); // Close the modal
      } else {
        throw new Error('Failed to post job.');
      }
    } catch (error) {
      toast({
        title: 'Error posting job.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };




  const handlePdfSubmit= async () => {
    setLoadingAI(true)
    const formData = new FormData();
    formData.append('pdf', images[0].file); // Append the PDF file to formData
    try {
      const response = await fetch('https://www.pursueli.com/api/admin_extract_pdf_text/', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        console.log("data mane, ", data);
        const text = data.text;
        setJobDescriptionRawText(text);
        parseJobDescription();
      } else {
        throw new Error('Failed to post job.');
      }
    } catch (error) {
      console.error("Error posting job:", error.message);
    }
};

  const modules = {
    toolbar: [

        ['bold', 'italic', 'underline', 'link'], // Adding 'link' for hyperlinks
        [{'list': 'ordered'}, {'list': 'bullet'}],
        
        // Ensure headers or any 'header' related options are not included here
    ],
};

const formats = {
formats : [
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'link'
]
};



  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      
      <ModalOverlay  />
      <ModalContent maxWidth="60vw" backgroundColor={'#F7FAFC'}>
      <style>

                {
                  `.custom-quill .ql-container {
  border: 1px solid #E2E8F0; /* Chakra's gray.300 border color */
  border-radius: 0 0 0.375rem 0.375rem; /* Chakra's md border radius */
}

.custom-quill .ql-editor {
  min-height: 120px; /* Set to desired minimum height */
  font-size: 1rem; /* Chakra's default font size */
}
.custom-quill .ql-container:focus-within {
  border-color: #3182CE; /* Chakra's blue.500 */
  box-shadow: 0 0 0 1px #3182CE; /* Simulate focus ring */
  .ql-toolbar {
    border-color: #3182CE; /* Chakra's blue.500 */
    box-shadow: 0 0 0 1px #3182CE; /* Simulate focus ring */
  }
}


.custom-quill .ql-toolbar {
  border-color: #E2E8F0; /* Chakra's gray.300 border color */
  border-radius: 0.375rem 0.375rem 0 0; /* Rounded top corners */
}`


                }
            </style>
        <ModalHeader>Post a Job for {schoolName}</ModalHeader>
        <ModalCloseButton />
        <ImageUploading
  multiple={false}
  value={images}
  onChange={onImageChange} // Changed from handleImagesChange to onImageChange based on your setup
  maxNumber={maxNumber}
  dataURLKey="data_url"
  allowNonImageType={true}
>
  {({
    imageList,
    onImageUpload,
    onImageRemoveAll,
    // Include other provided functions as needed
  }) => (
    <div>
      <Flex pt={2} px={5} mb={10} justifyContent={'center'} backgroundColor={'#F7FAFC'}>
        <Box w={'33%'} pr={4}>
          <Button
            colorScheme='gray'
            size="lg"
            variant={selectedButton === 'upload' ? 'solid' : 'outline'}
            shadow={'xl'}
            width="100%"
            p={3}
            height="125px"
            borderWidth="2px"
            borderColor="gray.200"
            flexWrap={'wrap'}
            style={{textWrap: 'wrap',}}
            textAlign={'start'}
            justifyContent={'flex-start'}
            onClick={() => {
              handleUploadClick();
              onImageUpload(); // Direct call to trigger file dialog
            }}
          >




            
            <Box mb={2} w={'100%'}>
              <FiUpload fontSize={'22px'} />
            </Box>
            <Text mb={2}>Upload PDF</Text>
            <Text style={{fontSize:'14px', textWrap:'wrap', flexWrap:'wrap', fontWeight:'normal'}}>
              Create job post by uploading a PDF of the job description
            </Text>
          </Button>
        </Box>
        <Box w={'34%'} pr={4}>
        <Button
          size="lg"
          variant={selectedButton === 'paste' ? 'solid' : 'outline'}
          width="100%"
          shadow={'xl'}

          p={3}
          height="125px"
          borderWidth="2px"
          borderColor="gray.200"
          flexWrap={'wrap'}
          style={{textWrap:'wrap',}}
          textAlign={'start'}
          justifyContent={'flex-start'}
          onClick={togglePasteClick}
 
        >
          <Box mb={2} w={'100%'}>
            <FiClipboard fontSize={'22px'} />
          </Box>
          <Text mb={2} >Paste Raw Text</Text>
          <Text style={{fontSize:'14px',textWrap:'wrap', flexWrap:'wrap', fontWeight:'normal'}}>Create job post by copying and pasting the full description.</Text>
        </Button>
        </Box>
        

        <Box w={'33%'} >
        <Button
          size="lg"
          variant={selectedButton === 'manual' ? 'solid' : 'outline'}
          shadow={'xl'}

          width="100%"
          p={3}
          height="125px"
          borderWidth="2px"
          borderColor="gray.200"
          flexWrap={'wrap'}
          style={{textWrap:'wrap',}}
          textAlign={'start'}
          justifyContent={'flex-start'}
          onClick={toggleManualAddClick} // Updated onClick handler
        >
          <Box mb={2} w={'100%'}>
            <FiServer fontSize={'22px'} />
          </Box>
          <Text mb={2} >Manually Add</Text>
          <Text style={{fontSize:'14px',textWrap:'wrap', flexWrap:'wrap', fontWeight:'normal'}}>Create job post by copying and pasting the full description.</Text>
        </Button>
        </Box>
    </Flex>
    <Box px={4} pb={4}>
            {imageList.map((image, index) => (
              <div key={index}>

                {/* Display the uploaded file */}
                {image.file.type.includes("pdf") ? (
                  <Box>
                  <embed src={image.data_url} type="application/pdf" style={{ width: '100%', height: '355px', borderRadius:'10px' }} />

{!loadingAI && (

<Flex justifyContent={'center'} mt={8}>
<Button variant={'ghost'} colorScheme="red" onClick={onImageRemoveAll}>
<Flex alignItems={'center'}>
<FiX style={{marginRight:'10px'}}/>
<Text mb={'3px'}>
remove
</Text>
</Flex>
</Button>
<Button ml={4} variant={'ghost'} colorScheme="green" onClick={handlePdfSubmit}>
<Flex alignItems={'center'}>
<FiCheck style={{marginRight:'10px'}}/>
<Text mb={'3px'}>
confirm
</Text>
</Flex>
</Button>
</Flex>
)}
                  </Box>
                ) : (
                  <Box>
                                      <Text textAlign={'center'} color={'red'}>Please remove and upload a PDF</Text>

                    {!loadingAI && (

<Flex justifyContent={'center'} mt={3}>
<Button variant={'ghost'} colorScheme="red" onClick={onImageRemoveAll}>
<Flex alignItems={'center'}>
<FiX style={{marginRight:'10px'}}/>
<Text mb={'3px'}>
remove
</Text>
</Flex>
</Button>
</Flex>
)}
                  </Box>
                )}

        

              </div>
            ))}
            </Box>






    {showTextInput && (
        <Box px={4} pb={4} >
          <Textarea
          minH={'130px'}
          shadow={'sm'}
            value={jobDescriptionRawText}
            onChange={handleChange}
            placeholder="Please paste the job description and other pertinent role details here. Include qualifications, responsibilities, pay ranges, job summary, etc... Don't worry about formatting!"
            mb={8}
          />
          {!loadingAI && jobDescriptionRawText != "" && (
          <Flex justifyContent={'center'}>
          <Button
          variant={'solid'}
            colorScheme="green"
            onClick={handleSubmitText}
          >
<Flex alignItems={'center'}>
                  <FiCheck style={{marginRight:'10px'}}/>
                  <Text mb={'3px'}>
                  Build job post
                  </Text>
                  </Flex>          </Button>
          </Flex>
          )}
        </Box>
      )}

        {
          loadingAI && (
            <Flex textAlign="center" mb={4} alignItems={'center'} justifyContent={'center'}>
              <Spinner size="sm" />
              <Text ml={4}>Building Job Post ... Do not refresh, this can take up to 45 seconds.</Text>
            </Flex>
          )
        }


       
          </div>
        )}



      </ImageUploading>
        

    <Divider pt={0} mt={0} mb={2} />

    {showForm && (

        <form onSubmit={handleSubmit} style={{padding:'23px', backgroundColor:'#F7FAFC' }} backgroundColor={'red'}  >
                <Card style={{padding:'10px'}} shadow={'xl'}
                          borderWidth="2px"
                          borderColor="gray.200"
                >

<Flex mx={4} justifyContent={'space-between'} alignItems={'center'}>
<Text as="h3"  mb={3} fontSize="lg" fontWeight="bold">Job Post Details</Text>
{/* <Button size={'xs'}  colorScheme='red' variant={'outline'} mt={'-8px'} >clear all fields</Button> */}
</Flex>

          <ModalBody ref={modalRef}>
            
          <Input  style={{display:'none'}} name="school_id" defaultValue={schoolId} />

          <Flex style={{justifyContent:'space-between'}} >

            <FormControl isRequired minW={'65%'} pr={4}>
              <FormLabel>Title</FormLabel>
              <Input name="title" placeholder="Job title ..."
                  defaultValue={jobTitle}
                  size='large'
                  disabled = {loadingAI}
                  onChange={(e) => setJobTitle(e.target.value)}
            
              />
            </FormControl>

            

            <FormControl isRequired>
              <FormLabel>Job Category</FormLabel>
            <Select
                  style={{
                    width: '100%',
                  }}
                  size='large'
                  disabled = {loadingAI}
                  name="job_category" 
                  // value={jobCategory}
                  // onChange={(e) => setJobCategory(e.target.value)}

                  placeholder="select employment types ..."
                  defaultValue={jobCategory}
                  onChange={handleJobCategoryChange}
                  getPopupContainer={() => modalRef.current || document.body}
                  options={employment_type_options}
                  optionRender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}>
                        {option.data.emoji}
                      </span>
                      {option.data.desc}
                    </Space>
                  )}
                />
            </FormControl>
        </Flex>


        <FormControl isRequired pt={6}>
              <FormLabel>Employment Type

                <span style={{color:'gray', fontWeight:300,  marginLeft:'10px'}}>
                  select all that apply
                </span>
              </FormLabel>


              <Select
                  mode="multiple"
                  style={{
                    width: '100%',
                  }}
                  size='large'

                  name="employment_type" 
                  // value={employmentType}
                  // onChange={(e) => setEmploymentType(e.target.value)}


                  disabled = {loadingAI}
                  placeholder="select employment types ..."
                  defaultValue={jobTypes}
                  onChange={handleEmploymenTypeChange}
                  getPopupContainer={() => modalRef.current || document.body}
                  options={job_type_options}
                  optionRender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}>
                        {option.data.emoji}
                      </span>
                      {option.data.desc}
                    </Space>
                  )}
                />




             
            </FormControl>
        

            <Flex style={{justifyContent:'space-between'}} >
                <FormControl isRequired  mt={6} w={'35%'} pr={4}>
                <FormLabel>Minimum Pay</FormLabel>
                <Input
                size='large'
      name="min_salary"
      disabled={loadingAI}
      value={displayMinSalaryValue}
      onChange={handleMinSalaryChange}
      placeholder="$"
    />
                </FormControl>
                <FormControl  mt={6} w={'35%'}  pr={4}>
                <FormLabel>Maximum Pay</FormLabel>
                <Input
                                size='large'

      name={'max_salary'}
      disabled={loadingAI}
      value={displayMaxSalaryValue}
      onChange={handleMaxSalaryChange}
      placeholder="$"
    />

                </FormControl>
                <FormControl  mt={6} w={'30%'}>
                <FormLabel>Pay type</FormLabel>

                <Select
                  style={{
                    width: '100%',
                  }}
                  size='large'
                  name="pay_type" 
                  disabled = {loadingAI}
                  placeholder="select one country"
                  defaultValue={payType}
                  onChange={handlePayTypeChange}
                  getPopupContainer={() => modalRef.current || document.body}
                  options={pay_time_options}
                  optionRender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}>
                        {option.data.emoji}
                      </span>
                      {option.data.desc}
                    </Space>
                  )}
                />

                </FormControl>

            </Flex>

                <FormControl isRequired mt={6}>
                <FormLabel>Role Summary</FormLabel>
                <Textarea
                disabled = {loadingAI}
                minH={'70px'}
                name="summary"
                ref={textareaRef}
                overflow="hidden" // Prevent scrollbar from appearing
                value={summary}
                onChange={(e) => setSummary(e.target.value)} // Correctly handle the event object
              />                
              </FormControl>

                <FormControl isRequired mt={6}>
                <FormLabel>Role Requirements</FormLabel>
                <Box className="custom-quill"  p={0} maxW="full" mx="auto">
                  <ReactQuill
                  readOnly={loadingAI} // Make the editor read-only
                  name="requirements" value={responsibilities} onChange={setResponsibilities}    formats={formats}             modules={modules}/>
                </Box>
                </FormControl>

                <FormControl isRequired mt={6}>
                <FormLabel>Role Qualifications</FormLabel>
                <Box className="custom-quill" p={0} maxW="full" mx="auto">
                  <ReactQuill
                  readOnly={loadingAI}
                  name="qualifications" theme="snow" value={qualifications} onChange={setQualifications}    formats={formats}             modules={modules}/>
                </Box>
                </FormControl>

                <Flex style={{justifyContent:'space-between'}} mt={6} >


              <FormControl pr={4} >
              <FormLabel >
              <Switch 
              disabled = {loadingAI}
              name="credential_required" 
              checked={askForCredential}
              style={{marginRight:'10px'}}
              onChange={onAskForCredentialChange}
                  />
                
                Ask for teaching credential</FormLabel>

                {askForCredential && (
                  <>
                    <Text display="flex" alignItems="center" color="gray" fontWeight="500">
                      Required
                      <Checkbox 
                        disabled={loadingAI}
                        name="credential_required"
                        checked={credentialRequired}
                        style={{marginLeft:'10px'}}
                        onChange={onCredentialRequiredChange}
                      />
                    </Text>

                    <Text fontSize="sm" color="gray">
                      Enabling this setting requires candidates to upload a copy of their teaching credential 
                      <span style={{ textDecoration: 'underline' }}> before </span> they can submit their application.
                    </Text>
                  </>
                )}

            </FormControl>

            <FormControl pl={4} >
              <FormLabel >
              <Switch 
              disabled = {loadingAI}
              checked={askForLetterOfInterest}
              style={{marginRight:'10px'}}
              onChange={onAskForLetterOfInterestChange}
                  />
                
                Ask for Letter of interest</FormLabel>

                {askForLetterOfInterest && (
                  <>
                    <Text display="flex" alignItems="center" color="gray" fontWeight="500">
                      Required
                      <Checkbox 
                        disabled={loadingAI}
                        checked={coverLetterRequired}
                        style={{marginLeft:'10px'}}
                        onChange={onCoverLetterRequiredChange}
                      />
                    </Text>

                    <Text fontSize="sm" color="gray">
                    Enabling this setting requires candidates to write a letter of initerest (cover letter)  <span style={{ textDecoration: 'underline' }}> before </span>  they can submit their application.     
                    </Text>
                  </>
                )}
              </FormControl>
            </Flex>

            <FormControl id="text5" pt={8} >
          <FormLabel mb={'4px'} >Recruiting Admins
          <Text fontSize="sm" color="gray" fontWeight={'normal'}>
            Selected users will be notified on new applications for this job post.
      </Text>
          </FormLabel>
          <Select
          mode="multiple"
          size={'large'}
          style={{ width: '100%', borderRadius: '0.375rem' }}
          placeholder="Select which team members should be admins for this school site."
          value={[...newSchoolUsers]} // Ensure this is an array
          onChange={handleNewSchoolUsers}
          optionLabelProp="label"
          options={teamMembers.map((member) => ({
            value: member.id, // Assuming each team member has a unique id
            label: `${member.user.first_name} ${member.user.last_name} (${member.user.email})`, // Adjust according to your data structure
          }))}
          getPopupContainer={() => modalRef.current || document.body}
        
        />
        </FormControl>
        

        <FormControl id="text5" pt={8} >
          <FormLabel mb={'4px'} >Pipeline
          <Text fontSize="sm" color="gray" fontWeight={'normal'}>
          Pipelines define the sequence of steps (e.g., Phone Screen, Onsite Interview, Offer) that applicants will go through for this position.</Text>
          </FormLabel>
          <Select
          // mode="multiple"
          size={'large'}
          style={{ width: '100%', borderRadius: '0.375rem' }}
          placeholder="Select the hiring process this job will follow."
          // value={[...newSchoolUsers]} // Ensure this is an array
          onChange={handleNewPipeline}
          optionLabelProp="label"
          options={pipelineOptions}
          getPopupContainer={() => modalRef.current || document.body}
          isRequired
        />
        </FormControl>




          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Finish
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
          </Card>

        </form>
                )}

      </ModalContent>
    </Modal>
  );
}

export default JobPostModal;
