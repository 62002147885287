import React, { useRef, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay, Text, Box, VStack,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Spinner,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast, Flex, Card,
  Textarea,NumberInputField, NumberInput, Divider
} from '@chakra-ui/react';
import { FiBriefcase, FiCheck } from "react-icons/fi";
import { FiUpload, FiClipboard, FiServer, FiX } from "react-icons/fi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { marked } from 'marked';
import { upload } from '@testing-library/user-event/dist/upload';
import { UploadSingleResume } from './upload_resume';
import ImageUploading from 'react-images-uploading';
import { message, Upload, Dropdown, Option, Select } from 'antd';

function EditJobPostEmailsModal({ isOpen, onClose, job, replaceJob, schoolId, emailTemplates, org }) {

    const modalRef = useRef(); // Ref for the modal content

    const handleSelectedRejectedEmail= (value) => {
        setOnApplicationRejectedEmail(value);
      };
    const [onApplicationRejectedEmail, setOnApplicationRejectedEmail] = useState('');
    useEffect(() => {
      console.log("here and there ->", job.email_sent_on_rejected_application)
      if (!job.email_sent_on_rejected_application || job.email_sent_on_rejected_application === null) {
        setOnApplicationRejectedEmail(org.default_reject_application_email.id);
      } else {
        setOnApplicationRejectedEmail(job.email_sent_on_rejected_application.id);
      }
    }, [job, org]);
    const selectedApplicationRejecetedEmail = emailTemplates.find(email => email.id === onApplicationRejectedEmail) || org.default_reject_application_email;


    const handleSelectedAcceptedEmail= (value) => {
        setOnApplicationAcceptedEmail(value);
      };
    const [onApplicationAcceptedEmail, setOnApplicationAcceptedEmail] = useState('');
    useEffect(() => {
      console.log("here and there ->", job.email_sent_on_accepted_application)
      if (!job.email_sent_on_accepted_application || job.email_sent_on_accepted_application === null) {
        setOnApplicationAcceptedEmail(org.default_accept_application_email.id);
      //   alert("money")
      } else {
        setOnApplicationAcceptedEmail(job.email_sent_on_accepted_application.id);
      }
    }, [job, org]);
    const selectedApplicationAcceptedEmail = emailTemplates.find(email => email.id === onApplicationAcceptedEmail) || org.default_accept_application_email;




    const [onApplicationEmail, setOnApplicationEmail] = useState('');

    const handleSelectedGradesServed = (value) => {
        setOnApplicationEmail(value);
      };


    const [expandedItems, setExpandedItems] = useState({});
    const toggleExpand = (inviteId) => {
      setExpandedItems(prevState => ({
        ...prevState,
        [inviteId]: !prevState[inviteId]
      }));
    };

  const [summary, setSummary] = useState(job.description);
  const [qualifications, setQualifications] = useState(job.qualifications);
  const [responsibilities, setResponsibilities] = useState(job.requirements);
 
  const [selectedButton, setSelectedButton] = useState('');



  const [jobTitle, setJobTitle] = useState(job.title);
  const [minSalary, setMinSalary] = useState(job.minimum_pay);
  const [displayMinSalaryValue, setDisplayMinSalaryValue] = useState('$0'); // State to store formatted display value
  useEffect(() => {
    // Format minSalary for display whenever it changes
    setDisplayMinSalaryValue(`$${new Intl.NumberFormat().format(minSalary)}`);
  }, [minSalary]);

  const handleMinSalaryChange = (event) => {
    // Remove non-numeric characters and parse integer from the input value
    const numericValue = parseInt(event.target.value.replace(/[^0-9]/g, ''), 10) || 0;
    setMinSalary(numericValue); // Update numeric value
  };
  const [maxSalary, setMaxSalary] = useState(job.maximum_pay);
  const [displayMaxSalaryValue, setDisplayMaxSalaryValue] = useState('$0'); // State to store formatted display value
  useEffect(() => {
    setDisplayMaxSalaryValue(`$${new Intl.NumberFormat().format(maxSalary)}`);
  }, [maxSalary]);

  const handleMaxSalaryChange = (event) => {
    // Remove non-numeric characters and parse integer from the input value
    const numericValue = parseInt(event.target.value.replace(/[^0-9]/g, ''), 10) || 0;
    setMaxSalary(numericValue); // Update numeric value
  };

  const [employmentType, setEmploymentType] = useState(job.employment_type);


  useEffect(() => {
    console.log("here and there ->", job.email_sent_on_application)
    if (!job.email_sent_on_application || job.email_sent_on_application === null) {
      setOnApplicationEmail(org.default_new_application_email.id);
    //   alert("money")
    } else {
      setOnApplicationEmail(job.email_sent_on_application.id);
    }
  }, [job, org]);

  const selectedEmail = emailTemplates.find(email => email.id === onApplicationEmail) || org.default_new_application_email;

  const [loadingAI, setLoadingAI] = useState(false);


  const toast = useToast();


  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;
console.log('user refe coming')
    // Reset the height to auto to get the correct scrollHeight
    textarea.style.height = 'auto';
    // Set the height to the scrollHeight plus a little extra space
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [summary]); // Depend on text, so it updates on text change




  const simulateTyping = (text, setterFunction) => {
    let index = 0; // Start at the first character
    console.log("This is the first letter .... ", index)
    const interval = 50; // Interval in milliseconds between each character
  
    const typeCharacterByCharacter = () => {
      if (index < text.length) {
        setterFunction(prev => {
          console.log(`Current: ${prev}, Adding: ${text.charAt(index)}`); // Debugging line
          return prev + text.charAt(index);
        });
        index++; // Move to the next character
        setTimeout(typeCharacterByCharacter, interval); // Schedule the next character
      }
    };
    };

    const handleSubmit = async (event) => {
        console.log(onApplicationEmail)
        console.log(onApplicationRejectedEmail)
        console.log(onApplicationAcceptedEmail)
        
        
        const formData = new FormData();
        formData.set('id', job.id); // Update to use the integer state value
        formData.set('onApplicationEmail', onApplicationEmail); // Update to use the integer state value
        formData.set('onApplicationAcceptedEmail', onApplicationAcceptedEmail); // Update to use the integer state value
        formData.set('onApplicationRejectedEmail', onApplicationRejectedEmail);
        try {
          const response = await fetch('https://www.pursueli.com/api/admin_edit_job_email_templates/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                // 'Content-Type': 'application/json', // Comment out for FormData
            },
            body: formData,
          });
          if (response.ok) {
            const data = await response.json();
            console.log("data mane, ", data)
            const job_id = data.new_job.id
    
            const newJob = data.new_job; // Assuming the API returns the new job data under 'new_job'
            replaceJob(newJob);
            toast({
              title: 'Job post email templates saved.',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            onClose(); // Close the modal
          } else {
            throw new Error('Failed to save job post email templates');
          }
        } catch (error) {
          toast({
            title: 'Error savinig.',
            description: error.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }





    }


  const handleSubmit2 = async (event) => {
    console.log(onApplicationEmail)
    return
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.set('min_salary', minSalary); // Update to use the integer state value
    formData.set('max_salary', maxSalary); // Update to use the integer state value
    formData.append('qualifications', qualifications);
    formData.append('id', job.id);
    formData.append('responsibilities', responsibilities);
    // Example POST request with formData
    try {
      const response = await fetch('https://www.pursueli.com/api/admin_edit_job/', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            // 'Content-Type': 'application/json', // Comment out for FormData
        },
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        console.log("data mane, ", data)
        const job_id = data.new_job.id

        const newJob = data.new_job; // Assuming the API returns the new job data under 'new_job'
        replaceJob(newJob);
        toast({
          title: 'Job post edited.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose(); // Close the modal
      } else {
        throw new Error('Failed to post job.');
      }
    } catch (error) {
      toast({
        title: 'Error posting job.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const modules = {
    toolbar: [

        ['bold', 'italic', 'underline', 'link'], // Adding 'link' for hyperlinks
        [{'list': 'ordered'}, {'list': 'bullet'}],
        
        // Ensure headers or any 'header' related options are not included here
    ],
};

const formats = {
formats : [
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'link'
]
};



  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      
      <ModalOverlay  />
      <ModalContent maxWidth="60vw" backgroundColor={'#F7FAFC'}>
      <style>

                {
                  `.custom-quill .ql-container {
  border: 1px solid #E2E8F0; /* Chakra's gray.300 border color */
  border-radius: 0 0 0.375rem 0.375rem; /* Chakra's md border radius */
}

.custom-quill .ql-editor {
  min-height: 120px; /* Set to desired minimum height */
  font-size: 1rem; /* Chakra's default font size */
}
.custom-quill .ql-container:focus-within {
  border-color: #3182CE; /* Chakra's blue.500 */
  box-shadow: 0 0 0 1px #3182CE; /* Simulate focus ring */
  .ql-toolbar {
    border-color: #3182CE; /* Chakra's blue.500 */
    box-shadow: 0 0 0 1px #3182CE; /* Simulate focus ring */
  }
}


.custom-quill .ql-toolbar {
  border-color: #E2E8F0; /* Chakra's gray.300 border color */
  border-radius: 0.375rem 0.375rem 0 0; /* Rounded top corners */
}`


                }
            </style>
        <ModalHeader>Edit Job Email Templates

        <Box display={'flex'}  color={'#4A5568'} alignItems={'center'}>
              <FiBriefcase fontSize={'14px'}/>
            <Text  ml={2} color={'#4A5568'} fontSize={'sm'}>{job.title} at {job.school_sites[0].school_name}</Text>
        </Box>
                </ModalHeader>
        <ModalCloseButton />

       

        <form onSubmit={handleSubmit} style={{paddingLeft:'10px',paddingRight:'10px',paddingBottom:'18px', paddingTop:'10px', backgroundColor:'#F7FAFC' }} backgroundColor={'red'}  >
                <Box>

          <ModalBody ref={modalRef}>
            
          <Input display={'none'} name="school_id" value={schoolId} />
          <Flex style={{justifyContent:'space-between'}} >
            <FormControl minW={'35%'} maxW={'35%'} pr={4}>
            <Text mb={2} fontWeight={'bold'}>On application</Text>
            <Select
             placeholder='Select email template'
          style={{
            width: '100%',
          }}
          defaultValue={onApplicationEmail}
          getPopupContainer={() => modalRef.current || document.body}
          size='large'
          onChange={handleSelectedGradesServed}
          options={[
            { value: org.default_new_application_email.id, label: org.default_new_application_email.name },
            ...emailTemplates
              .filter(email => email.action === 'On Application')
              .map(email => ({
                value: email.id,
                label: email.name,
              })),
          ]}
          
        />

            </FormControl>

            <FormControl pl={5}>
            <Text color={'gray'} fontWeight="bold" mb={1}>{selectedEmail.subject}</Text>                    
                <Box key={selectedEmail.id}overflow="hidden"  style={expandedItems[selectedEmail.id] ? { paddingBottom:'40px'} : { position: 'relative', minHeight:'250px', maxHeight:'250px', overflow: 'hidden' }}>
                <Flex direction="column" h="100%" pt={1}>
                <Flex align="center" mb={0}>
                <Box w={'100%'}>
                    <Box style={expandedItems[selectedEmail.id] ? {} : { position: 'relative', maxHeight: '150px', overflow: 'hidden' }}>
                    {!expandedItems[selectedEmail.id] && (
                    <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="120px" /* Height of the fade effect */
                        background="linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(247, 250, 252))" /* Adjust the color to match your background */
                    />
                    )}
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                {selectedEmail.email_body}
                    </Text>
                    </Box>
                    <Text mt={3} cursor="pointer" justify="center" textAlign={'center'} onClick={() => toggleExpand(selectedEmail.id)}>
                    {/* <Text mt={3} cursor="pointer" justify="center" textAlign={'center'}> */}
                    {expandedItems[selectedEmail.id] ? 'Show less' : 'Show more'}
                </Text>
                </Box>
                </Flex>
                </Flex>
                </Box>
            </FormControl>
        </Flex>


        <Flex style={{justifyContent:'space-between'}} >
            <FormControl minW={'35%'} maxW={'35%'} pr={4}>
            <Text mb={2} fontWeight={'bold'}>Application accepted</Text>
            <Select
             placeholder='Select email template'
          style={{
            width: '100%',
          }}
          defaultValue={onApplicationAcceptedEmail}
          getPopupContainer={() => modalRef.current || document.body}
          size='large'
          onChange={handleSelectedAcceptedEmail}
          options={[
            { value: org.default_accept_application_email.id, label: org.default_accept_application_email.name },
            ...emailTemplates
              .filter(email => email.action === 'Application Accepted')
              .map(email => ({
                value: email.id,
                label: email.name,
              })),
          ]}
          
        />

            </FormControl>

            <FormControl pl={5}>
            <Text color={'gray'} fontWeight="bold" mb={1}>{selectedApplicationAcceptedEmail.subject}</Text>                    
              

                <Box key={selectedEmail.id}overflow="hidden"  style={expandedItems[selectedApplicationAcceptedEmail.id] ? { paddingBottom:'40px'} : { position: 'relative', minHeight:'250px', maxHeight:'250px', overflow: 'hidden' }}>
                <Flex direction="column" h="100%" pt={1}>
                <Flex align="center" mb={0}>
                <Box w={'100%'}>
                    <Box style={expandedItems[selectedApplicationAcceptedEmail.id] ? {} : { position: 'relative', maxHeight: '150px', overflow: 'hidden' }}>
                    {!expandedItems[selectedApplicationAcceptedEmail.id] && (
                    <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="120px" /* Height of the fade effect */
                        background="linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(247, 250, 252))" /* Adjust the color to match your background */
                    />
                    )}
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                {selectedApplicationAcceptedEmail.email_body}
                    </Text>
                    </Box>
                    <Text mt={3} cursor="pointer" justify="center" textAlign={'center'} onClick={() => toggleExpand(selectedApplicationAcceptedEmail.id)}>
                    {/* <Text mt={3} cursor="pointer" justify="center" textAlign={'center'}> */}
                    {expandedItems[selectedApplicationAcceptedEmail.id] ? 'Show less' : 'Show more'}
                </Text>
                </Box>
                </Flex>
                </Flex>
                </Box>
            </FormControl>
        </Flex>

        <Flex style={{justifyContent:'space-between'}} >
            <FormControl minW={'35%'} maxW={'35%'} pr={4}>
            <Text mb={2} fontWeight={'bold'}>Application rejected</Text>
            <Select
             placeholder='Select email template'
          style={{
            width: '100%',
          }}
          defaultValue={onApplicationRejectedEmail}
          getPopupContainer={() => modalRef.current || document.body}
          size='large'
          onChange={handleSelectedRejectedEmail}
          options={[
            { value: org.default_reject_application_email.id, label: org.default_reject_application_email.name },
            ...emailTemplates
              .filter(email => email.action === 'Application Rejected')
              .map(email => ({
                value: email.id,
                label: email.name,
              })),
          ]}
          
        />

            </FormControl>

            <FormControl pl={5}>
            <Text color={'gray'} fontWeight="bold" mb={1}>{selectedApplicationRejecetedEmail.subject}</Text>                    
              

                <Box key={selectedEmail.id}overflow="hidden"  style={expandedItems[selectedApplicationRejecetedEmail.id] ? { paddingBottom:'40px'} : { position: 'relative', minHeight:'250px', maxHeight:'250px', overflow: 'hidden' }}>
                <Flex direction="column" h="100%" pt={1}>
                <Flex align="center" mb={0}>
                <Box w={'100%'}>
                    <Box style={expandedItems[selectedApplicationRejecetedEmail.id] ? {} : { position: 'relative', maxHeight: '150px', overflow: 'hidden' }}>
                    {!expandedItems[selectedApplicationRejecetedEmail.id] && (
                    <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="120px" /* Height of the fade effect */
                        background="linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(247, 250, 252))" /* Adjust the color to match your background */
                    />
                    )}
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                {selectedApplicationRejecetedEmail.email_body}
                    </Text>
                    </Box>
                    <Text mt={3} cursor="pointer" justify="center" textAlign={'center'} onClick={() => toggleExpand(selectedApplicationRejecetedEmail.id)}>
                    {/* <Text mt={3} cursor="pointer" justify="center" textAlign={'center'}> */}
                    {expandedItems[selectedApplicationRejecetedEmail.id] ? 'Show less' : 'Show more'}
                </Text>
                </Box>
                </Flex>
                </Flex>
                </Box>
            </FormControl>
        </Flex>


          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
          </Box>

        </form>

      </ModalContent>
    </Modal>
  );
}

export default EditJobPostEmailsModal;
