import React, { useRef, useEffect, useState } from 'react';
import {
  Modal, Alert, AlertDescription, AlertTitle, AlertIcon,
  ModalOverlay, Text, Box, VStack,
  ModalContent,  Menu,
  MenuButton, Switch,
  MenuList,
  MenuItem, Grid, GridItem,
  ModalHeader,
  ModalFooter,
  ModalBody, option,
  ModalCloseButton, Spinner,
  Button,
  FormControl,
  FormLabel,
  Input, Select,
  useToast, Flex, Card,
  Textarea,NumberInputField, NumberInput, Divider, Checkbox
} from '@chakra-ui/react';
import { FiBriefcase, FiCheck, FiGlobe, FiZap } from "react-icons/fi";
import { FiUpload, FiClipboard, FiServer, FiX } from "react-icons/fi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { marked } from 'marked';
import { upload } from '@testing-library/user-event/dist/upload';
import { UploadSingleResume } from './upload_resume';
import ImageUploading from 'react-images-uploading';
import { ChevronDownIcon } from '@chakra-ui/icons';

function BoostJobModal({ isOpen, onClose, job, replaceJob, schoolId }) {

  const [jobTitle, setJobTitle] = useState(job.title);
  const [isLoading, setIsLoading] = useState(false);

  const calculateDaysUntilExpiration = (expirationDateString) => {
    const expirationDate = new Date(expirationDateString);
    const currentDate = new Date();
    const differenceInMilliseconds = expirationDate - currentDate;
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    return differenceInDays;
  };

  const [showBoost, setShowBoost] = useState(false);


  const toast = useToast();



  const handleBoost = async () => {
    // Prepare the data to be sent
    setIsLoading(true)
    const payload = {
      id: job.id,
    };
  
    try {
      const response = await fetch('https://www.pursueli.com/api/admin_boost_job/', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Send the JSON payload
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log("data mane, ", data);
        const newJobId = data.new_job.id;
  
        const newJob = data.new_job; // Assuming the API returns the new job data under 'new_job'
        replaceJob(newJob); // Update the job list with the new job details
        toast({
          title: 'Job post boosted.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose(); // Close the modal
      } else {
        // Handle server errors (e.g., 4XX, 5XX responses)
        const errorData = await response.json(); // Assuming the server sends an error message as JSON
        throw new Error(errorData.message || 'Failed to arhive job post.');
      }
    } catch (error) {
      // Handle errors in the request itself (e.g., network error)
      toast({
        title: 'Error boosting job post.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  
  const handleSwitchChange = async (jobId, isActive) => {
    setIsLoading(true)

    try {
      const response = await fetch(`https://www.pursueli.com/api/update_job_talent_collective_visibility/${jobId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ is_active: isActive }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update job status.');
      }
      const data = await response.json();
      console.log("data mane, ", data);
      const newJobId = data.new_job.id;

      const newJob = data.new_job; // Assuming the API returns the new job data under 'new_job'
      replaceJob(newJob); // Update the job list with the new job details

      toast({
        title: 'Visibility updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false)
      onClose(); // Close the modal

  
      // Request succeeded, no further action needed

      console.log('Job status updated successfully');
    } catch (error) {
      console.error('Error updating job status:', error);
      // Revert the change in the UI if the request fails
    

      // Optionally, inform the user that the update failed
    }
  };

  return (
    <Modal isOpen={isOpen} size={'lg'} onClose={onClose}>
      
      <ModalOverlay  />
      <ModalContent size={'lg'} backgroundColor={'#F7FAFC'}>

        <ModalHeader display={'flex'}  color={'#4A5568'} alignItems={'center'}>
              <FiBriefcase fontSize={'14px'}/>
            <Text  ml={2} color={'#4A5568'} fontSize={'sm'}>{job.title} at {job.school_sites[0].school_name}</Text>
        </ModalHeader>
        <ModalCloseButton />


        <Box p={5}>
        <Flex justifyContent={'center'} mb={3}>
                    <Box w={'50px'} h={'50px'} justifySelf={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={'#FFFFF0'}>
                    <FiGlobe color='#D69E2E' fontSize={'20px'}/>
                    </Box>
                    </Flex>

            <Text fontWeight={'bold'} textAlign={'center'} fontSize={'lg'} mb={4}>
              Edpursuit Talent Collective Promotion
            </Text>

          <Text mb={4} fontSize={'md'}>
          The Edpursuit Talent Collective serves as a dedicated job search community for educators, school administrators, and individuals aspiring to careers in education.
          <br></br>
          </Text>
          <Text mb={4}>
          Members, particularly teachers, benefit from exclusive access to a job board that compiles openings from member charter schools.
           <br></br>
          </Text>
          <Text>
          Making your job visible on the Edpursuit Talent Collective increases your school's exposure to qualified educators who are actively seeking new opportunities.          </Text>
</Box>

<hr></hr>
<Grid templateColumns="repeat(12, 1fr)"  p={4} pl={6}>
      <GridItem colSpan={3}>
        <Text color={'#718096'} fontWeight={'600'} mb={2}>Visible</Text>
        <Box>
        {isLoading ? <Spinner  color="gray" size={'sm'} ml={1} /> : 
        
        <Switch size="md" colorScheme="green" isChecked={job.visible_to_talent_collective}
                  onChange={(e) => handleSwitchChange(job.id, e.target.checked)}
          />
        }
        </Box>
      </GridItem>
      <GridItem colSpan={4}>
        <Text color={'#718096'} fontWeight={'600'} mb={2}>Expires in</Text>
        <Box>
        <Text fontWeight={'600'} mr={2}>
                {
                  calculateDaysUntilExpiration(job.expiration_date) < 1
                  ? 'Expired'
                  : `${calculateDaysUntilExpiration(job.expiration_date)} days`
                }
              </Text>
        </Box>
      </GridItem>

      <GridItem colSpan={4}>
        <Text color={'transparent'} fontWeight={'600'} mb={2}>.</Text>
        <Box>
          <Button size={'sm'} colorScheme='yellow' variant={'solid'} onClick={handleBoost}>
          {isLoading ? <Spinner color="white" /> : "Extend Expiration"}

          </Button>
        </Box>
        </GridItem>
      {/* <GridItem colSpan={6}>
        <Text mb={2}>Invite Rejected Applicants</Text>
        <Box pt={1}>
        <Checkbox isChecked disabled/>
        </Box>
      </GridItem> */}
    </Grid>


           
        <Box p={5} display={'none'}>
                    <form >
                    <Flex justifyContent={'center'} mb={3}>
                    <Box w={'50px'} h={'50px'} justifySelf={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={'#FFFFF0'}>
                    <FiZap color='#D69E2E' fontSize={'20px'}/>
                    </Box>
                    </Flex>


            <Text fontWeight={'bold'} textAlign={'center'} fontSize={'lg'}>
                 Confirm Boost
            </Text>
            <Text fontSize={'sm'} textAlign={'center'} color={'gray'}>
            Boosting will extend the posting duration and prioritize it as a listing on the public job board.
             </Text>
        
        


<Flex mx={4} justifyContent={'space-between'} alignItems={'center'}>
{/* <Button size={'xs'}  colorScheme='red' variant={'outline'} mt={'-8px'} >clear all fields</Button> */}
</Flex>

          <ModalBody>
        



          </ModalBody>

          <ModalFooter justifyContent={'center'}>
          <Button w={'50%'} variant="ghost" onClick={onClose}>Cancel</Button>
 

          
            <Button colorScheme='yellow' onClick={handleBoost} as={Button} w={'50%'} ml={4} borderColor='red'>
                {isLoading ? <Spinner color="white" /> : "Boost"}
            </Button>
  






          </ModalFooter>

        </form>
        </Box>


      </ModalContent>
    </Modal>
  );
}

export default BoostJobModal;
