import React, { useState, useEffect } from 'react';
import { Box, Img, Flex,  Skeleton,
    SkeletonCircle, Link,
    SkeletonText, Stack, Grid,Card,Badge, GridItem, Text, Button, Image, Heading, Tag, Avatar, Divider, ChakraProvider } from '@chakra-ui/react';

import { FiFolder } from 'react-icons/fi';
import { UploadSingleResume } from '../../../components/upload_resume';
import { FaLinkedin, FaEmail } from 'react-icons/fa';
import { FiMail, FiPhoneCall, FiMapPin } from "react-icons/fi";
import { PiArrowLineRight,PiTrash, PiPencilLineLight, PiPlusBold } from "react-icons/pi";
import { format, parseISO } from 'date-fns';


function ConfirmProfile() {
  const [userData, setUserData] = useState(null);


  const [images, setImages] = React.useState([]);

  const handleImagesChange = (updatedImages) => {
    setImages(updatedImages);
};

const eventData = {
    images,
  };
  function formatPhoneNumber(phoneNumber) {
    // Convert to string in case it's not already
    const phoneStr = phoneNumber.toString();
    
    // Use a regular expression to add a dash after the third digit
    return phoneStr.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}


const handleSubmit = async () => {
    try {
      const response = await fetch(`https://www.pursueli.com/api/upload_resume/`, {
        method: 'POST', // or 'DELETE'
        // ... headers and other configurations
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
            // Include other headers as required
          },
          body: JSON.stringify(eventData)
      });

      if (response.ok) {
        // playerRef.current.play();
        // setTimeout(() => {
        //     navigate('/home'); // Navigate to home after state update and successful login
        //   }, 3000); // 3000 milliseconds = 3 seconds
        console.log("success")
        // Add this new outivity to the existing list
        // setUpcomingEvents(prevEvents => [...prevEvents, newOutivity]);
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to delete the event:", error);
      // Handle error
    }
  };


  useEffect(() => {
    // Fetch the upcoming events
    fetch("https://www.pursueli.com/api/my_profile/", {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
        }
      })          
      .then((response) => response.json())
      .then((data) => {
        console.log(data.user); // Add this line
        // setPastEvents(data.past_things_to_do);
        setUserData(data.user); // Update state with fetched data

      })
    .catch((error) => console.error("Error fetching upcoming events:", error));

  }, []);


  if (!userData) {
    return      <Flex
    // width="100vh" // Use "100vw" for full viewport width if needed
    justifyContent="center"
    marginTop={100}
  > <Grid
    textAlign={'center'}
    templateColumns={{ sm: 'repeat(0, 0fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
    width={'100vh'}
  >
    <Box  padding="6" boxShadow="lg" width={'100%'} minWidth={'100%'} bg="white">
    <Flex justifyContent={'space-between'}>
        <Flex>
        <SkeletonCircle size="10" />

            <Box>
        <Skeleton ml={5} mb={2} height="20px" width="100px" />
        <Skeleton ml={5} height="20px" width="200px" />
        </Box>
    </Flex>

    <Button isDisabled variant="outline">
        <Skeleton height="20px" width="100px" />
      </Button>
    </Flex>

    <Grid templateColumns="repeat(2, 1fr)" gap={4} my="6">
      <GridItem colSpan={1}>
      <Skeleton height='40px' mt="4" noOfLines={2} spacing="4" />
      </GridItem>
      <GridItem colSpan={1}>
      <Skeleton height='40px' mt="4" noOfLines={2} spacing="4" />
      </GridItem>
    </Grid>

    <Grid templateColumns="repeat(2, 1fr)" gap={4} my="6">
      <GridItem colSpan={1}>
      <Skeleton height='40px' my="4" noOfLines={2} spacing="10" />
      </GridItem>
      <GridItem colSpan={1}>
      <Skeleton height='40px' my="4" noOfLines={2} spacing="10" />
      </GridItem>
    </Grid>


    
<Box mt={6}>
<Stack mt="6">
      <Skeleton height="30px" />
      <Skeleton height="30px" />
      <Skeleton height="30px" />
    </Stack>
</Box>


    <Box mt="6">

    </Box>

    <Box mt={6}>
<Stack mt="6">
      <Skeleton height="30px" />
      <Skeleton height="30px" />
      <Skeleton height="30px" />
    </Stack>
</Box>



  </Box>
  </Grid>
  </Flex>
  ;
  }
  

  return (
    <Box bg="gray.50" align="center" h={'100%'} minHeight={'90vh'} justify="center">
        <Box mt={25} color={'transparent'}>.</Box>
      <Box bg="white" p={6}  width={'100vh'} rounded="md">
        <Flex align="flex-start" mb={4} justifyContent={'space-between'}>
            <Flex align="center">
                <Avatar size={'lg'} name={`${userData.first_name} ${userData.last_name}`} mr={4} src={userData.photo.url} />
                <Box style={{justifyContent:'start', justifySelf:"start"}}>
                    <Heading style={{textAlign:'start'}} as="h2" size="lg">{userData.first_name} {userData.last_name}</Heading>
                    {
                    userData.work_history && userData.work_history.length > 0 && (
                        <Text style={{textAlign:'start'}} as="p" size="sm">
                        {userData.work_history[0].title} - {userData.work_history[0].institution_name}
                        </Text>
                    )
                    }
                </Box>
          </Flex>
          {/* <a href={userData.linkedin_url} target="_blank" rel="noopener noreferrer">
  <Button leftIcon={<FaLinkedin />} colorScheme="linkedin" variant="solid" size="md" fontSize="sm" iconSpacing="2" boxShadow="base">
    LinkedIn
  </Button>
</a> */}
  
        </Flex>
        <Divider my={4} />
        <Box  textAlign={'start'}>
        <Text fontWeight="bold" mb={2}>Personal Information</Text>
        <Grid
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Flex  align={'flex-start'}>
            <Card p={3} mr={4}>
                <FiMail size={24} />
            </Card>
            <Box alignSelf={'flex-end'}>
            <Heading style={{textAlign:'start'}} size="sm">{userData.email}</Heading>
                <Text color={'grey'}>email</Text>
            </Box>
        </Flex>
      </GridItem>
      <GridItem w="100%">
        <Flex  align={'flex-start'}>
            <Card p={3} mr={4}>
                <FiPhoneCall size={24} />
            </Card>
            <Box alignSelf={'flex-end'}>
            <Heading style={{textAlign:'start'}} size="sm">
            {userData.phone ? formatPhoneNumber(userData.phone) : ''}    
            </Heading>
                <Text color={'grey'}>phone number</Text>
            </Box>
        </Flex>
      </GridItem>
    </Grid>
    <GridItem w="100%" mt={4}>
        <Flex  align={'flex-start'}>
            <Card p={3} mr={4}>
                <FiMapPin size={24} />
            </Card>
            <Box alignSelf={'flex-end'}>
            <Heading style={{textAlign:'start'}} size="sm">{userData.address}</Heading>
                <Text color={'grey'}>address</Text>
            </Box>
        </Flex>
      </GridItem>
      <Text mt={6} fontWeight="bold" mb={1}>Bio</Text>
      <Text>
        {userData.bio}
      </Text>

      </Box>
      </Box>

      <Box bg="white" p={6} width={'100vh'} mt={4} rounded="md">
        <Box  textAlign={'start'}>
        <Text fontWeight="bold" mb={2}>Work history</Text>
        <Grid
        templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
        gap={6}
        >

{userData && userData.work_history.map((workItem, index) => (
  <React.Fragment key={index}>
    <GridItem w="100%">
      <Flex align={'flex-start'}>
        <Img width={'50px'} borderRadius={'5px'} p={0} mr={4} src={workItem.institution.clear_bit_logo_url}/>
        <Box alignSelf={'flex-end'} width={'100%'}>
          <Heading style={{textAlign:'start', display:'flex', justifyContent:"space-between"}} size="sm">
            <Box>
              {workItem.title}
              <Badge fontWeight={500} borderRadius={'5px'} py={.5} px={2} size={'md'} ml={2}>
                {workItem.employment_type}
              </Badge>
            </Box>
            <Flex mt={-1} textAlign={'end'}>
              <Button colorScheme='gray' variant='ghost' size='sm' ml='2'>
                <PiPencilLineLight w={4} />
              </Button>
              <Button colorScheme='red' variant='ghost' size='sm' ml='2'>
                <PiTrash w={4} />
              </Button>
            </Flex>
          </Heading>
          <Flex mt={'-5px'} mb={1}>
            <Text color={'gray'} fontWeight={'bold'}>{workItem.institution_name}</Text>
            <Text pt={'1px'} ml={2} color={'gray'} fontSize={'sm'}>
              ( {workItem.start_date && format(parseISO(workItem.start_date), 'MMM, yyyy')}
 - {workItem.start_date && format(parseISO(workItem.start_date), 'MMM, yyyy') || 'Present'} )
            </Text>
          </Flex>
          <Box whiteSpace="pre-line">
            {workItem.description}
          </Box>
        </Box>
      </Flex>
    </GridItem>
    <Divider my={'0px'} />
  </React.Fragment>
))}


    </Grid>

      </Box>
      </Box>



      <Box bg="white"  p={6} width={'100vh'} mt={4} rounded="md">
        <Box  textAlign={'start'}>
        <Text fontWeight="bold" mb={2}>Education</Text>
        <Grid
        templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
        gap={6}
        >
                    {userData && userData.education.map((education, index) => (
  <React.Fragment key={index}>
      <GridItem w="100%">
        <Flex  align={'flex-start'}>
               <Img width={'50px'} borderRadius={'5px'} p={0} mr={4} src={education.institution.clear_bit_logo_url}/>
            <Box alignSelf={'flex-end'} width={'100%'}>
                
            <Heading style={{textAlign:'start', display:'flex', justifyContent:"space-between"}} size="sm">
            <Box>
              {education.major}
              <Badge fontWeight={500} borderRadius={'5px'} py={.5} px={2} size={'md'} ml={2}>
                {education.degree_type}
              </Badge>
            </Box>
            <Flex mt={-1} textAlign={'end'} float={'right'} justifySelf={'flex-end'}>
              <Button colorScheme='gray' variant='ghost' size='sm' ml='2'>
                <PiPencilLineLight w={4} />
              </Button>
              <Button colorScheme='red' variant='ghost' size='sm' ml='2'>
                <PiTrash w={4} />
              </Button>
            </Flex>
          </Heading>



            
                <Flex mt={'-6px'}>
                    <Text color={'gray'} fontWeight={500}>{education.institution_name}</Text> 
                    {education.start_date && education.end_date && (
                    <Text pt={'1px'} ml={2} color={'gray'} fontSize={'sm'}>
                        ({format(parseISO(education.start_date), 'MMM, yyyy')} - {format(parseISO(education.end_date), 'MMM, yyyy')})
                    </Text>
                    )}
                 </Flex>
                <Box whiteSpace="pre-line">
                        {education.about}
                </Box>
                <Box whiteSpace="pre-line">
                        {education.extra_curriculars}
                </Box>
            </Box>
        </Flex>
      </GridItem>
      <Divider my={'0px'} />
      </React.Fragment>
))}
    </Grid>

      </Box>
      </Box>
      <Box mt={12}>..</Box>

      <Box position="fixed" bottom="0" left="0" right="0" p={2}  bg="gray.100" boxShadow="md">
      <Flex justifyContent="center" alignItems="center">
        {/* <Button mx={2} colorScheme="teal">Button 1</Button> */}
        <Link href="/">
        <Button mx={2} colorScheme="teal">Start Applying</Button>
        </Link>
      </Flex>
    </Box>

    </Box>
  );
}

export default ConfirmProfile;
