import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader, Link,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Switch,
  IconButton,
  Popover, PopoverTrigger, Portal, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverBody, PopoverAnchor,
  Table,
  Thead, Avatar,
  Tbody,Grid, GridItem,
  Tr,
  Th,Card, Spinner,
  Td,
  TableContainer,   Skeleton,
  VStack,
  Button,
  Text,
  Checkbox,
} from '@chakra-ui/react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { PiArrowLineRight ,PiFunnel,PiCheckBold, PiXBold, PiArchive,PiNote, PiTrash, PiPencilLineLight, PiPlusBold } from "react-icons/pi";
import { HiOutlineSparkles, HiSparkles } from "react-icons/hi2";
import { FaWandMagicSparkles } from "react-icons/fa6";

import SideNavBar from '../../../components/side_nav_bar';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { useSchool } from '../../../context/admin_selected_school';
import JobPostModal from '../../../components/post_job_modal';
import { useDisclosure } from '@chakra-ui/react';
import { Select, Empty, Tag } from 'antd';
import ApplicationDrawer from '../../../components/application_drawer_v2';





function SchoolDashboardApplications() {
 

  const { isOpen: isPiNoteModalOpen, onOpen: onPiNoteModalOpen, onClose: onPiNoteModalClose} = useDisclosure();
  const [resumeUrl, setResumeUrl] = useState(null);
  const [selectedResumeFirstName, setSelectedResumeFirstName] = useState('');
  const [selectedResumeLastName, setSelectedResumeLastName] = useState('');
  const [loadingStates, setLoadingStates] = useState({});

  const handleApplicationStatusUpdate = (updatedApplication) => {
    // Update the applications state with the updated application data
    setApplications(prevApplications => {
      return prevApplications.map(application => {
        if (application.id === updatedApplication.id) {
          return updatedApplication; // This is the updated application
        }
        return application; // This returns unchanged applications
      });
    });
  };

  const [loadingAiMatchScore, setLoadingAiMatchScore] = useState(false);

  const calculateApplicationMatchScore = (applicationId) => {
    setLoadingStates(prevStates => ({ ...prevStates, [applicationId]: true }));

    setLoadingAiMatchScore(true)
    const url = 'https://www.pursueli.com/api/admin_ai_calculate_match_score/';
    
    const payload = {
      applicationId: applicationId,
    };
    return fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      handleApplicationStatusUpdate({
          ...data,
          id: applicationId
      });
      console.log("Match score updated:", data);
    })
    .catch(error => {
      console.error("Error:", error);
      throw error; // Rethrow after logging
    })
    .finally(() => {
      setLoadingStates(prevStates => ({ ...prevStates, [applicationId]: false }));
      setLoadingAiMatchScore(false)
      console.log("done"); // This will execute after the fetch operation is complete
    });
  };

  const handlePiNoteClick = (id, firstName, lastName) => {
    setSelectedResumeFirstName(firstName)
    setSelectedResumeLastName(lastName)
    onPiNoteModalOpen(); // Open the PiNote modal after setting the data
    fetch(`https://www.pursueli.com/api/admin_get_resume/?applicationId=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming you're using bearer token authentication
      },
    })
    .then(response => response.json())
    .then(data => {
      console.log(data)
      setResumeUrl(data.resume); // Store the URL in state
    })
    .catch(error => console.error("Error fetching data:", error));
  };

  const closeResumeModal = () => {
    onPiNoteModalClose(); // Correctly close the modal
    setSelectedResumeFirstName('');
    setSelectedResumeLastName('');
    setResumeUrl(null);
    console.log(resumeUrl)
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [applicationId, setApplicationId] = useState(null);
  const [aiMatchScoreReason, setAiMatchScoreReason] = useState(null);
  const [selectedJobTitle, setSelectedJobTitle] = useState(null);
  const [selectApplicationMatchScore, setSelectApplicationMatchScore] = useState(null);
  const [selectedApplicationStatus, setSelectedApplicationStatus] = useState(null);
  const [selectedApplicationSource, setsSlectedApplicationSource] = useState(null);
  const [selectedApplicationSlug, setSelectedApplicationSlug] = useState(null);

  const [selectedApplicationDate, setSlectedApplicationDate] = useState(null);


  const openDrawer = (userId, applicationID, aiMatchScoreReasoning, jobTitle, applicationMatchScore, applicationStatus, applicationSource, applicationDate, applicationSlug) => {
  //  alert(applicationSlug)
    setSelectedUserId(userId);
    setApplicationId(applicationID)
    setAiMatchScoreReason(aiMatchScoreReasoning)
    setSelectedJobTitle(jobTitle)
    setIsDrawerOpen(true);
    setSelectApplicationMatchScore(applicationMatchScore)
    setSelectedApplicationStatus(applicationStatus)
    setsSlectedApplicationSource(applicationSource)
    setSlectedApplicationDate(applicationDate)
    setSelectedApplicationSlug(applicationSlug)
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedUserId(null); // Optionally reset selected user ID on close
    setApplicationId(null);
    setSelectedApplicationSlug(null);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);

  const handleFilterLoading = () => {
    setFilterLoading(true)
  };


  const { Option } = Select;
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedapplications = applications.map(job => ({
      ...job,
      isSelected: newSelectAll // Update isSelected based on newSelectAll value
    }));
    setApplications(updatedapplications);
  };

  const handleCheckboxChange = (id) => {
    setApplications(applications.map(job => {
      if (job.id === id) {
        return { ...job, isSelected: !job.isSelected };
      }
      return job;
    }));
  };
  useEffect(() => {
    setApplications(applications.map(job => ({ ...job, isSelected: selectAll })));
  }, [selectAll]);

  const calculateDaysUntilExpiration = (expirationDateString) => {
    const expirationDate = new Date(expirationDateString);
    const currentDate = new Date();
    const differenceInMilliseconds = expirationDate - currentDate;
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    return differenceInDays;
  };
  
  const [applications, setApplications] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [schoolName, setSchoolName] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [roleFilter, setRoleFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('Pending');
  const [matchScoreFilter, setMatchScoreFilter] = useState('all');
  const [pipelines, setPipelines] = useState([]);


const { school } = useSchool();

  // Function to append a new job to the list
  const addNewJobToList = (newJob) => {
    setApplications((prevapplications) => [...prevapplications, newJob]);
  };
  
  const [expandedApplicationId, setExpandedApplicationId] = useState(null);
  const toggleDetails = (id) => {
    setExpandedApplicationId(prevId => prevId === id ? null : id);
  };
useEffect(() => {
  const filters = [];
  if (roleFilter) filters.push(`role=${encodeURIComponent(roleFilter)}`);
  if (statusFilter) filters.push(`status=${encodeURIComponent(statusFilter)}`);
  if (matchScoreFilter) filters.push(`matchScore=${encodeURIComponent(matchScoreFilter)}`);
  const filterQueryString = filters.length > 0 ? `&${filters.join('&')}` : '';


  const schoolParam = encodeURIComponent(school); // Use this if `school` is a string

  // Assuming 'school' is the query parameter expected by your backend
  // and 'school' state contains the current selected school value.
  fetch(`https://www.pursueli.com/api/admin_get_school_applicatiions/?school=${schoolParam}${filterQueryString}`, {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
    console.log("Job posts:", data.jobs);
    const updatedapplications = data.applications.map(job => ({
        ...job,
        isSelected: false // Initialize isSelected as false
      }));
    setApplications(updatedapplications);
    setSchoolName(data.school_name)
    setSchoolId(data.school_id)
    setIsLoading(false)
    setFilterLoading(false)
    setJobs(data.jobs)

    // Handle your data
  })
  .catch(error => console.error("Error fetching jobs:", error));
}, [school, roleFilter, statusFilter, matchScoreFilter]);

 
  const numberOfRows = 9; // For example

  const isAnyapplicationselected = () => {
    return applications.some(job => job.isSelected);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const day = date.getDate();
    let daySuffix;
    switch (day) {
      case 1: case 21: case 31:
        daySuffix = 'st';
        break;
      case 2: case 22:
        daySuffix = 'nd';
        break;
      case 3: case 23:
        daySuffix = 'rd';
        break;
      default:
        daySuffix = 'th';
    }
    const monthIndex = date.getMonth();
    const monthName = monthNames[monthIndex];
    return `${monthName} ${day}${daySuffix}`;
  }
  function daysFromPast(dateString) {
    const pastDate = new Date(dateString);
    const currentDate = new Date();
    
    // Set hours to 00:00:00 to ignore time part
    pastDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
  
    const diffTime = currentDate - pastDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
    // Check if the difference is 0, which means the date is today
    if (diffDays === 0) {
      return "today";
    } else {
      return `${diffDays} days ago`;
    }
  }
  const renderStars = (score) => {
    const totalStars = 5;
    const fullStarCount = Math.floor((score / 10) * 5);
    const halfStar = score % 2 >= 1 ? 1 : 0;
    const emptyStars = totalStars - fullStarCount - halfStar;
  
    return (
      <>
        {Array(fullStarCount)
          .fill()
          .map((_, index) => (
            <span key={`full-${index}`} style={{ color: "#F44100" }}>★</span>
          ))}
        {halfStar > 0 && <span style={{ color: "#F44100" }}>★</span>}
        {Array(emptyStars)
          .fill()
          .map((_, index) => (
            <span key={`empty-${index}`} style={{ color: "#EDF2F7" }}>★</span>
          ))}
      </>
    );
  };

  return (
    <ChakraProvider width={'100%'}  backgroundColor={'red'} >

{isLoading ? (
  <Box flex="1" p={8} left={'6%'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'} bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}> 

<Box style={{  borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
                    <Card h={'100%'}  top={0}>
    <Table variant="simple">
          <Thead>
            <Tr>
              <Th color={'transparent'}>Active</Th>
              <Th color={'transparent'}>Job Title</Th>
              <Th color={'transparent'}>Expires</Th>
              <Th color={'transparent'}>Views</Th>
              <Th color={'transparent'}>Applications</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array(numberOfRows).fill().map((_, index) => (
              <Tr key={index}>
                <Td><Skeleton borderRadius={'20px'} height="25px" /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" width={'280px'} /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" width={'80px'} /></Td>

                <Td><Skeleton borderRadius={'10px'} height="40px" width={'80px'} /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" width={'80px'} /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" width={'40px'} /></Td>
                <Td><Skeleton borderRadius={'10px'} height="40px" marginLeft={'-30px'} width={'40px'} /></Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
</Card>
</Box>
</Box>


) : (
<Box flex="1" p={8} left={'6%'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'} bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}> 

<Box style={{  borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
        <Card px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px'}} py={'30px'}  boxShadow="xl"
   >
    
                <Box position={'sticky'}>
          <Flex justifyContent={'space-between'}>
          <Text fontSize="xl" textAlign={'start'} fontWeight="bold">{schoolName} Applications</Text>
          </Flex>

          <Flex width={'100%'} py={4} align="center">
            {/* filters below */}
            {/* <PiFunnel style={{fontSize:'20px', marginRight:'15px'}}/> */}
          <Box width="30%" mr={4}>
            <Select
            defaultValue={roleFilter} 
            style={{ width: '100%' }} placeholder="Filter by role" onChange={(value) => {setRoleFilter(value); handleFilterLoading();}} >
                <Option value="all">All Roles</Option>
                {jobs.map(job => (
                    <Option key={job.id} value={job.id}>{job.title}</Option>
                ))}
            </Select>
            </Box>

            <Box width="20%" mr={4}>
            <Select
            defaultValue={statusFilter} // Set the default value here
            style={{ width: '100%' }} placeholder="Filter by status" onChange={(value) => {setStatusFilter(value); handleFilterLoading();}}>
\                <Option value="Pending">Pending</Option>
                <Option value="Accepted">Accepted</Option>
                <Option value="Rejected">Rejected</Option>
                <Option value="all">All Statuses</Option>
            </Select>
            </Box>

            <Box width="20%">
            <Select
            defaultValue={matchScoreFilter} // Set the default value here
            style={{ width: '100%' }} placeholder="Filter by match score" onChange={(value) => {setMatchScoreFilter(value); handleFilterLoading();}}>
                <Option value="all">All Match Scores</Option>
                <Option value="1" style={{color:'grey'}}>
                1 <span style={{ color: "#F44100" }}>★</span>
                </Option>
                <Option value="2" style={{color:'grey'}}>
                2 <span style={{ color: "#F44100" }}>★★</span>
                </Option>
                <Option value="3" style={{color:'grey'}}>
                3 <span style={{ color: "#F44100" }}>★★★</span>
                </Option>
                <Option value="4" style={{color:'grey'}}>
                4 <span style={{ color: "#F44100" }}>★★★★</span>
                </Option>
                <Option value="5" style={{color:'grey'}}>
                5 <span style={{ color: "#F44100" }}>★★★★★</span>
                </Option>
            </Select>
            </Box>

            <Box width="fit-content" >
              {filterLoading && <Spinner size={'md'} ml={6} color='gray' />}
            </Box>


            </Flex>
            </Box>


              {
      applications && applications.length > 0 ? (
        <Box overflowY="scroll" > {/* Adjust maxHeight as needed */}
        <ChakraProvider overflow={'scroll'}> 
        <Table variant="simple" overflow={'scroll'}>
        <Thead bg="gray.200" sx={{ position: 'sticky', top: 0, zIndex: 1}}>

      <Tr w={'100%'}>
        {/* <Th textAlign={'start'} sx={{ maxWidth: '90px' }}  fontSize={'sm'} fontWeight={'bold'} color={'gray.500'}>
            <Button size={'xs'} onClick={handleSelectAll}>
                Select all
            </Button>
        </Th> */}
        <Th textTransform="uppercase"  sx={{ minWidth: '320px' }}  fontSize={'xs'} fontWeight={'bold'} color={'gray.500'}>Applicant</Th>
        <Th textTransform="uppercase"  sx={{ minWidth: '200px' }}  fontSize={'xs'} fontWeight={'bold'} color={'gray.500'}>Role</Th>
        <Th textTransform="uppercase" sx={{ minWidth: '165px' }}  textAlign={'center'} fontSize={'xs'} fontWeight={'bold'} color={'gray.500'}>Applied On</Th>
        <Th textTransform="uppercase" sx={{  }}  textAlign={'center'} fontSize={'xs'} fontWeight={'bold'} color={'gray.500'}>Status</Th>
        <Th textTransform="uppercase" sx={{ minWidth: '145px' }}  textAlign={'center'} fontSize={'xs'} fontWeight={'bold'} color={'gray.500'}>Match Score</Th>

        {/* <Th textAlign={'center'} sx={{ width: '10%' }}  fontSize={'sm'} fontWeight={'bold'} color={'gray.500'}>Actions</Th> */}
      </Tr>
    </Thead>
    <Tbody>
      {applications.sort((b, a) => a.id - b.id).map((application) => (
        <React.Fragment key={`fragment-${application.id}`}>
          {
  application.user ? (
        <Tr key={application.id} height={'50px'} borderWidth={'0px'} borderColor={'red'}>
          {/* <Td textAlign={'center'} borderWidth={'0px'}>
            <Checkbox size="md" colorScheme="blue"
            isChecked={application.isSelected}
            onChange={() => handleCheckboxChange(application.id)}
            />
          </Td> */}
          <Td as={Link} style={{textDecoration:'none'}} display={'flex'} borderWidth={'0px'}>
            <Avatar style={{ height: '40px', width: '40px', marginRight:'10px' }} src={application.user.photo} />
            <Box>
              <Flex>
              <Text fontWeight={'bold'}  onClick={() => openDrawer(application.user.id, application.id, application.ai_match_score_reasoning, application.job.title, application.match_score, application.status, application.source, formatDate(application.application_date), application.slug  )}>
              {application.user.first_name} {application.user.last_name}
              </Text>
              {/* {application.user.resume && (
                <Button size={'xs'} ml={2} p={0} onClick={() => handlePiNoteClick(application.id, application.user.first_name, application.user.last_name)}>
                  <PiNote h={4} w={4} />
                </Button>
              )} */}
              </Flex>

              {
                application.user.work_history && application.user.work_history.length > 0 && (
                  <Text fontSize={'sm'} color={'gray.600'}>
                            {application.user.work_history[0].title},  {application.user.work_history[0].institution_name}
                  </Text>
                )
              }

              
            </Box>
          </Td>
          <Td borderWidth={'0px'}>
            <Text fontSize={'sm'}>
            {application.job.title}
            </Text>
          </Td>
          <Td textAlign={'center'} borderWidth={'0px'}>
            <Text fontSize={'sm'}>{formatDate(application.application_date)}</Text>
            <Text fontSize={'xs'} color={'gray.600'}>{daysFromPast(application.application_date)}</Text>
            </Td>
            <Td borderWidth={'0px'} padding={0} fontSize={'sm'} textAlign={'center'}>
        <Tag bordered={false} style={{fontSize:"0.875rem", fontFamily:'inherit'}} 
          color={
            application.status === "Pending"
              ? "gold"
              : application.status === "Accepted"
              ? "green"
              : application.status === "Rejected"
              ? "error"
              : "default"
          }
        
        >
        {application.status}

        </Tag>
        </Td>
          <Td borderWidth={'0px'} fontSize={'sm'} textAlign={'center'}>
          {application.match_score === 0 ? (
        <Button variant={'solid'} fontSize={'sm'}  size={'sm'} fontWeight={500} onClick={() => calculateApplicationMatchScore(application.id)}
        isDisabled={loadingStates[application.id]}  // Disable button when loading
    >
        {loadingStates[application.id] ? (
            <Spinner size='xs' marginRight="10px" />
        ) : (
            <FaWandMagicSparkles style={{ marginRight: '10px' }}/>
        )}
        {loadingStates[application.id] ? 'calculating...' : 'calculate'}
    </Button>
        ) : (
        <>
      <Button variant={'ghost'} fontSize={'sm'} size={'sm'} onClick={() => toggleDetails(application.id)}>
        {renderStars(application.match_score)}
      </Button>
        </>
      )}
                
          </Td>

        </Tr>
        ) : (
    <Tr key={application.id} height={'50px'} borderWidth={'0px'} borderColor={'red'}>
      {/* <Td textAlign={'center'} borderWidth={'0px'}>
        <Checkbox size="md" colorScheme="blue"
        isChecked={application.isSelected}
        onChange={() => handleCheckboxChange(application.id)}
        />
      </Td> */}
          <Td as={Link} style={{textDecoration:'none'}} display={'flex'} borderWidth={'0px'}>
        <Avatar style={{ height: '40px', width: '40px', marginRight:'10px' }} src={application.unauthticated_applicant.photo} name={`${application.unauthticated_applicant.first_name} ${application.unauthticated_applicant.last_name}`}  />
        <Box>
          <Flex>
          <Text fontWeight={'bold'}  onClick={() => openDrawer(application.unauthticated_applicant.id, application.id, application.ai_match_score_reasoning, application.job.title, application.match_score, application.status, application.source, formatDate(application.application_date), application.slug  )}>
          {application.unauthticated_applicant.first_name} {application.unauthticated_applicant.last_name}
          </Text>
          {/* {application.unauthticated_applicant.resume && (
            <Button size={'xs'} ml={2} p={0} onClick={() => handlePiNoteClick(application.id, application.unauthticated_applicant.first_name, application.unauthticated_applicant.last_name)}>
              <PiNote h={4} w={4} />
            </Button>
          )} */}
          </Flex>
          {
          application.unauthticated_applicant.work_history && application.unauthticated_applicant.work_history.length > 0 && (
            <Text fontSize={'sm'} color={'gray.600'}>
              {application.unauthticated_applicant.work_history[0].title}, {application.unauthticated_applicant.work_history[0].institution_name}
            </Text>
          )
        }      
        </Box>
      </Td>
      <Td borderWidth={'0px'}>
        <Text fontSize={'sm'}>
        {application.job.title}
        </Text>
      </Td>
      <Td textAlign={'center'} borderWidth={'0px'}>
        <Text fontSize={'sm'}>{formatDate(application.application_date)}</Text>
        <Text fontSize={'xs'} color={'gray.600'}>{daysFromPast(application.application_date)}</Text>
        </Td>
      <Td borderWidth={'0px'} padding={0} fontSize={'sm'} textAlign={'center'}>
        <Tag bordered={false} style={{fontSize:"0.875rem", fontFamily:'inherit'}} 
          color={
            application.status === "Pending"
              ? "gold"
              : application.status === "Accepted"
              ? "green"
              : application.status === "Rejected"
              ? "error"
              : "default"
          }
        
        >
        {application.status}

        </Tag>
        </Td>
      <Td borderWidth={'0px'} fontSize={'sm'} textAlign={'center'}>
      {application.match_score === 0 ? (
        <Button variant={'solid'} fontSize={'sm'}  size={'sm'} fontWeight={500} onClick={() => calculateApplicationMatchScore(application.id)}
        isDisabled={loadingStates[application.id]}  // Disable button when loading
    >
        {loadingStates[application.id] ? (
            <Spinner size='xs' marginRight="10px" />
        ) : (
            <FaWandMagicSparkles style={{ marginRight: '10px' }}/>
        )}
        {loadingStates[application.id] ? 'calculating...' : 'calculate'}
    </Button>
        ) : (
        <>
      <Button variant={'ghost'} fontSize={'sm'} size={'sm'} onClick={() => toggleDetails(application.id)}>
        {renderStars(application.match_score)}
      </Button>
        </>
      )}
            
      </Td>
    </Tr>
  )
}
        {expandedApplicationId === application.id && (

        <Tr p={0} key={`details-${application.id}`}>
          <Td p={0} /> {/* This is the first empty cell */}
          <Td colSpan={5}  pb={3} pt={'-15px'} > {/* Adjust the colSpan according to your total number of columns */}
          <Card display="flex" alignItems="center" bg={'#EDF2F7'} justifyContent="center" height="full" p={3} > 
            
            <Text fontSize={'sm'} color={'gray'} width="full" justifySelf={'start'} fontWeight={'bold'}  textAlign={'start'}>✨ AI Scoring Notes</Text>
            <Text

              pl={0} pr={0} 
              width="full"
              fontSize={'sm'}
            >
              {application.ai_match_score_reasoning}
            </Text>
            </Card>
          </Td>
        </Tr>
        
              )}

          </React.Fragment>
      ))}
    </Tbody>
  </Table>
  <ApplicationDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        selectedUserId={selectedUserId}
        applicationId={applicationId}
        jobTitle={selectedJobTitle}
        aiMatchScoreReasoning={aiMatchScoreReason}
        applicationMatchScore={selectApplicationMatchScore}
        onApplicationStatusUpdate={handleApplicationStatusUpdate}
        applicationStatus={selectedApplicationStatus}
        applicationSource={selectedApplicationSource}
        applicationDate={selectedApplicationDate}
        applicationSlug={selectedApplicationSlug}
      />
    </ChakraProvider>
    </Box>

      ) : (
        // Display a message instead of the table if there are no job posts
<Box textAlign="center" fontSize="xl" mt={5}>
  <Empty 
    description={
      statusFilter === "Pending" ? "No pending applications" :
      statusFilter === "Accepted" ? "No accepted applications" :
      statusFilter === "Rejected" ? "No rejected applications" :
      "No applications"
    }
  />
</Box>

      )
    }
    </Card>
      </Box>
      {isAnyapplicationselected() && (
          <Box  p={4} textAlign="center" bg="transparent">
            <Flex justifyContent={'center'}>
            <Button marginRight={'30px'} variant='ghost' colorScheme="green">
            <PiCheckBold />
            <Text ml={2}>
            Accept Selected
            </Text>
            </Button>
            <Button variant='ghost' colorScheme="red">
                <PiXBold />
                <Text ml={2}>
                Reject Selected
                </Text>
            </Button>
            </Flex>
          </Box>
        )}
      </Box>
          )}
              <JobPostModal isOpen={isOpen} onClose={onClose} schoolName={schoolName} schoolId={schoolId} addNewJob={addNewJobToList} pipelines={pipelines} />
              <Modal isOpen={isPiNoteModalOpen} onClose={closeResumeModal} size="xl">
  <ModalOverlay />
  <ModalContent maxWidth="75vw" height="85vh">
    <ModalHeader>{selectedResumeFirstName} {selectedResumeLastName}'s Resume</ModalHeader>
    <ModalCloseButton />
    <ModalBody overflow="hidden" p={0}> {/* Remove padding and hide overflow */}
      <iframe 
        src={resumeUrl} 
        width="100%" 
        height="100%" 
        style={{border: 'none', maxHeight: '100%', overflow: 'hidden'}} /* Adjusted styles */
        frameBorder="0"
      >
        This browser does not support PDFs. Please download the PDF to view it: <a href={resumeUrl}>Download PDF</a>.
      </iframe>
    </ModalBody>
    <ModalFooter>
      <Button colorScheme="blue" mr={3} onClick={onPiNoteModalClose}>
        Close
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>

    </ChakraProvider>

  );
}

export default SchoolDashboardApplications;
