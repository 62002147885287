import React, { useRef, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay, Text, Box, VStack,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,Icon,
  ModalCloseButton, Spinner,
  Button,
  FormControl,
  FormLabel,
  useToast, Flex, Card,
  Textarea,NumberInputField, NumberInput, Divider
} from '@chakra-ui/react';
import { FiAlertTriangle, FiBriefcase, FiCheck } from "react-icons/fi";
import { FiUpload, FiClipboard, FiServer, FiX } from "react-icons/fi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
import { marked } from 'marked';
import { upload } from '@testing-library/user-event/dist/upload';
import { UploadSingleResume } from './upload_resume';
import ImageUploading from 'react-images-uploading';
import { Checkbox, Switch, Select, Space, Input, Alert } from 'antd';

function EditJobPostModal({ isOpen, onClose, job, replaceJob, schoolId,  teamMembers, userId, pipelines }) {

  const [defaultPipeline, setDefaultPipeline] = useState(job.associated_pipeline ? job.associated_pipeline.id : '');


  const [pipeline, setPipeline] = useState(defaultPipeline);

  const handleNewPipeline = (value) => {
    setPipeline(value);
    console.log(value)
  };

  const pipelineOptions = pipelines.map((pipeline) => ({
    value: pipeline.id,
    label: (
      <div>
        {`${pipeline.name}`}
        {/* <br />
        <small>{string}</small> */}
      </div>
    ),
  }));

  const [newSchoolUsers, setNewSchoolUsers] = useState([]);
  const handleNewSchoolUsers = (selectedValues) => {
    console.log(selectedValues)
    setNewSchoolUsers(selectedValues);
  };

  const modalRef = useRef(); // Ref for the modal content
  const employment_type_options = [
    {
      label: 'Administration',
      value: 'Administration',
      desc: 'Administration',
    },
    {
      label: 'Leadership',
      value: 'Leadership',
      desc: 'Leadership',
    },
    {
      label: 'Teaching',
      value: 'Teaching',
      desc: 'Teaching',
    },
    {
      label: 'Teaching support',
      value: 'Teaching support',
      desc: 'Teaching support',
    },
  ];

  const handleJobCategoryChange = (value) => {
    setJobCategory(value)
  };


  const pay_time_options = [
    {
      label: 'Salary',
      value: 'Salary',
      desc: 'Salary',
    },
    {
      label: 'Hourly',
      value: 'Hourly',
      desc: 'Hourly',
    },
  ];
  const handlePayTypeChange = (value) => {
    console.log(value)
    setPayType(value)
  };


  const job_type_options = [
    {
      label: 'Full-time',
      value: 'Full-time',
      desc: 'Full-time',
    },
    {
      label: 'Part-time',
      value: 'Part-time',
      desc: 'Part-time',
    },
    {
      label: 'Contract',
      value: 'Contract',
      desc: 'Contract',
    },
    {
      label: 'Temporary',
      value: 'Temporary',
      desc: 'Temporary',
    },
  ];
  const [jobTypes, setJobTypes] = useState([]);



  // Function to update job types
  const updateUserTypes = () => {
    console.log(job)
    const employmentOptionIds = job.employment_options.map(item => item.option);
    const userIds = job.users_to_notify_on_new_applications.map(item => item.id);
    console.log(userIds)
    setJobTypes(employmentOptionIds);
    setNewSchoolUsers(userIds)
  };

  // Use useEffect to call updateUserTypes on component mount
  useEffect(() => {
    updateUserTypes();
  }, []); //

  const handleEmploymenTypeChange = (value) => {
    setJobTypes(value)
  };

  const [jobCategory, setJobCategory] = useState(job.job_group.name);




  const [payType, setPayType] = useState(job.pay_type);

  const [askForCredential, setAskForCredential] = useState(job.ask_for_credential);
  const onAskForCredentialChange = (checked) => {
    if (askForCredential === true) {
      setCredentialRequired(false);
    }
    setAskForCredential(checked);
  };
  const [credentialRequired, setCredentialRequired] = useState(job.copy_of_credential_required);
  const onCredentialRequiredChange = (e) => {
    setCredentialRequired(e.target.checked)
  };

  const [askForLetterOfInterest, setaskForLetterOfInterest] = useState(job.ask_for_letter_of_interest);
  const onAskForLetterOfInterestChange = (checked) => {
    if (askForLetterOfInterest === true) {
      setCoverLetterRequired(false);
    }
    setaskForLetterOfInterest(checked);
  };
  const [coverLetterRequired, setCoverLetterRequired] = useState(job.letter_of_interest_required);
  const onCoverLetterRequiredChange = (e) => {
    setCoverLetterRequired(e.target.checked)
  };
  


  const [summary, setSummary] = useState(job.description);
  const [qualifications, setQualifications] = useState(job.qualifications);
  const [responsibilities, setResponsibilities] = useState(job.requirements);
 
  const [selectedButton, setSelectedButton] = useState('');



  const [jobTitle, setJobTitle] = useState(job.title);
  const [minSalary, setMinSalary] = useState(job.minimum_pay);
  const [displayMinSalaryValue, setDisplayMinSalaryValue] = useState('$0'); // State to store formatted display value
  useEffect(() => {
    // Format minSalary for display whenever it changes
    setDisplayMinSalaryValue(`$${new Intl.NumberFormat().format(minSalary)}`);
  }, [minSalary]);

  const handleMinSalaryChange = (event) => {
    // Remove non-numeric characters and parse integer from the input value
    const numericValue = parseInt(event.target.value.replace(/[^0-9]/g, ''), 10) || 0;
    setMinSalary(numericValue); // Update numeric value
  };
  const [maxSalary, setMaxSalary] = useState(job.maximum_pay);
  const [displayMaxSalaryValue, setDisplayMaxSalaryValue] = useState('$0'); // State to store formatted display value
  useEffect(() => {
    setDisplayMaxSalaryValue(`$${new Intl.NumberFormat().format(maxSalary)}`);
  }, [maxSalary]);

  const handleMaxSalaryChange = (event) => {
    // Remove non-numeric characters and parse integer from the input value
    const numericValue = parseInt(event.target.value.replace(/[^0-9]/g, ''), 10) || 0;
    setMaxSalary(numericValue); // Update numeric value
  };

  const [employmentType, setEmploymentType] = useState(job.employment_type);


  const [loadingAI, setLoadingAI] = useState(false);


  const toast = useToast();


  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;
console.log('user refe coming')
    // Reset the height to auto to get the correct scrollHeight
    textarea.style.height = 'auto';
    // Set the height to the scrollHeight plus a little extra space
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [summary]); // Depend on text, so it updates on text change




  const simulateTyping = (text, setterFunction) => {
    let index = 0; // Start at the first character
    console.log("This is the first letter .... ", index)
    const interval = 50; // Interval in milliseconds between each character
  
    const typeCharacterByCharacter = () => {
      if (index < text.length) {
        setterFunction(prev => {
          console.log(`Current: ${prev}, Adding: ${text.charAt(index)}`); // Debugging line
          return prev + text.charAt(index);
        });
        index++; // Move to the next character
        setTimeout(typeCharacterByCharacter, interval); // Schedule the next character
      }
    };
    };




  const handleSubmit = async (event) => {
    
    event.preventDefault();

    if (!pipeline) {
      alert("Please select a pipeline for this job post before submitting");
      return;
    }
    const formData = new FormData(event.target);

    formData.set('min_salary', minSalary); // Update to use the integer state value
    formData.set('max_salary', maxSalary); // Update to use the integer state value
    formData.append('qualifications', qualifications);
    formData.append('id', job.id);
    formData.append('responsibilities', responsibilities);
  
    formData.append('ask_for_credential', askForCredential);
    formData.append('teacher_credential_required', credentialRequired);

    formData.append('ask_for_cover_letter', askForLetterOfInterest);
    formData.append('cover_letter_required', coverLetterRequired);

    formData.append('job_category', jobCategory);
    formData.append('employment_types', jobTypes);
    formData.append('pay_type', payType);
    formData.append('users_to_alert', newSchoolUsers);

    formData.append('pipeline', pipeline);

    

    // Example POST request with formData
    try {
      const response = await fetch('https://www.pursueli.com/api/admin_edit_job/', {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            // 'Content-Type': 'application/json', // Comment out for FormData
        },
        body: formData,
      });
      if (response.ok) {
        const data = await response.json();
        console.log("data mane, ", data)
        const job_id = data.new_job.id

        const newJob = data.new_job; // Assuming the API returns the new job data under 'new_job'
        replaceJob(newJob);
        toast({
          title: 'Job post edited.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onClose(); // Close the modal
      } else {
        throw new Error('Failed to post job.');
      }
    } catch (error) {
      toast({
        title: 'Error posting job.',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const modules = {
    toolbar: [

        ['bold', 'italic', 'underline', 'link'], // Adding 'link' for hyperlinks
        [{'list': 'ordered'}, {'list': 'bullet'}],
        
        // Ensure headers or any 'header' related options are not included here
    ],
};

const formats = {
formats : [
  'bold', 'italic', 'underline',
  'list', 'bullet',
  'link'
]
};

const [acceptedApplicationCount, setAcceptedApplicationCount] = useState(0);
useEffect(() => {

  console.log("Starting fetch for data for", job.id);
  fetch(`https://www.pursueli.com/api/admin_get_job_application_count_for_pipeline_change/?job_id=${job.id}`, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
      }
  })
  .then(response => {
      console.log("Received response:", response);
      return response.json();
  })
  .then(data => {
      console.log("Received data:", data);
      setAcceptedApplicationCount(data.applications_count)
  })
  .catch(error => {
      console.error("Error in fetch:", error.message);
  });

  return () => {
        console.log("done");
  };
}, []);





  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      
      <ModalOverlay  />
      <ModalContent maxWidth="60vw" backgroundColor={'#F7FAFC'}>
      <style>

                {
                  `.custom-quill .ql-container {
  border: 1px solid #E2E8F0; /* Chakra's gray.300 border color */
  border-radius: 0 0 0.375rem 0.375rem; /* Chakra's md border radius */
}

.custom-quill .ql-editor {
  min-height: 120px; /* Set to desired minimum height */
  font-size: 1rem; /* Chakra's default font size */
}
.custom-quill .ql-container:focus-within {
  border-color: #3182CE; /* Chakra's blue.500 */
  box-shadow: 0 0 0 1px #3182CE; /* Simulate focus ring */
  .ql-toolbar {
    border-color: #3182CE; /* Chakra's blue.500 */
    box-shadow: 0 0 0 1px #3182CE; /* Simulate focus ring */
  }
}


.custom-quill .ql-toolbar {
  border-color: #E2E8F0; /* Chakra's gray.300 border color */
  border-radius: 0.375rem 0.375rem 0 0; /* Rounded top corners */
}`


                }
            </style>
        <ModalHeader>Edit Job

        <Box display={'flex'}  color={'#4A5568'} alignItems={'center'}>
              <FiBriefcase fontSize={'14px'}/>
            <Text  ml={2} color={'#4A5568'} fontSize={'sm'}>{job.title} at {job.school_sites[0].school_name}</Text>
        </Box>
       </ModalHeader>
        <ModalCloseButton />

       

        <form onSubmit={handleSubmit} style={{paddingLeft:'18px',paddingRight:'18px',paddingBottom:'18px', paddingTop:'10px', backgroundColor:'#F7FAFC' }} backgroundColor={'red'}  >
                <Card style={{padding:'10px'}} shadow={'xl'}
                          borderWidth="2px"
                          borderColor="gray.200"
                >

<Flex mx={4} justifyContent={'space-between'} alignItems={'center'}>
{/* <Button size={'xs'}  colorScheme='red' variant={'outline'} mt={'-8px'} >clear all fields</Button> */}
</Flex>

<ModalBody ref={modalRef}>
            
          <Input style={{display:'none'}}  name="school_id" value={schoolId} />

          <Flex style={{justifyContent:'space-between'}} >

            <FormControl isRequired minW={'65%'} pr={4}>
              <FormLabel>Title</FormLabel>
              <Input name="title" placeholder="Job title ..."
                  value={jobTitle}
                  size='large'
                  disabled = {loadingAI}
                  onChange={(e) => setJobTitle(e.target.value)}
            
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel>Job Category</FormLabel>
            <Select
                  style={{
                    width: '100%',
                  }}
                  size='large'
                  disabled = {loadingAI}
                  name="job_category" 
                  // value={jobCategory}
                  // onChange={(e) => setJobCategory(e.target.value)}

                  placeholder="select employment types ..."
                  defaultValue={jobCategory}
                  onChange={handleJobCategoryChange}
                  getPopupContainer={() => modalRef.current || document.body}
                  options={employment_type_options}
                  optionRender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}>
                        {option.data.emoji}
                      </span>
                      {option.data.desc}
                    </Space>
                  )}
                />
            </FormControl>

        </Flex>

        <FormControl isRequired pt={6}>
              <FormLabel>Employment Type

                <span style={{color:'gray', fontWeight:300,  marginLeft:'10px'}}>
                  select all that apply
                </span>
              </FormLabel>


              <Select
                  mode="multiple"
                  style={{
                    width: '100%',
                  }}
                  size='large'

                  name="employment_type" 
                  // value={employmentType}
                  // onChange={(e) => setEmploymentType(e.target.value)}

                  disabled = {loadingAI}
                  placeholder="select employment types ..."
                  defaultValue={jobTypes}
                  onChange={handleEmploymenTypeChange}
                  getPopupContainer={() => modalRef.current || document.body}
                  options={job_type_options}
                  optionRender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}>
                        {option.data.emoji}
                      </span>
                      {option.data.desc}
                    </Space>
                  )}
                />




             
            </FormControl>

            <Flex style={{justifyContent:'space-between'}} >
                <FormControl isRequired  mt={6} w={'35%'}  pr={4}>
                <FormLabel>Minimum Pay</FormLabel>
                <Input
      name="min_salary"
      disabled={loadingAI}
      value={displayMinSalaryValue}
      onChange={handleMinSalaryChange}
      placeholder="$"
      size='large'

    />
                </FormControl>
                <FormControl  mt={6} w={'35%'}  pr={4}>
                <FormLabel>Maximum Pay</FormLabel>
                <Input
      name={'max_salary'}
      disabled={loadingAI}
      value={displayMaxSalaryValue}
      onChange={handleMaxSalaryChange}
      placeholder="$"
      size='large'

    />
                {/* <NumberInput
                      disabled = {loadingAI}
                     value={maxSalary}
                     onChange={(valueAsString) => setMaxSalary(valueAsString)}
                     format={(val) => `$${new Intl.NumberFormat().format(val)}`}
                     parse={(val) => val.replace(/^\$/, '').replace(/,/g, '')} 
                >
                <NumberInputField 
                name={'max_salary'} defaultValue={'0'} placeholder="$"/>
                </NumberInput> */}
                </FormControl>

                <FormControl  mt={6} w={'30%'}>
                <FormLabel>Pay type</FormLabel>

                <Select
                  style={{
                    width: '100%',
                  }}
                  size='large'
                  name="pay_type" 
                  disabled = {loadingAI}
                  placeholder="select one country"
                  defaultValue={payType}
                  onChange={handlePayTypeChange}
                  getPopupContainer={() => modalRef.current || document.body}
                  options={pay_time_options}
                  optionRender={(option) => (
                    <Space>
                      <span role="img" aria-label={option.data.label}>
                        {option.data.emoji}
                      </span>
                      {option.data.desc}
                    </Space>
                  )}
                />

                </FormControl>

            </Flex>

                <FormControl isRequired mt={6}>
                <FormLabel>Role Summary</FormLabel>
                <Textarea
                disabled = {loadingAI}
                minH={'70px'}
                name="summary"
                ref={textareaRef}
                overflow="hidden" // Prevent scrollbar from appearing
                value={summary}
                onChange={(e) => setSummary(e.target.value)} // Correctly handle the event object
              />                
              </FormControl>

                <FormControl isRequired mt={6}>
                <FormLabel>Role Requirements</FormLabel>
                <Box className="custom-quill"  p={0} maxW="full" mx="auto">
                  <ReactQuill
                  readOnly={loadingAI} // Make the editor read-only
                  name="requirements" value={responsibilities} onChange={setResponsibilities}    formats={formats}             modules={modules}/>
                </Box>
                </FormControl>

                <FormControl isRequired mt={6}>
                <FormLabel>Role Qualifications</FormLabel>
                <Box className="custom-quill" p={0} maxW="full" mx="auto">
                  <ReactQuill
                  readOnly={loadingAI}
                  name="qualifications" theme="snow" value={qualifications} onChange={setQualifications}    formats={formats}             modules={modules}/>
                </Box>
                </FormControl>


                <Flex style={{justifyContent:'space-between'}} mt={6} >


<FormControl pr={4} >
<FormLabel >
<Switch 
disabled = {loadingAI}
name="credential_required" 
checked={askForCredential}
style={{marginRight:'10px'}}
onChange={onAskForCredentialChange}
    />
  
  Ask for teaching credential</FormLabel>

  {askForCredential && (
    <>
      <Text display="flex" alignItems="center" color="gray" fontWeight="500">
        Required
        <Checkbox 
          disabled={loadingAI}
          name="credential_required"
          checked={credentialRequired}
          style={{marginLeft:'10px'}}
          onChange={onCredentialRequiredChange}
        />
      </Text>

      <Text fontSize="sm" color="gray">
        Enabling this setting requires candidates to upload a copy of their teaching credential 
        <span style={{ textDecoration: 'underline' }}> before </span> they can submit their application.
      </Text>
    </>
  )}

</FormControl>

<FormControl pl={4} >
<FormLabel >
<Switch 
disabled = {loadingAI}
checked={askForLetterOfInterest}
style={{marginRight:'10px'}}
onChange={onAskForLetterOfInterestChange}
    />
  
  Ask for Letter of interest</FormLabel>

  {askForLetterOfInterest && (
    <>
      <Text display="flex" alignItems="center" color="gray" fontWeight="500">
        Required
        <Checkbox 
          disabled={loadingAI}
          checked={coverLetterRequired}
          style={{marginLeft:'10px'}}
          onChange={onCoverLetterRequiredChange}
        />
      </Text>

      <Text fontSize="sm" color="gray">
      Enabling this setting requires candidates to write a letter of initerest (cover letter)  <span style={{ textDecoration: 'underline' }}> before </span>  they can submit their application.     
      </Text>
    </>
  )}
</FormControl>
</Flex>

<FormControl id="text5" pt={8} >
          <FormLabel mb={'4px'} >Recruiting Admins
          <Text fontSize="sm" color="gray" fontWeight={'normal'}>
            Selected users will be notified on new applications for this job post.
      </Text>
          </FormLabel>
          <Select
          mode="multiple"
          size={'large'}
          style={{ width: '100%', borderRadius: '0.375rem' }}
          placeholder="Select which team members should be admins for this school site."
          value={[...newSchoolUsers]} // Ensure this is an array
          onChange={handleNewSchoolUsers}
          optionLabelProp="label"
          options={teamMembers.map((member) => ({
            value: member.user.id, // Assuming each team member has a unique id
            label: `${member.user.first_name} ${member.user.last_name} (${member.user.email})`, // Adjust according to your data structure
          }))}
          getPopupContainer={() => modalRef.current || document.body}
        
        />
        </FormControl>


        <FormControl id="text5" pt={8} >
          <FormLabel mb={'4px'} >Pipeline
          <Text fontSize="sm" color="gray" fontWeight={'normal'}>
          Select the hiring process this job will follow. Pipelines define the sequence of steps (e.g., Phone Screen, Onsite Interview, Offer) that applicants will go through for this position.</Text>
          
          <>
      {acceptedApplicationCount > 0 && (
        <Alert
          type="warning"
          closable
          style={{ padding: '10px', marginTop: '6px', marginBottom: '6px', width: '101.5%' }}
          description={
            <Box flex="1" color={'#874d00'} fontSize={'0.875rem'}>
              <span style={{ marginRight: '5px', color: '#874d00' }}>
                ⚠️
              </span>
              You currently have candidates who have applied to this role in the pipeline. Changing the pipeline for this job post will not update the stages of candidates who have already applied. To update the pipelines of existing candidates, please do so from the candidate profile on the Pipeline screen.
            </Box>
          }
        />
      )}
    </>

          
          </FormLabel>
          <Select
          // mode="multiple"
          size={'large'}
          style={{ width: '100%', maxWidth:'inherrit' }}
          placeholder="Select the hiring process that this job post should follow."
          defaultValue={defaultPipeline}
          onChange={handleNewPipeline}
          optionLabelProp="label"
          options={pipelineOptions} 
          getPopupContainer={() => modalRef.current || document.body}
          isRequired
        />
        </FormControl>





          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} type="submit">
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>Cancel</Button>
          </ModalFooter>
          </Card>

        </form>

      </ModalContent>
    </Modal>
  );
}

export default EditJobPostModal;
