import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Item from './item';
import { Text, Box, Flex, Menu, MenuButton, MenuList, IconButton, MenuItem, useDisclosure,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button 
} from '@chakra-ui/react';
import { FiEdit2, FiMoreVertical } from "react-icons/fi";
import EditPipelineStageModal from './edit_pipeline_stage_modal';


function Column({ id, initialName, initialItems, isLastColumn, initialColor, initialBackground, stages, onApplicationStatusUpdate }) {

    console.log(initialItems)

     // State for name, background color, and text color
    const [name, setName] = useState(initialName);
    const [background, setBackground] = useState(initialBackground);
    const [color, setColor] = useState(initialColor);
    const [items, setItems] = useState(initialItems);
  
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  
    const handleEditClick = (id) => {
        setSelectedApplicationId(id);
      onOpen();
    };
    
    const handleSave = async (updatedItem) => {
        const updatedItems = items.map(item => item.id === updatedItem.id ? updatedItem : item);
        if (updatedItem.name) setName(updatedItem.name);
        if (updatedItem.background) setBackground(updatedItem.background);
        if (updatedItem.color) setColor(updatedItem.color);

  
            const response = await fetch("https://www.pursueli.com/api/admin_update_pipeline_stage/", {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                id: updatedItem.id,
                name: updatedItem.name,
                color: updatedItem.color,
                background: updatedItem.background,
              }),
            });
      
            if (response.ok) {
              const data = await response.json();
              setName(data.pipeline_stage.name);
              setColor(data.pipeline_stage.color);
              setBackground(data.pipeline_stage.background);
            } else {
              console.error("Failed to update stage");
            }

      };
    

    const style = {
      backgroundColor: 'white',
      minWidth: '275px',
    //   minHeight: '100%',
      paddingBottom: '20px',
      borderRadius: '0px',
      borderTopRightRadius:'5px',
      borderRightWidth: isLastColumn ? '0px' : '1px',
      marginX: 'auto',
      position: 'relative',
      height: '100%', // Adjust this value based on your layout
      overflow: 'hidden', // Ensure 

    };
    const repeatedItems = initialItems.flatMap(item => Array(10).fill(item));

    return (
      <Box style={style}>
        <Box position='sticky' left='-10px' top='0' zIndex='10' minWidth='100%' borderTopRadius={'5px'} backgroundColor={background} color={color} py={3} px={1}  borderBottomWidth={1}>
          <Flex justifyContent='space-between' alignItems='center' pr={3}>
            <Text fontWeight='bold' textAlign='start' pl='12px'>{name}
            </Text>
            <Menu>
              <MenuButton as={IconButton} aria-label='Options' icon={<FiMoreVertical />} variant='outline' size='xs' variant='ghost' />
              <MenuList>
                <MenuItem
                style={{color:'black'}}
                onClick={() => handleEditClick(id)}
                icon={<FiEdit2 />}>Edit stage name</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
        <Droppable droppableId={String(id)}>
          {(provided, snapshot) => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{
                paddingRight: '12px',
                paddingLeft: '12px',
                background: snapshot.isDraggingOver ? background : 'linear-gradient(to bottom, #FAFAFA,white)',
                flexGrow: 1,
                minHeight: '100%',
                paddingTop: 10, 
                height: '100%', // Ensure the content box takes full height
                overflowY: 'auto', // Allow vertical scrolling
             paddingBottom:'100%',
             scrollbarWidth: 'none', /* Firefox */
              msOverflowStyle: 'none',  
                }}
            >
              {initialItems && initialItems.map((item, index) => (
                <Item key={item.id} id={String(item.id)} application={item} index={index} stages={stages}  onApplicationStatusUpdate={onApplicationStatusUpdate} 
                />
              ))}
                          {/* {repeatedItems.map((item, index) => (
              <Item key={`${item.id}-${index}`} id={`${item.id}-${index}`} application={item} index={index} />
            ))} */}

              {provided.placeholder}
            </Box>
          )}
        </Droppable>


      {selectedApplicationId && (
        <EditPipelineStageModal
          isOpen={isOpen}
          onClose={onClose}
          stageId={selectedApplicationId}
          onSave={handleSave}
        />
      )}



      </Box>
    );
  }
  
  export default Column;