import { useEffect, useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack, Img,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Avatar,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import WebFont from 'webfontloader';

export default function AdminInvite() {
    useEffect(() => {
        WebFont.load({
          google: {
            families: ['Kurale']
            
          }
        });
      }, []);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const emailFromUrl = queryParams.get('email');
    const id = queryParams.get('id');
    const name = queryParams.get('name');
    const logo_url = queryParams.get('logo');
    const school = queryParams.get('school');


  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const [email, setEmail] = useState(emailFromUrl);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [firstName, setFirstName] = useState(name);
  const [lastName, setLastName] = useState('');

  const [authenticated, setAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // For navigation after login

  const handleRegistration = async () => {
    await registerUser(email, id, password, password2, setErrorMessage);
  };


  const loginUser = async (email, password) => {
    const registrationData = {
      email: email,
      password: password,
    };
  
    try {
        const loginResponse = await fetch('https://www.pursueli.com/api/login/', {
            method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });
  
      if (loginResponse.ok) {
        const data = await loginResponse.json();
        console.log(data);
        localStorage.setItem('authToken', data.token); // Use localStorage for web
        localStorage.setItem('userPhoto', data.user_profile_photo_url); // Use localStorage for web
        localStorage.setItem('authenticated', 'True'); // Use localStorage for web
        setAuthenticated(true); // Update the authentication state
        navigate('/admin_school_jobs'); // Navigate to home after state update and successful login
      } else {
        const errorData = await loginResponse.json();
        let errorMessage = '';
        if (errorData.email) {
          errorMessage += `Email Error: ${errorData.email[0]}`;
        }
        if (errorData.password) {
          if (errorMessage) errorMessage += '\n';
          errorMessage += `Password Error: ${errorData.password[0]}`;
        }
  
        if (!errorMessage) {
          errorMessage = 'Invalid credentials. Please try again.';
        }
  
        setErrorMessage(errorMessage);
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again later.');
    }
  };




  const registerUser = async (email,id, password, password2, setErrorMessage) => {
    if (password !== password2) {
        alert("Passwords do not match.");
        return;
      }
      const registrationData = {
        email: email,
        password: password,
        id: id,
      };
    
      try {
          const registerResponse = await fetch('https://www.pursueli.com/api/admin_invite_register/', {
              method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(registrationData),
        });
    
        if (registerResponse.ok) {
          const data = await registerResponse.json();
          console.log(data);
          loginUser(email, password)
        } else {
          const errorData = await registerResponse.json();
          let errorMessage = '';
          if (errorData.email) {
            errorMessage += `Email Error: ${errorData.email[0]}`;
          }
          if (errorData.password) {
            if (errorMessage) errorMessage += '\n';
            errorMessage += `Password Error: ${errorData.password[0]}`;
          }
    
          if (!errorMessage) {
            errorMessage = 'Invalid credentials. Please try again.';
          }
    
          setErrorMessage(errorMessage);
        }
      } catch (error) {
        setErrorMessage('An error occurred. Please try again later.');
      }
    };




  return (
    <Flex
    direction="column"
    align="center"
    justify="center"
    w="100%"
    h="100vh"
    mx="auto"
    bg={useColorModeValue('gray.50', 'gray.800')}
    px={3}
  >

    
    <Stack spacing={8} w="100%" maxW="lg" py={6} px={{ base: 2, md: 6 }}>
      <Stack align="center" spacing={4}>
        <Avatar src={logo_url}/>
        <Heading fontSize={{ base: '2xl', md: '4xl' }} textAlign="center">
          Welcome, {firstName}
        </Heading>
        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.600">
          Create your password to start recruiting talent for {school}.
        </Text>
      </Stack>
      <Box
        rounded="lg"
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow="lg"
        w="100%"
        p={8}
      >
        <Stack spacing={4}>
          <FormControl id="email" isRequired>
            <FormLabel>Email address</FormLabel>
            <Input value={email} disabled type="email" />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input onChange={(e) => setPassword(e.target.value)} type={showPassword ? 'text' : 'password'} />
              <InputRightElement h="full">
                <Button variant="ghost" onClick={toggleShowPassword}>
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl id="password2" isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup>
              <Input onChange={(e) => setPassword2(e.target.value)} type={showPassword ? 'text' : 'password'} />
              <InputRightElement h="full">
                <Button variant="ghost" onClick={toggleShowPassword}>
                  {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Stack spacing={6} pt={4}>
            <Button
              onClick={handleRegistration}
              loadingText="Submitting"
              size="lg"
              bg="blue.400"
              color="white"
              _hover={{
                bg: 'blue.500',
              }}
            >
              Sign up
            </Button>
            <Text>{errorMessage}</Text>
          </Stack>
        </Stack>
      </Box>
    </Stack>
    <Text>
      powered by
    </Text>
    <Link href="https://www.pursueli.com" style={{ textDecoration: 'none', display:'flex'}}>
      <Text opacity={'.7'}  paddingLeft={2} fontFamily={'Kurale, serif'} fontSize={{ base: '20px', md: '30px' }} fontWeight={800} color={useColorModeValue('gray.800', 'white')}>
        Pursueli
      </Text>
      <Img opacity={'.7'}  height={{ base: '35px', md: '45px' }} src="https://i.ibb.co/xMgZRwP/Myfolks-2.png"/>
    </Link>
  </Flex>
  
  );
}
