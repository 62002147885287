import { createContext, useState, useContext, useEffect } from 'react';

// Create a context
const SchoolContext = createContext();

// Use a custom hook for easy useContext access
export const useSchool = () => useContext(SchoolContext);

// Provider component
export const SchoolProvider = ({ children }) => {
  const [school, setSchool] = useState(localStorage.getItem('school') || '');

  useEffect(() => {
    // Update local storage when school changes
    localStorage.setItem('school', school);
  }, [school]);

  // Function to update the school
  const changeSchool = (newSchool) => {
    setSchool(newSchool);
  };

  return (
    <SchoolContext.Provider value={{ school, changeSchool }}>
      {children}
    </SchoolContext.Provider>
  );
};
