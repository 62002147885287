import React, { useEffect, useState } from 'react';
import {
  Avatar,Icon,
  Box, Flex,
  VStack,  useBreakpointValue, Img,
  Text, Select, Spinner,
  IconButton, Card, SkeletonCircle,Skeleton,SkeletonText,
  useColorMode,
  useColorModeValue, Button,
  Divider,
} from '@chakra-ui/react';
import {
  FaTachometerAlt,
  FaBox,
  FaTags,
  FaShoppingCart,
  FaUsers,
  FaChartLine,
  FaCog,
  FaSignOutAlt,
  FaMoon,
  FaSun,
} from 'react-icons/fa';
import { PiBriefcaseFill, PiMagnifyingGlassBold } from "react-icons/pi";
import { AiOutlineSetting, AiOutlineProfile, AiOutlineFileDone } from "react-icons/ai";
import { FiSettings, FiFileText, FiBriefcase, FiFlag, FiFilter, FiBarChart2 } from "react-icons/fi";
import { LuSchool, LuUsers } from "react-icons/lu";

import { useSchool } from '../context/admin_selected_school';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import WebFont from 'webfontloader';

function SideNavBar202407() {
  const location = useLocation();



  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Kurale']
      }
    });
  }, []);
  const [loading, setLoading] = useState(true);

  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const color = useColorModeValue('gray.700', 'gray.100');

  const [schoolLogo, setSchoolLogo] = useState('');
  const [schoolOrgName, setSchoolOrgName] = useState('');

  const { school, changeSchool } = useSchool();
  console.log("this is school, ", school)
  const handleSchoolChange = (event) => {
    changeSchool(event.target.value);
  };


  const [schoolAdmin, setSchoolAdmin] = useState('');
  
  const navigate = useNavigate(); // For navigation after login

  useEffect(() => {
    const schoolParam = encodeURIComponent(school); // Use this if `school` is a string
    // Assuming 'school' is the query parameter expected by your backend
    // and 'school' state contains the current selected school value.
    fetch(`https://www.pursueli.com/api/get_school_admin/`, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      if (!localStorage.getItem('authToken')) {
        localStorage.setItem('redirectPath', location.pathname); // Use localStorage for web
      }
      if (!data.admin_user) {
        navigate('/recruiter_login');
      }
      console.log("Admin user:", data.admin_user);
      setSchoolAdmin(data.admin_user)
      setSchoolLogo(data.school_logo)
      setSchoolOrgName(data.school_org_name)
      setLoading(false)
      if (!school && data.admin_user.approved_schools.length > 0) {
        // If school is null and there are approved schools, set the school value to the first option
        changeSchool(data.admin_user.approved_schools[0].school_name);
      }

      // Handle your data
    })
    .catch(error => console.error("Error fetching jobs:", error));
  }, [school]);


  return (
    <Box w={'20%'} top={0} position="fixed" h={'full'} zIndex={'1'}>
    <Card  w="20%" h={'100vh'} borderRadius={0} zIndex={'1'} position="fixed"  shadow={'xl'} left="0"   p={4} color={color} >
    
    
    <Box style={{position:'absolute', bottom:'50px'}} left={0} w={'100%'}  >

    <Text

        display={'flex'}
        justifyContent={'center'}
          fontFamily={'Kurale, serif'}
          fontSize={'25px'} 
          fontWeight={800}
          color={useColorModeValue('gray.800', 'white')}>
          <Link color='black' fontFamily={'Kurale, serif'} href="https://www.edpursuiit.com" style={{display:'flex', textDecoration:'none'}}>
            {/* Pursueli */}
          <Img  height="40px" src="https://i.ibb.co/7bSBvCL/Edpursuit-Logo-Dark-Grey-Red.png"/>
          
          </Link>
        </Text>

        </Box>
      <VStack  spacing={4} align="stretch">
        {/* Logo and User Info */}
        {loading?(

      <Card bg={'transparent'} variant='outline' textAlign={'center'} shadow={'none'} mb={10} p={4} mt={4}>
            <SkeletonCircle size="14" mx={'auto'}/>
            <Box mx={'auto'}>
            <Skeleton  mx={'auto'} height="20px" mt={4} width="180px"> dsffsdc</Skeleton> {/* Adjust width as needed */}
            <Skeleton mx={'auto'}  height="15px" mt={3} width="180px"> dsffsdc</Skeleton> {/* Adjust width as needed */}
            </Box>
      </Card>


        ):(
          <Card bg={'transparent'} variant='outline' textAlign={'start'} shadow={'none'} mb={10} p={1} mt={4}>
            <Flex>
            <Avatar size={'md'}  w={'50px'} h={'50px'}  src={'https://i.ibb.co/wRvkLZB/Memoji-Girls-4-17.png'} />
          <Box>
          <Text  fontSize={'sm'} mx={'auto'} textAlign={'start'} noOfLines={1} fontWeight="bold">Sara Mattingly deLeeuw</Text>
          <Text  fontSize={'sm'} mx={'auto'} opacity={.7} textAlign={'start'} noOfLines={1} fontWeight="500">sdeleeuw@ccsa.org</Text>
          </Box>

            </Flex>
         

          {/* <Text fontSize="sm">UI/UX Designer</Text> */}
        </Card>

        )}
      

        {/* Navigation Items */}
        <VStack align="stretch" spacing={4}>
        <NavItem icon={LuSchool} label="Schools"  path="/ccsa_staff/schools" />
        {/* <NavItem icon={FaTachometerAlt} label="Promote Jobs" /> */}

        <NavItem icon={LuUsers} label="Talent Community" path="/ccsa_staff/community?page=2" />

        {/* <NavItem pt={3}  style={{textAlign:'start', float: 'inline-start'}} icon={FiFilter} label="Pipeline" path="/admin_candidate_pipeline" />

        <NavItem pt={3}  style={{textAlign:'start', float: 'inline-start'}} icon={FiBarChart2} label="Metrics" path="/admin_reports" />

        <NavItem pt={3}  style={{textAlign:'start', float: 'inline-start'}} icon={FiSettings} label="Settings" path="/admin_team_settings" /> */}




        {/* <NavItem icon={PiMagnifyingGlassBold} label="Source Candidates" /> */}
          {/* <Divider my={4} />
          <NavItem icon={FaCog} label="Settings" />
          <NavItem icon={FaSignOutAlt} label="Logout" /> */}
        </VStack>

        

        {/* Theme Toggle */}
        <IconButton
          aria-label="Toggle theme"
          icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
          onClick={toggleColorMode}
          isRound={true}
          alignSelf="center"
          size="lg"
          mt={10}
        />
      </VStack>

      
    </Card>
    </Box>
  );
}

function NavItem({ icon, label, path, collapsed }) {
    let navigate = useNavigate();
    return (
      <Button
        textAlign={'start'}
        justifyContent={collapsed ? 'center' : 'flex-start'}
        variant='ghost'
        leftIcon={React.createElement(icon, { boxSize: 10 })} // Adjust the boxSize as needed
        onClick={() => navigate(path)}
      >
        { label}
      </Button>
    );
  }

export default SideNavBar202407;
