// src/ChartComponent.js
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ChartComponent = ({ labels, datasets, options }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'bar', // You can make this dynamic by adding a prop for the chart type
      data: {
        labels,
        datasets,
      },
      options,
    });

    return () => {
      myChart.destroy();
    };
  }, [labels, datasets, options]);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default ChartComponent;
