import React from 'react';
import ImageUploading from 'react-images-uploading';
import { PiArrowLineRight,PiTrash, PiPencilLineLight, PiPlusBold } from "react-icons/pi";
import { FiFolder } from 'react-icons/fi';

import {    Button
  } from '@chakra-ui/react';


export function UploadSingleResume({onImagesChange}) {
    // Only map over photos if it's defined and not empty
    const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    onImagesChange(imageList); // Call the callback function with the updated images
};

  return (
    <div className="App" style={{marginBottom:'20px', marginTop:'-10px'}}>
      <ImageUploading
        multiple={false}
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        allowNonImageType={true}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div >
            &nbsp;
            <div>

            {imageList.map((image, index) => (
              <div key={index} className="image-item" style={{overflow:'hidden', maxWidth:'100%' }}>
{
      image.file.type.includes("pdf") ?
      // For PDF, use an <embed> tag or a PDF viewer component
      <embed src={image['data_url']} type="application/pdf" width="100%" style={{borderRadius:'10px', backgroundColor:'transparent', height:'55vh', }} />
      :
      // For images, keep using the <img> tag
      <img src={image['data_url']} alt="" style={{height:'90%', width:'90%', borderRadius:'10px'}} />
    }             
              </div>
            ))}

            </div>


            <div   style={{height:'50px', textAlign:'center'}}>
<div
  style={{
    // border: '2px dotted #cccccc',
    // padding: '20px',
    // textAlign: 'center',
    // cursor: 'pointer',
    // color: isDragging ? 'red' : 'initial',
    // height:'90%', 
    // aspectRatio:'1',
    // borderRadius:'10px',
    // marginRight:'10px',
    // textAlign:'center'
    marginTop:10

  }}
  onClick={onImageUpload}
  {...dragProps}
>
    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
  <Button leftIcon={<FiFolder />}> 
                    Upload resume
    </Button>
    {imageList.length > 0 && (
                  <Button  colorScheme='red' variant='ghost' size='md' ml='2' onClick={onImageRemoveAll}>
                                      <PiTrash  w={4} h={4} />
                  </Button>
              )}
</div>
</div>
</div>


          </div>
        )}
      </ImageUploading>
    </div>
  );
}