import React, { useState, useEffect } from 'react';
import { Box, Img, Flex,  Skeleton,
    Modal,
    ModalOverlay, Spinner,
    ModalContent,
    ModalHeader,
    ModalFooter,FormControl, FormLabel, 
    ModalBody,
    ModalCloseButton,
    SkeletonCircle, Link, Tabs, TabList, TabPanels, Tab, TabPanel,
    SkeletonText, Stack, Grid,Card,Badge, GridItem, Text, Button, Image, Heading, Avatar, Divider, ChakraProvider, Textarea, useDisclosure, useToast } from '@chakra-ui/react';

import { LuGraduationCap } from "react-icons/lu";

import { FiBookOpen, FiBriefcase, FiCheckSquare, FiFolder, FiHeart, FiSave, FiSlash, FiUser } from 'react-icons/fi';
import { FaLinkedin, FaEmail } from 'react-icons/fa';
import { FiMail, FiEdit3, FiPhoneCall, FiMapPin } from "react-icons/fi";
import { PiArrowLineRight,PiTrash, PiPencilLineLight, PiPlusBold } from "react-icons/pi";
import { format, parseISO } from 'date-fns';
import { DatePicker, InputNumber, Space, Input, Select, Tag, Button as AntButton } from 'antd';
import ImageUploading from 'react-images-uploading';
import moment from 'moment';
import GoogleMapsUserInput from './profile_google_maps_input';
import { useNavigate } from 'react-router-dom';


function UserProfileSection() {
  const { Option } = Select;
  const navigate = useNavigate();

    const handleLocationSetting = (location) => {
        console.log(location)
        setUserAddress(location)
      };
      const handleCityAndState = (cityState) => {
        setCityAndState(cityState)
      };

    

    const [isPreferenceEditMode, setIsPreferenceEditMode] = useState(false);


    const [isEditMode, setIsEditMode] = useState(false);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userBio, setUserBio] = useState('');
    const [userAddress, setUserAddress] = useState('');
    
    const [loadingProfileSave, setLoadingProfileSave] = useState(false);

    
    // const [locationAddress, setNewSchoolLocationAddress] = React.useState('');
    const [cityAndState, setCityAndState] = React.useState('');



    async function handlePersonalDetailForm() {
        setUserFirstName(userData.first_name);
        setUserLastName(userData.last_name)
        setUserBio(userData.bio)
        setUserAddress(userData.address)
        setUserPhone(userData.phone)
        setIsEditMode(true);
      }


    async function handlePersonalDetailsSave() {
        setLoadingProfileSave(true)
        const payload = {
            firstName: userFirstName,
            lastName: userLastName,
            bio: userBio,
            address: userAddress,
            phone: userPhone,
            cityAndState: cityAndState,
        };
        try {
          const response = await fetch('https://www.pursueli.com/api/edit_personal_details/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify(payload),
          });
      
          if (response.ok) {
            console.log('Status updated successfully');
            const data = await response.json();
            setUserData(data.user)
          } else {
            console.error('Failed to update user.');
          }
        } catch (error) {
          console.error('Error updating status:', error);
        } finally {
            setLoadingProfileSave(false); // Ensure loading state is turned off after the operation
            setIsEditMode(false);
        }
    
      }

      async function handlePreferencesForm() {
        // setUserFirstName(userData.first_name);
        // setUserLastName(userData.last_name)
        // setUserBio(userData.bio)
        // setUserAddress(userData.address)
        // setUserPhone(userData.phone)
        setIsPreferenceEditMode(true);
      }


    async function handlePreferencesSave() {

        setLoadingProfileSave(true)
        const payload = {
          selectedGrades: selectedGrades,
          selectedRoles: selectedRoles,
          selectedCounties: selectedCounties,
          selectedEmploymentTypes: selectedEmploymentTypes,
          selectedSchoolTypes: selectedSchoolTypes,
          minimumSalaryPreference: minimumSalaryPreference,
          selectedSubjects: selectedSubjects,
        };
        try {
          const response = await fetch('https://www.pursueli.com/api/edit_preferences/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            },
            body: JSON.stringify(payload),
          });
      
          if (response.ok) {
            console.log('Status updated successfully');
            const data = await response.json();
            setUserData(data.user)
          } else {
            console.error('Failed to update user.');
          }
        } catch (error) {
          console.error('Error updating status:', error);
        } finally {
            setLoadingProfileSave(false); // Ensure loading state is turned off after the operation
            setIsPreferenceEditMode(false);
        }
    
      }














    function formatPhoneNumber(phoneNumber) {
        // Convert to string in case it's not already
        const phoneStr = phoneNumber.toString();
        
        // Use a regular expression to add a dash after the third digit
        return phoneStr.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
const { isOpen: isAddeducationModalOpen, onOpen: onAddEducationModalOpen, onClose: onAddEducationModalClose } = useDisclosure();
const [newEdMajor, setNewEdMajor] = useState('');
const [newEdOrg, setnewEdOrg] = useState('');
const [newEdStartDate, setNewEdStartDate] = useState('');
const onEducationEndDateChange = (date, dateString) => {
    setNewEdEndDate(date)
  };
const onEducationStartDateChange = (date, dateString) => {
    setNewEdStartDate(date)
  };
const [newEdEndDate, setNewEdEndDate] = useState('');
const [newEdAbout, setNewEdAbout] = useState('');
const [newEdExtracurriculars, setNewEdExtracurriculars] = useState('');

const [newEdAccreditation, setNewEdAccreditation] = useState('');
const [newEdGPA, setNewEdGPA] = useState('');
const onNewEducationaccreditationChange = (item) => {
    console.log(item);
    setNewEdAccreditation(item);
};
const onNewEducationGPAChange = (item) => {
    console.log(item);
    setNewEdGPA(item)
}


const [addEdError, setAddEdError] = useState(false);


const AddEducation = async (user_id) => {
      if (!newEdMajor || !newEdOrg || !newEdStartDate || !newEdEndDate) {
        setAddEdError(true)
      return
      }
    setSendingInvite(true)
    const payload = { 
        newEdMajor: newEdMajor, 
        newEdOrg: newEdOrg, 
        newEdStartDate: newEdStartDate,
        newEdEndDate: newEdEndDate,
        newEdAbout: newEdAbout,
        newEdExtracurriculars: newEdExtracurriculars,
        newEdAccreditation: newEdAccreditation,
        newEdGPA: newEdGPA,

      };    try {
      const response = await fetch('https://www.pursueli.com/api/user_add_education/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        setEducation(data.education)
        setSendingInvite(false)

        setNewEdMajor('')
        setnewEdOrg('')
        setNewEdStartDate('')
        setNewEdStartDate('')
        setNewEdAbout('')
        setNewEdAccreditation('')
        setNewEdExtracurriculars('')
        setNewEdGPA('')
        toast({
            title: 'Education added.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAddEducationModalClose();
      } else {
        console.error('Failed to add education.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };










const [editingEd, setEditingEd] = useState(false);
const [loadingEditedEd, setLoadingEditedEd] = useState(false);
const [editedMajor, setEditedMajor] = useState('');
const [editedSchoolOrg, setEditedSchoolOrg] = useState('');
const [editedEdStartDate, setEditedEdStartDate] = useState('');
const [editedEdEndDate, setEditedEdEndDate] = useState('');
const [editedAbout, setEditedAbout] = useState('');
const [editedEdExtraCurricular, setEditedEdExtraCurricular] = useState('');
const [editedEdGpa, setEditedEdGpa] = useState('');
const [editedEdAccreditation, setEditedEdAccreditation] = useState('');

const onEditedEducationGPAChange = (item) => {
    console.log(item);
    setEditedEdGpa(item);
};
const onEditedEducationaccreditationChange = (item) => {
    console.log(item);
    setEditedEdAccreditation(item);
};


const onEditedEdStartDateChange = (date, dateString) => {
    setEditedEdStartDate(date)
  };
const onEditedEdEndDateChange = (date, dateString) => {
    setEditedEdEndDate(date)
  };
const toggleEducationEditMode = index => {
    const itemBeingEdited = education[index];
    if (itemBeingEdited.isEditing) {
        // Prepare for saving: show loading, log the state, but don't toggle isEditing yet
        setLoadingEditedEd(true);
        console.log('Saving education', itemBeingEdited);
        // Trigger the async save operation
        EditEducation(itemBeingEdited.id, index);
    } else {
        setEditingEd(true)
        // Toggle isEditing to true for this item, to enter edit mode
        const updatedEducation = education.map((item, idx) => ({
            ...item,
            isEditing: idx === index ? !item.isEditing : item.isEditing,
        }));
        setEducation(updatedEducation);
        setEditedMajor(itemBeingEdited.major);
        setEditedSchoolOrg(itemBeingEdited.institution_name);
        setEditedEdStartDate(itemBeingEdited.start_date);
        setEditedEdEndDate(itemBeingEdited.end_date);
        setEditedAbout(itemBeingEdited.about);
        setEditedEdAccreditation(itemBeingEdited.degree_type);
        setEditedEdExtraCurricular(itemBeingEdited.extra_curriculars);
        setEditedEdGpa(itemBeingEdited.gpa)
    }
};
const [edError, setEdError] = useState(false);




const EditEducation = async (id, index) => {
  if (!editedEdStartDate || !editedMajor || !editedSchoolOrg || !editedEdEndDate || !editedEdAccreditation) {
    setEdError(true);
    setEditingEd(false);
    setLoadingEditedEd(false); // Ensure loading state is turned off after the operation
    return; // Stop the submission process
  }
  
    const payload = { 
        editedMajor: editedMajor,
        editedSchoolOrg: editedSchoolOrg,
        editedEdStartDate: editedEdStartDate,
        editedEdEndDate: editedEdEndDate,
        editedAbout: editedAbout,
        editedEdAccreditation: editedEdAccreditation,
        editedEdGpa:editedEdGpa,
        editedEdExtraCurricular: editedEdExtraCurricular,
        educationId: id 
      }; try {
      const response = await fetch('https://www.pursueli.com/api/edit_education/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        setEducation(data.education.map(item => ({ ...item, isEditing: false })));
      } else {
        console.error('Failed to update invites.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
        setLoadingEditedEd(false); // Ensure loading state is turned off after the operation
        setEditingEd(false)
    }
  };

  const deleteEducation = async (id, index) => {
    const payload = { 
        workHistoryId: id 
      };    try {
      const response = await fetch('https://www.pursueli.com/api/delete_education/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        setEducation(data.education.map(item => ({ ...item, isEditing: false })));
      } else {
        console.error('Failed to update invites.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
        setLoadingEditedEd(false); // Ensure loading state is turned off after the operation
    }
  };


    const { TextArea } = Input;
    const [workExperience, setWorkExperience] = useState([]);

    const [editingJob, setEditingJob] = useState(false);
    const [loadingEditedJob, setLoadingEditedJob] = useState(false);
    const [editedJobRole, setEditedJobRole] = useState('');
    const [editedJobOrg, setEditedJobOrg] = useState('');
    const [editedJobStartDate, setEditedJobStartDate] = useState('');
    const [editedJobEndDate, setEditedJobEndDate] = useState('');
    const [editedJobSummary, setEditedJobSummary] = useState('');
    
    const onEditedJobStartDateChange = (date, dateString) => {
        setEditedJobStartDate(date)
      };
    const onEditedJobEndDateChange = (date, dateString) => {
        setEditedJobEndDate(date)
      };
    const toggleEditMode = index => {
        const itemBeingEdited = workExperience[index];
        if (itemBeingEdited.isEditing) {
            // Prepare for saving: show loading, log the state, but don't toggle isEditing yet
            setLoadingEditedJob(true);
            console.log('Saving work history', itemBeingEdited);
            // Trigger the async save operation
            EditWorkExperience(itemBeingEdited.id, index);
        } else {
            setEditingJob(true)
            // Toggle isEditing to true for this item, to enter edit mode
            const updatedWorkExperiences = workExperience.map((item, idx) => ({
                ...item,
                isEditing: idx === index ? !item.isEditing : item.isEditing,
            }));
            setWorkExperience(updatedWorkExperiences);
            // Populate the form fields for editing
            setEditedJobRole(itemBeingEdited.title);
            setEditedJobOrg(itemBeingEdited.institution_name);
            setEditedJobStartDate(itemBeingEdited.start_date);
            setEditedJobEndDate(itemBeingEdited.end_date);
            setEditedJobSummary(itemBeingEdited.description);
        }
    };
    
      

    const [sendingInvite, setSendingInvite] = useState(false);
    const toast = useToast();

    const [invites, setInvites] = useState([]);




    const [newJobRole, setNewJobRole] = useState('');
    const [newJobOrg, setnewJobOrg] = useState('');
    const [newJobStartDate, setNewJobStartDate] = useState('');

    const onEndDateChange = (date, dateString) => {
        // alert(date, dateString);
        setNewJobEndDate(date)
      };

    const onStartDateChange = (date, dateString) => {
        // alert(date, dateString);
        setNewJobStartDate(date)
      };

    const [newJobEndDate, setNewJobEndDate] = useState('');
    const [newJobSummary, setNewJobSummary] = useState('');


    const [education, setEducation] = useState([]);

    
    const [inviteLastName, setInviteLastName] = useState('');
    const [inviteEmail, setInviteEmail] = useState('');
  
    const [schoolSites, setSchoolSites] = useState([]);
  
    const [organization, setOrganization] = useState('');
    const [copyOfCredential, setCopyOfCredential] = React.useState([]);
    const handleCredentialsChange = (updatedCredentials) => {
        setCopyOfCredential(updatedCredentials);
    };
    const onCredentialChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setCopyOfCredential(imageList);
        handleCredentialsChange(imageList); // Call the callback function with the updated images
    };

    const [coverLetter, setCoverLetter] = React.useState([]);
    const handleCoverLetterChange = (updatedCoverLetter) => {
        setCoverLetter(updatedCoverLetter);
    };
    const onCoverLetterChange = (imageList, addUpdateIndex) => {
        console.log(imageList, addUpdateIndex);
        setCoverLetter(imageList);
        handleCoverLetterChange(imageList); // Call the callback function with the updated images
    };


    const { isOpen: isAddWorkExpModalOpen, onOpen: onAddWorkExpModalOpen, onClose: onAddWorkExpModalClose } = useDisclosure();

    // const [images, setImages] = React.useState([]);

    const handleImagesChange = (updatedImages) => {
      setImages(updatedImages);
  };
  
  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
    handleImagesChange(imageList); // Call the callback function with the updated images
};


  const [userData, setUserData] = useState(null);

  const [countyLocations, setCountyLocations] = React.useState([]);
  const [gradeLevels, setGradeLevels] = React.useState([]);
  const [subjectMatters, setSubjectMatters] = React.useState([]);
  const [roles, setRoles] = React.useState([]);


  const [images, setImages] = React.useState([]);

  useEffect(() => {
    // Fetch the upcoming events
    fetch("https://www.pursueli.com/api/get_user_data/", {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
        }
      })          
      .then((response) => response.json())
      .then((data) => {
        console.log("user data")
        console.log(data.user); // Add this line
        // setPastEvents(data.past_things_to_do);
        setUserData(data.user); // Update state with fetched data
        setWorkExperience(data.work_history.map(item => ({ ...item, isEditing: false })));
        setEducation(data.education)
        setCountyLocations(data.counties)
        setGradeLevels(data.grades)
        setSubjectMatters(data.subjects)
        setRoles(data.roles)
      })
    .catch((error) => console.error("Error fetching upcoming events:", error));

  }, []);

  
  const [editJobError, setEditJobError] = useState(false);
  const EditWorkExperience = async (id, index) => {
    if (!editedJobRole || !editedJobOrg || !editedJobStartDate || !editedJobEndDate) {
      setEditJobError(true);
      setEditingJob(false);
      setLoadingEditedJob(false); // Ensure loading state is turned off after the operation
      return;
       // Stop the submission process
    }
    const payload = { 
        setEditedJobRole: editedJobRole,
        setEditedJobOrg: editedJobOrg,
        setEditedJobStartDate: editedJobStartDate,
        setEditedJobEndDate: editedJobEndDate,
        setEditedJobSummary: editedJobSummary,
        workHistoryId: id 
      };    try {
      const response = await fetch('https://www.pursueli.com/api/edit_work_exp/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        setWorkExperience(data.work_history.map(item => ({ ...item, isEditing: false })));
      } else {
        console.error('Failed to update invites.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
        setLoadingEditedJob(false); // Ensure loading state is turned off after the operation
        setEditingJob(false)
    }
  };

  const deleteWorkExperience = async (id, index) => {
    const payload = { 
        workHistoryId: id 
      };    try {
      const response = await fetch('https://www.pursueli.com/api/delete_work_exp/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        setWorkExperience(data.work_history.map(item => ({ ...item, isEditing: false })));
      } else {
        console.error('Failed to update invites.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
        setLoadingEditedJob(false); // Ensure loading state is turned off after the operation
    }
  };

    // Initialize state with an empty array or based on a prop
   
    const [addJobError, setAddJobError] = useState(false);

  const AddWorkExp = async (user_id) => {

    if (!newJobRole || !newJobOrg || !newJobStartDate || !newJobEndDate) {
      setAddJobError(true);}
      return;

    setSendingInvite(true)
    const payload = { 
        newJobRole: newJobRole, 
        newJobOrg: newJobOrg, 
        newJobStartDate: newJobStartDate,
        newJobEndDate: newJobEndDate,
        newJobSummary: newJobSummary,
      };    try {
      const response = await fetch('https://www.pursueli.com/api/user_add_work_exp/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        setWorkExperience(data.work_history)
        setSendingInvite(false)
        setNewJobRole('')
        setnewJobOrg('')
        setNewJobStartDate('')
        setNewJobEndDate('')
        setNewJobSummary('')
        toast({
            title: 'Invite sent.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          onAddWorkExpModalClose();
      } else {
        console.error('Failed to update invites.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = '.ant-picker-dropdown { z-index: 1500 !important; }';
    document.head.appendChild(style);

    return () => document.head.removeChild(style);
  }, []);

  const getCredentialColor = (credentialType) => {
    const colors = {
      'AA': 'magenta',
      'AS': 'red',
      'AAS': 'volcano',
      'BA': 'orange',
      'BS': 'gold',
      'MA': 'lime',
      'MS': 'green',
      'MBA': 'cyan',
      'MPA': 'blue',
      'MEd': 'geekblue',
      'PhD': 'purple',
      'MD': 'processing',
      'EdD': 'success',
      'LLB': 'error',
      'LLM': 'warning',
      'MPP': 'orange', // Feel free to adjust colors as per your preference
      'Certificate': 'gold',
    };
    return colors[credentialType] || 'default'; // 'default' is a fallback color
  };

  const [selectedGrades, setSelectedGrades] = useState([]);
  useEffect(() => {
    if (userData && userData.grade_level_preference) {
      const gradeValues = userData.grade_level_preference.map(grade => grade.level); // Adjust based on your actual data structure
      setSelectedGrades(gradeValues);
    }
  }, [userData]);

  const [selectedRoles, setSelectedRoles] = useState([]);
  useEffect(() => {
    if (userData && userData.role_preference) {
      const roleValues = userData.role_preference.map(role => role.title); // Adjust based on your actual data structure
      setSelectedRoles(roleValues);
    }
  }, [userData]);
  
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  useEffect(() => {
    if (userData && userData.subject_matter_preference) {
      const subjectValues = userData.subject_matter_preference.map(subject => subject.subject); // Adjust based on your actual data structure
      setSelectedSubjects(subjectValues);
    }
  }, [userData]);

  const [selectedCounties, setselectedCounties] = useState([]);
  useEffect(() => {
    if (userData && userData.location_preference) {
      const countyValues = userData.location_preference.map(location => location.county); // Adjust based on your actual data structure
      setselectedCounties(countyValues);
    }
  }, [userData]);

  const [selectedEmploymentTypes, setSelectedEmploymentTypes] = useState([]);
  useEffect(() => {
    if (userData && userData.employment_type_preference) {
      const values = userData.employment_type_preference.map(type => type.title); // Adjust based on your actual data structure
      setSelectedEmploymentTypes(values);
    }
  }, [userData]);

  const [minimumSalaryPreference, setMinimumSalaryPreference] = useState([]);
  useEffect(() => {
    if (userData && userData.minimum_salary_preference) {
      setMinimumSalaryPreference(userData.minimum_salary_preference);
    }
  }, [userData]);

  
  const [selectedSchoolTypes, setSelectedSchoolTypes] = useState([]);
  useEffect(() => {
    if (userData && userData.school_type_preference) {
      const schoolTypeValues = userData.school_type_preference.map(schoolType => schoolType.type); // Adjust based on your actual data structure
      setSelectedSchoolTypes(schoolTypeValues);
    }
  }, [userData]);
  

  if (!userData) {
    return      <Flex
    // width="100vh" // Use "100vw" for full viewport width if needed
    justifyContent="center"
    height={'70vh'}
    alignItems={'center'}
    marginTop={10}>
      <Spinner/>
    </Flex>

//   > <Grid 
//     textAlign={'center'}
//     templateColumns={{ sm: 'repeat(0, 0fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
//     width={'100vh'}
//   > 
//     <Box  padding="6" boxShadow="lg" width={'100%'} minWidth={'100%'} bg="white">
//     <Flex justifyContent={'space-between'}>
//         <Flex>
//         <SkeletonCircle size="10" />

//             <Box>
//         <Skeleton ml={5} mb={2} height="20px" width="100px" />
//         <Skeleton ml={5} height="20px" width="200px" />
//         </Box>
//     </Flex>

//     <Button isDisabled variant="outline">
//         <Skeleton height="20px" width="100px" />
//       </Button>
//     </Flex>

//     <Grid templateColumns="repeat(2, 1fr)" gap={4} my="6">
//       <GridItem colSpan={1}>
//       <Skeleton height='40px' mt="4" noOfLines={2} spacing="4" />
//       </GridItem>
//       <GridItem colSpan={1}>
//       <Skeleton height='40px' mt="4" noOfLines={2} spacing="4" />
//       </GridItem>
//     </Grid>

//     <Grid templateColumns="repeat(2, 1fr)" gap={4} my="6">
//       <GridItem colSpan={1}>
//       <Skeleton height='40px' my="4" noOfLines={2} spacing="10" />
//       </GridItem>
//       <GridItem colSpan={1}>
//       <Skeleton height='40px' my="4" noOfLines={2} spacing="10" />
//       </GridItem>
//     </Grid>


    
// <Box mt={6}>
// <Stack mt="6">
//       <Skeleton height="30px" />
//       <Skeleton height="30px" />
//       <Skeleton height="30px" />
//     </Stack>
// </Box>


//     <Box mt="6">

//     </Box>

//     <Box mt={6}>
// <Stack mt="6">
//       <Skeleton height="30px" />
//       <Skeleton height="30px" />
//       <Skeleton height="30px" />
//     </Stack>
// </Box>



//   </Box>
//   </Grid>
  }
  

  return (
    
<Box 
p={0}
mx={'auto'}
>


                <Tabs 
                      orientation={{ base: 'horizontal', md: 'vertical' }}

                >
                    

        <Box
        // mr={10}
        minWidth={{ sm: '100%', md: '100%', lg: '100%' }}>

  <TabList mt={5} overflow={'scroll'}  justifyContent={'space-between'}
  css={{
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none', // IE and Edge
    'scrollbar-width': 'none', // Firefox
  }}

  >
    <Tab py={2} style={{WebkitJustifyContent:'flex-start', justifyContent:'flex-start', minWidth:'fit-content'}}>
        {/* <FiUser style={{marginRight:'10px'}}/> */}
        Personal Details</Tab>
    <Tab py={2} style={{WebkitJustifyContent:'flex-start', justifyContent:'flex-start', minWidth:'fit-content'}} >
        {/* <FiBriefcase style={{marginRight:'10px'}}/> */}
        Work Experience</Tab>
    <Tab py={2} style={{WebkitJustifyContent:'flex-start', justifyContent:'flex-start', minWidth:'fit-content'}} >
        {/* <LuGraduationCap style={{marginRight:'10px', fontSize:'19px', marginLeft:'-3px'}}/> */}
        Education & Credentials</Tab>
        <Tab py={2} style={{WebkitJustifyContent:'flex-start', justifyContent:'flex-start', minWidth:'fit-content'}} >
        {/* <FiCheckSquare style={{marginRight:'10px'}}/> */}
        Career Preferences</Tab>

  </TabList>

  <Button display={'none'} justifySelf={'flex-start'} position={'absolute'} left={0} mt={6}
    onClick={() => navigate('/home')}
>
          Finish updating profile
        </Button>
  

        </Box>
        <TabPanels maxWidth={{ sm: '100%', md: '80%', lg: '100%' }} minW={'70%'} >
        
    <TabPanel  minW={'100%'} p={3}     textAlign={'start'}
>
<Flex alignItems={'center'}  borderBottomWidth={0} w={'100%'} mb={6} pb={2} justifyContent={'space-between'} >
            <Heading size={'md'} fontWeight="bold" pl={0}>
            <Text color={'gray'} fontWeight={'normal'} fontSize={'sm'}>Update your personal and contact details below.</Text>
            </Heading>

            {
                            loadingProfileSave ? (
                                <Button size={'sm'} >
                                    <Spinner size='sm' variant={'solid'}  speed='0.65s'/>
                                </Button>
                            ) : (
                                <Button size={'sm'} variant={'outline'} onClick={() => {
                                    if (isEditMode) {
                                        handlePersonalDetailsSave(); // Call handleSave only when in edit mode and attempting to save
                                    } else {
                                        handlePersonalDetailForm(); // Enter edit mode
                                    }
                                    }}>
                                    {isEditMode ? '' : <FiEdit3 style={{marginRight: '10px', display:'none'}} />}
                                    {isEditMode ? 'Save' : 'Edit'}
                            </Button>
                            )
                        }
   </Flex>
        {isEditMode ? (
          <>
        <Grid
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>First name</Text>
            <Box alignSelf={'flex-end'}>
            <Input size='large' defaultValue={userFirstName} onChange={(e) => setUserFirstName(e.target.value)} placeholder="Jane" />
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Last name</Text>
            <Box alignSelf={'flex-end'}>
            <Input size='large' defaultValue={userLastName} onChange={(e) => setUserLastName(e.target.value)} placeholder="Doe" />
            </Box>
        </Box>
      </GridItem>
    </Grid>
        <Grid
        mt={5}
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Email</Text>
            <Box alignSelf={'flex-end'}>
            <Input size='large' disabled defaultValue={userData.email}/>
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Phone</Text>
            <Box alignSelf={'flex-end'}>
            <Input size='large' 
                defaultValue={userPhone} 
              maxLength={10} // Optionally enforce maxLength for additional restriction
              onChange={(e) => {
                const value = e.target.value;
                // Check if the value is all digits and not longer than 10 digits
                if (/^\d{0,10}$/.test(value)) {
                  setUserPhone(value);
                }
              }} 
              placeholder="Phone Number" />
            </Box>
        </Box>
      </GridItem>
    </Grid>
    <Grid
        mt={5}
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Address</Text>
            <Box alignSelf={'flex-end'}>
                <GoogleMapsUserInput handleLocationSetting={handleLocationSetting} handleCityAndState={handleCityAndState} defaultValue={userAddress} />
                {/* <Google_maps_input/> */}
            {/* <Input size='large' defaultValue={userAddress} placeholder="Address" /> */}
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Text fontWeight={'bold'}>Bio</Text>
      <TextArea
      size='large'
      onChange={(e) => setUserBio(e.target.value)}
        value={userBio}
        // onChange={(e) => setValue(e.target.value)}
        placeholder="Controlled autosize"
        autoSize={{
          minRows: 2,
        }}
      />
      </GridItem>
    </Grid>
    
    </>
    ) : (
        <>
       <Grid
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>First name</Text>
            <Box alignSelf={'flex-end'}>
            {userData.first_name}
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Last name</Text>
            <Box alignSelf={'flex-end'}>
            {userData.last_name}
            </Box>
        </Box>
      </GridItem>
    </Grid>
        <Grid
        mt={5}
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Email</Text>
            <Box alignSelf={'flex-end'}>
            {userData.email}
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Phone</Text>
            {
              userData && userData.phone &&
              <Box alignSelf={'flex-end'}>
                {formatPhoneNumber(userData.phone)}
              </Box>
            }
        </Box>
      </GridItem>
    </Grid>
    <Grid
        mt={5}
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Address</Text>
            <Box alignSelf={'flex-end'}>
            {userData.address}
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Text fontWeight={'bold'}>Bio</Text>
      <Box>
      {userData.bio}

      </Box>
      </GridItem>
    </Grid>
      </>
    )}
      </TabPanel>


      <TabPanel textAlign={'start'}   p={3} minWidth={'100%'}>
          <Flex alignItems={'center'} w={'100%'} pb={6} >
            {/* <Heading size={'md'} fontWeight="bold" pl={0}>Work Experience</Heading> */}
            {/* <Button ml={3} size={'sm'} variant={'outline'} colorScheme='blue' onClick={onAddWorkExpModalOpen}>Add</Button> */}
          </Flex>
              {workExperience.map((workItem, index) => (
                <React.Fragment key={index}>
                    <Card padding={4} w="100%" mb={6}>               
                    {workItem.isEditing ? (
                    <React.Fragment key={index} >
                           <Flex justifyContent={'flex-end'}  >
                           {
                            loadingEditedJob ? (
                                <Button>
                                    <Spinner size='sm' variant={'solid'}  speed='0.65s'/>
                                </Button>
                            ) : (
                                <Button mr={3} size={'sm'} variant={'ghost'} onClick={() => toggleEditMode(index)}>
                                    {workItem.isEditing ? 'Save' : 'Edit'}
                                </Button>
                            )
                        }
                    </Flex>
                        <Flex align={'space-between'} w={'100%'} >
                        <Box w={'49%'} pr={5} >
                        <Text fontWeight={'bold'}>Job title
                        {
                          !editedJobRole &&
                          <span style={{color: 'red'}}> *</span>
                        }
                        </Text>
                            <Box alignSelf={'flex-end'}>
                            <Input  disabled={loadingEditedJob} size='large' defaultValue={editedJobRole} placeholder="Title" onChange={(e) => setEditedJobRole(e.target.value)} />
                            </Box>
                        </Box>

                        <Box w={'49%'} >
                        <Text fontWeight={'bold'}>Company
                        {
                          !editedJobOrg &&
                          <span style={{color: 'red'}}> *</span>
                        }
                        </Text>
                            <Box alignSelf={'flex-end'}>
                            <Input  disabled={loadingEditedJob} size='large' defaultValue={editedJobOrg} onChange={(e) => setEditedJobOrg(e.target.value)} placeholder="Company" />
                            </Box>
                        </Box>
                        </Flex>


                    <Flex justifyContent={'flex-start'} mt={4}  >
                        <Box w={'35%'}  >
                        <Text fontWeight={'bold'}>Start date
                        {
                          !editedJobStartDate &&
                          <span style={{color: 'red'}}> *</span>
                        }
                        </Text>
                            <Box alignSelf={'flex-end'}>
                            <DatePicker  disabled={loadingEditedJob} style={{width:'100%'}} defaultValue={moment(editedJobStartDate)} required onChange={onEditedJobStartDateChange} picker="month" />
                            </Box>
                        </Box>

                        <Box  px={5} >
                            <Box alignSelf={'flex-end'} color={'transparent'} >
                                .
                            </Box>
                            <Text fontSize={'25px'} color={'grey'}> - </Text>

                        </Box>

                        <Box w={'35%'} >
                        <Text fontWeight={'bold'}>End date
                        {
                          !editedJobEndDate &&
                          <span style={{color: 'red'}}> *</span>
                        } 
                        </Text>
                            <Box alignSelf={'flex-end'}>
                            <DatePicker  disabled={loadingEditedJob} style={{width:'100%'}} defaultValue={moment(editedJobEndDate)} required onChange={onEditedJobEndDateChange} picker="month" />
                            </Box>
                        </Box>
                    </Flex>

                    <Flex justifyContent={'flex-start'} mt={4} >
                        <Box w={'100%'} >
                        <Text fontWeight={'bold'}>Role overview</Text>
                            <Box alignSelf={'flex-end'}>
                            <TextArea
                             disabled={loadingEditedJob}
            value={editedJobSummary} 
            onChange={(e) => setEditedJobSummary(e.target.value)}
            placeholder="Role overview"
            autoSize={{
            minRows: 2,
            maxRows: 5,
            }}
            size='large'

        />
                      {editJobError && <Text size={'sm'} pt={3} textAlign={'center'} style={{color: 'red'}}>Complete required fields.</Text>}

          
                        </Box>
                        </Box>
                    </Flex>
                    </React.Fragment>
                    ) : (
                        <React.Fragment key={index}>      
                          <Flex align={'flex-start'} minW={'100%'}>
                            <Img width={'75px'} borderRadius={'5px'} p={0} mr={5} src={workItem.institution.clear_bit_logo_url}/>
                            <Box alignSelf={'flex-end'} width={'100%'}>
                              <Heading style={{textAlign:'start', display:'flex', justifyContent:"space-between"}} size="sm">
                                <Box width={'100%'}>
                                    <Flex width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                                    <Text fontWeight={'800'} mtop={1}>
                                    {workItem.title}
                                    </Text>
                                    <Flex justifyContent={'center'}  >
                        <Button mr={3} size={'sm'} variant={'ghost'} onClick={() => toggleEditMode(index)}>
                            {workItem.isEditing ? 'Save' : 'Edit'}
                        </Button>
                        <Button colorScheme='red' size={'sm'} variant={'ghost'}  onClick={() => deleteWorkExperience(workItem.id)}>
                            Remove
                        </Button>
                    </Flex>
                            
                                    </Flex>
                                    <Text mb={1} fontWeight={'bold'} color={'RGBA(0, 0, 0, 0.48)'} >{workItem.institution_name}</Text>

                                    <Text my={1} fontWeight={'normal'}  color={'#2D3748'}>
                                    {workItem.start_date && format(parseISO(workItem.start_date), 'MMM, yyyy')}
                                        -   {workItem.end_date && format(parseISO(workItem.end_date), 'MMM, yyyy') || 'Present'}
                                </Text>
                                </Box>
                              </Heading>
                            </Box>
                          </Flex>
                          <Box whiteSpace="pre-line" my={1}>
                                {workItem.description}
                              </Box>
                      </React.Fragment>
                    )}
                  </Card>
                </React.Fragment>
              ))}

                {!editingJob && (
                <Flex justifyContent={'center'} w={'100%'} pb={6}>
                    <Button ml={3} size={'md'} variant={'outline'} colorScheme='blue' onClick={onAddWorkExpModalOpen}>Add</Button>
                </Flex>
                )}


    </TabPanel>

    <TabPanel textAlign={'start'}  p={3}  minWidth={'100%'}>
<Flex alignItems={'center'} w={'100%'} pb={6} >
  {/* <Heading size={'md'} fontWeight="bold" pl={0}>Education & Credentials</Heading> */}
</Flex>
    {education.map((education, index) => (
      <React.Fragment key={index}>
          <Card padding={4} w="100%" mb={6}>               
          {education.isEditing ? (
          <React.Fragment key={index} >
                 <Flex justifyContent={'flex-end'}  >
                 {
                  loadingEditedEd ? (
                      <Button>
                          <Spinner size='sm' variant={'solid'}  speed='0.65s'/>
                      </Button>
                  ) : (
                      <Button mr={3} size={'sm'} variant={'ghost'} onClick={() => toggleEducationEditMode(index)}>
                          {education.isEditing ? 'Save' : 'Edit'}
                      </Button>
                  )
              }
          </Flex>
              <Flex align={'space-between'} w={'100%'} >
              <Box w={'50%'} pr={5} >
              <Text fontWeight={'bold'}>Major or Credential Name
              {
                !editedMajor &&
                <span style={{color: 'red'}}> *</span>
              }
              </Text>
                  <Box alignSelf={'flex-end'}>
                  <Input  disabled={loadingEditedEd} size='large' defaultValue={editedMajor} placeholder="Title" onChange={(e) => setEditedMajor(e.target.value)} />
                  </Box>
              </Box>

              <Box w={'50%'} >
              <Text fontWeight={'bold'}>School or Institution
              {
                !editedSchoolOrg &&
                <span style={{color: 'red'}}> *</span>
              }
              </Text>
                  <Box alignSelf={'flex-end'}>
                  <Input  disabled={loadingEditedEd} size='large' defaultValue={editedSchoolOrg} onChange={(e) => setEditedSchoolOrg(e.target.value)} placeholder="Company" />
                  </Box>
              </Box>
              </Flex>


          <Flex justifyContent={'flex-start'} mt={4}  >
          <Box w={'25%'} pr={3}  >
              <Text fontWeight={'bold'}>Accreditation
              {
                !editedEdAccreditation &&
                <span style={{color: 'red'}}> *</span>
              }
              </Text>
                  <Box alignSelf={'flex-end'}>
                  <Select 
                  defaultValue = {editedEdAccreditation}
                  options={[
                    {
                      value: 'AA',
                      label: 'AA',
                    },
                    {
                      value: 'AS',
                      label: 'AS',
                    },
                    {
                      value: 'AAS',
                      label: 'AAS',
                    },
                    {
                      value: 'BA',
                      label: 'BA',
                    },
                    {
                        value: 'BS',
                        label: 'BS',
                    },
                    {
                        value: 'MA',
                        label: 'MA',
                    },
                    {
                        value: 'MS',
                        label: 'MS',
                    },
                    {
                        value: 'MBA',
                        label: 'MBA',
                    },
                    {
                        value: 'MPA',
                        label: 'MPA',
                    },
                    {
                        value: 'MEd',
                        label: 'MEd',
                    },
                    {
                        value: 'PhD',
                        label: 'PhD',
                    },
                    {
                        value: 'MD',
                        label: 'MD',
                    },
                    {
                        value: 'EdD',
                        label: 'EdD',
                    },
                    {
                        value: 'LLB',
                        label: 'LLB',
                    },
                    {
                        value: 'LLM',
                        label: 'LLM',
                    },
                    {
                        value: 'MPP',
                        label: 'MPP',
                    },
                    {
                        value: 'Certificate',
                        label: 'Certificate',
                    },
                  ]}
                  
                  disabled={loadingEditedEd} style={{width:'100%'}} required onChange={onEditedEducationaccreditationChange}/>
                  </Box>
              </Box>
              <Box w={'20%'} pr={5}  >
              <Text fontWeight={'bold'}>GPA</Text>
                  <Box alignSelf={'flex-end'}>
                  <InputNumber
                        style={{
                        width: '100%',
                        }}
                        defaultValue={editedEdGpa}
                        min="0"
                        max="10"
                        step="0.01"
                        onChange={onEditedEducationGPAChange}
                        stringMode
                        disabled={loadingEditedEd}
                    />
                  </Box>
              </Box>
              <Box w={'25%'} justifySelf={'flex-end'} >
              <Text fontWeight={'bold'}>Start
              {
                !editedEdStartDate &&
                <span style={{color: 'red'}}> *</span>
              }
              </Text>
                  <Box alignSelf={'flex-end'}>
                  <DatePicker  disabled={loadingEditedEd} style={{width:'100%'}} defaultValue={editedEdStartDate ? moment(editedEdStartDate) : undefined}  required onChange={onEditedEdStartDateChange} picker="month" />
                  </Box>
              </Box>
              <Box  px={5} >
                  <Box alignSelf={'flex-end'} color={'transparent'} >
                      .
                  </Box>
                  <Text fontSize={'25px'} color={'grey'}> - </Text>

              </Box>

              <Box w={'25%'} justifySelf={'flex-end'}  >
              <Text fontWeight={'bold'}>Graduation
              {
                !editedEdEndDate &&
                <span style={{color: 'red'}}> *</span>
              }
              </Text>
                  <Box alignSelf={'flex-end'}>
                  <DatePicker  disabled={loadingEditedEd} style={{width:'100%'}} defaultValue={moment(editedEdEndDate)} required onChange={onEditedEdEndDateChange} picker="month" />
                  {/* {endDateError && <Text size={'sm'} style={{color: 'red'}}>This field is required.</Text>}  */}
                  </Box>
              </Box>
          </Flex>

          <Flex justifyContent={'flex-start'} mt={4} >
              <Box w={'100%'} >
              <Text fontWeight={'bold'}>Details</Text>
                  <Box alignSelf={'flex-end'}>
                  <TextArea
                   disabled={loadingEditedEd}
                    value={editedAbout} 
                    onChange={(e) => setEditedAbout(e.target.value)}
                    placeholder="Additional details about your education ..."
                    autoSize={{
                    minRows: 2,
                    maxRows: 5,
                    }}
                    size='large'

                    />
              </Box>
              </Box>
          </Flex>
          <Flex justifyContent={'flex-start'} mt={4} >
              <Box w={'100%'} >
              <Text fontWeight={'bold'}>Extracurriculars</Text>
                  <Box alignSelf={'flex-end'}>
                  <TextArea
                   disabled={loadingEditedEd}
                    value={editedEdExtraCurricular} 
                    onChange={(e) => setEditedEdExtraCurricular(e.target.value)}
                    placeholder="Provide details about your participation in extracurricular activities here..."
                    autoSize={{
                    minRows: 2,
                    maxRows: 5,
                    }}
                    size='large'

                    />
              </Box>

              {edError && <Text size={'sm'} textAlign={'center'} style={{color: 'red'}}>Complete required fields.</Text>}

              </Box>
          </Flex>
          </React.Fragment>
          ) : (
              <React.Fragment key={index}>      
                <Flex align={'flex-start'} minW={'100%'}>
                  <Img width={'75px'} borderRadius={'5px'} p={0} mr={5} src={education.institution.clear_bit_logo_url}/>
                  <Box alignSelf={'flex-end'} width={'100%'}>
                    <Heading style={{textAlign:'start', display:'flex', justifyContent:"space-between"}} size="sm">
                      <Box width={'100%'}>
                          <Flex width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                          <Flex alignItems={'center'}>
                          <Text fontWeight={'800'} mtop={1}>
                          {education.major}

                          </Text>
                          <Tag bordered={false} color={getCredentialColor(education.degree_type)} style={{marginLeft:'10px', marginRight:'10px'}} >
                           {education.degree_type}
                        </Tag>
                        {
                        education.gpa && 
                        <Tag bordered={false} style={{}}>
                            GPA: {education.gpa}
                        </Tag>
                        }

                    </Flex>
                          <Flex justifyContent={'center'}  >
              <Button mr={3} size={'sm'} variant={'ghost'} onClick={() => toggleEducationEditMode(index)}>
                  {education.isEditing ? 'Save' : 'Edit'}
              </Button>
              <Button colorScheme='red' size={'sm'} variant={'ghost'}  onClick={() => deleteEducation(education.id)}>
                  Remove
              </Button>
          </Flex>
                          </Flex>
                          <Text mb={1} fontWeight={'bold'} color={'RGBA(0, 0, 0, 0.48)'} >{education.institution_name}</Text>
                          <Text my={1} fontWeight={'normal'}  color={'#2D3748'}>
                          {education.start_date && format(parseISO(education.start_date), 'MMM, yyyy')}
                              -   {education.end_date && format(parseISO(education.end_date), 'MMM, yyyy') || 'Present'}
                      </Text>
                      </Box>
                    </Heading>
                  </Box>
                </Flex>
                <Box whiteSpace="pre-line" my={1}>
                      {education.about}
                    </Box>
                    <Box whiteSpace="pre-line" my={1}>
                      {education.extra_curriculars}
                    </Box>
            </React.Fragment>
          )}
        </Card>
      </React.Fragment>
    ))}
      {!editingEd && (
      <Flex justifyContent={'center'} w={'100%'} pb={6}>
          <Button ml={3} size={'md'} variant={'outline'} colorScheme='blue' onClick={onAddEducationModalOpen}>Add</Button>
      </Flex>
      )}
</TabPanel>
<TabPanel  minW={'100%'}  p={3}    textAlign={'start'}>
<Flex alignItems={'center'}  borderBottomWidth={0} w={'100%'} mb={6} pb={2} justifyContent={'space-between'} >
            <Heading size={'md'} fontWeight="bold" pl={0}>
            <Text color={'gray'} fontWeight={'normal'} fontSize={'sm'}>Update your career preferences below.</Text>
            </Heading>
            {
                            loadingProfileSave ? (
                                <Button size={'sm'} >
                                    <Spinner size='sm' variant={'solid'}  speed='0.65s'/>
                                </Button>
                            ) : (
                                <Button size={'sm'} variant={'outline'} onClick={() => {
                                    if (isPreferenceEditMode) {
                                        handlePreferencesSave(); // Call handleSave only when in edit mode and attempting to save
                                    } else {
                                      handlePreferencesForm(); // Enter edit mode
                                    }
                                    }}>
                                    {isPreferenceEditMode ? '' : <FiEdit3 style={{marginRight: '10px', display:'none'}} />}
                                    {isPreferenceEditMode ? 'Save' : 'Edit'}
                            </Button>
                            )
                        }
   </Flex>
        {isPreferenceEditMode ? (
          <>
        <Grid
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Box  align={'flex-start'}>
          <Text fontWeight={'bold'}>Employment</Text>
          <Text pb={1} color={'grey'}>Select the types of employment opportunities that you are interested in</Text>
            <Box alignSelf={'flex-end'}>
            <Select
            size='large'
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select employment type(s)"
              defaultValue={[]}
              value={selectedEmploymentTypes}
              onChange={setSelectedEmploymentTypes}           
            >
              <Option value="Full-time">Full-time</Option>
              <Option value="Part-time">Part-time</Option>
              <Option value="Contract">Contract</Option>
              <Option value="Temporary">Temporary</Option>
            </Select>
            {/* <Input size='large' defaultValue={userFirstName} onChange={(e) => setUserFirstName(e.target.value)} placeholder="Jane" /> */}
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
      <Text fontWeight={'bold'}>Location</Text>
          <Text pb={1} color={'grey'}>Select the counties that you are open to working in or relocating to</Text>            
          <Box alignSelf={'flex-end'}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            size='large'
            placeholder="Select counties"
            value={selectedCounties}
            onChange={setselectedCounties}
          >
            {countyLocations.map((location) => (
              <Option key={location.id} value={location.county}>{location.county}</Option>
            ))}
          </Select>


            </Box>
        </Box>
      </GridItem>

    </Grid>
        <Grid
        mt={5}
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
            <GridItem w="100%">
        <Box  align={'flex-start'}>
        <Text fontWeight={'bold'}>Salary</Text>
          <Text pb={1} color={'grey'}>The minimum annual salary you're open to</Text>            
            <Box alignSelf={'flex-end'}>
            <InputNumber
            style={{width:'fit-content'}}
              size='large'
              value={minimumSalaryPreference}
              onChange={setMinimumSalaryPreference}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
        <Text fontWeight={'bold'}>School Type</Text>
          <Text pb={1} color={'grey'}>Types of school organizations</Text>                 
          <Box alignSelf={'flex-end'}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              size="large"
              placeholder="Select school types"
              value={selectedSchoolTypes}
              onChange={setSelectedSchoolTypes}
              // onChange={handleChange}
            >
              <Option value="Private">Private</Option>
              {/* <Option value="Magnet">Magnet</Option> */}
              <Option value="Public District">Public District</Option>
              <Option value="Public Charter">Public Charter</Option>
            </Select>
            </Box>
        </Box>
      </GridItem>
    </Grid>
    <Grid
        mt={5}
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(1, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
      <Box  align={'flex-start'}>
      <Text fontWeight={'bold'}>Grades Served</Text>
          <Text pb={1} color={'grey'}>Select the grade levels you are open to supporting</Text>     
          <Box alignSelf={'flex-end'}>

          <Select
            mode="multiple"
            style={{ width: '100%' }}
            size='large'
            placeholder="Select counties"
            value={selectedGrades}
            onChange={setSelectedGrades}
          >
            {[...gradeLevels].sort((a, b) => a.label_id - b.label_id).map((level) => (
            <Option key={level.id} value={level.level}>{level.level}</Option>
          ))}
          </Select>
          </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Text fontWeight={'bold'}>Roles</Text>
          <Text pb={1} color={'grey'}>Select the role focus areas you are most interested in</Text>     
          <Box alignSelf={'flex-end'}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            size='large'
            placeholder="Select roles"
            value={selectedRoles}
            onChange={setSelectedRoles}
          >
            {roles.map((role) => (
            <Option key={role.id} value={role.title}>{role.title}</Option>
            ))}
          </Select>
          </Box>
      </GridItem>
      {(['Teaching'].some(role => selectedRoles.includes(role)) && (

      <GridItem w="100%">
      <Text fontWeight={'bold'}>Subject Matter</Text>
          <Text pb={1} color={'grey'}>Select the subject matters you are most interested in teaching </Text>     
          <Box alignSelf={'flex-end'}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            size='large'
            placeholder="Select counties"
            value={selectedSubjects}
            onChange={setSelectedSubjects}
            // onChange={handleChange}
          >
            {subjectMatters.map((subject) => (
            <Option key={subject.id} value={subject.subject}>{subject.subject}</Option>
            ))}
          </Select>
          </Box>
      </GridItem>
      ))}

    </Grid>
    
    </>
    ) : (
        <>
       <Grid
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(1, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Employment</Text>
            <Box alignSelf={'flex-end'}>
              {userData.employment_type_preference.length > 0 ? (
                userData.employment_type_preference.map((item, index) => (
                  <React.Fragment key={index}>
                    <Tag style={{ fontSize: '16px', marginBottom: '7px' }}>{item.title}</Tag>
                  </React.Fragment>
                ))
              ) : (
                <Text color={'gray'} >No employment preferences selected.</Text>
              )}
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Location (county)</Text>
            <Box alignSelf={'flex-end'}>
              {userData.location_preference.length > 0 ? (
                userData.location_preference.map((item, index) => (
                  <React.Fragment key={index}>
                    <Tag style={{ fontSize: '16px', marginBottom: '7px' }}>{item.county}</Tag>
                  </React.Fragment>
                ))
              ) : (
                <Text color={'gray'} >No locations selected.</Text>
              )}
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
        <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Minmimum Salary</Text>
            <Box alignSelf={'flex-end'}>
            $ {userData.minimum_salary_preference.toLocaleString()}
            </Box>
        </Box>
      </GridItem>
    </Grid>
        <Grid
        mt={5}
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(1, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
        <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>School Type</Text>
            <Box alignSelf={'flex-end'}>
              {userData.school_type_preference.length > 0 ? (
                userData.school_type_preference.map((item, index) => (
                  <React.Fragment key={index}>
                    <Tag style={{ fontSize: '16px', marginBottom: '7px' }}>{item.type}</Tag>
                  </React.Fragment>
                ))
              ) : (
                <Text color={'gray'} >No school types selected.</Text>
              )}
            </Box>
        </Box>
      </GridItem>
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Grades</Text>
            <Box alignSelf={'flex-end'}>
                {userData.grade_level_preference.length > 0 ? (
                userData.grade_level_preference.map((item, index) => (
                  <React.Fragment key={index}>
                    <Tag style={{ fontSize: '16px', marginBottom: '7px' }}>{item.level}</Tag>
                  </React.Fragment>
                ))
              ) : (
                <Text color={'gray'} >No grade levels selected.</Text>
              )}
            </Box>
        </Box>
      </GridItem>
    </Grid>
    <Grid
        mt={5}
      templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }}
      gap={6}
    >
      <GridItem w="100%">
      <Box  align={'flex-start'}>
            <Text fontWeight={'bold'}>Role</Text>
            <Box alignSelf={'flex-end'}>
              {userData.role_preference.length > 0 ? (
                userData.role_preference.map((item, index) => (
                  <React.Fragment key={index}>
                    <Tag  style={{ fontSize: '16px', marginBottom: '7px' }}>{item.title}</Tag>
                  </React.Fragment>
                ))
              ) : (
                <Text color={'gray'} >No roles selected.</Text>
              )}
            </Box>
        </Box>
      </GridItem> 
      {(['Teaching'].some(role => userData.role_preference.includes(role) || selectedRoles.includes(role)) && (
  <GridItem w="100%">
    <Text fontWeight={'bold'}>Teaching Subject Matter</Text>
    <Box>
      {userData.subject_matter_preference.length > 0 ? (
        userData.subject_matter_preference.map((item, index) => (
          <React.Fragment key={index}>
            <Tag  style={{ fontSize: '16px', marginBottom: '7px' }}>{item.subject}</Tag>
          </React.Fragment>
        ))
      ) : (
        <Text color={'gray'}>No subject matter preferences selected</Text>
      )}
    </Box>
  </GridItem>
))}

    </Grid>
      </>
    )}
      </TabPanel>


      
      
      </TabPanels>


      </Tabs>

      <Modal isOpen={isAddWorkExpModalOpen} onClose={onAddWorkExpModalClose}>
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>Invite Team Member</ModalHeader> */}
        <ModalCloseButton />
        <ModalBody pb={6}>


        <Flex justifyContent={'center'} mt={9} mb={3}>
                    <Box w={'50px'} h={'50px'} justifySelf={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={'#BEE3F8'}>
                    <FiBriefcase color='#2A4365' fontSize={'20px'}/>
                    </Box>
                    </Flex>



            <Text fontWeight={'bold'} textAlign={'center'} fontSize={'lg'}>
                 Add Work Experience
            </Text>
            <Text fontSize={'sm'} display={'none'} textAlign={'center'} color={'gray'}>
                Fill out the form below to invite new team members to your organization's account.
             </Text>

        <Box mt={8}>
            <Flex justifyContent={'space-between'}>
            <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >Role
            {
                          !newJobRole &&
                          <span style={{color: 'red'}}> *</span>
            }
            </FormLabel>
            <Input required placeholder="AP math teacher" value={newJobRole} onChange={(e) => setNewJobRole(e.target.value)} />
          </FormControl>

          <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >Organization
                        {
                          !newJobOrg &&
                          <span style={{color: 'red'}}> *</span>
                        }
            </FormLabel>
            <Input required placeholder="Acme education" value={newJobOrg} onChange={(e) => setnewJobOrg(e.target.value)} />
          </FormControl>
            </Flex>
            <Flex mt={4} justifyContent={'space-between'}>
            <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >Start date
            {
                          !newJobStartDate &&
                          <span style={{color: 'red'}}> *</span>
            }
            </FormLabel>
            
            <DatePicker style={{width:'100%'}} required onChange={onStartDateChange} picker="month" />

            {/* <Input required placeholder="01/11/2011" value={newJobStartDate} onChange={(e) => setNewJobStartDate(e.target.value)} /> */}
          </FormControl>

          <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >End date
            {
                          !newJobEndDate &&
                          <span style={{color: 'red'}}> *</span>
            }
            </FormLabel>
            <DatePicker style={{width:'100%'}} onChange={onEndDateChange} picker="month" />
            {/* <Input required placeholder="Leave blank if current" value={newJobEndDate} onChange={(e) => setNewJobEndDate(e.target.value)} /> */}
          </FormControl>
            </Flex>

          <FormControl mt={4}>
          <FormLabel mb={'4px'} >Role Summary</FormLabel>
          <TextArea
            value={newJobSummary} 
            onChange={(e) => setNewJobSummary(e.target.value)}
            size='large'
            placeholder="Lead classroom instruction ..."
            autoSize={{
            minRows: 3,
            maxRows: 5,
            }}
        />
          </FormControl>


        </Box>

        {addJobError && <Text size={'sm'} pt={3} mb={-5} textAlign={'center'} style={{color: 'red'}}>Complete required fields.</Text>}

        </ModalBody>

        <ModalFooter>
          <Button  mr={3} onClick={onAddWorkExpModalClose}>Cancel</Button>
          <Button
            disabled={sendingInvite} // Disable the button while submitting
          colorScheme="blue" onClick={() => AddWorkExp( userData.id)}>
            
          {sendingInvite ? (
            <>
            <Spinner size="xs" mr={2} /> {/* Show a spinner next to the text */}
            Saving
            </>
        ) : (
            <Text>Add Work Experience</Text>
        )}  



          </Button>

        </ModalFooter>
      </ModalContent>
    </Modal>
    <Modal isOpen={isAddeducationModalOpen} onClose={onAddEducationModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pb={6}>


        <Flex justifyContent={'center'} mt={9} mb={3}>
                    <Box w={'50px'} h={'50px'} justifySelf={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={'#BEE3F8'}>
                    <LuGraduationCap color='#2A4365' fontSize={'30px'}/>
                    </Box>
                    </Flex>



            <Text fontWeight={'bold'} textAlign={'center'} fontSize={'lg'}>
                 Add Education
            </Text>
            <Text fontSize={'sm'} display={'none'} textAlign={'center'} color={'gray'}>
                Fill out the form below to add education to your profile.
             </Text>

        <Box mt={8}>
            <Flex justifyContent={'space-between'}>
            <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >Major
            {
                          !newEdMajor &&
                          <span style={{color: 'red'}}> *</span>
            }
            </FormLabel>
            <Input required placeholder="Math" value={newEdMajor} onChange={(e) => setNewEdMajor(e.target.value)} />
          </FormControl>

          <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >School or Organization
            {
                          !newEdOrg &&
                          <span style={{color: 'red'}}> *</span>
            }
            </FormLabel>
            <Input required placeholder="University of Acme" value={newEdOrg} onChange={(e) => setnewEdOrg(e.target.value)} />
          </FormControl>
            </Flex>

            <Flex mt={4} justifyContent={'space-between'}>
            <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >Accreditation
            </FormLabel>
            <Select 
                  options={[
                    {
                      value: 'AA',
                      label: 'AA',
                    },
                    {
                      value: 'AS',
                      label: 'AS',
                    },
                    {
                      value: 'AAS',
                      label: 'AAS',
                    },
                    {
                      value: 'BA',
                      label: 'BA',
                    },
                    {
                        value: 'BS',
                        label: 'BS',
                    },
                    {
                        value: 'MA',
                        label: 'MA',
                    },
                    {
                        value: 'MS',
                        label: 'MS',
                    },
                    {
                        value: 'MBA',
                        label: 'MBA',
                    },
                    {
                        value: 'MPA',
                        label: 'MPA',
                    },
                    {
                        value: 'MEd',
                        label: 'MEd',
                    },
                    {
                        value: 'PhD',
                        label: 'PhD',
                    },
                    {
                        value: 'MD',
                        label: 'MD',
                    },
                    {
                        value: 'EdD',
                        label: 'EdD',
                    },
                    {
                        value: 'LLB',
                        label: 'LLB',
                    },
                    {
                        value: 'LLM',
                        label: 'LLM',
                    },
                    {
                        value: 'MPP',
                        label: 'MPP',
                    },
                    {
                        value: 'Certificate',
                        label: 'Certificate',
                    },
                  ]}
                  dropdownStyle={{ zIndex: 999999999999999 }}
                  style={{width:'100%'}} required onChange={onNewEducationaccreditationChange}/>

          </FormControl>

          <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >GPA
            </FormLabel>
            <InputNumber
                        style={{
                        width: '100%',
                        }}
                        placeholder='3.0'
                        defaultValue={newEdGPA}
                        onChange={onNewEducationGPAChange}
                        min="0"
                        max="10"
                        step="0.01"
                        stringMode
                        disabled={loadingEditedEd}
                    />          </FormControl>
            </Flex>








            <Flex mt={4} justifyContent={'space-between'}>
            <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >Start date
            {
                          !newEdStartDate &&
                          <span style={{color: 'red'}}> *</span>
            }
            </FormLabel>
            <DatePicker style={{width:'100%'}} required onChange={onEducationStartDateChange} picker="month" />
          </FormControl>

          <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >End date
            {
                          !newEdEndDate &&
                          <span style={{color: 'red'}}> *</span>
            }
            </FormLabel>
            <DatePicker style={{width:'100%'}} required onChange={onEducationEndDateChange} picker="month" />
          </FormControl>
            </Flex>

          <FormControl mt={4}>
          <FormLabel mb={'4px'} >About</FormLabel>
          <TextArea
            value={newEdAbout} 
            onChange={(e) => setNewEdAbout(e.target.value)}
            placeholder="Add additional details about your education here ..."
            autoSize={{
            minRows: 2,
            maxRows: 5,
            }}
        />
          </FormControl>

          <FormControl mt={4}>
          <FormLabel mb={'4px'} >Extracurriculars</FormLabel>
          <TextArea
            value={newEdExtracurriculars} 
            onChange={(e) => setNewEdExtracurriculars(e.target.value)}
            placeholder="Add details about extracurricular activities here ..."
            autoSize={{
            minRows: 2,
            maxRows: 5,
            }}
        />
          </FormControl>

        </Box>
        {addEdError && <Text size={'sm'} pt={3} mb={-5} textAlign={'center'} style={{color: 'red'}}>Complete required fields.</Text>}

        </ModalBody>

        <ModalFooter>
          <Button  mr={3} onClick={onAddEducationModalClose}>Cancel</Button>
          <Button
            disabled={sendingInvite} // Disable the button while submitting
          colorScheme="blue" onClick={() => AddEducation( userData.id)}>
            
          {sendingInvite ? (
            <>
            <Spinner size="xs" mr={2} /> {/* Show a spinner next to the text */}
            Saving...
            </>
        ) : (
            <Text>Save</Text>
        )}  
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>



    </Box>

    
  );
}

export default UserProfileSection;
