import React, { useEffect, useState } from 'react';
import {
  Box, Drawer, DrawerContent, DrawerOverlay,DrawerBody,
  Button,
  Image, Spinner,
  Heading, Tabs, Tab, TabIndicator, TabPanel, TabPanels,
  Text, TabList,
  useDisclosure
} from '@chakra-ui/react';
import { AiOutlineClose } from 'react-icons/ai';
import { FiFileText, FiClock } from "react-icons/fi";
import { Timeline } from 'antd'; // Import Ant Design Timeline

function AdminJobScreen({
    isOpen,
  onClose,
  job,
}) {
    const [historyObjects, setHistoryObjects] = useState([]);
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);


useEffect(() => {
    if (!job || !isOpen) return; // Fetch only if job is available and drawer is open

    const url = new URL("https://www.pursueli.com/api/admin_get_job_history/");
    url.searchParams.append("jobId", job.id);
    // Fetch the upcoming events
    fetch(url, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
            'Content-Type': 'application/json',
        }
      })          
      .then((response) => response.json())
      .then((data) => {
        setHistoryObjects(data.job_history); // Update state with fetched data
        setIsLoadingHistory(false)
      })
    .catch((error) => console.error("Error fetching upcoming events:", error));

  }, [job, isOpen]);



  
   
  return (

      <>
    <Drawer isOpen={isOpen} placement='right' onClose={onClose} size={'lg'}>

          <DrawerOverlay />
          <DrawerContent >
            
        
            <DrawerBody overflowY="scroll" maxHeight={'100vh'}>
            <Tabs   marginTop={'100px'}>

            <Box marginTop="5px"></Box>

            <Box position="fixed" top={0} width="100%" zIndex="docked" backgroundColor="#FFFFFF">
            <Box display={'flex'} mb={'5px'} mt={5} marginRight={10} justifyContent={'space-between'} >

            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'}  alignItems={'center'}>
              <Box width={'60px'} marginRight={3}>
                  <Image cursor={'pointer'} width="100%"  object-fit="cover" borderRadius='50%' aspectRatio={{ base: "1", md: "1" }} src={job.school_sites[0].logo} alt="Event" />
                  </Box>
                  <Box  alignSelf={'center'} >
                      <Heading  as="h2" size="md"  /* other style props */>
                {job.title}
                </Heading>                    
            <Box fontSize={'16px'} alignSelf={'flex-start'} fontWeight={500} textAlign={'start'} color={'grey'}>
                      {job.school_sites[0].school_name}
                    </Box>
                  </Box>
              </Box>






            <Button onClick={onClose} size="md" variant="ghost" colorScheme="gray">
                <AiOutlineClose />
            </Button>
        </Box>

        <TabList mt={5} w={'92%'} >
      <Tab >
      <FiFileText style={{marginRight:'10px'}} />

        Job Post
      </Tab>
      <Tab>
      <FiClock  style={{marginRight:'10px'}} />
        Post History
      </Tab>
      {/* <Tab>Three</Tab> */}
    </TabList>


        </Box>
           
            <style>
        {`
            .hide-scrollbar::-webkit-scrollbar {
                display: none;
            }
            .hide-scrollbar {
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }
        `}
    </style>



    <TabIndicator
      mt="-1.5px"
      height="2px"
      bg="blue.500"
      borderRadius="1px"
    />
    <TabPanels>
      <TabPanel p={0} pt={3}>
        
      <Heading  as="h2" pt={5} size="md" mt={10}  /* other style props */>
                Summary
                </Heading> 

                <Box mt="2" style={{ whiteSpace: 'pre-wrap', paddingTop:'15px' }}>
                    {job.description}
                </Box>
              <Heading  as="h2" size="md" mt={10} /* other style props */>
                Responsibilities
              </Heading> 
              <Box>
              <div style={{padding:'20px'}} dangerouslySetInnerHTML={{ __html: job.requirements }}></div>
              </Box>
              <Heading  as="h2" size="md" mt={10} /* other style props */>
              Qualifications
              </Heading> 
              <Box>
              <div style={{padding:'20px'}} dangerouslySetInnerHTML={{ __html: job.qualifications }}></div>
              </Box>
        
      </TabPanel>
      <TabPanel p={0} style={{paddingTop:'80px'}}>
      {isLoadingHistory ? (
                    <Spinner />
                  ) : (
                    <Timeline>
                    {historyObjects.map((object, index) => (
                      <Timeline.Item key={index}>
                        <Box>
                        <Text fontSize={'16px'} fontWeight="bold">{object.action_text}</Text>
                          <Text >{new Date(object.action_date).toLocaleDateString()}</Text>
                        </Box>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                  
                  )}
      </TabPanel>
      {/* <TabPanel>
        <p>three!</p>
      </TabPanel> */}
    </TabPanels>



    </Tabs>

            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </>
    )
  }
  export default AdminJobScreen;
