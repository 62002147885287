import { useState } from 'react';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack, Spinner,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';

export default function TalentCollectiveSetPassord() {

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const handleToggleShowPassword = () => setShowPassword(!showPassword);
    const fontSize = showPassword ? '16px' : '16px';

    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');
    

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [authenticated, setAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); // For navigation after login



  const handleSubmit = async () => {
    if (password !== password2) {
      alert("Passwords do not match");
      return;
    }
  
    const authToken = localStorage.getItem('authToken');
  
    setIsLoading(true);
    setLoadingText("Saving your password...");
  
    const data = {
      password: password,
      password2: password2,
    };
  
    try {
      const response = await fetch("https://www.pursueli.com/api/set_password/", {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const result = await response.json();
  
      // Handle success, for example:
      console.log("Password updated successfully:", result);
  
      // Navigate to home or a success page
      navigate('/home');
    } catch (error) {
      console.error("Failed to set the password:", error);
      setErrorMessage("Failed to set the password. Please try again.");
    } finally {
      setIsLoading(false);
      setLoadingText('');
    }
  };
  
  


  return (
    <Flex
      minH="70vh"
      align="center"
      justify="center"
      overflow={'hidden'}
      // bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx="auto"  py={8} px={6}
                // marginTop={{ base: '', md: '-200px' }}

                
      >
        <Stack align="center">
          <Heading fontSize="3xl" textAlign="center">
            Welcome to the edpursuit talent collective, Robert 👋
          </Heading>
          <Text fontSize="lg" color="gray.600">
            Set a password below to secure your account.
          </Text>
        </Stack>
        <Box
        >

<Flex alignItems={'center'} justifyContent={'center'} gap={5} mb={4}  w={'100%'} >
<Button variant={'ghost'} onClick={handleToggleShowPassword}>
            {showPassword ? <ViewOffIcon /> : <ViewIcon />}
          </Button>
        <Input
          ml={0}
          type={showPassword ? 'text' : 'password'}
          name="password"
          width={{ base: '100%', md: '250px' }}
        //   size='large'
          style={{ fontSize }}
          placeholder="Create password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          type={showPassword ? 'text' : 'password'}
          name="password2"
          width={{ base: '100%', md: '250px' }}
        //   size='large'
          style={{ fontSize }}
          placeholder="Confirm password"
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
        />

<Button colorScheme='blue' variant='solid' onClick={handleSubmit}
            //   isDisabled={isLoading} // Disable button when isLoading is true
            >
              {isLoading ? <Spinner color='white' /> : "Save"}
            </Button>

        </Flex>





          
        </Box>
      </Stack>
    </Flex>
  );
}
