import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { DatePicker,Select,Space, Button, Card, Tag,Modal, FloatButton} from 'antd';
import { BiSolidPalette, BiSolidDrink } from "react-icons/bi";
import { AiOutlineClose, AiOutlineShareAlt,AiOutlineHeart,AiOutlineLike, AiOutlineSelect, AiOutlineCalendar, AiFillHeart, AiOutlineMail} from "react-icons/ai";
import { BsHandThumbsUp } from "react-icons/bs";
import { PiBookmarkBold } from "react-icons/pi";
import { MdOutlineMoneyOffCsred, MdOutlineVisibilityOff } from "react-icons/md";
import { BsLightningCharge } from "react-icons/bs";
import Slider from 'react-infinite-logo-slider'

import {
  EmailShareButton, FacebookShareButton,  TwitterShareButton, WhatsappShareButton,
} from "react-share";
import { FaXTwitter, FaFacebookF, FaWhatsapp, FaRegCopy } from "react-icons/fa6";

import {
  Box,  Image, Menu, MenuButton, MenuItem, MenuList,
  Grid, Heading, Badge, GridItem,
  Drawer, Spinner, CircularProgress,
  useDisclosure, useMediaQuery,
  DrawerBody,Flex,
  DrawerOverlay, Img,
  DrawerContent, ModalBody, ModalCloseButton, ModalOverlay,ModalContent, ModalHeader, ModalFooter
} from '@chakra-ui/react';

import { TbPigOff } from "react-icons/tb";
import WebFont from 'webfontloader';
import Carousel from '../../../components/carousel';
import filters from '../../../components/outivity_search_filters';
import UploadResumeTalentCollectiveReferral from '../../../components/talent_collective_referral_resume_upload';



  

function TalentNetworkReferralPageAdmin() {

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Kurale', 'Inter:300,400,600,700']  // Including Inter with specific weights
      }
    });
  }, []);
  console.log("Haligekugh")
  const { Option } = Select;

  const [roleType, setRoleType] = useState('all');
  const [county, setCounty] = useState('all');
  const [employmentType, setEmploymentType] = useState('all');
  const [minSalary, setMinSalary] = useState('0');

    const [isAuthenticated] = useState(localStorage.getItem('authToken') !== null);



    const isInitialMount = useRef(true); // Ref to track initial mount
    const [jobs, setJobs] = useState([]);
    const [userAppliedJobs, setUserAppliedJobs] = useState([]);

    const [loading, setLoading] = useState(false)

    const [dateOne, setDateOne] = useState('')
    const [dateTwo, setDateTwo] = useState('')


    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // New state for total pages

    const [hasMore, setHasMore] = useState(true);
    const dateFormat = 'MMM D, YYYY';



    const defaultStyles = `
    .customRangePicker .ant-picker-input input {
        font-size: 0.875rem;
        font-weight: 600;
    }
    .customRangePicker .ant-picker-range-separator .anticon svg {
        color:black;
    }
    .ant-picker-suffix .anticon-calendar svg {
        color:black;
        margin-right:15px;
    }

    .ant-picker-clear .anticon-close-circle
    `;
    
    const blackStyles = `
    .customRangePicker .ant-picker-input input {
        font-size: 0.875rem;
        font-weight: 600;
    }
    .customRangePicker .ant-picker-range-separator .anticon svg {
        color:rgba(0, 0, 0, 0.25);
    }
    .ant-picker-suffix .anticon-calendar svg {
        color:rgba(0, 0, 0, 0.25);
    }
    `;

  const navBar = `
  .sticky-top-navbar {
    position: sticky;
    top: 0;
    z-index: 1000; /* Adjust z-index as needed */
    /* Add other styles for your top navbar */
  }
  `;
const customStyles = dateOne === '' && dateTwo === '' ? blackStyles : defaultStyles;

    // Fetch Events
    const fetchEvents = (page) => {
      setJobs([])

      setLoading(true);

        console.log("coming to fetch")
        const filterData = {
          roleType,
          county,
          employmentType,
          minSalary,
          page
        };
        
        const queryParams = new URLSearchParams(filterData).toString();
        const url = `https://www.pursueli.com/api/all_public_jobs/?${queryParams}`;
        fetch(url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.json())
        .then((data) => {
          
            if (data.status === "noData") {
              console.log("no data coming")
                // If status is "noData", only update the hasMore state
                setHasMore(false);
            } else {
              console.log("some data coming")
                // If status is not "noData", update both jobs and hasMore
                // setJobs(prevEvents => [...prevEvents, ...data.results.jobs]);
                setJobs(data.results.jobs)
                // setCurrentPage(page);
                setTotalPages(data.results.total_pages); // Assuming your backend sends total_pages
                // setJobs(data.results.jobs)
                console.log(data)
                setHasMore(true);
            }
            setUserAppliedJobs(data.results.user_applied_jobs)
            console.log("This is the data ", data.results.user_applied_jobs)
        })
        .catch((error) => console.error("Error fetching upcoming events:", error))
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      fetchEvents(currentPage);
    }, [currentPage]); 

    useEffect(() => {
      fetchEvents(1);
    }, [roleType, county, employmentType, minSalary]); 

    useEffect(() => {
        if (currentPage > 1 && hasMore == true) {
            fetchEvents(currentPage);
        }
    }, [currentPage]);

    const { RangePicker } = DatePicker;

    const hideScroll = `
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
  }
  .hide-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
  }
    `;



    const schoolLogos = [
        { school: 'Navigator Charter School', imageUrl: 'https://media.licdn.com/dms/image/v2/C4D1BAQHnhgNh1zJGuw/company-background_1536_768/company-background_1536_768/0/1583157370591?e=2147483647&v=beta&t=3Nps1ctO-Qx-uhnso9Bm-acomSuiq1yyWk5u96-MAYI'},
        { school: 'Voices Charter School', imageUrl: 'https://media.licdn.com/dms/image/v2/C4E0BAQHHXTDAmjXoSg/company-logo_200_200/company-logo_200_200/0/1631306540867?e=1732147200&v=beta&t=wvRnZUPq_BGe_Jwb6DHBm8X7nEqqK15hDg_R5CR23k8'},
        { school: 'Contra Costa School of Performing Arts', imageUrl: 'https://cdn3.locable.com/uploads/resource/file/952082/fill/700x0/SPA_20Logo.png?timestamp=1664409278'},
        { school: 'The Foundation for Hispanic Education', imageUrl: 'https://pursueli.s3.amazonaws.com/static/images/tfhe_footer_logo-d.png'},
        { school: 'ACE', imageUrl: 'https://www.acecharter.org/wp-content/uploads/2021/07/Ace_Logo_Red_Rev.png'},
      ];






  return (



    <Box minWdth={'100%'}>
    
<Box paddingLeft={{  }} maxH={'50vh'}>

  
<style>{customStyles}</style>
<style>
{navBar}
</style>
<style>
{hideScroll}
</style>

<Grid
    mx={{
      base: '20px', // 12 columns on small screens
      md: '10%',  // 10 columns on medium screens
    }}
    templateColumns={{
      base: 'repeat(1, 1fr)', // 12 columns on small screens
      md: 'repeat(1, 1fr)',  // 10 columns on medium screens
      lg: 'repeat(1, 1fr)'    // 8 columns on large screens
    }}
    justifyContent={'center'}
    justifySe={'center'}
    textAlign={'center'}
>
<GridItem 
    colStart={1}
    justifyContent="center"
    width={{base:'100%', md:'80%', lg:'100%'}} 

    maxWidth="100%" // Set a max-width if you want to limit how wide the grid can get
    mx="auto" // This centers the grid in its parent
 >
<Box 
mt={{base:'60px',md:'100px'}} 
style={{ fontFamily: 'Inter' , fontWeight: '800'}}
fontSize={{ base: "30px", md: "44px" }}
lineHeight={{base:'1.2em', md:'1em'}}
textAlign={{ base: "start", md: "center" }}
>
Your dream job in education awaits.
</Box>
<Box fontSize={{ base: "18px", md: "22px" }} textAlign={{ base: "start", md: "center" }}  mt={5} style={{ fontFamily: 'Inter'}}>
Join the Edpursuit Talent Network to have your resume matched with job opportunities at local public schools.
</Box>
</GridItem>







<Flex mx={'auto'} w={{base:'100%', md:'60%'}} mt={6}>
<UploadResumeTalentCollectiveReferral/>

</Flex>

</Grid>

<br></br>
<br></br>
<br></br>
<br></br>

<Slider
    width="250px"
    duration={ 120}
    pauseOnHover={false}
    blurBorders={true}
    blurBoderColor={'#fff'}
    toRight={false} // or direction="right" depending on the library

  >
    {schoolLogos.map((school, index) => (
      <Slider.Slide key={index} style={{justifyContent:'center', width:'250px'}}>
        <div className="" style={{marginTop:'20px', justifyContent:'center'}}>
          <img style={{height:'70px', width:'auto'}} src={school.imageUrl} alt={school.school} className="" />
        </div>
      </Slider.Slide>
    ))}
  </Slider>
  <br></br>
  <br></br>



<Grid
    mx={{
      base: '20px', // 12 columns on small screens
      md: '10%',  // 10 columns on medium screens
    }}
  mt={7}
  templateColumns={{
    base: 'repeat(1, 1fr)', // 1 column on small screens
    md: 'repeat(3, 1fr)',  // 3 columns on medium and larger screens
  }}
  justifyContent="center"
  textAlign="center"
  gap={4}
>
  <GridItem display="flex">
    <Box 
      style={{ fontFamily: 'Inter', fontWeight: '600' }}
      fontSize={{ base: '24px', md: '1.6rem' }}
      display="flex"
      flexDirection="column"
      shadow={'lg'}
      background={'transparent'}
      borderRadius={'8px'}
      flex={1}
      _hover={{ transform: 'translate(0px, -10px)', transition: 'transform 0.3s ease-in-out' }}
    >
      <Card flex={1} display="flex" flexDirection="column">
      <MdOutlineMoneyOffCsred style={{fontSize:'20px', color:'gray'}} />

        <Box textAlign={'start'} display={'flex'} alignItems={'baseline'}>
          <Box textAlign={'start'} color={'gray'} fontWeight={'bold'} fontSize={'18px'}>Free to sign up</Box>
        </Box>
        <Box textAlign={'start'}>edpursuit will always be free for educators, school administrators, and support staff.</Box>
        <Box style={{color: 'transparent'}}
              display={{ base: 'none', md: 'block' }}

        
        >.</Box>      </Card>
    </Box>
  </GridItem>
  <GridItem display="flex">
    <Box 
      style={{ fontFamily: 'Inter', fontWeight: '600' }}
      fontSize={{ base: '24px', md: '1.6rem' }}
      display="flex"
      shadow={'lg'}
      background={'transparent'}
      borderRadius={'8px'}
      flexDirection="column"
      flex={1}
      _hover={{ transform: 'translate(0px, -10px)', transition: 'transform 0.3s ease-in-out' }}
    >
      <Card flex={1} display="flex" flexDirection="column" shadow={'sm'}>
        <MdOutlineVisibilityOff style={{fontSize:'20px', color:'gray'}} />
        <Box textAlign={'start'} display={'flex'} alignItems={'baseline'}>
          <Box textAlign={'start'} color={'gray'} fontWeight={'bold'} fontSize={'18px'}>Control profile visibility</Box>
        </Box>
        <Box textAlign={'start'}>You control which schools are able to view your profile. Create a profile, see your worth on your terms.</Box>      
      </Card>
    </Box>
  </GridItem>
  <GridItem display="flex">
    <Box 
      style={{ fontFamily: 'Inter', fontWeight: '600' }}
      fontSize={{ base: '24px', md: '1.6rem' }}
      display="flex"
      flexDirection="column"
      shadow={'lg'}
      background={'transparent'}
      borderRadius={'8px'}
      flex={1}
      _hover={{ transform: 'translate(0px, -10px)', transition: 'transform 0.3s ease-in-out' }}
    >
      <Card flex={1} display="flex" flexDirection="column">
      <BsLightningCharge style={{fontSize:'20px', color:'gray'}} />
        <Box textAlign={'start'} display={'flex'} alignItems={'baseline'}>
          <Box color={'gray'} textAlign={'start'} fontWeight={'bold'} fontSize={'18px'}>Land interviews without stress</Box>
        </Box>
        <Box textAlign={'start'}>Discover which schools would be interested in your profile without the stress of applying.
        <Box style={{color: 'transparent'}}
              display={{ base: 'none', md: 'block' }}

        
        >.</Box>
        </Box>      
      </Card>
    </Box>
  </GridItem>
</Grid>






    
    </Box>

<footer className="App-footer" style={{width:'100%',height:'30vh', bottom:0, position:'fixed', zIndex:'-1',
backgroundImage: 'url("https://i.ibb.co/q9ndMKr/2.png")',
backgroundSize: 'contain', // Cover the entire div
backgroundPosition: 'center' 
}}>
  <Box textAlign="center" fontSize="xl">
    {/* <Text mb={4}>Welcome to Outivity</Text> */}
  </Box>
</footer>
</Box>
  );
}



export default TalentNetworkReferralPageAdmin;
