import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box, CircularProgress,
  Tabs,
  TabList, Flex, 
  TabPanels,
  Tab, Input,
  TabPanel,
  Button, 
  Textarea,FormControl,
  Center, Spinner,
  VStack, useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { FiFolder } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { UploadSingleResume } from './upload_resume';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

function UploadResumeTalentCollectiveReferral() {
    
    const [userEmail, setUserEmail] = useState('');
    const [authenticated, setAuthenticated] = React.useState('');
    const toast = useToast();

    const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleToggleShowPassword = () => setShowPassword(!showPassword);
  const fontSize = showPassword ? '16px' : '16px';

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const navigate = useNavigate();

  const inputFileRef = React.useRef(null);

  const handleFileInputClick = () => {
    inputFileRef.current.click();
  };

  const [images, setImages] = React.useState([]);

  const handleImagesChange = (updatedImages) => {
    setImages(updatedImages);
};

const eventData = {
    images,
  };



  const handleSubmit = async () => {
    
    const searchParams = new URLSearchParams(window.location.search);
    const sourceParam = searchParams.get('src');
    let authToken = localStorage.getItem('authToken');
    const formData = new FormData();
  
    setIsLoading(true);
    setLoadingText("Saving your information. This may take up to 30 seconds ...");
    formData.append('email', userEmail);
    formData.append('source', sourceParam);
    if (images.length > 0) {
      formData.append('resume', images[0].file);
    }
  
    try {
      const response = await fetch("https://www.pursueli.com/api/talent_network_school_referral_sign_up/", {
        method: 'POST',
        headers: authToken ? { 'Authorization': `Bearer ${authToken}` } : {},
        body: formData,
      });
  
      if (!response.ok) throw new Error(`Error: ${response.status}`);
      const data = await response.json();
  
      console.log("Profile update response:", data);
  
      const registrationData = {
        email: userEmail,
        password: data.password,
      };
  
      try {
        const loginResponse = await fetch('https://www.pursueli.com/api/login/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(registrationData),
        });
  
        if (!loginResponse.ok) throw new Error(`Login failed: ${loginResponse.status}`);
        const loginData = await loginResponse.json();
  
        console.log("Login response:", loginData);
  
        // Store tokens and update states
        localStorage.setItem('authToken', loginData.token);
        localStorage.setItem('userPhoto', loginData.user_profile_photo_url);
        localStorage.setItem('authenticated', 'True');
        setAuthenticated(true);
  
        console.log("User logged in");
        setLoadingText("Creating your talent collective profile...");
  
        // AI code
        const baseUrl = "hhttps://www.pursueli.com/api";
        authToken = localStorage.getItem('authToken');
        const text = data.text; // Assuming 'data.text' is correct
  
        // Run the AI requests sequentially
        try {
          const workExpResponse = await fetch(`${baseUrl}/talent_network_school_referral_sign_up_update_work_exp/`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text }),
          });
  
          if (!workExpResponse.ok) {
            throw new Error(`Error in work experience update: ${workExpResponse.status}`);
          }
          const workExpData = await workExpResponse.json();
          console.log("Work experience AI response:", workExpData);
  
          const eduExpResponse = await fetch(`${baseUrl}/talent_network_school_referral_sign_up_update_education/`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ text }),
          });
  
          if (!eduExpResponse.ok) {
            throw new Error(`Error in education update: ${eduExpResponse.status}`);
          }
          const eduExpData = await eduExpResponse.json();
          console.log("Education AI response:", eduExpData);
  
          toast({
            title: 'Success.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
  
          console.log("Both processes completed. Navigating to home screen...");
          navigate('/talent_collective_set_password/');
        } catch (error) {
          console.error("An error occurred while processing the AI requests:", error);
        }
  
        setIsLoading(false);
      } catch (loginError) {
        console.error("Failed to log in the user:", loginError);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to submit the form:", error);
      setIsLoading(false);
    }
  };
  


  const [secondsLeft, setSecondsLeft] = useState(30);


  
    const [progress, setProgress] = useState(100); // Start at 100%
  
    useEffect(() => {
        if (isLoading) {
          const timer = setInterval(() => {
            setSecondsLeft((prev) => prev - 1);
            setProgress((prev) => prev - (100 / 30)); // Decrease progress proportionally for 45 seconds
          }, 1000);
      
          if (secondsLeft === 0) {
            clearInterval(timer);
            setProgress(0); // Ensure progress reaches 0 when time is up
          }
      
          return () => clearInterval(timer);
        }
      }, [isLoading, secondsLeft]);
  


  return (
    <ChakraProvider backgroundColor={'red'}>
      <Center width={'100%'} >
        <Box width="100%"  borderRadius="lg">

        {images.length > 0 && (
            <Flex alignItems={'center'} justifyContent={'center'} mb={4}  w={'100%'} >
          <Input
          mr={3}
          type='email'
          name="email"
          width={{ base: '100%', md: '50%' }}
        //   size='large'
          style={{ fontSize }}
          placeholder="type email address here ..."
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
            <Button colorScheme='blue' variant='solid' onClick={handleSubmit}
              isDisabled={isLoading} // Disable button when isLoading is true
            >
              {isLoading ? <Flex direction="row" align="center" justify="center" >
              <CircularProgress value={progress} size="30px" thickness="8px" 
               color="#999"     // Color of the progress indicator
               trackColor="white" // Color of the background track
              >
                {/* <Box mt={4}>
                  {secondsLeft > 0 ? `${secondsLeft} seconds remaining` : 'Done!'}
                </Box> */}
              </CircularProgress>
              <Box ml={3}>
                submitting can take up to 30 seconds
              </Box>
            </Flex> : "Join edpursuit"}
            </Button>
            </Flex>

          )}






          
                    <UploadSingleResume onImagesChange={handleImagesChange} />

          

          <Box  mt={'-30px'} >{loadingText}</Box>

          <Box display={'flex'} justifyContent={'center'} mb={1}>


        </Box>
        </Box>
      </Center>
    </ChakraProvider>
  );
}

export default UploadResumeTalentCollectiveReferral;
