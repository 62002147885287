import React, { useEffect, useState, useRef } from 'react';
import { format } from 'date-fns';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { DatePicker,Select,Space, Button, Tag,Modal, FloatButton} from 'antd';
import { BiSolidPalette, BiSolidDrink } from "react-icons/bi";
import { AiOutlineClose, AiOutlineShareAlt,AiOutlineHeart,AiOutlineLike, AiOutlineSelect, AiOutlineCalendar, AiFillHeart, AiOutlineMail} from "react-icons/ai";
import { BsHandThumbsUp } from "react-icons/bs";
import { PiBookmarkBold } from "react-icons/pi";

import {
  EmailShareButton, FacebookShareButton,  TwitterShareButton, WhatsappShareButton,
} from "react-share";
import { FaXTwitter, FaFacebookF, FaWhatsapp, FaRegCopy } from "react-icons/fa6";

import {
  Box,  Image, Menu, MenuButton, MenuItem, MenuList,
  Grid, Heading, Badge, GridItem,
  Drawer, Spinner,
  useDisclosure, useMediaQuery,
  DrawerBody,Flex,
  DrawerOverlay, Img,
  DrawerContent, ModalBody, ModalCloseButton, ModalOverlay,ModalContent, ModalHeader, ModalFooter
} from '@chakra-ui/react';

import Carousel from '../../components/carousel';
import filters from '../../components/outivity_search_filters';
import { TbPigOff } from "react-icons/tb";
import WebFont from 'webfontloader';
import CCSAFooter from '../../components/ccsa_footer';



function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, 'MMM dd @ h aaaa').replace('AM', 'a.m.').replace('PM', 'p.m.');
  }

  function formatNumber(number) {
    return new Intl.NumberFormat('en-US').format(number);
  }

  function ShareModal({ isOpen, onClose, title, url }) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay  />
        <ModalContent  position={'absolute'} right={6} marginTop={'52px'} width={'fit-content'}>
          {/* <ModalHeader>Share this Event</ModalHeader>
          <ModalCloseButton /> */}
          <ModalBody width={'fit-content'}>
           
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems:'center', position:'relative',top:'2px', gap: '10px' }}>

            <Box marginRight={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <EmailShareButton    url={url} subject={title} body="Check this out!">
                <FaRegCopy size={20}  />
              </EmailShareButton>
            </Box>



              {/* Email Share */}
              <Box marginRight={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <EmailShareButton mr={5} url={url} subject={title} body="Check this out!">
                <AiOutlineMail size={20}  />
              </EmailShareButton>
              </Box>

              {/* WhatsApp Share */}
              <Box marginRight={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <WhatsappShareButton url={url} title={title}>
                <FaWhatsapp size={20} />
              </WhatsappShareButton>
              </Box>

              {/* Twitter Share */}
              <Box marginRight={'10px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <TwitterShareButton url={url} title={title}>
                <FaXTwitter size={20}/>
              </TwitterShareButton>
              </Box>

              <Box  display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <FacebookShareButton url={url} title={title}>
                <FaFacebookF size={20}/>
              </FacebookShareButton>
              </Box>

            </Box>



          </ModalBody>

        </ModalContent>
      </Modal>
    );
  }


  function DrawerExample({job, handleDelete, handleSave, handleLike, checkIfLiked, isAuthenticated, userAppliedJobs, setUserAppliedJobs }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
    const [setIsHovered] = useState(false);
    const [isApplying, setIsApplying] = useState(false); // State to track the loading state of the apply button

    const {
      isOpen: isShareOpen,
      onOpen: onShareOpen,
      onClose: onShareClose
    } = useDisclosure();

    const handleShare = (title, text, url) => {
      if (navigator.share) {
        navigator.share({
          title: job.title,
          text: 'Check out this website!',
          url: job.id
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
      } else {
        onShareOpen(); // Open the fallback modal
      }
    };
    const handleApply = async () => {
      if (!isAuthenticated) {
          alert("Please log in to apply");
          return;
      }
      setIsApplying(true); // Set loading to true when request starts
      try {
          const response = await fetch('https://www.pursueli.com/api/apply/', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  // Assuming you store the user's token in localStorage
                  'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
              },
              body: JSON.stringify({
                  job_id: job.id,
              }),
          });

          if (response.ok) {
            setIsApplying(false); // Set loading to false when request ends
              const data = await response.json();
              const application_id = data
              console.log('Application successful:', application_id);
              fetch('https://www.pursueli.com/api/enrich_application/', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      // Assuming you store the user's token in localStorage
                      'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                  },
                  body: JSON.stringify({
                      application_id: application_id,
                  }),
              });
              console.log("post complete")
              setUserAppliedJobs(prevUserAppliedJobs => [...prevUserAppliedJobs, job.id]);
          } else {
              throw new Error('Failed to apply for job.');
          }
      } catch (error) {
          console.error('Error applying for job:', error);
          alert("Error applying for job.");
      }
  };
    return (
      <>
        <Box cursor={'pointer'} onClick={onOpen} fontSize={'16px'} fontWeight="800" display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Box textDecoration={'underline'} textAlign={'start'} noOfLines={1}  maxWidth={'fit-content'} mr={2}>{job.title} 
      </Box>
   
      {/* <Box display={'flex'} size={'md'} onClick={() => handleLike(job)}>
                {checkIfLiked(job) ? <AiFillHeart color={'red'} /> : <PiBookmarkBold  color='grey'/>}        
                   </Box> */}



      </Box>
      {/* <Image cursor={'pointer'} onClick={onOpen} width="100%" display="none" filter="brightness(0.3)" object-fit="cover" aspectRatio={{ base: "1.6", md: "1.25" }} src={events[0].marketing_photos[0].photo} alt="Event" /> */}

        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
          finalFocusRef={btnRef}
          size={'lg'}
        >
          <DrawerOverlay />
          <DrawerContent >
            
        
            <DrawerBody overflowY="scroll" maxHeight={'95vh'}>
            <Box marginTop="5px"></Box>

            <Box position="fixed" top={0} width="100%" zIndex="docked" backgroundColor="#FFFFFF">
            <Box display={'flex'} mb={'5px'} mt={5} marginRight={10} justifyContent={'space-between'} >


  

            <Box display={'flex'} mb={'5px'} justifyContent={'end'}  >
                {/* <Button  style={{display:'flex', marginRight:'20px'}} onClick={() => handleLike(job)}>
                {checkIfLiked(job) ? <AiFillHeart color={'red'} /> : <AiOutlineHeart />}
                <Box textDecoration={'underline'} ml={'10px'} >
                     {checkIfLiked(job) ? "Unlike" : "Like"}
                   </Box>               
                   </Button> */}
                {/* <Button display={'flex'} size={'sm'} variant='ghost' minW={'50px'}  onClick={handleShare}>
                <AiOutlineShareAlt />
                   <Box textDecoration={'underline'} ml={'10px'}>
                   Share
                   </Box>
                </Button> */}
                <Button
                   type="primary"
                  style={{display:'flex'}}
                  display={'flex'}
                  onClick={handleApply}
                  disabled={isApplying || userAppliedJobs.includes(job.id)}
                >
                  {isApplying ? (
                    <Spinner size="sm" />
                  ) : userAppliedJobs.includes(job.id) ? (
                    'Applied'
                  ) : (
                    'Apply'
                  )}
                </Button>
            </Box>

            <Button
              onClick={onClose}
              size="sm"
              variant="ghost"
              colorScheme="gray"    
            >
<AiOutlineClose />
            </Button>
        </Box>
        </Box>

            {/* <ShareModal isOpen={isShareOpen} onClose={onShareClose} title={events[0].title} url={events[0].recurrence_slug} /> */}




            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} marginTop={24} alignItems={'center'}>
              <Box width={'60px'} marginRight={3}>
                  <Image cursor={'pointer'} width="100%"  object-fit="cover" borderRadius={2} aspectRatio={{ base: "1", md: "1" }} src={job.school_sites[0].icon} alt="Event" />
                  </Box>
                  <Box  alignSelf={'center'} >
                      <Heading  as="h2" size="md"  /* other style props */>
                {job.title}
                </Heading>                    
            <Box fontSize={'16px'} alignSelf={'flex-start'} fontWeight={500} textAlign={'start'} color={'grey'}>
                      {job.school_sites[0].school_name}
                    </Box>
                  </Box>
              </Box>

            



            <style>
        {`
            .hide-scrollbar::-webkit-scrollbar {
                display: none;
            }
            .hide-scrollbar {
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }
        `}
    </style>

{/*       
            <Box  mt="2" mb={4} display={'flex'} minWidth={'100%'}
                maxWidth={"100%"}
                flexWrap={"nowrap"}
                overflow={"scroll"}
                alignItems={'center'}
                className="hide-scrollbar"
            >
            </Box> */}

      
            <Heading  as="h2" size="md" mt={10}  /* other style props */>
                Summary
                </Heading> 

            <Box mt="2" style={{ whiteSpace: 'pre-wrap', paddingTop:'15px' }}>
                {job.description}
            </Box>

              <Heading  as="h2" size="md" mt={10} /* other style props */>
                Responsibilities
              </Heading> 
              <Box>
              <div style={{padding:'20px'}} dangerouslySetInnerHTML={{ __html: job.requirements }}></div>
              </Box>

              <Heading  as="h2" size="md" mt={10} /* other style props */>
              Qualifications
              </Heading> 

              <Box>
              <div style={{padding:'20px'}} dangerouslySetInnerHTML={{ __html: job.qualifications }}></div>
              </Box>



            {/* <Heading  as="h2"  mb="2"  size="sm" mt={10}  color={'grey'}>
                Culture
                </Heading> 

            <Carousel photos={job.school_sites[0].media} /> */}


            </DrawerBody>



  
            {/* <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter> */}
          </DrawerContent>


        </Drawer>
      </>
    )
  }
  

function PublicJobsCCSA() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  
  const { isOpen: isFilterModalOpen, onOpen: onFilterModalOpen, onClose: onFilterModalClose } = useDisclosure();
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const FilterContent = () => (
    <>
      <Select style={{ width: '100%' }} placeholder="Filter by role type" onChange={value => setRoleType(value)}>
        {/* Options here */}
      </Select>
      {/* Repeat for other selects */}
    </>
  );


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Kurale', 'Inter:300,400,600,700']  // Including Inter with specific weights
      }
    });
  }, []);
  console.log("Haligekugh")
  const { Option } = Select;

  const [roleType, setRoleType] = useState('all');
  const [county, setCounty] = useState('all');
  const [employmentType, setEmploymentType] = useState('all');
  const [minSalary, setMinSalary] = useState('0');

    const [isAuthenticated] = useState(localStorage.getItem('authToken') !== null);



    const isInitialMount = useRef(true); // Ref to track initial mount
    const [jobs, setJobs] = useState([]);
    const [userAppliedJobs, setUserAppliedJobs] = useState([]);

    const [loading, setLoading] = useState(false)

    const [dateOne, setDateOne] = useState('')
    const [dateTwo, setDateTwo] = useState('')


    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // New state for total pages

    const [hasMore, setHasMore] = useState(true);
    const dateFormat = 'MMM D, YYYY';

    function checkIfLiked(events) {
        // Check if there are likes and if the first like's user ID matches the logged-in user's ID
        if (events && events.length > 0 && events[0].outivity_likes && events[0].outivity_likes.length > 0) {
          return events[0].outivity_likes[0].user.id.toString() === "1";
        }
        return false;
      }


      const handleLike = async (outivity, callback) => {
        // Check if the user is authenticated
        if (isAuthenticated) {
          const url = `https://www.outivity.io/web_api/like_outivity/`;
    
            const updateEvents = (events, updatedEvents) => {
                return events.map(eventGroup => {
                    const updatedGroupEvents = eventGroup.events.map(event => {
                        const updatedEvent = updatedEvents.find(uEvent => uEvent.id === event.id);
                        return updatedEvent ? updatedEvent : event;
                    });
                    return { ...eventGroup, events: updatedGroupEvents };
                });
            };
    
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(outivity)
                });
    
                if (response.ok) {
                    const updatedEvents = await response.json();
                    setJobs(prevEvents => updateEvents(prevEvents, updatedEvents));
                } else {
                    throw new Error(`Error: ${response.status}`);
                }
            } catch (error) {
                console.error("Failed to update like:", error);
            } finally {
                if (callback) {
                    callback();
                }
            }
        } else {
            // User is not authenticated
            onOpen()
        }
    };
    

    

    
    


    const defaultStyles = `
    .customRangePicker .ant-picker-input input {
        font-size: 0.875rem;
        font-weight: 600;
    }
    .customRangePicker .ant-picker-range-separator .anticon svg {
        color:black;
    }
    .ant-picker-suffix .anticon-calendar svg {
        color:black;
        margin-right:15px;
    }

    .ant-picker-clear .anticon-close-circle
    `;
    
    const blackStyles = `
    .customRangePicker .ant-picker-input input {
        font-size: 0.875rem;
        font-weight: 600;
    }
    .customRangePicker .ant-picker-range-separator .anticon svg {
        color:rgba(0, 0, 0, 0.25);
    }
    .ant-picker-suffix .anticon-calendar svg {
        color:rgba(0, 0, 0, 0.25);
    }
    `;

  const navBar = `
  .sticky-top-navbar {
    position: sticky;
    top: 0;
    z-index: 1000; /* Adjust z-index as needed */
    /* Add other styles for your top navbar */
  }
  `;
const customStyles = dateOne === '' && dateTwo === '' ? blackStyles : defaultStyles;

    // Fetch Events
    const fetchEvents = (page) => {
      setJobs([])

      setLoading(true);

        console.log("coming to fetch")
        const filterData = {
          roleType,
          county,
          employmentType,
          minSalary,
          page
        };
        
        const queryParams = new URLSearchParams(filterData).toString();
        const url = `https://www.pursueli.com/api/all_public_jobs/?${queryParams}`;
        fetch(url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                'Content-Type': 'application/json',
            },
        })
        .then((response) => response.json())
        .then((data) => {
          
            if (data.status === "noData") {
              console.log("no data coming")
                // If status is "noData", only update the hasMore state
                setHasMore(false);
            } else {
              console.log("some data coming")
                // If status is not "noData", update both jobs and hasMore
                // setJobs(prevEvents => [...prevEvents, ...data.results.jobs]);
                setJobs(data.results.jobs)
                // setCurrentPage(page);
                setTotalPages(data.results.total_pages); // Assuming your backend sends total_pages
                // setJobs(data.results.jobs)
                console.log(data)
                setHasMore(true);
            }
            setUserAppliedJobs(data.results.user_applied_jobs)
            console.log("This is the data ", data.results.user_applied_jobs)
        })
        .catch((error) => console.error("Error fetching upcoming events:", error))
        .finally(() => setLoading(false));
    };

    useEffect(() => {
      fetchEvents(currentPage);
    }, [currentPage]); 

    useEffect(() => {
      fetchEvents(1);
    }, [roleType, county, employmentType, minSalary]); 

    // // Initial Fetch
    // useEffect(() => {
    //     fetchEvents(currentPage);
    // }, []);

    // Infinite Scroll Logic
    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight || loading) {
    //             return;
    //         }
    //         setCurrentPage(prevPage => prevPage + 1);
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, [loading]);

    // Fetch More Events on currentPage Change
    useEffect(() => {
        if (currentPage > 1 && hasMore == true) {
            fetchEvents(currentPage);
        }
    }, [currentPage]);

    const { RangePicker } = DatePicker;

    const hideScroll = `
    .hide-scrollbar::-webkit-scrollbar {
      display: none;
  }
  .hide-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
  }
    `;

    const renderPageNumbers = () => {
      let pages = [];
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <Button key={i} 
          shape='circle'
          type={currentPage === i ? "primary" : ""}
          style={{marginRight:'10px'}} onClick={() => setCurrentPage(i)}>
            {i}
          </Button>
        );
      }
      return pages;
    };

      
    const { isOpen, onOpen, onClose } = useDisclosure()

  return (



    <Box minWdth={'100%'} mt={10}>
    
<Box paddingLeft={{ base: 5, lg: '10%' }} paddingRight={{ base: 5, lg: '10%' }}>

  
<style>{customStyles}</style>
<style>
{navBar}
</style>
<style>
{hideScroll}
</style>

<Grid
    templateColumns={{
      base: 'repeat(1, 1fr)', // 12 columns on small screens
      md: 'repeat(1, 1fr)',  // 10 columns on medium screens
      lg: 'repeat(1, 1fr)'    // 8 columns on large screens
    }}
    justifyContent={'center'}
    justifySe={'center'}
    textAlign={'center'}
>
<GridItem 
    colStart={1}
    justifyContent="center"
    width={{base:'100%', md:'80%', lg:'100%'}} 

    maxWidth="100%" // Set a max-width if you want to limit how wide the grid can get
    mx="auto" // This centers the grid in its parent
 >
<Box textAlign={'center'} 
mt={{base:'60px',md:'40px'}} 
style={{ fontFamily: 'Inter' , fontWeight: '600'}}
fontSize={{ base: "24px", md: "1.6rem" }}
lineHeight={{base:'30px', md:'50px'}}
>
  {/* The <span style={{textDecoration:'underline'}}> modern</span>  job search platform for K - 12 educators & administrators */}
</Box>
<Box fontSize={'md'} color={'gray'} style={{ fontFamily: 'Inter' , fontWeight: '300'}}>
Join the CCSA talent collective for personalized recommendations and the latest job matches in the charter movement</Box>
</GridItem>

</Grid>


{isLargerThan768 ? (
          // Inline display for desktop
<Box className="sticky-top-navbar" background={'transparent'}>
<Box  pt={4} display={'flex'} justifyContent={'center'} >
<Select style={{ width: '20%', display:'none' }} placeholder="Filter by role type"
  onChange={value => setRoleType(value)}
  options={[
    {
      label: 'Educator',
      options: [
        {
          label: 'Jack',
          value: 'jack',
        },
        {
          label: 'Lucy',
          value: 'lucy',
        },
      ],
    },
    {
      label: 'Administration',
      options: [
        {
          label: 'yiminghe',
          value: 'Yiminghe',
        },
      ],
    },
  ]}
  />


<Select style={{ width: '20%', marginLeft:'20px' }} placeholder="Filter by county"
  onChange={value => setCounty(value)}>
    <Option value="all">All</Option>
    <Option value="Alameda">Alameda County</Option>
    <Option value="Contra Costa">Contra Costa County</Option>
    <Option value="Santa Clara">Santa Clara County</Option>
</Select>
<Select style={{ width: '20%', marginLeft:'20px' }} placeholder="Filter by employment type"
  onChange={value => setEmploymentType(value)}>
    <Option value="all">All</Option>
    <Option value="Full-time">Full-time</Option>
    <Option value="Part-time">Part-time</Option>
    <Option value="Contract">Contract</Option>
    <Option value="Temporary">Temporary</Option>
</Select>
<Select style={{ width: '20%', marginLeft:'20px' }} placeholder="Filter by minimum salary"
  onChange={value => setMinSalary(value)}>
    <Option value="all">All</Option>
    <Option value="50000">$50,000</Option>
    <Option value="75000">$75,000</Option>
    <Option value="100000">$100,000</Option>
    <Option value="125000">$125,000 +</Option>
</Select>


</Box>
</Box>
        ) : (
          // Modal display for mobile
          <>
            <Button style={{marginTop:'20px', marginBottom:'-10px'}} onClick={showModal}>Open Filters</Button>
            <Modal title="Filters" style={{top:0, position:'relative'}} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>

                {/* <ModalHeader>Filters</ModalHeader>
                <ModalCloseButton /> */}
                 
                <Select style={{ width: '100%', display:'none' }} placeholder="Filter by role type"
  onChange={value => setRoleType(value)}
  options={[
    {
      label: 'Educator',
      options: [
        {
          label: 'Jack',
          value: 'jack',
        },
        {
          label: 'Lucy',
          value: 'lucy',
        },
      ],
    },
    {
      label: 'Administration',
      options: [
        {
          label: 'yiminghe',
          value: 'Yiminghe',
        },
      ],
    },
  ]}
  />


<Select style={{ width: '100%', marginBottom:'20px' }} placeholder="Filter by county"
  onChange={value => setCounty(value)}>
    <Option style={{zIndex:'9999999999999',}} value="all">All</Option>
    <Option value="Alameda">Alameda County</Option>
    <Option value="Contra Costa">Contra Costa County</Option>
    <Option value="Santa Clara">Santa Clara County</Option>
</Select>
<Select style={{ width: '100%',marginBottom:'20px'  }} placeholder="Filter by employment type"
  onChange={value => setEmploymentType(value)}>
    <Option value="all">All</Option>
    <Option value="Full-time">Full-time</Option>
    <Option value="Part-time">Part-time</Option>
    <Option value="Contract">Contract</Option>
    <Option value="Temporary">Temporary</Option>
</Select>
<Select style={{ width: '100%', marginBottom:'20px' }} placeholder="Filter by minimum salary"
  onChange={value => setMinSalary(value)}>
    <Option value="all">All</Option>
    <Option value="50000">$50,000</Option>
    <Option value="75000">$75,000</Option>
    <Option value="100000">$100,000</Option>
    <Option value="125000">$125,000 +</Option>
</Select>





            </Modal>
          </>
        )}



<Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alert</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        You must be logged in to like Outivities. Email robert@outivity.io to create an account.
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={onClose}>
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>








<Grid
    templateColumns={{
      base: 'repeat(12, 1fr)', // 12 columns on small screens
      md: 'repeat(10, 1fr)',  // 10 columns on medium screens
      lg: 'repeat(8, 1fr)'    // 8 columns on large screens
    }}
    // gap={{ base: 0, lg: 6 }} // base for mobile and lg for large screens
    justifyContent="center"
    width="full" // Ensures the grid takes the full width of its parent
    // maxWidth="100%" // Set a max-width if you want to limit how wide the grid can get
    // mx="auto" // This centers the grid in its parent
  >
    <GridItem 
    colStart={1}
    justifyContent="center"
    width="full" // Ensures the grid takes the full width of its parent
    maxWidth="100%" // Set a max-width if you want to limit how wide the grid can get
    mx="auto" // This centers the grid in its parent
    overflow="hidden" // Prevents content from exceeding the grid area
 colSpan={{ base: 12, md: 10, lg: 12 }}
 >
    <Box marginX="auto" paddingBottom={'10px'}>

    <Box>
    {loading ? (
        <Flex textAlign="center" alignItems={'center'} justifyContent={'center'} height={'50vh'}>
            <Spinner />
        </Flex>
    ) : jobs.length > 0 ? (
        <Grid templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "repeat(3, 1fr)" }} mt={8} gap={6}>
            {jobs.map(job => (
                <EventCard 
                    key={job.id}
                    job={job}
                    badgeColor={'blue'}
                    handleLike={handleLike}
                    checkIfLiked={checkIfLiked}
                    isAuthenticated={isAuthenticated}
                    userAppliedJobs={userAppliedJobs}
                    setUserAppliedJobs={setUserAppliedJobs}
                />
            ))}
        </Grid>
    ) : (
        <Flex fontSize="lg" color="gray.500" alignItems={'center'} justifyContent={'center'} height={'40vh'} width="full" p={10}>
            No jobs found.
        </Flex>
    )}
</Box>





        

    </Box>

    <Box display="flex" justifyContent="center" marginTop="20px" marginBottom={'50px'}>
      {renderPageNumbers()}
      </Box>


    </GridItem>


  

    </Grid>


    
    
    </Box>

<footer className="App-footer" style={{minWdth:'100%',height:'30vh', bottom:0, position:'', zIndex:'100',
margin:'auto', display:'flex', justifyContent:'center',
}}>
    <CCSAFooter/>

</footer>
</Box>
  );
}

function EventCard({title, job, handleDelete, handleSave, handleBulkEdit, badgeColor, handleLike, checkIfLiked, isAuthenticated, userAppliedJobs, setUserAppliedJobs }) {


  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden" shadow="lg" bg={"white"} padding={2}  minHeight="190px" maxHeight="190px">
        

<Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'}>
<Box width={'15%'} marginRight={1}>
<a  rel="noopener noreferrer">
{/* <a href={`https://www.pursueli.com/school/${job.school_sites[0].slug}`} target="_blank" rel="noopener noreferrer"> */}
      <Image cursor={'pointer'} width="100%"  object-fit="cover" borderRadius={2}  aspectRatio={{ base: "1", md: "1" }} src={job.school_sites[0].icon} alt="Event" />
    </a>
    </Box>
    <Box width={'85%'} alignSelf={'flex-start'}  pl={2}>
      <DrawerExample job={job}  handleDelete={handleDelete} handleSave={handleSave} handleLike={handleLike} checkIfLiked={checkIfLiked} isAuthenticated={isAuthenticated} userAppliedJobs={userAppliedJobs} setUserAppliedJobs={setUserAppliedJobs} />
      <Box fontSize={'16px'} alignSelf={'flex-start'} noOfLines={1} fontWeight={500} textAlign={'start'} color={'grey'}>
        {job.school_sites[0].school_name}
      </Box>
    </Box>
</Box>
<Box fontSize={'14px'} alignSelf={'flex-start'} mt={2} textAlign={'start'} color={'#333'}noOfLines={3}>
{job.description}
</Box>


<Box display={'flex'} fontSize={'14px'} mt={3} overflowX={'scroll'} 
style={{
  // For Webkit browsers like Chrome and Safari
  '::-webkit-scrollbar': {
    display: 'none',
  },
  // For IE and Edge
  '-ms-overflow-style': 'none',
  // For Firefox
  'scrollbar-width': 'none',
}}
>
  <Tag textAlign={'start'} style={{marginRight:'10px', fontSize:'13px', fontWeight:'bold'}}  >
  📍 {job.school_sites[0].city_and_state}
  </Tag>
  <Tag textAlign={'start'} style={{marginRight:'10px', fontSize:'13px', fontWeight:'bold'}} >
  💼 {job.employment_type}
  </Tag>
  <Tag textAlign={'start'} color="gold" style={{fontSize:'13px', fontWeight:'bold'}} >
  💰 ${formatNumber(job.minimum_pay)} - ${formatNumber(job.maximum_pay)}
  </Tag>
</Box>



    </Box>
  );
  
}

export default PublicJobsCCSA;
