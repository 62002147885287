import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Avatar, Stack, Heading,
  Switch,   Tabs, TabList, TabPanels, Tab, TabPanel,
  IconButton, Spinner,
  Table,
  Thead,MenuItem, MenuButton, MenuList,
  Tbody,Grid, GridItem,SimpleGrid, Menu,
  Tr, Image, option,
  Th,useToast,
  Td,
  TableContainer,   Skeleton,
  VStack, Card,
  Button,
  Text, InputGroup, InputLeftElement, InputRightElement,
  textDecoration,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel,
} from '@chakra-ui/react';
import { FaBuilding, FaEdit, FaSchool, FaLink, FaTrash } from 'react-icons/fa';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Select, Space, Tag } from 'antd';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { useSchool } from '../../../context/admin_selected_school';
import { useDisclosure } from '@chakra-ui/react';
import { FiArchive, FiEdit3, FiUpload, FiPlus, FiLink,FiBookOpen, FiCheck, FiClock, FiSlash, FiSend, FiRefreshCw, FiZap, FiMail } from "react-icons/fi";
import GoogleMapComponent from '../../../components/google_maps_input';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload, Input, Dropdown, Option } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import UploadMultipleImages from '../../../components/multi_image_upload';
import { css } from '@emotion/react';

// import GoogleMapComponent from '../../../components/profile_google_maps_input';

function TeamSettings() {
  const [expandedItems, setExpandedItems] = useState({});
  const toggleExpand = (inviteId) => {
    setExpandedItems(prevState => ({
      ...prevState,
      [inviteId]: !prevState[inviteId]
    }));
  };
  
  const [defaultAddVariableText, setDefaultAddVariableText] = useState('add variable');

  const [emailBody, setEmailBody] = useState('');
  const [cursorPosition, setCursorPosition] = useState(null);
  const textAreaRef = useRef(null);

  const handleEmailMessageChange = (e) => {
    setEmailBody(e.target.value);
  };

  const handleTextAreaClick = () => {
    // Update cursor position when the TextArea is clicked or focused
    const position = textAreaRef.current?.selectionStart;
    setCursorPosition(position);
  };

  const insertVariable = (variable) => {
    // Ensure the cursor position is up to date
    const position = cursorPosition !== null ? cursorPosition : emailBody.length;
    const beforeCursor = emailBody.substring(0, position);
    const afterCursor = emailBody.substring(position, emailBody.length);
    const updatedMessage = `${beforeCursor}[${variable}]${afterCursor}`;
    setEmailBody(updatedMessage);

    // Reset Select (if using controlled value as previously discussed)
    // setDefaultAddVariableText('add variable')
    
    // Move focus back to the TextArea and update the cursor position
    if (textAreaRef.current) {
      textAreaRef.current.focus();
      const newPosition = position + variable.length + 2; // Adjust for the added text
      setTimeout(() => {
        textAreaRef.current.setSelectionRange(newPosition, newPosition);
      }, 0);
    }
  };

  // Variable options as previously defined
  const variableOptions = [
    { label: 'Applicant First Name', value: 'applicant_first_name' },
    { label: 'Applicant Last Name', value: 'applicant_last_name' },
    { label: 'Role Title', value: 'role_title' },
    { label: 'Job Owner First Name', value: 'job_owner_first_name' },
    { label: 'Job Owner Last Name', value: 'job_owner_last_name' },
    { label: 'Job Owner Email', value: 'job_owner_email' },
    { label: 'Job Owner Calander Link', value: 'job_owner_calander_link' },
    { label: 'School Site', value: 'school_site_name' },
    { label: 'School Organization', value: 'school_organization' },
  ];
  const [emailTemplateAction, setEmailTemplateAction] = useState('');
  const [emailTemplateSubjectLine, setEmailTemplateSubjectLine] = useState('');
  const [emailTemplateName, setEmailTemplateName] = useState('');

  const handleEmailTemplateActionChange = (value) => {
    setEmailTemplateAction(value);
  };
  
  const { TextArea } = Input;

  const [newSchoolUsers, setNewSchoolUsers] = useState([]);
  const modalRef = useRef(); // Ref for the modal content

  const handleNewSchoolUsers = (selectedValues) => {
    setNewSchoolUsers(selectedValues);
  };
  const { isOpen: isAddSchoolModalOpen, onOpen: onAddSchoolModalOpen, onClose: onAddSchoolModalClose } = useDisclosure();
  const [loadingStates, setLoadingStates] = useState({});
  const [sendingLoadingStates, setSendingLoadingStates] = useState({});

  
  const [newSchoolName, setNewSchoolName] = useState('');
  const [newSchoolType, setNewSchoolType] = useState('');
  const [newSchoolAddress, setNewSchoolAddress] = useState('');
  const [newSchoolGradesServed, setNewSchoolGradesServed] = useState('');
  const [newSchoolEnrollment, setNewSchoolEnrollment] = useState('');
  const [newSchoolYearFounded, setNewSchoolYearFounded] = useState('');
  const [newSchoolAbout, setNewSchoolAbout] = useState('');
  // const [locationAddress, setNewSchoolLocationAddress] = React.useState('');
  const [cityAndState, setCityAndState] = React.useState('');

  const handleSelectedGradesServed = (value) => {
    setNewSchoolGradesServed(value);
  };
  const handleSelectedCharterSchoolType = (value) => {
    setNewSchoolType(value);
  };
  const [logo, setLogo] = React.useState([]);

  const onLogoChange = (imageList) => {
    // data for submit
    setLogo(imageList);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const handleChange = (info) => {
      if (info.file.status === 'uploading') {
        setLoading(true);
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (url) => {
          setLoading(false);
          setImageUrl(url);
        });
      }
    };
  
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> :<Flex w={'100%'} justifyContent={'center'} textAlign={'center'} ><FiUpload style={{textAlign:'center', height:'20px', width:'20px'}} /></Flex>}
      <div
        style={{
          marginTop: 8,
          fontSize:'12px',
          padding:'4px'
        }}
      >
      School Logo
      </div>
    </button>
  );
  

  const [newSchoolImages, setNewSchoolImages] = React.useState([]);

  const handleImagesChange = (newImages) => {
    console.log(newImages)
    setNewSchoolImages(newImages);
};

    // const handleImagesChange = (updatedImages) => {
    //     setImages(updatedImages);
    // };
    const toast = useToast();

    const handleLocationSetting = (location) => {
      console.log(location)
      setNewSchoolAddress(location)
    };

    const handleCityAndState = (cityState) => {
      setCityAndState(cityState)
    };

  const [teamMembers, setTeamMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sendingInvite, setSendingInvite] = useState(false);

  const [creatingSchoolSite, setCreatingSchoolSite] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);

  const [invites, setInvites] = useState([]);
  const [inviteFirstName, setInviteFirstName] = useState('');
  const [inviteLastName, setInviteLastName] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');

  const [schoolSites, setSchoolSites] = useState([]);

  const [organization, setOrganization] = useState('');


  const { isOpen: isInviteModalOpen, onOpen: onInviteModalOpen, onClose: onInviteModalClose } = useDisclosure();

  const { isOpen: isNewTemplateModalOpen, onOpen: onNewTemplateModalOpen, onClose: onNewTemplateModalClose } = useDisclosure();


  const updateTeamMember = (teamMember) => {
    const updatedTeamMembers = teamMembers.map((member) => {
      if (member.id === teamMember.id) {
        // Replace the job post with the updated one
        return teamMember;
      }
      return member;
    });
    setTeamMembers(updatedTeamMembers); // Update the state with the modified array
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Approved':
        return <Box  color='green' display={'flex'} alignItems={'center'}><FiCheck style={{ marginRight: '10px', color: 'green' }} />{status}</Box>
        ;
      case 'Pending':
        return <Box color={'orange'} display={'flex'} alignItems={'center'}><FiClock style={{ marginRight: '10px', color: 'orange' }} />{status}</Box>
      case 'Revoked':
        return <Box color={'#F56565'} display={'flex'} alignItems={'center'}><FiSlash style={{ marginRight: '10px', color: '#F56565' }} />{status}</Box>
      default:
        return null; // Default case if none of the statuses match
    }
  };

const { school } = useSchool();
useEffect(() => {
  const schoolParam = encodeURIComponent(school); // Use this if `school` is a string

  // Assuming 'school' is the query parameter expected by your backend
  // and 'school' state contains the current selected school value.
  fetch(`https://www.pursueli.com/api/admin_get_account_users/?school=${schoolParam}`, {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
 
    setTeamMembers(data.team_members)
    setInvites(data.invites)
    setSchoolSites(data.schools)
    setOrganization(data.organization)
    setEmailTemplates(data.email_templates)
    console.log(data.team_members)
    // setSchoolName(data.school_name)
    // setSchoolId(data.school_id)
    setIsLoading(false)

    // Handle your data
  })
  .catch(error => console.error("Error fetching jobs:", error));
}, [school]);

  useEffect(() => {
    console.log(school)
  }, []); 

// Function to handle status change
const handleStatusChange = async (userid, updatedStatus) => {
    const payload = { userid, updatedStatus };
    try {
      const response = await fetch('https://www.pursueli.com/api/admin_update_user_status/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        console.log("data mane, ", data);
        const team_member_id = data.team_member.id;
        const teamMember = data.team_member; // Assuming the API returns the new job data under 'new_job'
        updateTeamMember(teamMember); // Update the job list with the new job details
  
        // Optionally, refresh the data or update UI here
      } else {
        console.error('Failed to update status');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  
  const inviteTeamMember = async (inviteEmail, inviteLastName, inviteFirstName) => {
    setSendingInvite(true)
    const payload = { 
        inviteFirstName: inviteFirstName, 
        inviteLastName: inviteLastName, 
        inviteEmail: inviteEmail 
      };    try {
      const response = await fetch('https://www.pursueli.com/api/admin_invite_user/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        setInvites(data.invites)
        setSendingInvite(false)
        setInviteEmail('')
        setInviteFirstName('')
        setInviteLastName('')
        toast({
            title: 'Invite sent.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        onInviteModalClose();
      } else {
        console.error('Failed to update invites.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const createNewTemplate = async (emailTemplateAction, emailTemplateSubjectLine, emailBody, emailTemplateName) => {
    setSendingInvite(true)

  console.log(emailTemplateAction, emailTemplateSubjectLine, emailBody)
    const payload = { 
      emailTemplateAction: emailTemplateAction, 
      emailTemplateSubjectLine: emailTemplateSubjectLine, 
      emailBody: emailBody,
      emailName: emailTemplateName
      };    try {
      const response = await fetch('https://www.pursueli.com/api/admin_create_email_template/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        console.log(data)
        
        setEmailTemplates(data.email_templates)
        setSendingInvite(false)
        setEmailBody('')
        setEmailTemplateAction('')
        setEmailTemplateSubjectLine('')
        setEmailTemplateName('')
        toast({
            title: 'Email template created',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        onNewTemplateModalClose();
      } else {
        console.error('Failed to create email template.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  
  const createNewSchool = async (newSchoolName, newSchoolType, newSchoolAddress, newSchoolGradesServed, newSchoolEnrollment, newSchoolYearFounded, newSchoolAbout, newSchoolImages, imageUrl, cityAndState, newSchoolUsers) => {
    setCreatingSchoolSite(true)
    const payload = { 
      newSchoolName: newSchoolName, 
      newSchoolType: newSchoolType, 
      newSchoolAddress: newSchoolAddress,
      newSchoolGradesServed: newSchoolGradesServed,
      newSchoolEnrollment: newSchoolEnrollment,
      newSchoolYearFounded: newSchoolYearFounded,
      newSchoolAbout: newSchoolAbout,
      imageUrl: imageUrl,
      images: newSchoolImages,
      cityAndState:cityAndState,
      newSchoolUsers: newSchoolUsers,
      };    try {
      const response = await fetch('https://www.pursueli.com/api/admin_create_school/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        console.log('Status updated successfully');
        const data = await response.json();
        setSchoolSites(data.schools)
        setNewSchoolName('')
        setNewSchoolType('')
        setNewSchoolAddress('')
        setNewSchoolGradesServed('')
        setNewSchoolEnrollment('')
        setNewSchoolYearFounded('')
        setNewSchoolAbout('')
        setNewSchoolImages([])
        setImageUrl('')
        setCityAndState('')
        setNewSchoolUsers([])
        toast({
            title: 'School created.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        onAddSchoolModalClose();
        setCreatingSchoolSite(false);
      } else {
        console.error('Failed to update invites.');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const numberOfRows = 8; // For example
  const revokeInvite = async (inviteId) => {
  setLoadingStates(prev => ({ ...prev, [inviteId]: true })); // Start loading
    try {
      const response = await fetch('https://www.pursueli.com/api/admin_revoke_invite/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming you store your token in localStorage
        },
        body: JSON.stringify({ inviteId }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoadingStates(prev => ({ ...prev, [inviteId]: false })); // Stop loading
      const data = await response.json();
      setInvites(data.invites)
      console.log('Revoke successful', data);
      toast({
        title: 'Invite revoked.',
        description: "The invite has been successfully revoked.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
  
      // Optionally, refresh your invites here or update the UI accordingly
    } catch (error) {
      console.error('Error revoking invite:', error);
      toast({
        title: 'Error revoking invite.',
        description: "There was an error revoking the invite.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const resendInvite = async (inviteId) => {
    setSendingLoadingStates(prev => ({ ...prev, [inviteId]: true })); // Start loading
      try {
        const response = await fetch('https://www.pursueli.com/api/admin_resend_invite/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`, // Assuming you store your token in localStorage
          },
          body: JSON.stringify({ inviteId }),
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        setSendingLoadingStates(prev => ({ ...prev, [inviteId]: false })); // Stop loading
        toast({
          title: 'Invite sent.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
    
        // Optionally, refresh your invites here or update the UI accordingly
      } catch (error) {
        console.error('Error sending invite:', error);
        toast({
          title: 'Error resending invite.',
          description: "There was an error sending the invite.",
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    };
  

  return (
    <ChakraProvider>
        <style>
    {`
      .collapsed::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 20px;
        background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
      }
    `}
  </style>
      


  <Box flex="1" p={8} left={'6%'} overflowY="auto" maxW={'94%'}  position="relative" justifyContent={'start'} bg={'rgba(0, 0, 0, 0.01)'} height={'100vh'}> 
<Box style={{  borderWidth:'0px'}} py={'0px'} borderWidth={0} borderColor={'transparent'} boxShadow={'none'}>
        <Card px={'80px'} style={{ paddingLeft:'30px', paddingRight:'30px'}} py={'30px'}  boxShadow="xl"
   >
          <Flex justifyContent={'space-between'}>
          <Text mb={4} fontSize="xl" textAlign={'start'} fontWeight="bold">Settings</Text>

          </Flex>


          <Tabs  variant="enclosed" orientation="vertical">
        <TabList minH={'60vh'} display={'block'} borderRightWidth={1} borderBottomWidth={0} flex={0} minW={'18%'} marginLeft={'-10px'}>
          <Tab borderWidth={0} textAlign={'start'}>School Sites</Tab>
          <Tab  borderWidth={0} textAlign={'start'}>Team & Invites</Tab>
          <Tab  borderWidth={0} textAlign={'start'}>Email Templates</Tab>
        </TabList>

        {isLoading ? (
 <Box position="relative" paddingLeft={9}>
   <Card ml={7} w={'100vh'} mb={6} p={5} boxShadow="lg" borderRadius="lg">
       <Skeleton height="50px" mb={4} borderRadius="md" />
       <Skeleton mt="4" noOfLines={2} spacing="4" mb={4} />
       <Flex justifyContent="space-between" mt={4}>
         <Button isDisabled>
           <Skeleton height="20px" width="70px" />
         </Button>
         <Button isDisabled>
           <Skeleton height="20px" width="70px" />
         </Button>
       </Flex>
     </Card>
 <SimpleGrid ml={7} columns={2} spacing={5}>
   {Array(2).fill().map((_, index) => (
     <Card key={index} p={5} boxShadow="lg" borderRadius="lg">
       <Skeleton height="50px" mb={4} borderRadius="md" />
       <Skeleton mt="4" noOfLines={2} spacing="4" mb={4} />
       <Flex justifyContent="space-between" mt={4}>
         <Button isDisabled>
           <Skeleton height="20px" width="70px" />
         </Button>
         <Button isDisabled>
           <Skeleton height="20px" width="70px" />
         </Button>
       </Flex>
     </Card>
   ))}
 </SimpleGrid>
</Box>




) : (


        <TabPanels paddingLeft={5}>
    
          <TabPanel  mt={'-10px'} textAlign={'start'} >
          <Heading  size="md" >
        <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
        Organization
        </Flex>
      </Heading>
      <Text  mt={''} mb={4}>
      Manage the core information of your organization HQ here. Update your charter school or CMO details, including the organization's name, mission, and contact information. Note, jobs will be associated with the school site profiles below, not the HQ organization.
      </Text>

      <Box borderWidth="1px" shadow={'xl'} borderRadius="lg" textAlign={'center'} overflow="hidden"  p={4}>


          <Flex direction="column" h="100%" >

          <Flex  mb={4} align={'center'}>
          <Avatar
          borderRadius={5}
          src={organization.icon}
          alt='Green double couch with wooden legs'
          size={'lg'}
          // height={'100px'}
          />
          <Box w={'80%'} ml={5}>
          <Flex 
          justifyContent={'flex-start'}>
          <Text  fontWeight="bold">{organization.organization_name}</Text>
          </Flex>
          <Flex justifyContent={'flex-start'}>
          <Text textAlign={'start'} fontWeight="">{organization.hq_address}</Text>
          </Flex>
          </Box>
          </Flex>
          <Flex justifyContent={'space-evenly'} >
          <Button size={'sm'} w={'50%'}  colorScheme="gray" mr={2} as="a" href={`/jobs/career-profile/${organization.slug}`} target="_blank" display={'none'}>
          <FaBuilding   style={{marginRight:'6px'}} />

          Organization Profile</Button>
          <Button size={'sm'} variant={'solid'} w={'50%'} colorScheme="gray" as="a" href={`/jobs/organization/${organization.slug}`} target="_blank">
          <FaLink style={{marginRight:'6px'}} />
          Public Jobs Page</Button>

          </Flex>
          </Flex>
          </Box>


                        

          <Heading mt={10} pt={10} size="md" >
        <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
        School Sites
        <Button colorScheme='blue' size={'sm'} onClick={onAddSchoolModalOpen}>
            <FiPlus />
            <Text pl={3}>
            Add School Site
            </Text>
            </Button>
        </Flex>
      </Heading>
      <Text  mt={'-10px'} mb={4}>
        View your school sites below.
      </Text>


      <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={6}>
      {schoolSites.map((school) => (
                  <Box key={school.id} borderWidth="1px" borderRadius="lg" textAlign={'center'} shadow={'xl'} overflow="hidden"  p={4}>


          <Flex direction="column" h="100%" >

<Flex  mb={4} align={'center'}>
<Avatar
          borderRadius={5}
          src={school.icon}
          alt='school icon'
          size={'lg'}
          // height={'100px'}
          />
  <Box w={'80%'} ml={5}>
    <Flex 
    justifyContent={'flex-start'}>
    <Text textAlign={'start'}  fontWeight="bold">{school.school_name}</Text>
    </Flex>
    <Flex justifyContent={'flex-start'}>
    <Text textAlign={'start'} fontWeight="">{school.address}</Text>
    </Flex>
  </Box>
</Flex>
<Flex justifyContent={'space-evenly'} >
<Button size={'sm'} w={'50%'}  colorScheme="gray" style={{display:'none'}} mr={2}>
  <FaSchool   style={{marginRight:'6px'}} />

    School Profile</Button>
  <Button size={'sm'} variant={'solid'} w={'50%'} colorScheme="gray" as="a" href={`/jobs/${school.slug}`} target="_blank">
  <FaLink style={{marginRight:'6px'}} />
   Public Jobs Page</Button>

</Flex>
</Flex>
        </Box>
      ))}
    </SimpleGrid>






            {/* <Flex justifyContent={'center'}>
            <Empty style={{maxWidth:'40%'}} description={"The ability to add additional school sites, edit school profiles, etc ... is currently being built. This will be available by March 18th. Send an email to robert@pursueli.com with any questions."}/>
            </Flex> */}
          </TabPanel>
          <TabPanel mt={'-10px'} left={'20%'}>

          <Box  textAlign={'start'}>
      <Heading size="md" >
        Team
      </Heading>
      <Text mb={4}>
        View your team members below. Admins can invite new members.
      </Text>


      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
      {teamMembers.map((member) => (
        <Box key={member.id} borderWidth="1px" borderRadius="lg" shadow={'xl'}  p={4}>
          <Flex direction="column" >

            <Flex align="center" mb={4}>
            <Avatar name={`${member.user.first_name} ${member.user.last_name}`} mr={4} />
              <Box w={'100%'} maxW={'70%'}>
                <Flex justifyContent={'space-between'}>
                <Text fontWeight="bold">{member.user.first_name}</Text>
                </Flex>
                <Text fontSize="sm" color="gray.600">{member.user.email}</Text>
              </Box>
            </Flex>
            <Flex mt="auto" justify="space-between">
            <Menu>
            <MenuButton as={Button}  paddingLeft={3} textAlign={'start'} justifyContent={'flex-start'} rightIcon={<ChevronDownIcon />} w={'full'} size={'sm'} colorScheme="gray">
           <Box display={'flex'} alignItems={'center'}>
           {getStatusIcon(member.status)}
           </Box>





            </MenuButton>
            <MenuList>
                <MenuItem onClick={() => handleStatusChange(member.id, 'Approved')}>
                <FiCheck style={{marginRight:'10px'}} />
                Approved</MenuItem>
                <MenuItem onClick={() => handleStatusChange(member.id, 'Pending')}>
                <FiClock style={{marginRight:'10px'}} />
                Pending</MenuItem>
                <MenuItem onClick={() => handleStatusChange(member.id, 'Revoked')}>
                <FiSlash style={{marginRight:'10px'}} />
                Revoked</MenuItem>
            </MenuList>
            </Menu>
            </Flex>
          </Flex>
    
        </Box>
      ))}
    </SimpleGrid>


    <Heading mt={10} pt={9} size="md" >
        <Flex justifyContent={'space-between'} alignItems={'flex-start'}>
        Invited
        <Button colorScheme='blue' size={'sm'} onClick={onInviteModalOpen}>
            <FiSend />
            <Text pl={3}>
            Invite team member
            </Text>
            </Button>
        </Flex>
      </Heading>
      <Text mt={'-5px'} mb={4}>
        View invited team members below.
      </Text>


      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
      {invites.map((invite) => (
        <Box key={invite.id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
          <Flex direction="column" h="100%">

            <Flex align="center" mb={4}>
              <Box w={'100%'}>
                <Flex justifyContent={'space-between'}>
                <Text fontWeight="bold">{invite.invite_first_name} {invite.invite_last_name}</Text>
                </Flex>
                <Text fontSize="sm">{invite.invite_email}</Text>
                <Text fontSize="sm" color="gray.600">Invited on March 8th</Text>
              </Box>
            </Flex>
            <Flex mt="auto" justify="flex-start">
              <Button size={'sm'} variant={'outline'} colorScheme="gray" onClick={() => revokeInvite(invite.id)}
               disabled={loadingStates[invite.id]} 
              >
            {loadingStates[invite.id]  ? (
            <>
            <Spinner size="xs" mr={2} /> {/* Show a spinner next to the text */}
            Revoking...
            </>
            ) : (
                <Text display={'flex'}>
                   <FiSlash style={{marginRight:'6px'}} />
                Revoke
                </Text>
            )}  
             </Button>
              <Button ml={4} size={'sm'}  colorScheme="gray"
               onClick={() => resendInvite(invite.id)}
               disabled={sendingLoadingStates[invite.id]}
              >
              {sendingLoadingStates[invite.id]  ? (
            <>
            <Spinner size="xs" mr={2} /> {/* Show a spinner next to the text */}
            Sending...
            </>
            ) : (
                <Text display={'flex'}>
                   <FiSend style={{marginRight:'6px'}} />
                Resend
                </Text>
            )}  
              </Button>
            </Flex>
          </Flex>
        </Box>
      ))}
    </SimpleGrid>




    
    </Box>

    </TabPanel>
          <TabPanel mt={'-10px'} left={'20%'}>
              <Box  textAlign={'start'}>
              <Heading  size="md" >
              <Flex justifyContent={'space-between'} center={'flex-start'}>
              Email Templates
              <Button colorScheme='blue' size={'sm'} onClick={onNewTemplateModalOpen}>
                {/* <FiSend /> */}
                <Text pl={3}>
                Create New Template
                </Text>
                </Button>
              </Flex>
              </Heading>

              <Heading pt={4} size="sm" >
              When candidate applies
              </Heading>
              <Text mt={'5px'} mb={4} fontSize={'sm'}  color={'gray'}>
              These emails are sent as soon as a candidate applys to one of your jobs.
              </Text>

              <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={6}>
              <Box key={organization.default_new_application_email.id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} shadow={'lg'} style={expandedItems[organization.default_new_application_email.id] ? {} : { position: 'relative', minHeight:'250px', maxHeight:'250px', overflow: 'hidden' }}>
              <Flex direction="column" h="100%" pt={1}>
                <Flex align="center" mb={0}>
                  <Box w={'100%'}>
                  {/* <Text fontSize={'sm'} color={'gray'}>subbject</Text> */}
                    <Text fontWeight="bold" mb={1}>{organization.default_new_application_email.subject}</Text>                    
                    <Box style={expandedItems[organization.default_new_application_email.id] ? {} : { position: 'relative', maxHeight: '150px', overflow: 'hidden' }}>
                    {!expandedItems[organization.default_new_application_email.id] && (
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="120px" /* Height of the fade effect */
                        background="linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%)" /* Adjust the color to match your background */
                      />
                    )}
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                   {organization.default_new_application_email.email_body}
                    </Text>
                    </Box>
                    <Text mt={3} cursor="pointer" justify="center" textAlign={'center'} onClick={() => toggleExpand(organization.default_new_application_email.id)}>
                    {expandedItems[organization.default_new_application_email.id] ? 'Show less' : 'Show more'}
                </Text>
                  </Box>
                </Flex>
              </Flex>
              <Tag color="magenta" bordered={false} style={{position:'relative', top:'-23px', left:'0px'}}>default</Tag>

              </Box>
              {emailTemplates
              .filter(template => template.action === 'On Application') // Filter templates where action is 'accepted'
              .map((template, index) => (
              <Box key={template.id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} shadow={'lg'} style={expandedItems[template.id] ? {} : { position: 'relative', minHeight:'250px', maxHeight:'250px', overflow: 'hidden' }}>
              <Flex direction="column" h="100%">
                <Flex align="center" mb={0}>
                  <Box w={'100%'}>
                  {/* <Text fontSize={'sm'} color={'gray'}>subbject</Text> */}
                    <Text fontWeight="bold" mb={1}>{template.subject}</Text>                    
                    <Box style={expandedItems[template.id] ? {} : { position: 'relative', maxHeight: '150px', overflow: 'hidden' }}>
                    {!expandedItems[template.id] && (
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="120px" /* Height of the fade effect */
                        background="linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%)" /* Adjust the color to match your background */
                      />
                    )}
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                   {template.email_body}
                    </Text>
                    </Box>
                    <Text mt={3} cursor="pointer" justify="center" textAlign={'center'} onClick={() => toggleExpand(template.id)}>
                    {expandedItems[template.id] ? 'Show less' : 'Show more'}
                </Text>
                  </Box>
                </Flex>
              </Flex>
              </Box>
              ))}
              </SimpleGrid>

              <Heading pt={8} size="sm" color={'green'} >
              When application is accepted
              </Heading>
              <Text mt={'5px'} mb={4} fontSize={'sm'}  color={'gray'}>
              These emails are sent to the applicant once you've accepted their application.
              </Text>

              <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={6}>
              <Box key={organization.default_accept_application_email.id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} shadow={'lg'} style={expandedItems[organization.default_accept_application_email.id] ? {} : { position: 'relative', minHeight:'250px', maxHeight:'250px', overflow: 'hidden' }}>
              <Flex direction="column" h="100%">
                <Flex align="center" mb={0}>
                  <Box w={'100%'}>
                  {/* <Text fontSize={'sm'} color={'gray'}>subbject</Text> */}
                    <Text fontWeight="bold" mb={1}>{organization.default_accept_application_email.subject}</Text>                    
                    <Box style={expandedItems[organization.default_accept_application_email.id] ? {} : { position: 'relative', maxHeight: '150px', overflow: 'hidden' }}>
                    {!expandedItems[organization.default_accept_application_email.id] && (
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="120px" /* Height of the fade effect */
                        background="linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%)" /* Adjust the color to match your background */
                      />
                    )}
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                   {organization.default_accept_application_email.email_body}
                    </Text>
                    </Box>
                    <Text mt={3} cursor="pointer" justify="center" textAlign={'center'} onClick={() => toggleExpand(organization.default_accept_application_email.id)}>
                    {expandedItems[organization.default_accept_application_email.id] ? 'Show less' : 'Show more'}
                </Text>
                  </Box>
                </Flex>
              </Flex>
              <Tag color="magenta" bordered={false} style={{position:'relative', top:'-23px', left:'0px'}}>default</Tag>
              </Box>
              {emailTemplates
              .filter(template => template.action === 'Application Accepted') // Filter templates where action is 'accepted'
              .map((template, index) => (
              <Box key={template.id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} shadow={'lg'} style={expandedItems[template.id] ? {} : { position: 'relative', minHeight:'250px', maxHeight:'250px', overflow: 'hidden' }}>
              <Flex direction="column" h="100%">
                <Flex align="center" mb={0}>
                  <Box w={'100%'}>
                  {/* <Text fontSize={'sm'} color={'gray'}>subbject</Text> */}
                    <Text fontWeight="bold" mb={1}>{template.subject}</Text>                    
                    <Box style={expandedItems[template.id] ? {} : { position: 'relative', maxHeight: '150px', overflow: 'hidden' }}>
                    {!expandedItems[template.id] && (
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="120px" /* Height of the fade effect */
                        background="linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%)" /* Adjust the color to match your background */
                      />
                    )}
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                   {template.email_body}
                    </Text>
                    </Box>
                    <Text mt={3} cursor="pointer" justify="center" textAlign={'center'} onClick={() => toggleExpand(template.id)}>
                    {expandedItems[template.id] ? 'Show less' : 'Show more'}
                </Text>
                  </Box>
                </Flex>
              </Flex>
              </Box>
              ))}
              </SimpleGrid>

              <Heading pt={8} size="sm" color={'red'} >
              When application is rejected
              </Heading>
              <Text mt={'5px'} mb={4} fontSize={'sm'}  color={'gray'}>
              These emails are sent to the applicant after you've rejected their application.
              </Text>

              <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} spacing={6}>
              <Box key={organization.default_reject_application_email.id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} shadow={'lg'} style={expandedItems[organization.default_reject_application_email.id] ? {} : { position: 'relative', minHeight:'250px', maxHeight:'250px', overflow: 'hidden' }}>
              <Flex direction="column" h="100%">
                <Flex align="center" mb={0}>
                  <Box w={'100%'}>
                  {/* <Text fontSize={'sm'} color={'gray'}>subbject</Text> */}
                    <Text fontWeight="bold" mb={1}>{organization.default_reject_application_email.subject}</Text>                    
                    <Box style={expandedItems[organization.default_reject_application_email.id] ? {} : { position: 'relative', maxHeight: '150px', overflow: 'hidden' }}>
                    {!expandedItems[organization.default_reject_application_email.id] && (
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="120px" /* Height of the fade effect */
                        background="linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%)" /* Adjust the color to match your background */
                      />
                    )}
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                   {organization.default_reject_application_email.email_body}
                    </Text>
                    </Box>
                    <Text mt={3} cursor="pointer" justify="center" textAlign={'center'} onClick={() => toggleExpand(organization.default_reject_application_email.id)}>
                    {expandedItems[organization.default_reject_application_email.id] ? 'Show less' : 'Show more'}
                </Text>
                  </Box>
                </Flex>
              </Flex>
              <Tag color="magenta" bordered={false} style={{position:'relative', top:'-23px', left:'0px'}}>default</Tag>
              </Box>
              {emailTemplates
              .filter(template => template.action === 'Application Rejected') // Filter templates where action is 'accepted'
              .map((template, index) => (
              <Box key={template.id} borderWidth="1px" borderRadius="lg" overflow="hidden" p={4} shadow={'lg'} style={expandedItems[template.id] ? {} : { position: 'relative', minHeight:'250px', maxHeight:'250px', overflow: 'hidden' }}>
              <Flex direction="column" h="100%">
                <Flex align="center" mb={0}>
                  <Box w={'100%'}>
                  {/* <Text fontSize={'sm'} color={'gray'}>subbject</Text> */}
                    <Text fontWeight="bold" mb={1}>{template.subject}</Text>                    
                    <Box style={expandedItems[template.id] ? {} : { position: 'relative', maxHeight: '150px', overflow: 'hidden' }}>
                    {!expandedItems[template.id] && (
                      <Box
                        position="absolute"
                        bottom="0"
                        left="0"
                        right="0"
                        height="120px" /* Height of the fade effect */
                        background="linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%)" /* Adjust the color to match your background */
                      />
                    )}
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                   {template.email_body}
                    </Text>
                    </Box>
                    <Text mt={3} cursor="pointer" justify="center" textAlign={'center'} onClick={() => toggleExpand(template.id)}>
                    {expandedItems[template.id] ? 'Show less' : 'Show more'}
                </Text>
                  </Box>
                </Flex>
              </Flex>
              </Box>
              ))}
              </SimpleGrid>



              </Box>
              </TabPanel>
          </TabPanels>
                 )}
      </Tabs>
            
      </Card>
      </Box>
    </Box>
   

<Modal isOpen={isAddSchoolModalOpen} onClose={onAddSchoolModalClose} >
      <ModalOverlay />
      <ModalContent maxWidth="40vw" > {/* Responsive width: 100% on small screens, 50% on larger screens */}
        <ModalHeader>Add School Site

          <Text fontSize={'sm'} color={'gray'} >Fill out the form below to add a new school site for your organization.</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} ref={modalRef} >



      
            <Flex w={'100%'} justifyContent={'center'} textAlign={'center'}>
            <Upload
              name="avatar"
              listType="picture-circle"
              className="avatar-uploader"
              showUploadList={false}
              action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
              beforeUpload={beforeUpload}
              onChange={handleChange}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{
                    width: '100%',
                    borderRadius:'50%'
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>


            
            </Flex>


          <Box mt={8}>
            <Flex justifyContent={'space-between'}>
            <FormControl w={'100%'}>
            <FormLabel mb={'4px'} >Name</FormLabel>
            <Input required size='large' placeholder="School Name" value={newSchoolName} onChange={(e) => setNewSchoolName(e.target.value)} />
          </FormControl>
          <FormControl paddingLeft={4}>
          <FormLabel mb={'4px'} >Type</FormLabel>

          <Select
          style={{
            width: '100%',
            zIndex:999,
          }}
          placeholder='Select school type'
          getPopupContainer={() => modalRef.current || document.body}
          size='large'
          onChange={handleSelectedCharterSchoolType}
          options={[
            {
              value: 'Charter Public School',
              label: 'Charter Public School',
            },
            {
              value: 'Traditional Public School',
              label: 'Traditional Public School',
            },
            {
              value: 'Private School',
              label: 'Private School',
            },
          ]}
        />
          </FormControl>
          </Flex>


          <FormControl id="text4" mt={6} mb={10} >
          <FormLabel mb={'4px'} >School Address</FormLabel>
          <GoogleMapComponent handleLocationSetting={handleLocationSetting} handleCityAndState={handleCityAndState}/>
        </FormControl>

        <Flex justifyContent={'space-between'} pt={8} >
        <FormControl w={'100%'} pr={5}>
            <FormLabel mb={'4px'} >Grades Served</FormLabel>
            <Select
             placeholder='Select grades served'
          style={{
            width: '100%',
          }}
          getPopupContainer={() => modalRef.current || document.body}
          size='large'
          onChange={handleSelectedGradesServed}
          options={[
            {
              value: 'K - 5',
              label: 'K - 5',
            },
            {
              value: 'K - 6',
              label: 'K - 6',
            },
            {
              value: 'K - 8',
              label: 'K - 8',
            },
            {
              value: 'K - 12',
              label: 'K - 12',
            },
            {
              value: '6 - 8',
              label: '6 - 8',
            },
            {
              value: '6 - 12',
              label: '6 - 12',
            },
            {
              value: '9 - 12',
              label: '9 - 12',
            },
            {
              value: 'Other',
              label: 'Other',
            },
          ]}
        />

          </FormControl>
          <FormControl w={'100%'} pr={5}>
            <FormLabel mb={'4px'} >Enrollment</FormLabel>
            <Input size='large' required placeholder="Enrollment" value={newSchoolEnrollment} 
            onChange={(e) => {
              const value = e.target.value;
              // Use a regular expression to allow only numeric input
              if (!value || value.match(/^\d+$/)) {
                setNewSchoolEnrollment(value);
              }
            }} 
            
            />
          </FormControl>
          <FormControl w={'100%'}>
            <FormLabel mb={'4px'} >Year Founded</FormLabel>
            <Input size='large' required placeholder="Year founded ..." value={newSchoolYearFounded} 
            onChange={(e) => {
              const value = e.target.value;
              // Use a regular expression to allow only numeric input
              if (!value || value.match(/^\d+$/)) {
                setNewSchoolYearFounded(value);
              }
            }} 
           />
          </FormControl>
          
        </Flex>



          <FormControl w={'100%'} pt={8}>
            <FormLabel mb={'4px'} >About</FormLabel>
            <TextArea
            style={{fontSize:"1rem"}}
                value={newSchoolAbout}
                placeholder = "Tell us about your school ..."
                autoSize={{ minRows: 3, maxRows: 5 }}
                onChange={(e) => setNewSchoolAbout(e.target.value)} // Correctly handle the event object
              />  
          </FormControl>



          <FormControl id="text5" pt={8} >
          <FormLabel mb={'4px'} >Culture Photos</FormLabel>
          <UploadMultipleImages onImagesChange={handleImagesChange}/>
        </FormControl>


        <FormControl id="text5" pt={8} >
          <FormLabel mb={'4px'} >Recruiting Admins</FormLabel>
          <Select
          mode="multiple"
          size={'large'}
          style={{ width: '100%', borderRadius: '0.375rem' }}
          placeholder="Select which team members should be admins for this school site."
          value={[...newSchoolUsers]} // Ensure this is an array
          onChange={handleNewSchoolUsers}
          optionLabelProp="label"
          options={teamMembers.map((member) => ({
            value: member.id, // Assuming each team member has a unique id
            label: `${member.user.first_name} ${member.user.last_name} (${member.user.email})`, // Adjust according to your data structure
          }))}
          getPopupContainer={() => modalRef.current || document.body}
        
        />
        </FormControl>
        



        </Box>        

        </ModalBody>

        <ModalFooter>
          <Button  mr={3} onClick={onInviteModalClose}>Cancel</Button>
          <Button
            disabled={creatingSchoolSite} // Disable the button while submitting
          colorScheme="blue" onClick={() => createNewSchool( newSchoolName, newSchoolType,  newSchoolAddress, newSchoolGradesServed, newSchoolEnrollment, newSchoolYearFounded, newSchoolAbout, newSchoolImages, imageUrl, cityAndState, newSchoolUsers)}>
            
          {creatingSchoolSite ? (
            <>
            <Spinner size="xs" mr={2} /> {/* Show a spinner next to the text */}
            Sending...
            </>
        ) : (
            <Text>Save</Text>
        )}  



          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

    <Modal isOpen={isInviteModalOpen} onClose={onInviteModalClose}>
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader>Invite Team Member</ModalHeader> */}
        <ModalCloseButton />
        <ModalBody pb={6} ref={modalRef}>


        <Flex justifyContent={'center'} mt={9} mb={3}>
                    <Box w={'50px'} h={'50px'} justifySelf={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={'#BEE3F8'}>
                    <FiSend color='#2A4365' style={{marginRight:'5px'}} fontSize={'20px'}/>
                    </Box>
                    </Flex>



            <Text fontWeight={'bold'} textAlign={'center'} fontSize={'lg'}>
                 Invite Team Member
            </Text>
            <Text fontSize={'sm'} textAlign={'center'} color={'gray'}>
                Fill out the form below to invite new team members to your organization's account.
             </Text>

        <Box mt={8}>
            <Flex justifyContent={'space-between'}>
            <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >First Name</FormLabel>
            <Input required placeholder="First Name" value={inviteFirstName} onChange={(e) => setInviteFirstName(e.target.value)} />
          </FormControl>

          <FormControl w={'48%'}>
            <FormLabel mb={'4px'} >Last Name</FormLabel>
            <Input required placeholder="Last Name" value={inviteLastName} onChange={(e) => setInviteLastName(e.target.value)} />
          </FormControl>
            </Flex>

          <FormControl mt={4}>
          <FormLabel mb={'4px'} >Email</FormLabel>
          <Input required placeholder="Email" value={inviteEmail} onChange={(e) => setInviteEmail(e.target.value)} />
          </FormControl>

        </Box>

        </ModalBody>

        <ModalFooter>
          <Button  mr={3} onClick={onInviteModalClose}>Cancel</Button>
          <Button
            disabled={sendingInvite} // Disable the button while submitting
          colorScheme="blue" onClick={() => inviteTeamMember( inviteEmail, inviteLastName, inviteFirstName)}>
            
          {sendingInvite ? (
            <>
            <Spinner size="xs" mr={2} /> {/* Show a spinner next to the text */}
            Sending...
            </>
        ) : (
            <Text>Send Invite</Text>
        )}  



          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>



    <Modal isOpen={isNewTemplateModalOpen} onClose={onNewTemplateModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pb={6} ref={modalRef}>
        <Flex justifyContent={'center'} mt={9} mb={3}>
                    <Box w={'50px'} h={'50px'} justifySelf={'center'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'50%'} bg={'#BEE3F8'}>
                    <FiMail color='#2A4365' style={{marginRight:'5px'}} fontSize={'20px'}/>
                    </Box>
                    </Flex>



            <Text fontWeight={'bold'} textAlign={'center'} fontSize={'lg'}>
                 Create New Email Template
            </Text>
            <Text fontSize={'sm'} textAlign={'center'} color={'gray'}>
                Add new template details below.
             </Text>

        <Box mt={6}>
            <FormControl w={'100%'}>
            <FormLabel mb={'4px'} >Action</FormLabel>
            <Select
             placeholder='Select action that triggers email'
          style={{
            width: '100%',
          }}
          getPopupContainer={() => modalRef.current || document.body}
          size='large'
          onChange={handleEmailTemplateActionChange}
          options={[
            {
              value: 'On Application',
              label: 'On Application',
            },
            {
              value: 'Application Accepted',
              label: 'Application Accepted',
            },
            {
              value: 'Application Rejected',
              label: 'Application Rejected',
            },
            
          ]}
        />
                  </FormControl>

                  <FormControl mt={6} w={'100%'}>
            <FormLabel mb={'4px'} >Template name</FormLabel>
            <Input required     size='large'
 placeholder="Give your template a unique name" value={emailTemplateName} onChange={(e) => setEmailTemplateName(e.target.value)} />
          </FormControl>


          <FormControl mt={6} w={'100%'}>
            <FormLabel mb={'4px'} >Subject line</FormLabel>
            <Input required           size='large'
 placeholder="Thanks for applying to this role" value={emailTemplateSubjectLine} onChange={(e) => setEmailTemplateSubjectLine(e.target.value)} />
          </FormControl>

          <FormControl mt={6}>
            <Flex  alignItems={'flex-start'} justifyContent={'space-between'}>
            <FormLabel alignSelf={'flex-start'} >Email message
            </FormLabel>

            <Select
            variant={'filled'}
                getPopupContainer={() => modalRef.current || document.body}
        size='small'
        defaultValue= {defaultAddVariableText}
        value={defaultAddVariableText}
        style={{
          zIndex:999,
          width:'min-content'
        }}
        dropdownMatchSelectWidth={false} // This allows customizing the dropdown width

        dropdownStyle={{
          minWidth: 'auto', // Adjust the dropdown width based on its content
        }}
        onChange={insertVariable}
        options={variableOptions}

      />

            </Flex>
          <TextArea
        value={emailBody}
        placeholder="Thanks for applying to this role, we'll be in touch within 48 hours with next steps ..."
        autoSize={{ minRows: 6, maxRows: 16 }}
        onChange={handleEmailMessageChange}
        style={{ marginBottom: '10px' }}
        id="your-text-area"
        size='large'
      />

     




          </FormControl>

        </Box>

        </ModalBody>

        <ModalFooter>
          <Button  mr={3} onClick={onInviteModalClose}>Cancel</Button>
          <Button
            disabled={sendingInvite} // Disable the button while submitting
          colorScheme="blue" onClick={() => createNewTemplate( emailTemplateAction, emailTemplateSubjectLine, emailBody, emailTemplateName)}>
            
          {sendingInvite ? (
            <>
            <Spinner size="xs" mr={2} /> {/* Show a spinner next to the text */}
            Saving...
            </>
        ) : (
            <Text>Save</Text>
        )}  
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

    </ChakraProvider>

  );
}

export default TeamSettings;
